import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import { DialogContent } from "components/Shared/Dialog";
import { PendingReleaseContext } from "contexts/PendingReleaseContext";
import { useCallback, useContext, useMemo, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { TruckIcon } from "styles/components";
import { Status } from "components/Pages/Invoices";

import * as S from "./styles";
import { Formatter } from "utils";
import { UpdateInvoiceBatchPayload } from "contracts/Invoice";
import { ReleaseInvoicesToTrackingActions } from "store/ducks/pendingRelease";
import { Input, Select } from "components/Shared";
import { useVehicleTypes } from "hooks/Selectors/vehicleTypes";
import { InputFile } from "components/Shared/Forms/InputFile";
import { useValidation } from "hooks";
import { ReleaseToTrackingValidator } from "validators/ReleaseToTracking";

const ReleaseToTrackingModal = () => {
  const {
    refModal,
    selectedList,
    onQueryChange,
    clearSelectList,
    changeOpenModal,
  } = useContext(PendingReleaseContext);
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { handleFormErrors } = useValidation();

  const { loading } = useSelector(
    (state: RootState) => state.releaseInvoicesToTracking
  );

  const { fetchVehicleTypes, vehicleTypeOptions } = useVehicleTypes();

  const formattedList = useMemo(() => {
    return selectedList.map((invoice) => ({
      id: invoice.id,
      nf: `${invoice.infnfeIdeNnf}-${invoice.infnfeIdeSerie}`,
      carrier: invoice.carrier?.tradeName || "---",
      dhEmi: Formatter.date(invoice.infnfeIdeDhemi, {
        format: "dd/MM/yyyy HH:mm",
      }),
      prev: invoice.deadlineDate
        ? Formatter.date(invoice.deadlineDate, { format: "dd/MM/yyyy HH:mm" })
        : "---",
      status:
        invoice.status.length > 0
          ? {
              name: invoice.status[0].name,
              color: invoice.status[0].color,
            }
          : null,
    }));
  }, [selectedList]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data: any): Promise<void> => {
      try {
        const { file, ...rest } = data;
        const payloadData: UpdateInvoiceBatchPayload = {
          ids: selectedList.map((invoice) => invoice.id),
          file: file ? file?.item(0) : null,
          ...rest,
        };

        formRef?.current?.setErrors({});
        const { schema } = new ReleaseToTrackingValidator();

        const validData = await schema.validate(payloadData, {
          abortEarly: false,
        });

        formRef.current?.setErrors({});

        dispatch(
          ReleaseInvoicesToTrackingActions.request(validData, () => {
            onQueryChange({}, true);
            clearSelectList();
            changeOpenModal();
          })
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [changeOpenModal, clearSelectList, dispatch, onQueryChange, selectedList]
  );

  useEffect(() => {
    fetchVehicleTypes();
  }, [fetchVehicleTypes]);

  return (
    <DialogContent
      container={refModal}
      title="Liberar para tracking as notas listadas abaixo"
      icon={<TruckIcon />}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.Content>
          <S.ListHeader>
            <span>NF</span>
            <span>Transportadora</span>
            <span>DT. Emissão</span>
            <span>Data Prevista</span>
            <span>Status</span>
          </S.ListHeader>
          {formattedList.map((invoice) => (
            <S.ListItem key={invoice.id}>
              <span>{invoice.nf}</span>
              <span>{invoice.carrier}</span>
              <span>{invoice.dhEmi}</span>
              <span>{invoice.prev}</span>
              <span>
                {invoice.status ? (
                  <Status color={invoice.status.color}>
                    {invoice.status.name}
                  </Status>
                ) : (
                  <Status>Sem status</Status>
                )}
              </span>
            </S.ListItem>
          ))}
        </S.Content>
        <S.Footer>
          <InputFile
            name="file"
            type="file"
            label="Anexar Arquivo"
            accept=".xml, .pdf, .png, .jpeg, .txt"
            compact={true}
            multiple
          />
          <Input name="note" placeholder="Observação..." />
          <Select
            name="vehicleTypeId"
            placeholder="Tipo de Veículo"
            options={vehicleTypeOptions}
            isClearable
          />
          <Input name="vehicle" placeholder="Veículo..." />
          <S.Button type="submit" disabled={loading}>
            {loading ? `Liberando...` : `Liberar`}
          </S.Button>
        </S.Footer>
      </Form>
    </DialogContent>
  );
};

export { ReleaseToTrackingModal };
