import { Scaffold } from 'layouts';
import {
    CollectView
} from 'components/Pages/ReverseLogistics';
import React, {
    useCallback,
    useEffect,
  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import {
    FetchCollectActions as FetchActions,
} from 'store/ducks/collects';
import { useParams } from 'react-router-dom';
// import { HomologAlert } from 'components/Shared';

export const CollectViewPage: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { id: collectId } = useParams() as { id: string };

    const { data: collect, loading: fetchingCollect } = useSelector(
        (state: RootState) => state.fetchCollect
    );

    const fetchCollect = useCallback((): void => {
    dispatch(FetchActions.request(collectId));
    }, [dispatch, collectId]);

    useEffect(() => {
    fetchCollect();
    }, [fetchCollect]);

    return (
        <Scaffold>
            {/* <HomologAlert /> */}
            {collect && <CollectView collect={collect} />}
        </Scaffold>
    )
}