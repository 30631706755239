import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import ListPerfis from 'pages/Settings/Perfis/List';
import CreatePerfil from 'pages/Settings/Perfis/Create';
import UpdatePerfil from 'pages/Settings/Perfis/Update';
import { Scaffold } from 'layouts';

const perfisRoutes = ({
    location,
    userBelongsToAnyOf,
    hasPermissionTo
}: RouterProps): RouteObject => ({
    path: '/configuracoes/perfis',
    element: hasPermissionTo(['PERMISSIONS.GETROLES']) ? (
        <Scaffold>
            <Outlet />
        </Scaffold>
    ) : (
        <Navigate to="/" state={{ from: location }} />
    ),
    children: [
        {
            path: '',
            element: <ListPerfis />,
        },
        {
            path: 'criar',
            element: <CreatePerfil />,
        },
        {
            path: 'edit/:id',
            element: <UpdatePerfil />,
        },
    ],
});

export default perfisRoutes;
