import React, { useContext } from 'react';
import { Paginator } from 'components/Shared';
import { Scaffold } from 'layouts';
import * as S from './styles';
import {
  CollectsList,
  CollectFilter,
  Navigation,
} from 'components/Pages/ReverseLogistics';
import { ReverseLogisticContext } from 'contexts/ReverseLogisticContext';
import { Loading } from 'components/Shared/Loading';
import { useAuth } from 'hooks';
import { Sheet } from 'lucide-react';
import { usePermission } from "hooks/usePermission";

export const CollectsListPage: React.FC = () => {
//   const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission()
  const {
    onPageChange,
    onSort,
    pagination,
    loading,
    handleExport,
    loadingExport,
  } = useContext(ReverseLogisticContext);

  return (
    <Scaffold>
      <S.Container>
        {/* <HomologAlert /> */}
        <Navigation />
        <S.Panel>
          <S.ButtonsWrapper>
            <S.Button mood="secondary" size="small" onClick={handleExport}>
              {loadingExport && <Loading />}
              {!loadingExport && <Sheet size={20} />}
              Exportar
            </S.Button>
            {/* {!userBelongsToAnyOf('carrierMember') && (
              <S.LinkButton to={'/logistica-reversa/criar'} size="small">
                <S.PlusIcon /> Nova coleta
              </S.LinkButton>
            )} */}
          </S.ButtonsWrapper>
          <S.FilterContainer>
            <CollectFilter />
            {loading && <Loading />}
            {hasPermissionTo(["REVERSE-LOGISTICS.CREATEREVERSELOGISTIC"]) && (
              <S.LinkButton to={"/logistica-reversa/criar"} size="small">
                <S.PlusIcon /> Nova coleta
              </S.LinkButton>
            )}
          </S.FilterContainer>
          <CollectsList onSort={onSort} />
          <Paginator onPageChange={onPageChange} pagination={pagination} />
        </S.Panel>
      </S.Container>
    </Scaffold>
  );
};
