import styled from "styled-components" 

export const HomologAlert = styled.div `
  padding: 16px 30px;
  background-color: #ffd900;
  border-radius: 6px;
  margin: -10px 0 0 0;
  font-size: 14px;
  text-align: center;
  border: 1px #838383 dashed;
  text-transform: uppercase;
`