import * as Yup from 'yup';
import {
  getAuditorShape,
  Props,
} from 'validators/Common/baseAuditorValidator';

export class CreateAuditorValidator {
  constructor(protected readonly props: Props) {}

  public get schema() {
    return Yup.object().shape({
      ...getAuditorShape(this.props),
    });
  }
}
