import { call, put } from '@redux-saga/core/effects';
import { notify, apiErrorHandler, api } from 'services';
import {
  FetchPublicInvoiceActions as Actions,
  FetchPublicInvoiceRequestAction as RequestAction,
} from 'store/ducks/publicInvoice';

export function* fetchPublicInvoiceRequest(action: any) {
  const { id, onSuccess, onFailure }: RequestAction = action;
  
  try {
    const { data } = yield call(api.get, `invoices/public/${id}`);
    onSuccess && onSuccess();
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
