import { ClientUpdateForm } from 'components/Pages/Clients';
import { Scaffold } from 'layouts';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const ClientUpdatePage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();

  const onUpdate = useCallback((): void => {
    navigate('/configuracoes/clientes');
  }, [navigate]);

  return (
    <Scaffold>
      <ClientUpdateForm clientId={id} onUpdate={onUpdate} />
    </Scaffold>
  );
};
