import { useState, useCallback, useEffect, useRef } from "react";
import { Formatter } from "utils";
import * as S from "./styles";
import { BookIcon } from "styles/components";
import { InvoiceItem } from "contracts/InvoiceItem";
import { InvoiceItemBatch } from "contracts/InvoiceItemBatch";
import { Invoice } from "contracts/Invoice";

type IBatches = [string | null, string | null, InvoiceItemBatch[]];

interface IInvoice {
  invoice: Invoice | null;
}

export const ItemInfo = ({ invoice }: IInvoice) => {
  const [taxOpen, setTaxOpen] = useState<number>(0);
  const [batches, setBatches] = useState<IBatches | null>();
  const [items, setItems] = useState<InvoiceItem[]>([]);
  const ref = useRef(null);

  const ModalBatch = useCallback(() => {
    if (!batches) {
      return <></>;
    }
    const [code, name, batch] = batches;
    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <BookIcon />
            {`${code} - ${name}`}
            <S.Close
              onClick={() => {
                setBatches(null);
              }}
            />
          </S.ModalTitle>
          <S.ModalGridTitle>
            <S.GridTitle>LOTE</S.GridTitle>
            <S.GridTitle>QTD/LOTE</S.GridTitle>
            <S.GridTitle>DT. FAB</S.GridTitle>
            <S.GridTitle>DT. VENC</S.GridTitle>
          </S.ModalGridTitle>
          {batch.map((item: any) => {
            const {
              id,
              rastroNlote: batch,
              rastroQlote: quantity,
              rastroDfab: manufacture,
              rastroDval: validity,
            } = item;
            return (
              <S.ModalGrid key={id}>
                <S.Column>{batch ? batch : "---"}</S.Column>
                <S.Column>{quantity ? quantity : "---"}</S.Column>
                <S.Column>
                  <S.Column>
                    {manufacture ? Formatter.date(manufacture) : "--/--/----"}
                  </S.Column>
                </S.Column>
                <S.Column>
                  {validity ? Formatter.date(validity) : "--/--/----"}
                </S.Column>
              </S.ModalGrid>
            );
          })}
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [batches]);

  const BoxTax = useCallback(() => {
    if (!items.length) {
      return <></>;
    }
    return (
      <S.MainGrid>
        <S.BoxHeader>
          <S.BoxTitle>Código</S.BoxTitle>
          <S.BoxTitle>Nome</S.BoxTitle>
          <S.BoxTitle>Pedido</S.BoxTitle>
          <S.BoxTitle>Qtd</S.BoxTitle>
          <S.BoxTitle>Valor UN</S.BoxTitle>
          <S.BoxTitle>Valor Total</S.BoxTitle>
        </S.BoxHeader>
        {items.map((item) => {
          const {
            id,
            prodCprod: code,
            prodXprod: name,
            prodXped: request,
            prodUcom: unit,
            prodQcom: quantity,
            prodVuncom: unitValue,
            prodVprod: amount,
            itemBatch,
            impostoIcmsIcms20Vicms: icms,
            impostoPisPisaliqVpis: pis,
            impostoCofinsCofinsaligVcofins: cofins,
            impostoIpiIpitribVipi: ipi,
          } = item;

          return (
            <S.Grid key={id}>
              <S.Column>{code}</S.Column>
              <S.Column>{name}</S.Column>
              <S.Column>{request || "----"}</S.Column>
              <S.Column>
                [ {unit} ] {quantity}
              </S.Column>
              <S.Column>
                {unitValue &&
                  unitValue.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
              </S.Column>
              <S.Column>
                {amount &&
                  amount.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
              </S.Column>
              <S.Column>
                <S.ContentIcon
                  className={itemBatch.length ? "" : "disabled"}
                  onClick={() => {
                    if (itemBatch.length) {
                      setBatches([code, name, itemBatch]);
                    }
                  }}
                />
              </S.Column>
              <S.Info>
                <S.Detail key={id} className={id === taxOpen ? "" : "none"}>
                  <S.DetailInfo className="start">
                    ICMS:
                    <p>
                      {icms &&
                        icms.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                    </p>
                  </S.DetailInfo>
                  <S.DetailInfo>
                    PIS:
                    <p>
                      {pis &&
                        pis.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                    </p>
                  </S.DetailInfo>
                  <S.DetailInfo>
                    COFINS:
                    <p>
                      {cofins &&
                        cofins.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                    </p>
                  </S.DetailInfo>
                  <S.DetailInfo>
                    IPI:
                    <p>
                      {ipi &&
                        ipi.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                    </p>
                  </S.DetailInfo>
                </S.Detail>
                <S.TaxIcon
                  className={icms || pis || cofins || ipi ? "" : "disabled"}
                  ref={ref}
                  onClick={() => {
                    if (icms || pis || cofins || ipi) {
                      setTaxOpen(taxOpen === item.id ? 0 : item.id);
                    }
                  }}
                />
              </S.Info>
            </S.Grid>
          );
        })}
      </S.MainGrid>
    );
  }, [items, taxOpen, setTaxOpen, ref]);

  useEffect(() => {
    if (invoice) setItems(invoice.items);
  }, [invoice]);

  useEffect(() => {
    const close = (e: any) => {
      const clickedPath = `${
        e.path[0]?.tagName === "svg" || e.path[0]?.tagName === "path" ? "svg" : ""
      }`;
      // @ts-ignore
      const clickedRef = `${ref.current.tagName}`;

      if (clickedPath !== clickedRef) {
        setTaxOpen(0);
      }
    };
    document.addEventListener("click", close);
    return () => {
      document.removeEventListener("click", close);
    };
  }, []);

  if (!invoice) {
    return <></>;
  }

  const {
    volume,
    totalIcmstotVnf: total,
    totalIcmstotVicms: icms,
    totalIcmstotVpis: pis,
    totalIcmstotVcofins: cofins,
    totalIcmstotVipi: ipi,
  } = invoice;

  const totalPrice = total.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
  const totalIcms = icms.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
  const totalPis = pis.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
  const totalCofins = cofins.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
  const totalIpi = ipi.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });

  return (
    <S.Container>
      {batches?.length && <ModalBatch />}
      <S.Header>
        <S.Box>
          Valor total
          <S.Value>{totalPrice}</S.Value>
        </S.Box>
        <S.Box>
          Peso líquido
          <S.Value>{volume ? `${volume?.transpvol_peso_l} KG` : "---"}</S.Value>
        </S.Box>
        <S.Box>
          Peso bruto
          <S.Value>{volume ? `${volume?.transpvol_peso_b} KG` : "---"}</S.Value>
        </S.Box>
        <S.Box>
          Total ICMS
          <S.Value>{totalIcms}</S.Value>
        </S.Box>
        <S.Box>
          Total IPI
          <S.Value>{totalIpi}</S.Value>
        </S.Box>
        <S.Box>
          Total PIS
          <S.Value>{totalPis}</S.Value>
        </S.Box>
        <S.Box>
          Total COFINS
          <S.Value>{totalCofins}</S.Value>
        </S.Box>
      </S.Header>
      <BoxTax />
    </S.Container>
  );
};
