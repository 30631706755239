import { ColorScheme, Fonts } from 'styles/constants';
import { BasePanel, CloseIcon } from 'styles/components';
import { BaseButtonStyle } from 'styles/components';
import { Colors } from 'styles/constants';
import styled from 'styled-components';
export {
    InvoiceIcon,
    ClientIcon,
    CompanyIconOutline,
    DetailIcon,
    TruckIcon,
    EditAltIcon,
    ListIcon,
    ArrowLeftIcon,
    CalendarIcon,
    AnchorButton,
} from 'styles/components';

export const Container = styled.div`
    display: block;
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    height: 400px;
    align-items: center;
    justify-content: center;
`;

export const Header = styled(BasePanel)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 15px;
    font-size: 14px;
    font-family: ${Fonts.GilroySemiBold};
    margin-bottom: 24px;
    margin-top: 24px;
    @media (max-width: 1035px) {
        display: grid;
        grid-template-rows: 0.5fr 1fr 0.5fr;
        height: auto;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
`;

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${Fonts.GilroyRegular};
    font-size: 16px;
    gap: 10px;
    white-space: nowrap;

    p {
        font-family: ${Fonts.GilroySemiBold};
        font-size: 14px;
    }
`;

export const Invoice = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
    white-space: nowrap;
    @media (max-width: 1035px) {
        flex-direction: row;
    }
`;

export const TitleInfo = styled.div`
    display: grid;
    grid-template-columns: auto auto 143px;
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: ${Fonts.GilroyRegular};
    font-size: 16px;
    gap: 10px;
    margin-bottom: 15px;
    border-bottom: 1px dashed ${Colors.Gray50};
`;

export const Button = styled.button`
    display: flex;
    ${BaseButtonStyle}
    background-color: ${Colors.Blue};
    color: ${Colors.White};
    font-family: ${Fonts.GilroySemiBold};
    font-size: 12px;
    padding: 3px 10px 3px 3px;
    justify-self: flex-end;
    border-radius: 4px;
    gap: 4px;
    white-space: nowrap;
`;

export const Back = styled(Button)`
    background-color: ${Colors.Gray70};
    height: 1.7rem;
    padding: 0.5rem 0.8rem;
`;

export const Show = styled(Button)`
    padding: 3px 10px 3px 3px;
`;

export const Box = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    span {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        p {
            position: absolute;
            top: 18px;
            font-size: 11px;
        }
    }
`;

export const Item = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 12px;
    font-family: ${Fonts.GilroySemiBold};
    gap: 10px;
    @media (max-width: 1080px) {
        flex-direction: column;
    }
`;
export const BoxHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-family: ${Fonts.GilroyRegular};
    font-size: 12px;
`;

export const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 20px;
`;

export const BoxBody = styled.div<{ justify?: string }>`
    display: flex;
    font-size: 12px;
    font-family: ${Fonts.OpenSans};
    color: ${ColorScheme.LightText};
    justify-content: ${({ justify }) => (justify ? justify : 'center')};
`;

export const BoxInfo = styled(BasePanel)`
    display: grid;
    grid-template-rows: 2fr repeat(6, 0.8fr);
    font-family: ${Fonts.GilroySemiBold};
    align-items: center;
    padding: 5px 20px 20px 20px;
    width: 342px;
    height: 230px;
`;

export const Info = styled.div`
    font-family: ${Fonts.GilroyRegular};
    width: auto;
    font-size: 12px;
`;

export const HeaderInfo = styled(BasePanel)`
    width: 100%;
    padding: 15px;
    font-size: 14px;
    font-family: ${Fonts.GilroySemiBold};
    margin-bottom: 24px;
    white-space: nowrap;

    div {
        table,
        tbody,
        thead {
            border: none !important;
        }
    }
`;

export const Close = styled(CloseIcon)`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    height: 22px;
    color: ${Colors.Gray50};
`;

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background-color: ${Colors.Black}cc;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px);
    z-index: 999;
    overflow: hidden;
`;

export const ModalContainer = styled(BasePanel)`
    position: relative;
    width: 700px;
    height: 570px;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    padding: 35px;
    animation: animateModalContainer 150ms;

    form {
        width: 100%;
    }

    @keyframes animateModalContainer {
        from {
            opacity: 0;
            transform: scale(0.96);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }
`;

export const ModalTitle = styled.div`
    display: flex;
    gap: 8px;
    font-family: ${Fonts.GilroyBold};
    font-size: 1.25rem;
    width: 100%;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid ${Colors.Gray30};
    padding-bottom: 22px;
    margin-bottom: 22px;
`;
