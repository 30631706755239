import { OperationCreationForm } from 'components/Pages/Operations';
import { Scaffold } from 'layouts';

export const OperationCreationPage = () => {
  return (
    <Scaffold>
      <OperationCreationForm />
    </Scaffold>
  );
};
