import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import { DialogContent } from "components/Shared/Dialog";
import { PendingReleaseContext } from "contexts/PendingReleaseContext";
import { useCallback, useContext, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { TruckIcon } from "styles/components";
import { Status } from "components/Pages/Invoices";
import { object, InferType, mixed } from "yup";

import * as S from "./styles";
import { Formatter } from "utils";
import { CreateCorrectionLetterPayload } from "contracts/Invoice";
import { InputFile } from "components/Shared/Forms/InputFile";
import { useValidation } from "hooks";
import { CreateCorrectionLetterActions } from "store/ducks/pendingRelease";

const SUPPORTED_FORMATS = ["application/pdf", "application/xml"];

const schema = object().shape({
  files: mixed()
    .required("Envio de arquivo obrigatório")
    .test(
      "fileType",
      "Os arquivos devem ser .PDF ou .XML",
      (value: FileList) => {
        return Array.from(value).every((file) =>
          SUPPORTED_FORMATS.includes(file.type)
        );
      }
    ),
});

type FormData = InferType<typeof schema>;

const CorrectionLetterModal = () => {
  const {
    refModal,
    selectedList,
    onQueryChange,
    clearSelectList,
    changeOpenModal,
  } = useContext(PendingReleaseContext);
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { handleFormErrors } = useValidation();

  const { loading } = useSelector(
    (state: RootState) => state.createCorrectionLetter
  );

  const formattedItem = useMemo(() => {
    const invoice = selectedList[0];
    return {
      id: invoice.id,
      nf: `${invoice.infnfeIdeNnf}-${invoice.infnfeIdeSerie}`,
      dhEmi: Formatter.date(invoice.infnfeIdeDhemi, {
        format: "dd/MM/yyyy HH:mm",
      }),
      carrierName: invoice.carrier?.tradeName || "---",
      status:
        invoice.status.length > 0
          ? {
              name: invoice.status[0].name,
              color: invoice.status[0].color,
            }
          : null,
    };
  }, [selectedList]);

  const handleSubmit = useCallback<SubmitHandler<FormData>>(
    async (dataForm) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const payloadData: CreateCorrectionLetterPayload = {
          invoiceId: formattedItem.id,
          files: dataForm.files,
        };

        dispatch(
          CreateCorrectionLetterActions.request(payloadData, () => {
            onQueryChange({}, true);
            clearSelectList();
            changeOpenModal();
          })
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      changeOpenModal,
      clearSelectList,
      dispatch,
      formattedItem.id,
      handleFormErrors,
      onQueryChange,
    ]
  );

  return (
    <DialogContent
      container={refModal}
      title="Enviar Carta de Correção da nota listada abaixo"
      icon={<TruckIcon />}
    >
      <Form ref={formRef} onSubmit={handleSubmit} encType="multipart/form-data">
        <S.Content>
          <S.ListHeader>
            <span>NF</span>
            <span>DT. Emissão</span>
            <span>Transportadora</span>
            <span>Status</span>
          </S.ListHeader>
          {formattedItem && (
            <S.ListItem key={formattedItem.id}>
              <span>{formattedItem.nf}</span>
              <span>{formattedItem.dhEmi}</span>
              <span>{formattedItem.carrierName}</span>
              <span>
                {formattedItem.status ? (
                  <Status color={formattedItem.status.color}>
                    {formattedItem.status.name}
                  </Status>
                ) : (
                  <Status>Sem status</Status>
                )}
              </span>
            </S.ListItem>
          )}
        </S.Content>
        <S.Footer>
          <InputFile
            name="files"
            type="file"
            label="Carta de Correção"
            multiple
            accept=".xml, .pdf"
          />
          <S.Button type="submit" disabled={loading}>
            {loading ? `Enviando...` : `Enviar`}
          </S.Button>
        </S.Footer>
      </Form>
    </DialogContent>
  );
};

export { CorrectionLetterModal };
