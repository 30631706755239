import { SortingParams } from "contracts/Common";
import { FindFinishedInvoices, PaginatedInvoice } from "contracts/Invoice";
import { Pagination } from "contracts/Pagination";
import { usePaginationCache } from "hooks";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  RefObject,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { PaginateFinishedInvoicesActions as PaginateActions } from "store/ducks/finishedInvoices";

export type ModalTypes = "justificativas";

interface FinishedContextType {
  finishedInvoices: PaginatedInvoice[];
  finishedInvoicesNotDelayed: PaginatedInvoice[];
  query: FindFinishedInvoices;
  pagination: Pagination | undefined;
  loading: boolean;
  showTrash: boolean;
  refModal: RefObject<HTMLDivElement>;
  openedModal: ModalTypes | null;
  changeOpenModal: (type?: ModalTypes) => void;
  clearSelectList: () => void;
  selectedList: PaginatedInvoice[];
  onToggleSelected: (invoice: PaginatedInvoice) => void;
  onPageChange: (page: number) => void;
  onSelectInvoices: (invoices: PaginatedInvoice[]) => void;
  onRemoveSelecteds: (ids: number[]) => void;
  onQueryChange: (newQuery?: FindFinishedInvoices, force?: boolean) => void;
  onSort: (newState: SortingParams) => void;
  reset: () => void;
  clearQuery: () => void;
}

export const FinishedContext = createContext({} as FinishedContextType);

interface FinishedInvoicesProviderProps {
  children: ReactNode;
}

const INITIAL_QUERY_STATE: FindFinishedInvoices = {
  page: 1,
  limit: 10,
};

export function FinishedInvoicesProvider({
  children,
}: FinishedInvoicesProviderProps) {
  const {
    paginationCache,
    updatePaginationCache,
    handleSort,
    deletePaginationCache,
  } = usePaginationCache<FindFinishedInvoices>("finishedInvoices");
  const [query, setQuery] = useState<FindFinishedInvoices>({
    page: 1,
    limit: 10,
    ...paginationCache,
  });
  const [selectedList, setSelectedList] = useState<PaginatedInvoice[]>([]);
  const [finishedInvoicesNotDelayed, setFinishedInvoicesNotDelayed] = useState<PaginatedInvoice[]>([]);
  const [showModal, setShowModal] = useState<ModalTypes | null>(null);
  const refModal = useRef<HTMLDivElement>(null);
  const dispatch: AppDispatch = useDispatch();
  
  const {
    data: finishedInvoices,
    pagination,
    loading,
  } = useSelector((state: RootState) => state.paginateFinishedInvoices);

  useEffect(() => {
    const arrayFinishedInvoicesNotDelayed: PaginatedInvoice[] = [];
    finishedInvoices.forEach((invoice: any) => {
      if(invoice.isDelayedLeadTime === false){
        arrayFinishedInvoicesNotDelayed.push(invoice)
      }
    });

    setFinishedInvoicesNotDelayed(arrayFinishedInvoicesNotDelayed);
    
  }, [finishedInvoices]);

  const showTrash = useMemo(
    () => JSON.stringify(query) !== JSON.stringify(INITIAL_QUERY_STATE),
    [query]
  );

  const changeOpenModal = useCallback((type?: ModalTypes) => {
    setShowModal(type ? type : null);
  }, [showModal]);

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback(
    (newQuery?: FindFinishedInvoices, force = false): void => {
      const search = newQuery?.invoiceNumber;
      if (search) {
        newQuery.invoiceNumber = search.replaceAll(" ", "|");
      }
      const newData = { ...query, ...newQuery };
      if (JSON.stringify(newData) !== JSON.stringify(query) || force) {
        setQuery((prev) => ({ ...prev, ...newQuery, page: 1 }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [query]
  );

  const onSelectInvoices = useCallback((invoices: PaginatedInvoice[]) => {
    setSelectedList((prev) => {
      const prevIDs = prev.map((invoice) => invoice.id);
      return [
        ...prev,
        ...invoices.filter((invoice) => !prevIDs.includes(invoice.id)),
      ];
    });
  }, []);

  const clearSelectList = useCallback(() => {
    setSelectedList([]);
  }, []);

  const onRemoveSelecteds = useCallback((ids: number[]) => {
    setSelectedList((prev) =>
      prev.filter((invoice) => !ids.includes(invoice.id))
    );
  }, []);

  const onToggleSelected = useCallback((invoice: PaginatedInvoice) => {
    setSelectedList((prev) => {
      const hasOnList = prev.find(
        (invoiceFind) => invoiceFind.id === invoice.id
      );

      if (hasOnList) {
        return prev.filter((invoiceFilter) => invoiceFilter.id !== invoice.id);
      }

      return [...prev, invoice];
    });
  }, []);
  
  const clearQuery = useCallback(() => setQuery(INITIAL_QUERY_STATE), []);

  function reset() {
    dispatch(PaginateActions.reset());
    updatePaginationCache(query);
  }

  const onSort = useCallback((newState: SortingParams) => {
    handleSort(query, newState, setQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(PaginateActions.request(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <FinishedContext.Provider
      value={{
        pagination,
        finishedInvoices,
        finishedInvoicesNotDelayed,
        loading,
        query,
        selectedList,
        onToggleSelected,
        showTrash,
        onPageChange,
        onQueryChange,
        refModal,
        changeOpenModal,
        clearSelectList,
        openedModal: showModal,
        onSort,
        reset,
        clearQuery,
        onRemoveSelecteds,
        onSelectInvoices
      }}
    >
      {children}
      <div ref={refModal} />
    </FinishedContext.Provider>
  );
}
