import { useContext, useRef } from "react";
import { Paginator } from "components/Shared";
import { Scaffold } from "layouts";
import * as S from "./styles";
import {
  DeliveredInvoicesList,
  DeliveredInvoicesFilter,
  Navigation,
  InvoiceModal,
  Ref as InvoiceModalRef,
} from "components/Pages/Invoices";
import { DeliveredContext } from "contexts/DeliveredContext";
import { Loading } from "components/Shared/Loading";
import { DownloadXLS } from "components/Shared/DownloadXLS";
import { DropdownMenuInvoices } from "components/Pages/Invoices/DeliveredInvoices/DropdownMenu";

export const ListDelivered = () => {
  const invoiceModalRef = useRef<InvoiceModalRef>(null);
  const {
    pagination,
    onPageChange,
    onSort,
    loading,
    query,
  } = useContext(DeliveredContext);

  return (
    <Scaffold>
      <S.Container>
        <Navigation />
        <S.Panel>
          <InvoiceModal ref={invoiceModalRef} />
          <S.FilterContainer>
            <DeliveredInvoicesFilter />
            {loading && <Loading />}
            <S.FilterContainerRight>
              <DownloadXLS
                archiveName="INBOUND-RELATORIO-ENTREGUES"
                query={query}
                type="Entregue"
              />
              <DropdownMenuInvoices />
            </S.FilterContainerRight>
          </S.FilterContainer>
          <DeliveredInvoicesList
            onSort={onSort}
            onSelectInvoice={(invoiceId) =>
              invoiceModalRef.current?.selectInvoice(invoiceId)
            }
          />
          <Paginator onPageChange={onPageChange} pagination={pagination} />
        </S.Panel>
      </S.Container>
    </Scaffold>
  );
};
