import { VehicleTypeCreationForm } from 'components/Pages/VehicleTypes';
import { Scaffold } from 'layouts';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const VehicleTypeCreationPage: React.FC = () => {
  const navigate = useNavigate();

  const onCreate = useCallback((): void => {
    navigate('/configuracoes/tipos-veiculo');
  }, [navigate]);

  return (
    <Scaffold>
      <VehicleTypeCreationForm onCreate={onCreate} />
    </Scaffold>
  );
};
