import styled from 'styled-components';
import { Colors, ColorScheme } from 'styles/constants';
import { FieldContainer } from 'styles/components';
export { FieldError, FieldLabel } from 'styles/components';

export const Container = styled(FieldContainer)``;

export const TextArea = styled.textarea`
  width: 100%;
  font-size: inherit;
  border-radius: 6px;
  background: ${Colors.White};
  border: 1px solid ${Colors.Gray50};
  color: ${ColorScheme.Text};
  height: 120px;
  padding: 8px;
`;