import { useCallback, useContext } from "react";
import type { SortingParams } from "contracts/Common";
import type { PaginatedInvoice } from "contracts/Invoice";
import { SortableHeader, SortableHeadersGroup } from "components/Shared";

import * as S from "./styles";
import { Formatter } from "utils/Formatter";
import { ReleasedContext } from "contexts/ReleasedContext";
import { Status } from "../../Status";
import { useNavigate } from "react-router-dom";
import { CheckboxRadix } from "components/Shared/Forms/CheckboxRadix";

interface Props {
  onSort?: (sort: SortingParams) => void;
  onSelectInvoice?: (invoiceId: number) => void;
}

interface ItemProps {
  releasedInvoice: PaginatedInvoice;
}

export const ReleasedInvoicesList = ({ onSort }: Props) => {
  const navigate = useNavigate();
  const {
    query,
    releasedInvoices,
    selectedList,
    onToggleSelected,
    onRemoveSelecteds,
    onSelectInvoices,
  } = useContext(ReleasedContext);

  const goToTracking = useCallback((id: number) => {
    navigate(`/inbound/notas-fiscais/tracking/${id}`);
  }, [navigate])

  const Item = useCallback(
    ({ releasedInvoice }: ItemProps): JSX.Element => {
      const {
        id,
        infnfeIdeNnf,
        infnfeIdeSerie,
        infnfeIdeDhemi,
        deadlineDate,
        supplier,
        company,
        carrier,
        releasedAt,
        status,
        destEnderdestUf,
      } = releasedInvoice;

      const isChecked = selectedList.some((invoice) => invoice.id === id);
      const isImport = destEnderdestUf ? ['EXTERIOR', 'EX'].includes(destEnderdestUf) : false;
      const finalOrigin = isImport ? company : supplier;
      const finalDestiny = isImport ? supplier : company;

      return (
        <S.ListItem>
          <S.Column>
            {infnfeIdeNnf}-{infnfeIdeSerie}
          </S.Column>
          <S.Column>{Formatter.date(infnfeIdeDhemi)}</S.Column>
          <S.Column>
            {(deadlineDate && Formatter.date(deadlineDate)) || "---"}
          </S.Column>
          <S.Column>{finalOrigin?.tradeName}</S.Column>
          <S.Column>{finalDestiny?.tradeName}</S.Column>
          <S.Column>{carrier?.tradeName}</S.Column>
          <S.Column>
            {(releasedAt &&
              Formatter.date(releasedAt, { format: "dd/MM/yyyy HH:mm" })) ||
              "---"}
          </S.Column>
          <S.Column width="140px">
            <Status color={status[0].color}>{status[0].name}</Status>
          </S.Column>
          <S.ActionsColumn>
            <S.ActionButton mood="void" onClick={() => goToTracking(id)}>
              <S.ChevronRightCircleIcon />
            </S.ActionButton>
            <CheckboxRadix
              id={id}
              handleCheck={() => onToggleSelected(releasedInvoice)}
              isChecked={isChecked}
            />
          </S.ActionsColumn>
        </S.ListItem>
      );
    },
    [goToTracking, onToggleSelected, selectedList]
  );

  const selectedListIDs = selectedList.map((invoice: any) => invoice.id);
  const allSelected = releasedInvoices.every((invoice) =>
    selectedListIDs.includes(invoice.id)
  );

  const handleToggleAllInvoices = () => {
    if (allSelected) {
      onRemoveSelecteds(releasedInvoices.map((invoice) => invoice.id));
    } else {
      onSelectInvoices(releasedInvoices);
    }
  };

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={query}>
          <SortableHeader column="infnfeIdeNnf" label="NF" />
          <SortableHeader column="infnfeIdeDhemi" label="DT.EMISSÃO" />
          <SortableHeader column="deadlineDate" label="PRAZO" />
          <SortableHeader column="supplierTradeName" label="ORIGEM" />
          <SortableHeader column="companyTradeName" label="DESTINO" />
          <SortableHeader column="carrierTradeName" label="TRANSPORTADORA" />
          <SortableHeader column="releasedAt" label="LIBERADO EM" />
          <SortableHeader column="status" label="STATUS" />
          <S.ActionsColumn>
            <CheckboxRadix
              id={0}
              handleCheck={handleToggleAllInvoices}
              isChecked={allSelected}
            />
          </S.ActionsColumn>
        </SortableHeadersGroup>
      </S.ListHeader>
      {releasedInvoices.map((releasedInvoice) => (
        <Item key={releasedInvoice.id} releasedInvoice={releasedInvoice} />
      ))}
    </S.List>
  );
};
