import styled, { css } from "styled-components";
import {
  BasePaginatorHeader,
  BasePaginatorListItem,
  PaginatorActionsColumn,
} from "styles/components";
import { ColorScheme, getScrollbarStyle } from "styles/constants";
export {
  ActionButton,
  PaginatorColumn as Column,
  LinkActionButton,
  EditIcon,
  ChevronRightCircleIcon,
} from "styles/components";

export const List = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 1536px) { 
      overflow-x: auto;
      ${getScrollbarStyle()};
    }
`;

const baseGridCss = css`
  grid-template-columns:
    40px repeat(4, 180px) 150px 200px 100px repeat(3, 100px) 30px;
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)<{ isDisabled?: boolean }>`
  ${baseGridCss}

  ${props => props.isDisabled === true && css`color: ${ColorScheme.Text}cc;`}
`;

export const ActionsColumn = styled(PaginatorActionsColumn)`
  align-items: center;
  overflow: visible;
  gap: 8px;
`;

export const GhostIcon = styled.div`
  width: 34px;
  height: 24px;
  display: block;
`;
