import * as S from './styles'

interface ILoading {
  color?: string
}

const Loading = ({color}: ILoading) => {
  return (
    <S.Loading color={color}/>
  )
}

export { Loading }
