import styled, { css } from 'styled-components';
import { BasePaginatorHeader, BasePaginatorListItem } from 'styles/components';
import { ColorScheme, Colors } from 'styles/constants';
export {
  PaginatorColumn as Column,
  PaginatorActionsColumn as ActionsColumn,
  ActionButton,
  LinkActionButton,
  EditIcon,
} from 'styles/components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const baseGridCss = css`
  grid-template-columns: 80px repeat(4, 200px) 80px 80px;
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  color: ${ColorScheme.Text};
`;

export const Input = styled.input`
  appearance: none;
  &:checked + div .thumb {
    right: 0px;
  }
  &:not(:checked) + div .thumb {
    right: calc(100% - 24px);
    background: ${Colors.Gray50};
    opacity: 0.5;
  }
`;

export const Toggler = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 44px;
`;

export const TogglerTrack = styled.div.attrs({ className: 'track' })`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 24px;
  background: ${Colors.Gray30};
  border-radius: 12px;
`;

export const TogglerThumb = styled.div.attrs({ className: 'thumb' })`
  position: absolute;
  display: flex;
  flex: 0 0 24px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: ${ColorScheme.Primary};
  border: 2px solid ${Colors.Gray30};
  transition: all 0.3s ease-in-out;
`;
