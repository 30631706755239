import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import { DialogContent } from "components/Shared/Dialog";
import { PendingReleaseContext } from "contexts/PendingReleaseContext";
import { useCallback, useContext, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { SettingsIcon } from "styles/components";
import { Status } from "components/Pages/Invoices";

import * as S from "./styles";
import { Formatter } from "utils";
import { RecalculateInvoicePayload } from "contracts/Invoice";
import { InvoiceRecalculateActions } from "store/ducks/pendingRelease";

const RecalculateModal = () => {
  const {
    refModal,
    selectedList,
    onQueryChange,
    clearSelectList,
    changeOpenModal,
  } = useContext(PendingReleaseContext);
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();

  const { loading } = useSelector(
    (state: RootState) => state.invoiceRecalculate
  );

  const formattedList = useMemo(() => {
    return selectedList.map((invoice) => ({
      id: invoice.id,
      nf: `${invoice.infnfeIdeNnf}-${invoice.infnfeIdeSerie}`,
      dhEmi: Formatter.date(invoice.infnfeIdeDhemi, {
        format: "dd/MM/yyyy HH:mm",
      }),
      carrier: invoice.carrier?.tradeName ? invoice.carrier.tradeName : "---",
      status:
        invoice.status.length > 0
          ? {
              name: invoice.status[0].name,
              color: invoice.status[0].color,
            }
          : null,
    }));
  }, [selectedList]);

  const handleSubmit = useCallback<SubmitHandler>(() => {
    const payloadData: RecalculateInvoicePayload = {
      invoiceKeys: selectedList.map(
        (invoice) => invoice.protocolo.protnfe_infprot_chnfe
      ),
    };
    dispatch(
      InvoiceRecalculateActions.request(payloadData, () => {
        onQueryChange({}, true);
        clearSelectList();
        changeOpenModal();
      })
    );
  }, [changeOpenModal, clearSelectList, dispatch, onQueryChange, selectedList]);

  return (
    <DialogContent
      container={refModal}
      title="Recalcular as notas listadas abaixo"
      icon={<SettingsIcon />}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.Content>
          <S.ListHeader>
            <span>NF</span>
            <span>DT. Emissão</span>
            <span>Transportadora</span>
            <span>Status</span>
          </S.ListHeader>
          {formattedList.map((invoice) => (
            <S.ListItem key={invoice.id}>
              <span>{invoice.nf}</span>
              <span>{invoice.dhEmi}</span>
              <span>{invoice.carrier}</span>
              <span>
                {invoice.status ? (
                  <Status color={invoice.status.color}>
                    {invoice.status.name}
                  </Status>
                ) : (
                  <Status>Sem status</Status>
                )}
              </span>
            </S.ListItem>
          ))}
        </S.Content>
        <S.Footer>
          <S.Button type="submit" disabled={loading}>
            {loading ? `Recalculando...` : `Recalcular`}
          </S.Button>
        </S.Footer>
      </Form>
    </DialogContent>
  );
};

export { RecalculateModal };
