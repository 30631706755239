import { Modal } from "components/Shared"
import React, { useState } from "react"

import * as S from "./styles"
import { Button } from "../CreationForm/styles"

interface ConfirmDeleteModalProps {
  confirm: () => void
  cancel: () => void
  onCloseButton: () => void
  isOpen: boolean
}

const ConfirmDeleteModal = ({
  confirm,
  cancel,
  onCloseButton,
  isOpen = false,
}: ConfirmDeleteModalProps) => {
  return (
    <Modal isOpen={isOpen}>
      <S.ModalContent style={{ maxWidth: "960px" }}>
        <S.ModalContainer>
          <S.ModalHeader>
            <div className="wrapper">
              <S.ModalHeaderTitle>Tem certeza?</S.ModalHeaderTitle>
            </div>
            <S.CloseButton onClick={onCloseButton}>
              <S.CloseIcon />
            </S.CloseButton>
          </S.ModalHeader>
          <S.ModalBody>
            Tem certeza que deseja remover este armazém?
          </S.ModalBody>
          <S.ModalFooter>
            <Button size="small" onClick={confirm}>
              Confirmar
            </Button>
            <Button size="small"  mood="light" onClick={cancel}>
              Cancelar
            </Button>
          </S.ModalFooter>
        </S.ModalContainer>
      </S.ModalContent>
    </Modal>
  )
}

export default ConfirmDeleteModal
