import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  UpdateSAPCodeInvoiceActions as Actions,
  UpdateSAPCodeInvoiceRequestAction as RequestAction,
} from 'store/ducks/deliveredInvoices';

export function* updateSAPCodeInvoiceRequest(action: any) {
  const { payload, onSuccess, onFailure }: RequestAction = action;
  try {
    const { data } = yield call(api.post, `invoices/finish`, payload);
    notify('success', "NF finalizada com sucesso!");
    onSuccess && onSuccess();
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
