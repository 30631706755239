import {
  MessageAltAlertIcon,
  MessageAltCheckIcon,
  MessageAltMinusIcon,
} from "./icons";

export const JustificationsAlerts = {
  none: <MessageAltMinusIcon title="Sem justificativa" />,
  alert: <MessageAltAlertIcon title="Justificativas pendentes" />,
  check: <MessageAltCheckIcon title="Justificativa abonada" />,
};