import { FormHandles } from "@unform/core";
import { Clear, Input, Select } from "components/Shared";
import { OPERATIONS_TYPES } from "constants/Selects";
import { PendingReleaseContext } from "contexts/PendingReleaseContext";
import { useCarriers, useOperationTypes } from "hooks";
import { useDebounce } from "hooks/useDebounce";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { FetchStatusListActions } from "store/ducks/status";
import * as S from "./styles";

const PendingReleaseFilter = () => {
  const dispatch = useDispatch();
  const { onQueryChange, clearQuery, showTrash } = useContext(
    PendingReleaseContext
  );
  const [invoiceNumber, setInvoiceNumber] = useState<string | undefined>(
    undefined
  );
  const { operationTypesOptions, loadingOperationTypes, fetchOperationTypes } =
    useOperationTypes();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const formRef = useRef<FormHandles>(null);
  const debouncedSearchTerm: string | undefined = useDebounce<
    string | undefined
  >(invoiceNumber, 500);
  const { carrierOptions, loadingCarriers, fetchCarriers } = useCarriers();

  const { data: statusList } = useSelector(
    (state: RootState) => state.fetchStatusList
  );

  const statusListOptions = statusList
    ? statusList.map((status) => ({
        value: status.name,
        label: status.name,
      }))
    : [];

  const handleChangeCarrier = useCallback(
    (carrierId?: string | number) => {
      onQueryChange({ carrierId: carrierId ? Number(carrierId) : undefined });
    },
    [onQueryChange]
  );

  const handleChangeOperation = useCallback(
    (operation?: string | number) => {
      if (operation === "inbound") {
        setIsSelected(true);
      } else {
        setIsSelected(false);
        onQueryChange({ type: operation ? String(operation) : undefined });
      }
    },
    [onQueryChange]
  );

  const handleChangeBranch = useCallback(
    (opt?: string | number) => {
      onQueryChange({ operationTypeId: Number(opt) });
    },
    [onQueryChange]
  );

  const handleChangeStatus = useCallback(
    (status?: string | number) => {
      onQueryChange({ status: status ? String(status) : undefined });
    },
    [onQueryChange]
  );

  const clearFilter = useCallback(() => {
    formRef.current && formRef.current?.reset();
    clearQuery();
  }, []);

  useEffect(() => {
    onQueryChange({
      invoiceNumber: debouncedSearchTerm ? debouncedSearchTerm : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchCarriers();
    dispatch(FetchStatusListActions.request({ asList: true, notFlow: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchOperationTypes();
  }, [fetchOperationTypes]);

  return (
    <S.ContainerFilter ref={formRef} onSubmit={() => {}}>
      <Select
        name="carrierId"
        options={carrierOptions}
        placeholder="Transportadora"
        onChange={(opt) => handleChangeCarrier(opt?.value)}
        isLoading={loadingCarriers}
        isClearable
      />
      <Select
        name="type"
        options={OPERATIONS_TYPES}
        placeholder="Operação"
        onChange={(opt) => handleChangeOperation(opt?.value)}
        isClearable
        classContainer="operation"
      />
      <Select
        name="operationTypeId"
        options={operationTypesOptions}
        placeholder="Ramificação"
        onChange={(opt) => handleChangeBranch(opt?.value)}
        isDisabled={!isSelected}
        isClearable
        isLoading={loadingOperationTypes}
      />
      <Select
        name="status"
        options={statusListOptions}
        placeholder="Status"
        onChange={(opt) => handleChangeStatus(opt?.value)}
        isClearable
      />
      <Input
        name="invoiceNumber"
        type="search"
        placeholder="Nº da NF"
        defaultValue={invoiceNumber}
        onChange={(e) => setInvoiceNumber(e.target.value)}
      />
      <Clear onClick={clearFilter} title="Limpar filtro" show={showTrash} />
    </S.ContainerFilter>
  );
};

export { PendingReleaseFilter };
