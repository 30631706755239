import * as Yup from 'yup';
import {
  getCollectShape
} from 'validators/Common/baseCollectValidator';

export class CreateCollectValidator {

  public get schema() {
    return Yup.object().shape({
      ...getCollectShape(),
    });
  }
}
