import { notify } from "services";
import * as Yup from "yup";

export const getProductShape = () => ({
  file: Yup.mixed()
    .nullable()
    .optional()
    .test({
      message: "",
      test: (file: File) => {
        if (!file) {
          return true;
        }

        const isValid = [
          "text/plain",
          "application/pdf",
          "text/xml",
          "image/png",
          "image/jpeg",
        ].includes(file?.type);

        if (!isValid) {
          notify(
            "error",
            "Formato de arquivo não suportado! Informe um pdf, xml, txt, png ou jpeg."
          );
          return false;
        }
        if (file?.size > 5000000) {
          notify(
            "error",
            "Tamanho de arquivo máximo excedido! Informe um arquivo com menos de 5mb."
          );
          return false;
        }

        return true;
      },
    }),
});
