import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import type { Auditor } from 'contracts/Auditors';

const { Types, Creators } = createActions(
  {
    request: ['id', 'onSuccess', 'onFailure'],
    success: ['data'],
    failure: ['errorMessage'],
    softReset: [],
    reset: [],
  },
  {
    prefix: 'FETCH_AUDITOR_',
  }
);

export interface FetchAuditorState {
  data: Auditor | null;
  loading: boolean;
  errorMessage: string | null;
}

export interface FetchAuditorRequestAction {
  id: number;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface SuccessAction {
  data: Auditor;
}

interface FailureAction {
  errorMessage: string;
}

const INITIAL_STATE: FetchAuditorState = {
  data: null,
  loading: false,
  errorMessage: null,
};

const request = (state: FetchAuditorState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (state: FetchAuditorState, action: SuccessAction) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (state: FetchAuditorState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const softReset = (state: FetchAuditorState) =>
  update(state, {
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const fetchAuditor = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const FetchAuditorTypes = Types;
export const FetchAuditorActions = Creators;
