import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "components/ui/DataTable";
import { IDataTableAction, DataTableActions } from "components/ui/DataTableActions";
import { Company } from "contracts/Company";
import { WarehouseMember } from "contracts/WarehouseMembers";
import { usePermission } from "hooks/usePermission";

export function useColumns(){

    const { hasPermissionTo } = usePermission()

    const columns: ColumnDef<WarehouseMember>[] = [
        {
            id: 'Id',
            accessorKey: 'id',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Id" />
            ),
            cell: ({ row }) => {
                return `${row.original.id}`
            },
            enableSorting: true,
            enableHiding: true,
        },
         {
            id: 'Nome',
            accessorKey: 'name',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Nome" />
            ),
            cell: ({ row }) => {
                return `${row.original.name ?? "---"}`
            },
            enableSorting: true,
            enableHiding: true,
        },
        {
            id: 'Email',
            accessorKey: 'email',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Email" />
            ),
            cell: ({ row }) => {
                return `${row.original.email ?? "---"}`
            },
            enableSorting: true,
            enableHiding: true,
        },
        {
            id: 'Armazéns',
            accessorKey: 'company',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Armazéns" />
            ),
            cell: ({ row }) => {
                return `${row.original.warehouseMember.map((c: Company) => c.tradeName).join(', ') ?? "---"}`
            },
            enableSorting: true,
            enableHiding: true,
        },
        {
            id: 'actions',
            cell: ({ row }) => {
                const actions: IDataTableAction[] = [
                    {
                        name: 'Editar',
                        route: `/configuracoes/usuarios/editar/${row.original.id}`,
                        hasPermission: hasPermissionTo(['WAREHOUSE_MEMBERS.UPDATE']),
                    },
                    {
                        name: 'Logar como',
                        route: `/configuracoes/usuarios/impersonate/${row.original.email}`,
                        hasPermission: row.original.warehouseMember.length > 0 && hasPermissionTo(['AUTH.IMPERSONATE'])
                    }
                ];
                return <DataTableActions actions={actions} />;
            },
        },
    ]

    return { columns }
}