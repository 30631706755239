import { createReducer, createActions } from "reduxsauce"
import update from "immutability-helper"

const { Types, Creators } = createActions(
  {
    request: ["companyId", "userId", "onSuccess", "onFailure"],
    success: [],
    failure: ["errorMessage"],
    softReset: [],
    reset: [],
  },
  {
    prefix: "DELETE_WAREHOUSE_COMPANY_MEMBER_",
  }
)

export interface DeleteWarehouseCompanyMemberState {
  id: number | null
  loading: boolean
  errorMessage: string | null
}

export interface DeleteWarehouseCompanyMemberRequestAction {
  companyId: number
  userId: number
  onSuccess?: () => void
  onFailure?: () => void
}

interface FailureAction {
  errorMessage: string
}

const INITIAL_STATE: DeleteWarehouseCompanyMemberState = {
  id: null,
  loading: false,
  errorMessage: null,
}

const request = (
  state: DeleteWarehouseCompanyMemberState,
  action: DeleteWarehouseCompanyMemberRequestAction
) =>
  update(state, {
    id: { $set: action.companyId },
    loading: { $set: true },
    errorMessage: { $set: null },
  })

const success = (state: DeleteWarehouseCompanyMemberState) =>
  update(state, {
    id: { $set: null },
    loading: { $set: false },
  })

const failure = (
  state: DeleteWarehouseCompanyMemberState,
  action: FailureAction
) =>
  update(state, {
    id: { $set: null },
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  })

const softReset = (state: DeleteWarehouseCompanyMemberState) =>
  update(state, {
    id: { $set: null },
    loading: { $set: false },
  })

const reset = () => INITIAL_STATE

export const deleteWarehouseCompanyMember = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
})

export const DeleteWarehouseCompanyMemberTypes = Types
export const DeleteWarehouseCompanyMemberActions = Creators
