import { FormHandles, SubmitHandler } from "@unform/core"
import { Form } from "@unform/web"
import { Input, Select } from "components/Shared"
import { DialogContent } from "components/Shared/Dialog"
import { DeliveredContext } from "contexts/DeliveredContext"
import {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { TruckIcon } from "styles/components"

import * as S from "./styles"
import { Formatter } from "utils"
import { UpdateSAPCodeInvoiceActions } from "store/ducks/deliveredInvoices"
import { UpdateSAPCodeInvoicePayload } from "contracts/Invoice"
import { JUSTIFICATION_TYPES, useAuth, useJustifications } from "hooks"
import { notify } from "services"

interface FormData {
  invoiceId: number
  finishedAt: string
  sapCode: string
  description: string
  justificationId: number
}

const AppInvoiceSAPModal: React.FC<{options:any[]}> = ({options}) => {
  const { refModal, onQueryChange, changeOpenModal, selectedInvoice, openedModal } =
    useContext(DeliveredContext)
  const formRef = useRef<FormHandles>(null)
  const dispatch = useDispatch()
  const [sapCode, setSapCode] = useState<string | null>(null)
  const [finished, setFinished] = useState<string | null>(null)
  const { justificationOptions, fetchJustifications, loadingJustifications } =
    useJustifications()
  const [isDisabled, setIsDisabled] = useState(true)
  const {userBelongsToAnyOf} = useAuth()
  const isAdmin = userBelongsToAnyOf('admin')
  const isCarrier = userBelongsToAnyOf('carrierMember')

  const { loading } = useSelector(
    (state: RootState) => state.updateInvoiceStatus
  )

  const handleSubmit = useCallback<SubmitHandler>(
    (data: FormData) => {
      if (selectedInvoice) {
        setIsDisabled(true)
        setFinished(null)
        const payloadData: UpdateSAPCodeInvoicePayload = {
          sapCode: data.sapCode,
          invoiceId: selectedInvoice.id,
          finishedAt: Formatter.dateToISO(data.finishedAt, {
            format: "yyyy-MM-dd HH:mm",
          }),
          description: data.description,
          justificationId: data.justificationId,
        }
        if(selectedInvoice.isDelayedLeadTime && (!data.justificationId || !data.description)){
          notify("error", "Preencha todos os campos")
        }else{
          dispatch(
            UpdateSAPCodeInvoiceActions.request(payloadData, () => {
              onQueryChange({}, true)
              changeOpenModal()
            })
          )
        }

      }
      formRef.current?.reset()
    },
    [changeOpenModal, dispatch, onQueryChange, selectedInvoice]
  )

  useEffect(() => {
    fetchJustifications()
    // console.log(options)
    return () => setIsDisabled(true)
  }, [selectedInvoice, openedModal])

  const formattedInvoice = useMemo(() => {
    if (selectedInvoice) {
      const {
        id,
        infnfeIdeNnf,
        infnfeIdeSerie,
        infnfeIdeDhemi,
        carrier,
        status,
        deliveryDate,
      } = selectedInvoice
      const { tradeName } = carrier
      return {
        id,
        nf: `${infnfeIdeNnf}-${infnfeIdeSerie}`,
        dhEmi: Formatter.date(infnfeIdeDhemi, { format: "dd/MM/yyyy HH:mm" }),
        entregue: deliveryDate
          ? Formatter.date(deliveryDate, { format: "dd/MM/yyyy HH:mm" })
          : "---",
        carrierName: tradeName || "---",
        status:
          status.length > 0
            ? {
                name: status[0].name,
                color: status[0].color,
              }
            : null,
      }
    }

    return null
  }, [selectedInvoice])

  if (!formattedInvoice) return null

  const isDisableAddButton =   !finished || !sapCode || loading

  return (
    <DialogContent
      container={refModal}
      title={`Adicionar código SAP - NF ${formattedInvoice?.nf}`}
      icon={<TruckIcon />}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.Content>
          <S.ListHeader>
            <span>NF</span>
            <span>DT. Emissão</span>
            <span>Transportadora</span>
            <span>DT. Entrega</span>
          </S.ListHeader>
          <S.ListItem>
            <span>{formattedInvoice.nf}</span>
            <span>{formattedInvoice.dhEmi}</span>
            <span>{formattedInvoice.carrierName}</span>
            <span>{formattedInvoice.entregue}</span>
          </S.ListItem>
        </S.Content>
        <S.Footer>
          <Select
            name="justificationId"
            options={justificationOptions}
            isLoading={loadingJustifications}
            placeholder="Justificativa"
            label="Justificativa"
            // onChange={(opt) => setIsDisabled(opt?.value ? false : true)}
            maxMenuHeight={120}
          />
          <Input name="description" label="Comentário" />
        </S.Footer>
        <S.InputFlex>
          <Input
            type="date"
            name="finishedAt"
            label="Data GR"
            placeholder="Data GR"
            onChange={(e) => setFinished(e.target.value)}
          />
          <Input
            name="sapCode"
            label="Código SAP"
            placeholder="Código SAP"
            onChange={(e) => setSapCode(e.target.value)}
          />
        </S.InputFlex>
        <S.ContainerButton>
          <S.Button
            type="submit"
            disabled={isDisableAddButton}
          >
            {loading ? `Adicionando` : `Adicionar`}
          </S.Button>
        </S.ContainerButton>
      </Form>
    </DialogContent>
  )
}

export { AppInvoiceSAPModal }
