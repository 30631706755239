import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import type { OperationType } from "contracts/OperationTypes";

const { Types, Creators } = createActions(
  {
    request: ["id", "onSuccess", "onFailure"],
    success: ["data"],
    failure: ["errorMessage"],
    softReset: [],
    reset: [],
  },
  {
    prefix: "FETCH_OPERATION_TYPE_",
  }
);

export interface FetchOperationTypeState {
  data: OperationType | null;
  loading: boolean;
  errorMessage: string | null;
}

export interface FetchOperationTypeRequestAction {
  id: number;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface SuccessAction {
  data: OperationType;
}

interface FailureAction {
  errorMessage: string;
}

const INITIAL_STATE: FetchOperationTypeState = {
  data: null,
  loading: false,
  errorMessage: null,
};

const request = (state: FetchOperationTypeState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (state: FetchOperationTypeState, action: SuccessAction) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (state: FetchOperationTypeState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const softReset = (state: FetchOperationTypeState) =>
  update(state, {
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const fetchOperationType = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const FetchOperationTypeTypes = Types;
export const FetchOperationTypeActions = Creators;
