import { call, put } from '@redux-saga/core/effects';
import { api, notify, formDataBuilder, apiErrorHandler } from 'services';
import {
  CreateOperationTypesCfopActions as Actions,
  CreateOperationTypesCfopRequestAction as RequestAction,
} from 'store/ducks/operationsTypesCfop';

export function* createOperationTypesCfopRequest(action: any) {
  const { postData, onSuccess, onFailure }: RequestAction = action;
  try {
    const formData = formDataBuilder(postData);
    yield call(api.post, `operation-types-cfop`, formData);
    onSuccess && onSuccess();
    notify('success', 'Rota criada com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
