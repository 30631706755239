import { ModalStatus } from "components/Shared/ModalStatus";
import { TracePoint } from "components/Shared/TracePoint";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { useNavigate, useLocation } from "react-router-dom";
import { FetchPendingReleaseActions as FetchActions } from "store/ducks/pendingRelease";
import { RecalculateTransitTimeActions as RecalculateTT } from "store/ducks/transitTime";
import { Status } from "../Status";
import { Formatter } from "utils";
import { ItemInfo } from "./ItemInfo";
import * as S from "./styles";
import { Navigation } from "../Navigation";
import { Boxes } from "./Boxes";
import { Loading } from "components/Shared/Loading";
import { ModalJustifications } from "components/Shared/ModalJustifications";
import { Map } from "./Map";
import { notify } from "services";
import { Supplier } from "contracts/Supplier";
import { Company } from "contracts/Company";
import { usePermission } from "hooks/usePermission";

export const TrackingDetails = () => {
  const { hasPermissionTo } = usePermission()
  const location = useLocation();
  const [distance, setDistance] = useState<string | null | undefined>("");
  const [isReleased, setIsReleased] = useState<boolean>(false);
  const [invoiceKey, setInvoiceKey] = useState<string>("");
  const invoiceId = useMemo(() => {
    return parseInt(location.pathname.replace(/[^0-9]/g, ""));
  }, [location]);

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { data: invoice, loading } = useSelector(
    (state: RootState) => state.fetchPendingRelease
  );

  const { loading: loadingTT } = useSelector(
    (state: RootState) => state.recalculateTransitTime
  );

  const onBack = useCallback(() => {
    navigate("/inbound/notas-fiscais/tracking");
  }, [navigate]);

  const fetchInvoice = useCallback((): void => {
    dispatch(FetchActions.request(invoiceId, null, onBack));
  }, [dispatch, invoiceId]);

  const recalculate = useCallback(() => {
    dispatch(RecalculateTT.request(invoiceKey));
  }, [invoiceKey, dispatch]);

  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  useEffect(() => {
    if (invoice) {
      setInvoiceKey(invoice.protocolo.protnfe_infprot_chnfe);
      if (invoice?.statuses[0].statusId !== 9) {
        onBack();
        notify("error", "Nota fiscal não está em trânsito");
      } else {
        setIsReleased(true);
      }
    }
  }, [invoice]);

  const isImport = invoice?.destEnderdestUf
    ? ["EXTERIOR", "EX"].includes(invoice.destEnderdestUf)
    : false;
  const { company, supplier } = invoice ?? {};
  const finalOrigin: Supplier | Company | undefined = isImport
    ? company
    : supplier;
  const finalDestiny: Supplier | Company | undefined = isImport
    ? supplier
    : company;

  return (
    <S.Container>
      <Navigation />
      <S.Header>
        {loading && (
          <S.Title>
            <S.InvoiceIcon />
            <Loading />
          </S.Title>
        )}
        {!loading && invoice && (
          <>
            <S.Invoice>
              <S.Title>
                <S.InvoiceIcon />
                {invoice.infnfeIdeNnf}-{invoice.infnfeIdeSerie}
              </S.Title>
              {invoice.statuses && invoice.statuses.length > 0 ? (
                <Status color={invoice.statuses[0].status.color}>
                  {invoice.statuses[0].status.name}
                </Status>
              ) : (
                <Status>Sem status</Status>
              )}
            </S.Invoice>
            <S.Box>
              <S.BoxContent>
                <S.BoxHeader>
                  <S.ClientIcon />
                  {finalOrigin?.tradeName}
                </S.BoxHeader>
                <S.BoxBody justify="end">
                  {finalOrigin?.addressCity}/{finalOrigin?.addressUf}
                </S.BoxBody>
              </S.BoxContent>
              <span>
                <TracePoint
                  text={distance}
                  arrowLeft={isImport}
                  arrowRight={!isImport}
                />
                {isImport && <p>NF IMPORTAÇÃO</p>}
              </span>
              <S.BoxContent>
                <S.BoxHeader>
                  {finalDestiny?.tradeName}
                  <S.CompanyIconOutline />
                </S.BoxHeader>
                <S.BoxBody justify="start">
                  {finalDestiny?.addressCity}/{finalDestiny?.addressUf}
                </S.BoxBody>
              </S.BoxContent>
            </S.Box>
          </>
        )}
        <S.Back onClick={onBack}>
          <S.ArrowLeftIcon />
          Voltar
        </S.Back>
      </S.Header>
      {isReleased && invoice && (
        <>
          <Map invoice={invoice} setDistance={setDistance} />
          <S.HeaderInfo>
            <S.Title>
              <S.DetailIcon />
              Informações
            </S.Title>
            <S.Item>
              Data de emissão
              <S.Info>
                {invoice?.infnfeIdeDhemi
                  ? Formatter.date(invoice.infnfeIdeDhemi)
                  : "--/--/----"}
              </S.Info>
            </S.Item>
            <S.Item>
              Previsão de entrega
              <S.Info>
                {invoice?.deliveryDate
                  ? Formatter.date(invoice.deliveryDate)
                  : "--/--/----"}
              </S.Info>
            </S.Item>
            <S.Item>
              Prazo contratado
              <S.Info>
                {invoice?.deadlineDate
                  ? Formatter.date(invoice.deadlineDate)
                  : "--/--/----"}
              </S.Info>
            </S.Item>
            {hasPermissionTo(['TRACKING.RECALCULATEDUE']) && (
                 <S.Button onClick={recalculate} disabled={loadingTT}>
                    {loadingTT ? (
                    <Loading color="white" />
                    ) : (
                    <S.EditAltIcon height={"18px"} />
                    )}
                    Recalcular
                </S.Button>
                )
            }
            <div />
            <S.Item>
              Observações
              <S.Info>{invoice?.note ?? "---"}</S.Info>
            </S.Item>
            <S.Item>
              Veículo
              <S.Info>{invoice?.vehicle ?? "---"}</S.Info>
            </S.Item>
            <S.Item>
              Tipo de Veículo
              <S.Info>{invoice?.vehicleType?.name ?? "---"}</S.Info>
            </S.Item>
          </S.HeaderInfo>
          <Boxes invoice={invoice} />
          <ItemInfo invoice={invoice} />
          <S.Box>
            <ModalStatus invoice={invoice} title={"Timeline Atividades"} />
            <ModalJustifications
              showAdd
              invoice={invoice}
              title={"Timeline Justificativas"}
            />
          </S.Box>
        </>
      )}
    </S.Container>
  );
};
