import React, { useRef, useCallback, useEffect } from 'react';
import { useField } from '@unform/core';
import * as S from './styles';

type Props = JSX.IntrinsicElements['input'] & {
  name: string;
  id?: string;
  label?: string;
  value?: string;
};

export const ToggleInput: React.FC<Props> = ({
  name,
  id,
  label = '',
  value = '1',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, error } = useField(name);

  const ErrorComponent = useCallback((): JSX.Element => {
    if (!error) return <></>;
    return <S.FieldError>{error}</S.FieldError>;
  }, [error]);

  const TogglerComponent = useCallback((): JSX.Element => {
    return (
      <S.Toggler>
        <S.TogglerTrack>
          <S.TogglerThumb />
        </S.TogglerTrack>
      </S.Toggler>
    );
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: HTMLInputElement) => {
        return ref.checked ? ref.value : '';
      },
      clearValue: (ref: HTMLInputElement) => {
        ref.checked = false;
      },
      setValue: (ref: HTMLInputElement, value: string) => {
        ref.checked = value === ref.value;
      },
    });
  }, [fieldName, registerField]);

  return (
    <S.Container>
      <S.FieldLabel>&nbsp;</S.FieldLabel>
      <S.ToggleContainer>
        <S.Input
          type="checkbox"
          id={id || fieldName}
          value={value}
          ref={inputRef}
          defaultChecked={rest?.defaultChecked}
        />
        <S.Label htmlFor={id || fieldName}>
          {label}
          <TogglerComponent />
        </S.Label>
      </S.ToggleContainer>
      <ErrorComponent />
    </S.Container>
  );
};
