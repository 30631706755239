import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { EMPTY_COLUMN_VALUE } from 'constants/Common';
import { SortingParams } from 'contracts/Common';
import type { PaginatedAuditor } from 'contracts/Auditors';
import React, { useCallback } from 'react';
import { Formatter } from 'utils';
import * as S from './styles';

interface Props {
  auditors: PaginatedAuditor[];
  onSort?: (sort: SortingParams) => void;
  currentSort: SortingParams;
}

interface ItemProps {
  auditor: PaginatedAuditor;
}

export const AuditorsList: React.FC<Props> = ({
  auditors,
  onSort,
  currentSort,
}) => {
  const Item = useCallback(({ auditor }: ItemProps): JSX.Element => {
    const { id, auditorName, auditorPhoneNumber, auditorOrgName, auditorEmail } =
      auditor;

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column title={auditorName}>{auditorName}</S.Column>
        <S.Column title={auditorOrgName}>{auditorOrgName}</S.Column>
        <S.Column title={auditorEmail}>{auditorEmail}</S.Column>
        <S.Column title={auditorPhoneNumber}>{auditorPhoneNumber}</S.Column>
        <S.ActionsColumn>
          <S.LinkActionButton to={`/configuracoes/fiscais/${id}/editar`}>
            <S.EditIcon />
          </S.LinkActionButton>
        </S.ActionsColumn>
      </S.ListItem>
    );
  }, []);

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="auditorName" label="NOME" />
          <SortableHeader column="auditoOrgName" label="ORGÃO" />
          <SortableHeader column="auditorEmail" label="EMAIL" />
          <SortableHeader column="auditorPhoneNumber" label="TELEFONE" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {auditors.map((auditor) => (
        <Item key={auditor?.id} auditor={auditor} />
      ))}
    </S.List>
  );
};
