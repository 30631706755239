import { ListJustificationsReverseLogisticsActions as MainActions } from 'store/ducks/justificationsReverseLogistics';
import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';

interface FindJustifications {
  excludeBlocked?: boolean;
}

export const useJustificationsReverseLogistics = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: justifications, loading: loadingJustifications } = useSelector(
    (state: RootState) => state.listJustificationsReverseLogistics
  );

  const [ignoredBlockedId, enableJustificationIfBlocked] = useState<number | null>(
    null
  );

  const fetchJustifications = useCallback(
    (query?: FindJustifications): void => {
      dispatch(MainActions.request({ ...query }));
    },
    [dispatch]
  );

  const onJustificationsLoad = useMemo<SelectOption[]>(() => {
    return justifications.map(({ id, description }) => ({
      value: id,
      label: description,
    }))
  }, [justifications, ignoredBlockedId]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    justifications,
    justificationOptions: onJustificationsLoad,
    loadingJustifications,
    fetchJustifications,
    enableJustificationIfBlocked,
  };
};

export type JustificationsReverseLogisticsHook = ReturnType<typeof useJustificationsReverseLogistics>;
