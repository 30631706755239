import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { PaginatedReason } from 'contracts/Reasons';
import { SortingParams } from 'contracts/Common';
import React, { useCallback } from 'react';
import * as S from './styles';

interface Props {
  reasons: PaginatedReason[];
  onSort?: (sort: SortingParams) => void;
  currentSort: SortingParams;
}

interface ItemProps {
  reason: PaginatedReason;
}

export const ReasonsList: React.FC<Props> = ({
  reasons,
  onSort,
  currentSort,
}) => {
  const Item = useCallback(({ reason }: ItemProps): JSX.Element => {
    const { id, description } = reason;

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column>{description}</S.Column>
        <S.ActionsColumn>
          <S.LinkActionButton
            to={`/configuracoes/logistica-reversa/motivos/${id}/editar`}
          >
            <S.EditIcon />
          </S.LinkActionButton>
        </S.ActionsColumn>
      </S.ListItem>
    );
  }, []);

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="description" label="DESCRIÇÃO" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {reasons.map((reason) => (
        <Item key={reason.id} reason={reason} />
      ))}
    </S.List>
  );
};
