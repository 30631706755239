import * as Yup from 'yup';
import { getProductShape } from 'validators/Common/baseTransitTimeValidator';

export class CreateTransitTimeValidator {
  public get schema() {
    return Yup.object().shape({
      ...getProductShape()
    });
  }
}
