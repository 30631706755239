import styled from "styled-components";
import { Fonts } from "styles/constants";
import { TriangleLeftIcon, TriangleRightIcon } from "styles/components";

export const Line = styled.div<{ dye?: string; size?: number | string }>`
  background-color: ${({ dye }) => (dye ? dye : "#57575e")};
  display: flex;
  width: ${({ size }) => (size ? `${size}px` : `220px`)};
  height: 2px;
  align-items: center;
  justify-content: center;
  animation: lineAnim 500ms;
  @keyframes lineAnim {
    from {
      transform: scaleX(0.5);
    }
    to {
      transform: scaleX(1);
    }
  }
`;

export const Circle = styled.div<{ dye?: string }>`
  background-color: ${({ dye }) => (dye ? dye : "#57575e")};
  border-radius: 10px;
  width: 8px;
  height: 8px;
`;

export const Left = styled(TriangleLeftIcon).attrs({ width: 12, height: 12 })<{
  dye?: string;
}>`
  margin-right: -3px;
  color: ${({ dye }) => (dye ? dye : "#57575e")};
`;

export const Right = styled(TriangleRightIcon).attrs({
  width: 12,
  height: 12,
})<{ dye?: string }>`
  margin-left: -3px;
  color: ${({ dye }) => (dye ? dye : "#57575e")};
`;

export const Box = styled.div<{ dye?: string; textColor?: string }>`
  background-color: ${({ dye }) => (dye ? dye : "#57575e")};
  &.animate {
    animation: boxAnimation 500ms;
    @keyframes boxAnimation {
      from {
        transform: scale(0.5);
      }
      to {
        transform: scale(1);
      }
    }
  }
  color: ${({ textColor }) => (textColor ? textColor : "white")};
  border-radius: 5px;
  text-align: center;
  padding: 4px 7px 4px 7px;
  font-size: 13px;
  font-family: ${Fonts.GilroySemiBold};
`;

export const Container = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
`;
