import { Formatter } from "utils";
import { api, notify } from "services";
import { useAuth } from "hooks";
import { useDispatch } from "react-redux";
import { FetchCollectActions as FetchActions } from "store/ducks/collects";
import { ConfirmationDialog, ConfirmationDialogRef } from "components/Shared";
import * as S from "./styles";
import { useRef } from "react";
import { usePermission } from "hooks/usePermission";

export const Allowance = ({ collect }: any) => {
  let {
    collectOficialDeadline,
    collectDate,
    collectDeliveryDeadline,
    deliveryDate,
    allowances,
  } = collect;
  const currentDate = new Date();
  const collectOficialDeadlineFormat = new Date(collectOficialDeadline);
  const collectDateFormat = new Date(collectDate);
  const collectDeliveryDeadlineFormat = new Date(collectDeliveryDeadline);
  const deliveryDateFormat = new Date(deliveryDate);
  const { profile, userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission();
  //   const isCarrierMember = userBelongsToAnyOf("carrierMember");
  const isCarrierMember = hasPermissionTo([
    "CARRIER-MEMBERS.FINDMEMBERS",
    "CARRIER-MEMBERS.FINDMEMBER",
  ]);
  const dialogRef = useRef<ConfirmationDialogRef>(null);
  const dispatch = useDispatch();

  interface AllowanceItem {
    createdAt: string;
    deletedAt: string | null;
    id: number;
    reverseLogisticId: number;
    type: string;
    updatedAt: string;
    user: {
      id: number;
      name: string;
    };
    userId: number;
  }

  let collectDelay = false;
  if (
    (currentDate > collectOficialDeadlineFormat && !collectDate) ||
    collectDateFormat > collectOficialDeadlineFormat
  )
    collectDelay = true;

  const collectAllowance = allowances.find(
    (item: AllowanceItem) => item.type === "collect"
  );

  let deliveryDelay = false;
  if (
    (collectDeliveryDeadline &&
      currentDate > collectDeliveryDeadlineFormat &&
      !deliveryDate) ||
    (collectDeliveryDeadline &&
      deliveryDateFormat > collectDeliveryDeadlineFormat)
  )
    deliveryDelay = true;

  //se tiver passado 20 dias da coleta, considerar atrasado mesmo que esteja dentro do prazo de entrega

  // Converter a string para um objeto Date
  if (collectDate && !deliveryDelay && !deliveryDate) {
    const dataColeta = new Date(collectDate);

    // Adicionar 20 dias à data original
    dataColeta.setDate(dataColeta.getDate() + 20);

    // Verificar se a data atual é maior do que a data original + 20 dias
    if (new Date() > dataColeta && !deliveryDelay) {
      deliveryDelay = true;
    }
  }

  const deliveryAllowance = allowances.find(
    (item: AllowanceItem) => item.type === "delivery"
  );

  const handleAllowance = async (type: string) => {
    const tipoMsg = type == "collect" ? "coleta" : "entrega";

    const data = {
      reverseLogisticId: collect.id,
      type,
      userId: profile?.userId,
    };

    const confirmed = await dialogRef.current?.openDialog({
      title: `Deseja abonar o atraso de ${tipoMsg}?`,
    });

    if (confirmed) {
      try {
        await api.post(`reverse-logistics/allowance`, data);
        notify("success", `Atraso de ${tipoMsg} abonado`);
        dispatch(FetchActions.request(collect.id));
      } catch (err) {
        notify("error", `${err}`);
      }
    }
  };

  return (
    <>
      {(collectDelay || deliveryDelay) && (
        <S.BoxDelay>
          <ConfirmationDialog ref={dialogRef} />
          {collectDelay && (
            <S.TagDelay className="coleta">
              <S.Text
                className={(collectAllowance && "coleta abonado") || "coleta"}
              >
                Atraso na coleta do material, data prevista:{" "}
                {(collectOficialDeadline &&
                  Formatter.date(collectOficialDeadline)) ||
                  "---"}
                ,
                {(collectDate &&
                  ` material coletado em ${Formatter.date(collectDate)}`) ||
                  " material não coletado até o momento"}
              </S.Text>
              {collectAllowance && (
                <S.Info>
                  Abonado em {Formatter.date(collectAllowance.createdAt)}
                  <span> por {collectAllowance.user.name}</span>
                </S.Info>
              )}
              {hasPermissionTo(["REVERSE-LOGISTICS.UPDATEREVERSELOGISTIC"]) && (
                <S.ButtonAllowance
                  disabled={collect.deletedAt || collect.finishDate}
                  onClick={() => handleAllowance("collect")}
                >
                  Abonar
                </S.ButtonAllowance>
              )}
            </S.TagDelay>
          )}
          {deliveryDelay && (
            <S.TagDelay>
              <S.Text>
                Atraso na entrega do material, data prevista:{" "}
                {(collectDeliveryDeadline &&
                  Formatter.date(collectDeliveryDeadline)) ||
                  "---"}
                ,
                {(deliveryDate &&
                  ` material entregue em ${Formatter.date(deliveryDate)}`) ||
                  " material não entregue até o momento"}
              </S.Text>
              {deliveryAllowance && (
                <S.Info>
                  Abonado em {Formatter.date(deliveryAllowance.createdAt)}
                  <span> por {deliveryAllowance.user.name}</span>
                </S.Info>
              )}
              {hasPermissionTo(["REVERSE-LOGISTICS.UPDATEREVERSELOGISTIC"]) && (
                <S.ButtonAllowance
                  disabled={
                    collect.deletedAt ||
                    collect.finishDate ||
                    !hasPermissionTo([
                      "REVERSE-LOGISTICS.UPDATEREVERSELOGISTIC",
                    ])
                  }
                  onClick={() => handleAllowance("delivery")}
                >
                  Abonar
                </S.ButtonAllowance>
              )}
            </S.TagDelay>
          )}
        </S.BoxDelay>
      )}
    </>
  );
};
