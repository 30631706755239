import {
  ListCitiesActions as Actions,
  ListCitiesRequestAction as RequestAction,
} from "./../../ducks/cities/listCities";
import { call, put } from "@redux-saga/core/effects";
import { api, notify, apiErrorHandler, applyQueryString } from "services";

export function* listCitiesRequest(action: any) {
  const { query = {}, onSuccess, onFailure }: RequestAction = action;
  try {
    const url = applyQueryString("cities", {
      ...query,
      asList: 1,
      orderBy: "name",
      direction: "asc",
    });
    const { data } = yield call(api.get, url);
    onSuccess && onSuccess();
    yield put(Actions.success(data, data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify("error", errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
