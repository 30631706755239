import { ModalStatus } from 'components/Shared/ModalStatus';
import { TracePoint } from 'components/Shared/TracePoint';
import { useCallback, useEffect, useState, useMemo, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    FetchPendingReleaseActions as FetchActions,
    FetchCorrectionLetterActions,
} from 'store/ducks/pendingRelease';
import { RecalculateTransitTimeActions as RecalculateTT } from 'store/ducks/transitTime';
import { Status } from '../Status';
import { FileHelper, Formatter } from 'utils';
import { ItemInfo } from './ItemInfo';
import * as S from './styles';
import { Navigation } from '../Navigation';
import { Boxes } from './Boxes';
import { Loading } from 'components/Shared/Loading';
import { ModalJustifications } from 'components/Shared/ModalJustifications';
import { notify } from 'services';
import { Supplier } from 'contracts/Supplier';
import { Company } from 'contracts/Company';
import { usePermission } from 'hooks/usePermission';
import { FilePDFIcon, FileXMLIcon } from 'styles/components';
import { DownloadIcon } from 'lucide-react';

import { useJustificationsColumns } from '../InvoiceModal/columns/justificationsColumns';
import { DataTable } from 'components/ui/DataTable';
import { useInvoiceJustifications } from 'hooks';
import ModalNewJustification, {
    ModalNewJustificationHandles,
} from 'components/Shared/ModalNewJustification';

export const modalNewJustificationRef =
    createRef<ModalNewJustificationHandles>();

export const InvoiceDetails = () => {
    const { hasPermissionTo } = usePermission();
    const location = useLocation();
    const [isReleased, setIsReleased] = useState<boolean>(false);
    const [invoiceKey, setInvoiceKey] = useState<string>('');
    const { invoiceJustifications, fetchInvoiceJustifications } =
        useInvoiceJustifications();


    const invoiceId = useMemo(() => {
        return parseInt(location.pathname.replace(/[^0-9]/g, ''));
    }, [location]);

    const nowLocation = location.pathname.match(/^(.*)\/\d+$/);
    const delivered = '/inbound/notas-fiscais/entregues';
    const finished = '/inbound/notas-fiscais/finalizadas';

    const showOccurrences = nowLocation
        ? [delivered, finished].includes(nowLocation[1])
        : false;

    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const { data: invoice, loading } = useSelector(
        (state: RootState) => state.fetchPendingRelease
    );


    const { columns } = useJustificationsColumns(invoiceJustifications, invoice);

    const onBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const fetchInvoice = useCallback((): void => {
        dispatch(FetchActions.request(invoiceId));
    }, [dispatch, invoiceId]);

    useEffect(() => {
        fetchInvoice();
    }, [fetchInvoice]);

    useEffect(() => {
        if (invoice) {
            setInvoiceKey(invoice.protocolo.protnfe_infprot_chnfe);
            setIsReleased(true);
            // if (invoice?.statuses[0].statusId !== 9) {
            //     onBack();
            //     notify('error', 'Nota fiscal não está em trânsito');
            // } else {
            //     setIsReleased(true);
            // }
        }
    }, [invoice]);

    const isImport = invoice?.destEnderdestUf
        ? ['EXTERIOR', 'EX'].includes(invoice.destEnderdestUf)
        : false;
    const { company, supplier } = invoice ?? {};
    const finalOrigin: Supplier | Company | undefined = isImport
        ? company
        : supplier;
    const finalDestiny: Supplier | Company | undefined = isImport
        ? supplier
        : company;

    const importDeclaration =
        isImport && invoice?.items && invoice?.items?.length > 0
            ? invoice?.items[0].importDeclaration
            : null;

    const correctionLetters =
        invoice &&
        invoice.correctionLetters &&
        invoice.correctionLetters.length > 0
            ? invoice.correctionLetters
            : [];

    const handleDownloadFile = useCallback(
        (letterId: number) => {
            dispatch(
                FetchCorrectionLetterActions.request(
                    letterId,
                    (url: string) => {
                        window.open(url, '_blank', 'noopener,noreferrer');
                    }
                )
            );
        },
        [dispatch]
    );

    useEffect(() => {
        fetchInvoiceJustifications(invoice?.id);
    }, [fetchInvoiceJustifications, invoice?.id]);

    

    return (
        <S.Container>
            <Navigation />
            <S.Header>
                {loading && (
                    <S.Title>
                        <S.InvoiceIcon />
                        <Loading />
                    </S.Title>
                )}
                {!loading && invoice && (
                    <>
                        <S.Invoice>
                            <S.Title>
                                <S.InvoiceIcon />
                                {invoice.infnfeIdeNnf}-{invoice.infnfeIdeSerie}
                            </S.Title>
                            {invoice.statuses && invoice.statuses.length > 0 ? (
                                <Status
                                    color={invoice.statuses[0].status.color}
                                >
                                    {invoice.statuses[0].status.name}
                                </Status>
                            ) : (
                                <Status>Sem status</Status>
                            )}
                        </S.Invoice>
                    </>
                )}
                <S.Back onClick={onBack}>
                    <S.ArrowLeftIcon />
                    Voltar
                </S.Back>
            </S.Header>
            {isReleased && invoice && (
                <>
                    <S.HeaderInfo>
                        <div className="min-w-[890px] grid grid-flow-row grid-cols-3 gap-x-6 gap-y-8">
                            <div className="detail flex flex-col gap-2">
                                <div className="label text-sm font-GilroySemibold">
                                    Origem{' '}
                                    {isImport ? 'Internacional' : 'Nacional'}
                                </div>
                                <div className="value text-xs font-sans">
                                    {finalOrigin?.tradeName}
                                </div>
                                <div className="value text-xs font-sans">
                                    {finalOrigin?.addressCity
                                        ? finalOrigin.addressCity
                                        : '----'}
                                </div>
                                <div className="value text-xs font-sans">
                                    {finalOrigin?.document && !isImport
                                        ? Formatter.cnpj(finalOrigin?.document)
                                        : '----'}
                                </div>
                            </div>
                            <div className="detail flex flex-col gap-2">
                                <div className="label text-sm font-GilroySemibold">
                                    Destino
                                </div>
                                <div className="value text-xs font-sans">
                                    {finalDestiny?.tradeName}
                                </div>
                                <div className="value text-xs font-sans">
                                    {finalDestiny?.addressCity
                                        ? finalDestiny.addressCity
                                        : '----'}
                                </div>
                                <div className="value text-xs font-sans">
                                    {finalDestiny?.document
                                        ? Formatter.cnpj(finalDestiny.document)
                                        : '----'}
                                </div>
                            </div>
                            <div className="detail flex flex-col gap-2">
                                <div className="label text-sm font-GilroySemibold">
                                    Transportadores
                                </div>
                                <div className="value text-xs font-sans">
                                    {invoice?.carrier?.tradeName || '----'}
                                </div>
                                <div className="value text-xs font-sans">
                                    {invoice?.carrier?.addressCity
                                        ? invoice.carrier.addressCity
                                        : '----'}
                                </div>
                                <div className="value text-xs font-sans">
                                    {invoice?.carrier?.document
                                        ? Formatter.cnpj(
                                              invoice.carrier.document
                                          )
                                        : '----'}
                                </div>
                            </div>
                            <div className="detail flex flex-col gap-2">
                                <div className="label text-sm font-GilroySemibold">
                                    Data de emissão
                                </div>
                                <div className="value text-xs font-sans">
                                    {Formatter.date(invoice.infnfeIdeDhemi)}
                                </div>
                            </div>
                            <div className="detail flex flex-col gap-2">
                                <div className="label text-sm font-GilroySemibold">
                                    Prazo contratado
                                </div>
                                <div className="value text-xs font-sans">
                                    {invoice.deadlineDate
                                        ? Formatter.date(invoice.deadlineDate)
                                        : '--/--/----'}
                                </div>
                            </div>
                            <div className="detail flex flex-col gap-2">
                                <div className="label text-sm font-GilroySemibold">
                                    Data liberação
                                </div>
                                <div className="value text-xs font-sans">
                                    {invoice.releasedAt
                                        ? Formatter.date(invoice.releasedAt)
                                        : '--/--/----'}
                                </div>
                            </div>

                            {invoice.deliveryDate && (
                                <div className="detail flex flex-col gap-2">
                                    <div className="label text-sm font-GilroySemibold">
                                        Data de entrega
                                    </div>
                                    <div className="value text-xs font-sans">
                                        {invoice.deliveryDate
                                            ? Formatter.date(
                                                  invoice.deliveryDate
                                              )
                                            : '--/--/----'}
                                    </div>
                                </div>
                            )}

                            {invoice.abonedAt && (
                                <div className="detail flex flex-col gap-2">
                                    <div className="label text-sm font-GilroySemibold">
                                        Data do abono
                                    </div>
                                    <div className="value text-xs font-sans">
                                        {Formatter.date(invoice.abonedAt)}
                                    </div>
                                </div>
                            )}

                            {invoice.returnDate && (
                                <div className="detail flex flex-col gap-2">
                                    <div className="label text-sm font-GilroySemibold">
                                        Data GR
                                    </div>
                                    <div className="value text-xs font-sans">
                                        {invoice.returnDate
                                            ? Formatter.date(invoice.returnDate)
                                            : '--/--/----'}
                                    </div>
                                </div>
                            )}

                            {invoice.sapCode && (
                                <div className="detail flex flex-col gap-2">
                                    <div className="label text-sm font-GilroySemibold">
                                        Código SAP
                                    </div>
                                    <div className="value text-xs font-sans">
                                        {invoice.sapCode || '---'}
                                    </div>
                                </div>
                            )}

                            {importDeclaration && (
                                <div className="detail flex flex-col gap-2">
                                    <div className="label text-sm font-GilroySemibold">
                                        Local de Coleta
                                    </div>
                                    <div className="value text-xs font-sans">
                                        {importDeclaration.diXlocdesemb} -{' '}
                                        {importDeclaration.diUfdesemb}
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <S.Title>
                            <S.DetailIcon />
                            Informações
                        </S.Title>
                        <S.Item>
                            Data de emissão
                            <S.Info>
                                {invoice?.infnfeIdeDhemi
                                    ? Formatter.date(invoice.infnfeIdeDhemi)
                                    : '--/--/----'}
                            </S.Info>
                        </S.Item>
                        <S.Item>
                            Previsão de entrega
                            <S.Info>
                                {invoice?.deliveryDate
                                    ? Formatter.date(invoice.deliveryDate)
                                    : '--/--/----'}
                            </S.Info>
                        </S.Item>
                        <S.Item>
                            Prazo contratado
                            <S.Info>
                                {invoice?.deadlineDate
                                    ? Formatter.date(invoice.deadlineDate)
                                    : '--/--/----'}
                            </S.Info>
                        </S.Item>
                        {hasPermissionTo(['TRACKING.RECALCULATEDUE']) && (
                            <S.Button
                                onClick={recalculate}
                                disabled={loadingTT}
                            >
                                {loadingTT ? (
                                    <Loading color="white" />
                                ) : (
                                    <S.EditAltIcon height={'18px'} />
                                )}
                                Recalcular
                            </S.Button>
                        )}
                        <div />
                        <S.Item>
                            Observações
                            <S.Info>{invoice?.note ?? '---'}</S.Info>
                        </S.Item>
                        <S.Item>
                            Veículo
                            <S.Info>{invoice?.vehicle ?? '---'}</S.Info>
                        </S.Item>
                        <S.Item>
                            Tipo de Veículo
                            <S.Info>
                                {invoice?.vehicleType?.name ?? '---'}
                            </S.Info>
                        </S.Item> */}
                    </S.HeaderInfo>

                    {correctionLetters.length > 0 && (
                        <S.HeaderInfo>
                            <div className="correctionGrid flex flex-col border-b-[1px] border-gray-400 mb-6">
                                <div className="detailHeader font-GilroySemibold text-lg">
                                    Carta de Correção de Transportadoras
                                </div>
                                <ul className="flex flex-col gap-1 my-2">
                                    {correctionLetters.map((letter) => (
                                        <li
                                            className="grid gap-2 items-center grid-cols-3 text-sm"
                                            key={letter.id}
                                        >
                                            <span>
                                                {letter.file_ext === 'xml' ? (
                                                    <FileXMLIcon />
                                                ) : (
                                                    <FilePDFIcon />
                                                )}
                                            </span>
                                            <span>
                                                {FileHelper.getFileNameByUrl(
                                                    letter.file_url
                                                )}
                                            </span>
                                            <S.AnchorButton
                                                href="#"
                                                onClick={() =>
                                                    handleDownloadFile(
                                                        letter.id
                                                    )
                                                }
                                                size="small"
                                                mood="void"
                                                title="Fazer download"
                                            >
                                                <DownloadIcon />
                                            </S.AnchorButton>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </S.HeaderInfo>
                    )}

                    {showOccurrences &&
                        invoice &&
                        invoice.occurrences.length > 0 && (
                            <S.HeaderInfo>
                                <div className="flex flex-col border-b-[1px] border-gray-400 mb-6 pb-6">
                                    <div className="detailHeader font-GilroySemibold text-lg">
                                        Ocorrências
                                    </div>
                                    <ul className="flex flex-col gap-1 my-2">
                                        <li className="grid gap-2 items-center grid-cols-3 py-1 text-xs">
                                            <strong>#</strong>
                                            <strong>Tipo de Ocorrência</strong>
                                            <strong>Data</strong>
                                        </li>
                                        {invoice.occurrences.map(
                                            (occurrence, index) => (
                                                <li
                                                    key={index.toString()}
                                                    className="grid gap-2 items-center grid-cols-3 py-1 text-xs"
                                                >
                                                    <span>{`#${
                                                        index + 1
                                                    }`}</span>
                                                    <span>
                                                        {
                                                            occurrence
                                                                .occurrenceType
                                                                .name
                                                        }
                                                    </span>
                                                    <span>
                                                        {occurrence.createdAt
                                                            ? Formatter.dateFull(
                                                                  occurrence.createdAt
                                                              )
                                                            : '--/--/----'}
                                                    </span>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </S.HeaderInfo>
                        )}

                    <ItemInfo invoice={invoice} />

                    {showOccurrences && (
                        <S.HeaderInfo>
                            <div className="w-full">
                                <div className="detailHeader font-GilroySemibold text-lg">
                                            Justificativas
                                </div>
                                <DataTable
                                    data={invoiceJustifications ?? []}
                                    columns={columns}
                                    showControlColumns={false}
                                />
                            </div>
                        </S.HeaderInfo>
                    )}
                    
                    <S.Box>
                        <ModalStatus
                            invoice={invoice}
                            title={'Timeline Atividades'}
                        />
                    </S.Box>
                </>
            )}
            <ModalNewJustification
                invoice={invoice}
                ref={modalNewJustificationRef}
            />
        </S.Container>
    );
};
