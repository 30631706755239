import { css } from 'styled-components';

export enum Fonts {
  GilroyBold = 'Gilroy-Bold',
  GilroySemiBold = 'Gilroy-SemiBold',
  GilroyRegular = 'Gilroy-Regular',
  OpenSans = 'Open Sans',
}

export enum Colors {
  Black = '#000000',
  White = '#FFFFFF',
  Blue = '#1AB1F3',
  Magenta = '#DE1745',
  Orange20 = '#ED711A',
  Orange = '#EE7C2C',
  Orange10 = '#ED8C49',
  Gold = '#EEB22C',
  Yellow10 = '#FFEBAF',
  Yellow = '#FFCD38',
  PaleGold = '#fad390',
  Green10 = '#DAFFEC',
  Green = '#0CC769',
  Peach = '#FDEBDE',
  Gray10 = '#F5F5F5',
  Gray20 = '#F0F0F0',
  Gray30 = '#E9E9E9',
  Gray40 = '#D1D1D1',
  Gray50 = '#96969A',
  Gray60 = '#57575E',
  Gray70 = '#2E2E36',
}

export enum ColorScheme {
  Text = Colors.Gray70,
  LightText = Colors.Gray50,
  Primary = Colors.Orange,
  Secondary = Colors.Green,
  Danger = Colors.Magenta,
  Warning = Colors.Gold,
  DisabledField = Colors.Gray20,
  Alert = Colors.PaleGold,
}

export enum OrderStatusColor {
  Aguardando = '#3498DB',
  Chegada = '#673AB7',
  Iniciado = '#F9A82A',
  Finalizado = '#0F97A7',
  Liberado = '#3BBCA3',
  NoShow = '#FE6043',
  Cancelado = '#EC1751',
}

export const getScrollbarStyle = (
  bgColor = Colors.Gray30,
  fgColor = Colors.Gray50
) => {
  return css`
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    ::-webkit-scrollbar:vertical {
      width: 8px;
    }
    ::-webkit-scrollbar:horizontal {
      height: 8px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 6px;
      border: 2px solid ${bgColor};
      background-color: ${fgColor};
    }
    ::-webkit-scrollbar-track {
      background-color: ${bgColor};
      border-radius: 6px;
    }
  `;
};
