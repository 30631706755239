import { Fonts } from 'styles/constants';
import { BasePanel } from 'styles/components';
import { BaseButtonStyle } from 'styles/components';
import { Colors } from 'styles/constants';
import styled from "styled-components";
import { FormActions as _FormActions } from 'styles/components';
import { Select, Input } from "components/Shared";
import { CloseIcon } from 'styles/components'
export {
  InvoiceIcon,
  ClientIcon,
  CompanyIconOutline,
  DetailIcon,
  TruckIcon,
  EditAltIcon,
  ListIcon,
  UserRequestIcon,
  AuditorIcon,
} from 'styles/components';

export const Empty = styled(BasePanel)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 342px;
  height: 50px;
`;

export const Close = styled(CloseIcon)`
  display: flex;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  height: 20px;
  color: ${Colors.Gray50};
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-family: ${Fonts.GilroyRegular};
  font-size: 16px;
  height: 40px;
  gap: 10px;
  white-space: nowrap;
`;

export const TitleInfo = styled.div`
  display: flex;
  width: 100%;
  grid-template-columns: auto auto 143px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: ${Fonts.GilroyRegular};
  font-size: 16px;
  gap: 10px;
  margin-bottom: 13px;
  padding-bottom: 13px;
  border-bottom: 1px dashed ${Colors.Gray50};
`;

export const TitleInfoText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Button = styled.button`
  display: flex;
  ${BaseButtonStyle}
  background-color: ${Colors.Blue};
  color: ${Colors.White};
  font-family: ${Fonts.GilroySemiBold};
  font-size: 12px;
  padding: 3px 10px 3px 3px;
  justify-self: flex-end;
  border-radius: 4px;
  gap: 4px;
  white-space: nowrap;
`;

export const FormButton = styled(Button)<{theme?: string}>`
  background-color: ${({theme})=> theme === 'accept' ? Colors.Orange : Colors.Gray60};
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 35px;
  padding: 10px;
  width: 50%;
`;


export const Boxes = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: row;
  padding: 0 30px;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 24px;
  /* margin-bottom: 32px;
  overflow-x: scroll; */
  @media (max-width: 1200px) {
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
  }

  .splide__arrow {
    background-color: transparent !important; 
  }

  .splide__arrow--prev {
    left: -30px;
  }

  .splide__arrow--next {
    right: -30px;
  }
`;

export const Box = styled(BasePanel)`
  display: flex;
  font-family: ${Fonts.GilroySemiBold};
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 20px;
  min-width: calc(33% - 64px);
  height: minmax(230px, auto);
`;

export const BoxBody = styled.div`
  display: grid;
  gap: 15px;
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 5px;
  justify-content: center;
`;

export const Info = styled.div`
  font-family: ${Fonts.GilroyRegular};
  width: auto;
  font-size: 12px;
`;

export const InfoLast = styled.div`
  font-family: ${Fonts.GilroyRegular};
  width: auto;
  font-size: 12px;
  line-height: 1.2rem;
  height: 2.4rem;
  overflow: hidden;
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background-color: ${Colors.Black}cc;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  z-index: 999;
  overflow: hidden;
`;

export const ModalContainer = styled(BasePanel)`
  position: relative;
  width: 350px;
  /* height: 230px; */
  align-items: start;
  justify-content: start;
  flex-direction: column;
  padding: 24px;
  animation: contentShow 150ms;

  form {
    width: 100%;
  }
  
  @keyframes contentShow {
    from { opacity: 0; transform: scale(.96) }
    to { opacity: 1; transform: scale(1) }
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  gap: 8px;
  font-family: ${Fonts.GilroyBold};
  font-size: 1.25rem;
  width: 100%;
  align-items: center;
  justify-content: start;
  border-bottom: 1px solid ${Colors.Gray30};
  margin-bottom: 13px;
  padding-bottom: 13px;
`;


export const FormActions = styled(_FormActions)`
  justify-content: center;
`;

export const SelectCarrier = styled(Select)`
  width: 100%;
`;

export const InputModal = styled(Input)`
  width: 100%;
`

