import styled, { css } from "styled-components"
import { BasePaginatorHeader, BasePaginatorListItem } from "styles/components"
import { Colors, Fonts, getScrollbarStyle } from "styles/constants"
export {
  PaginatorColumn as Column,
  PaginatorActionsColumn as ActionsColumn,
  ActionButton,
  LinkActionButton,
  EditIcon,
  TrashIcon,
  ActivityIndicator,
  LoginIcon,
  LockIcon,
  CloseIcon,
} from "styles/components"

export const List = styled.div`
  display: flex;
  flex-direction: column;
`

const baseGridCss = css`
  grid-template-columns: 60px 240px auto minmax(0px, max-content);
`

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
  & span {
    font-size: 13px;
  }
`

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`
