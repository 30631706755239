import * as Yup from "yup";
import {
  getCollectDateShape,
  getCollectDeadlineShape,
} from "validators/Common/baseCollectValidator";

export class UpdateCollectValidator {
  public get schemaDate() {
    return Yup.object().shape({
      ...getCollectDateShape(),
    });
  }
  public get schemaDeadline() {
    return Yup.object().shape({
      ...getCollectDeadlineShape(),
    });
  }
}
