import * as Yup from 'yup';
import {
  getOperationShape,
} from 'validators/Common/baseOperationValidator';

export class CreateOperationValidator {

  public get schema() {
    return Yup.object().shape({
      ...getOperationShape(),
    });
  }
}
