import * as Yup from 'yup';
import { getProductShape } from 'validators/Common/baseJustificationValidator';

export class CreateJustificationValidator {
  public get schema() {
    return Yup.object().shape({
      ...getProductShape(),
      type: Yup.number()
      .typeError('Informe o tipo')
      .required('Informe o tipo')
    });
  }
}
