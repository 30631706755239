import { SettingLayout } from 'layouts/SettingLayout';
import React from 'react';
import { PerfilInnerOptions } from '../PerfisInnerOptions';
import { Box } from 'components/ui/Box';
import CreatePerfilForm from './CreateForm';


const CreatePerfil: React.FC = () => {
    const {options} = PerfilInnerOptions()
  return <SettingLayout options={options}>
    <Box>
        <CreatePerfilForm/>
    </Box>
  </SettingLayout>
}

export default CreatePerfil;