import { Modal, Select } from "components/Shared"
import React, { useEffect, useRef } from "react"
import {
  FetchWarehouseMemberActions as FetchActions,
  UpdateWarehouseMemberActions as UpdateActions,
} from "store/ducks/warehouseMembers"
import * as S from "./styles"
import { Button } from "../CreationForm/styles"
import { useCompanies, useValidation } from "hooks"
import { Form } from "@unform/web"
import { FormHandles } from "@unform/core"
import { AddWarehouseForMemberValidator } from "validators/WarehouseMembers/AddWarehouseForMember"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { WarehouseMember } from "contracts/WarehouseMembers"

interface AddWarehouseModalProps {
  refresh: () => void
  member: WarehouseMember | null
  onCloseButton: () => void
  isOpen: boolean
}

type FormData = {
  companyId: number
}

const AddWarehouseModal = ({
  onCloseButton,
  isOpen = false,
  refresh,
  member,
}: AddWarehouseModalProps) => {
  const dispatch = useDispatch()
  const { companyOptions, loadingCompanies, fetchCompanies } = useCompanies()
  const formRef = useRef<FormHandles>(null)
  const { handleFormErrors, handleApiErrors } = useValidation()

  const { loading: updatingMember, validationErrors } = useSelector(
    (state: RootState) => state.updateWarehouseMember
  )

  const onSuccess = () => {
    refresh()
    formRef?.current?.reset()
  }

  const onSubmit = async (data: FormData) => {
    try {
      if (!member) return

      const { companyUser, ...user } = member
      formRef?.current?.setErrors({})
      const formatData = {
        userId: user.id,
        companyId: [data.companyId],
        user: user,
      }

      const { schema } = new AddWarehouseForMemberValidator()

      const validData = await schema.validate(formatData, {
        abortEarly: false,
      })

      dispatch(UpdateActions.request(user.id, validData, onSuccess))
    } catch (error) {
      handleFormErrors(error, formRef)
    }
  }

  useEffect(() => {
    fetchCompanies()
  }, [fetchCompanies])

  useEffect(() => {
    handleApiErrors(validationErrors, formRef)
  }, [handleApiErrors, validationErrors])

  return (
    <Modal isOpen={isOpen}>
      <S.ModalContent style={{ maxWidth: "960px", height: "auto" }}>
        <S.ModalContainer>
          <S.ModalHeader>
            <div className="wrapper">
              <S.ModalHeaderTitle>Associar novo armazém</S.ModalHeaderTitle>
            </div>
            <S.CloseButton onClick={onCloseButton}>
              <S.CloseIcon />
            </S.CloseButton>
          </S.ModalHeader>
          <S.ModalBody>
            <Form ref={formRef} onSubmit={onSubmit}>
              <Select
                name="companyId"
                label="Armazém"
                options={companyOptions}
                isLoading={loadingCompanies}
              />
            </Form>
          </S.ModalBody>
          <S.ModalFooter>
            <Button onClick={() => {}} mood="light" disabled={updatingMember}>
              Fechar
            </Button>
            <Button
              disabled={updatingMember}
              onClick={() => {
                formRef.current?.submitForm()
              }}
            >
              {updatingMember ? <S.ActivityIndicator /> : "Salvar"}
            </Button>
          </S.ModalFooter>
        </S.ModalContainer>
      </S.ModalContent>
    </Modal>
  )
}

export default AddWarehouseModal
