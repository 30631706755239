import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  HiddenInput,
  Input,
  MaskedInput,
  Select,
  FormPageHeader,
  LogoCropper,
} from 'components/Shared';
import { FORM_BACK_ACTION, SELECT_OPTIONS } from 'constants/Common';
import type { SupplierDocumentType, SelectOption } from 'contracts/Common';
import type { Supplier } from 'contracts/Suppliers';
import { useAddressLookup, useCountries, useValidation } from 'hooks';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import {
  FetchSupplierActions as FetchActions,
  UpdateSupplierActions as UpdateActions,
} from 'store/ducks/suppliers';
import { Formatter } from 'utils';
import { UpdateSupplierValidator } from 'validators/Suppliers';
import * as S from './styles';

interface Props {
  supplierId: string | number;
  onUpdate?: () => void;
}

export const SupplierUpdateForm: React.FC<Props> = ({ supplierId, onUpdate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { onZipcodeChange, fetchingAddress } = useAddressLookup(formRef);
  const { countryOptions, loadingCountries, fetchCountries } = useCountries();

  const [selectedType, setSelectedType] = useState<SupplierDocumentType>('cnpj');
  const [selectedCountryId, setSelectedCountryId] = useState<number>(1);
  const [logoFile, setLogoFile] = useState<File | undefined>();

  const { data: supplier, loading: fetchingSupplier } = useSelector(
    (state: RootState) => state.fetchSupplier
  );

  const { loading: updatingSupplier, validationErrors } = useSelector(
    (state: RootState) => state.updateSupplier
  );

  const documentLabel = useMemo(() => {
    if (selectedType === 'cnpj') return 'CNPJ';
    if (selectedType === 'cpf') return 'CPF';
    if (selectedType === 'other') return 'Documento';
  }, [selectedType]);

  const isEstranger = useMemo(() => selectedType === 'other', [selectedType])

  const documentMask = useMemo(() => {
    if (selectedType === 'cnpj') return '99.999.999/9999-99';
    if (selectedType === 'cpf') return '999.999.999-99';
    return [];
  }, [selectedType]);

  const fetchSupplier = useCallback((): void => {
    dispatch(FetchActions.request(supplierId));
  }, [dispatch, supplierId]);

  const onSupplierLoad = useCallback((): void => {
    if (!supplier) return;
    const { documentType, document, countryId, addressUf } = supplier;

    const autoSet: Array<keyof Supplier> = [
      'tradeName',
      'ibge',
      'addressStreet',
      'addressNumber',
      'addressComplement',
      'addressNeighborhood',
      'addressCity',
      'addressState',
      'addressZipcode',
      'addressLatitude',
      'addressLongitude',
    ];

    for (const [field, value] of Object.entries(supplier)) {
      if (autoSet.includes(field as keyof Supplier)) {
        formRef.current?.setFieldValue(field, value);
      }
    }

    // manually set fields

    const typeOption = SELECT_OPTIONS.DOCUMENT_TYPES.find(
      (o) => o.value === documentType
    );

    if (typeOption) {
      formRef.current?.setFieldValue('documentType', typeOption);
    }

    setSelectedType(documentType);
    formRef.current?.setFieldValue(
      'document',
      Formatter.document(document, documentType)
    );
    const countryOption = countryOptions.find((o) => o.value === countryId);

    setSelectedCountryId(countryId);
    if (countryOption) {
      formRef.current?.setFieldValue('countryId', countryOption);
    }

    const ufOption = SELECT_OPTIONS.STATES.find((o) => o.value === addressUf);

    if (ufOption) {
      formRef.current?.setFieldValue('addressUf', ufOption);
    }
  }, [supplier, countryOptions]);

  const onTypeChange = useCallback((option: SelectOption | null): void => {
    if (!option) return;
    setSelectedType(option.value as SupplierDocumentType);
    if(option.value === 'other') {
      formRef.current?.setFieldValue('addressZipcode', 'EX');
      formRef.current?.setFieldValue('addressState', 'EX');
      formRef.current?.setFieldValue('addressLatitude', 'EX');
      formRef.current?.setFieldValue('addressLongitude', 'EX');
    }
  }, []);

  const onCountryChange = useCallback((option: SelectOption | null): void => {
    if (!option) return;
    setSelectedCountryId(option.value as number);
  }, []);

  const onUfChange = useCallback((option: SelectOption | null): void => {
    if (!option) {
      formRef.current?.setFieldValue('addressState', '');
      return;
    }
    formRef.current?.setFieldValue('addressState', option.label);
  }, []);

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onUpdate && onUpdate();
  }, [onUpdate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});
        Object.assign(data, { logoFile });

        const { schema } = new UpdateSupplierValidator({
          selectedType,
          selectedCountryId,
        });

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(UpdateActions.request(supplierId, validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      supplierId,
      dispatch,
      handleFormErrors,
      logoFile,
      onSuccess,
      selectedCountryId,
      selectedType,
    ]
  );

  const Header = useCallback((): JSX.Element => {
    return (
      <FormPageHeader
        title="Editar fornecedor"
        icon={<S.FactoryIcon />}
        isLoading={fetchingSupplier}
        actions={
          <S.LinkButton size="small" to="/configuracoes/fornecedores">
            <S.ArrowLeftIcon /> Voltar
          </S.LinkButton>
        }
      />
    );
  }, [fetchingSupplier]);

  useEffect(() => {
    fetchCountries({ excludeBlocked: true });
  }, [fetchCountries]);

  useEffect(() => {
    fetchSupplier();
  }, [fetchSupplier]);

  useEffect(() => {
    onSupplierLoad();
  }, [onSupplierLoad]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(FetchActions.reset());
      dispatch(UpdateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <Header />
      <S.Content>
        <Form ref={formRef} onSubmit={onSubmit}>
          <HiddenInput name="ibge" readOnly />
          <S.FormRow>
            <Select
              name="documentType"
              label="Tipo de documento"
              options={SELECT_OPTIONS.DOCUMENT_TYPES}
              onChange={onTypeChange}
            />
            <Input
              name="tradeName"
              label={
                selectedType === 'cnpj' ? 'Nome fantasia' : 'Nome completo'
              }
            />
            {!!supplier?.documentType && (
              <>
                {(['cnpj', 'cpf'].includes(supplier.documentType as string) && (
                  <MaskedInput
                    name="document"
                    label={documentLabel}
                    mask={documentMask}
                  />
                )) || <Input name="document" label={documentLabel} />}
              </>
            )}
          </S.FormRow>

          <S.FormRow>
            <S.FormRow>
              <Select
                name="countryId"
                label="País"
                options={countryOptions}
                isLoading={loadingCountries}
                onChange={onCountryChange}
              />
              <Input
                name="addressZipcode"
                label="Código postal"
                onChange={onZipcodeChange}
                isLoading={fetchingAddress}
                readOnly={isEstranger}
                disabled={isEstranger}
              />
            </S.FormRow>
            <Input name="addressStreet" label="Logradouro" />
            <Input name="addressNumber" label="Número" />
          </S.FormRow>

          <S.FormRow>
            <Input name="addressComplement" label="Complemento" />
            <Input name="addressNeighborhood" label="Bairro" />
            <Input name="addressCity" label="Cidade" />
          </S.FormRow>

          <S.FormRow>
            {selectedType !== 'other' ? (
              <>
                <HiddenInput name="addressState" readOnly />
                <Select
                  name="addressUf"
                  label="Estado"
                  options={SELECT_OPTIONS.STATES}
                  onChange={onUfChange}
                />
              </>
            ) : (
              <Input
                name="addressState"
                label="Estado"
                readOnly={isEstranger}
                disabled={isEstranger}
                defaultValue={isEstranger ? 'EX': ''}
              />
            )}
            <Input
              name="addressLatitude" 
              label="Latitude"
              readOnly={isEstranger} 
              disabled={isEstranger}
            />
            <Input 
              name="addressLongitude" 
              label="Longitude"
              readOnly={isEstranger} 
              disabled={isEstranger}
            />
          </S.FormRow>

          <S.FormActions>
            <S.LinkButton mood="light" to="/configuracoes/fornecedores">
              {FORM_BACK_ACTION}
            </S.LinkButton>
            <S.Button type="submit">
              {updatingSupplier ? <S.ActivityIndicator /> : 'Salvar'}
            </S.Button>
          </S.FormActions>
        </Form>
        {/* <LogoCropper
          label="Logo do cliente"
          helpText="Uma imagem de ao menos 320 x 320px"
          onChange={(file) => setLogoFile(file)}
          preview={supplier?.logoUrl}
        /> */}
      </S.Content>
    </S.MainPanel>
  );
};
