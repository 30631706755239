import styled from 'styled-components';
import { Colors, ColorScheme, Fonts } from 'styles/constants';
import { FieldContainer } from 'styles/components';
import { FileIcon as File } from 'styles/components';
export {
  FieldError,
  FieldLabel,
  ActivityIndicator,
} from 'styles/components';

export const Container = styled(FieldContainer)`
  input {
    width: 100%;
    font-size: inherit;
    border-radius: 6px;
    background: ${Colors.White};
    border: 1px solid ${Colors.Gray50};
    color: ${ColorScheme.Text};
    height: 40px;
    padding: 0 8px;

    &[type=file] {
      display: inline-flex;
      align-items: center;
      padding: 0;
      margin: 0;
      border: none;
      
      &::file-selector-button {
        background-color: ${Colors.Gray70};
        border: none;
        height: 100%;
        margin: 0;
        color: white;
        padding: 4px 16px;
        border-radius: 6px;
        font-size: inherit;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          background-color: ${Colors.Gray60};
        }
      }
    }
  }
`;

export const CompactContent = styled.label`
  position: relative;
`

export const FileIcon = styled(File)`
  color: transparent;
  width: 35px;
  height: 35px;
  padding: 5px;
  border-radius: 50%;
  stroke: ${Colors.Gray20};
  stroke-width: 1.3pt;
  background-color: ${Colors.Orange};
  &:hover {
    background-color: ${Colors.Orange20};
    cursor: pointer;
  }
`;

export const LengthIndicator = styled.div`
  position: absolute;
  top: -3px;
  right: -4px;
  width: 14px;
  height: 14px;
  padding: 1px;
  background-color: #FF5252;
  color: ${Colors.White};
  font-family: ${Fonts.GilroyBold};
  font-size: 10px;
  text-align: center;
  border-radius: 50%;
  z-index: 10;
`;

export const RelativeWrapper = styled.div`
  display: flex;
  flex: 0;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const ActivityIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  width: 44px;
`;
