import styled, { css } from 'styled-components';
import { Colors, Fonts } from 'styles/constants';

interface TagProps {
  color?: string;
}
export const Tag = styled.div`
  font-family: ${Fonts.GilroySemiBold};
  font-size: 11px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${Colors.Gray40};
  width: min-content;

  span {
    display: block;
    color: ${Colors.White};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${(props: TagProps) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;
