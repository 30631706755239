import { GuestScaffold } from 'layouts';
import * as S from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch, RootState } from 'store';
import { useCallback, useEffect, useMemo } from 'react';
import { Formatter } from 'utils';
import { FetchPublicInvoiceActions as FetchActions } from "store/ducks/publicInvoice";
import { Loading } from 'components/Shared/Loading';
import { useQuery } from 'hooks/useQuery';

export const PublicInvoice = () => {
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams()
  const query = useQuery()

  const { data: invoice, loading } = useSelector(
    (state: RootState) => state.fetchPublicInvoice
  );

  const invoiceNumber = useMemo(() => `${id}/${query.get('token')}`, [id, query])

  const fetchInvoice = useCallback((): void => {
    if (!invoiceNumber) return;
    dispatch(FetchActions.request(invoiceNumber));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceNumber]);

  const ModalHeader = useCallback(() => {
    return (
      <S.ModalHeader>
        <div className="wrapper">
          <S.InvoiceIcon />
          {loading && <S.ActivityIndicator />}
          <S.InvoiceNumber>
            NF {invoice?.infnfeIdeNnf}-{invoice?.infnfeIdeSerie}
          </S.InvoiceNumber>
        </div>
      </S.ModalHeader>
    );
  }, [invoice, loading]);

  const ModalInfos = useCallback(() => {
    if (!invoice) return <></>;
    const {
      supplier,
      company,
      carrier,
      infnfeIdeDhemi,
      deadlineDate,
      releasedAt,
      deliveryDate,
      returnDate,
      sapCode,
      items,
      destEnderdestUf
    } = invoice;

    const isImport = destEnderdestUf ? ['EXTERIOR', 'EX'].includes(destEnderdestUf) : false;
    const importDeclaration = isImport && items.length > 0 ? items[0].importDeclaration : null;

    const finalOrigin = isImport ? company : supplier;
    const finalDestiny = isImport ? supplier : company;

    return (
      <S.MainGrid>
        <S.Detail>
          <S.DetailLabel>Origem {isImport ? 'Internacional' : 'Nacional'}</S.DetailLabel>
          <S.DetailValue>{finalOrigin?.tradeName}</S.DetailValue>
          <S.DetailValue>
            {finalOrigin?.addressCity ? finalOrigin.addressCity : "----"}
          </S.DetailValue>
          <S.DetailValue>
            {finalOrigin?.document && !isImport ? Formatter.cnpj(finalOrigin?.document) : "----"}
          </S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Destino</S.DetailLabel>
          <S.DetailValue>{finalDestiny?.tradeName}</S.DetailValue>
          <S.DetailValue>
            {finalDestiny?.addressCity ? finalDestiny.addressCity : "----"}
          </S.DetailValue>
          <S.DetailValue>
            {finalDestiny?.document ? Formatter.cnpj(finalDestiny.document) : "----"}
          </S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Transportadores</S.DetailLabel>
          <S.DetailValue>{carrier?.tradeName || "----"}</S.DetailValue>
          <S.DetailValue>
            {carrier?.addressCity ? carrier.addressCity : "----"}
          </S.DetailValue>
          <S.DetailValue>
            {carrier?.document ? Formatter.cnpj(carrier.document) : "----"}
          </S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Data de emissão</S.DetailLabel>
          <S.DetailValue>{Formatter.date(infnfeIdeDhemi)}</S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Prazo contratado</S.DetailLabel>
          <S.DetailValue>
            {deadlineDate ? Formatter.date(deadlineDate) : "--/--/----"}
          </S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Data liberação</S.DetailLabel>
          <S.DetailValue>
            {releasedAt ? Formatter.date(releasedAt) : "--/--/----"}
          </S.DetailValue>
        </S.Detail>
        {deliveryDate && (
          <S.Detail>
            <S.DetailLabel>Data da entrega</S.DetailLabel>
            <S.DetailValue>
              {deliveryDate ? Formatter.date(deliveryDate) : "--/--/----"}
            </S.DetailValue>
          </S.Detail>
        )}
        {returnDate && (
          <S.Detail>
            <S.DetailLabel>Data do Finalização</S.DetailLabel>
            <S.DetailValue>
              {returnDate ? Formatter.date(returnDate) : "--/--/----"}
            </S.DetailValue>
          </S.Detail>
        )}
        {sapCode && (
          <S.Detail>
            <S.DetailLabel>Código SAP</S.DetailLabel>
            <S.DetailValue>{sapCode || "---"}</S.DetailValue>
          </S.Detail>
        )}
        {sapCode && (
          <S.Detail>
            <S.DetailLabel>Código SAP</S.DetailLabel>
            <S.DetailValue>{sapCode || "---"}</S.DetailValue>
          </S.Detail>
        )}
        {importDeclaration && (
          <S.Detail>
            <S.DetailLabel>Local de Coleta</S.DetailLabel>
            <S.DetailValue>{importDeclaration.diXlocdesemb} - {importDeclaration.diUfdesemb}</S.DetailValue>
          </S.Detail>
        )}
      </S.MainGrid>
    );
  }, [invoice]);

  const ModalItems = useCallback(() => {
    if (!invoice) return <></>;
    const { items, volume } = invoice;
    const totalPrice = items
      .reduce((acc, item) => acc + item.prodVprod, 0)
      .toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    const itemsCount = `${items.length}`.padStart(2, "0");
    return (
      <>
        <S.TotalsGrid>
          <S.Detail>
            <S.DetailLabel>Valor total</S.DetailLabel>
            <S.DetailBigValue>{totalPrice}</S.DetailBigValue>
          </S.Detail>
          <S.Detail>
            <S.DetailLabel>Peso bruto</S.DetailLabel>
            <S.DetailBigValue>
              {volume?.transpvol_peso_b
                ? `${volume?.transpvol_peso_b} KG`
                : "----"}
            </S.DetailBigValue>
          </S.Detail>
          <S.Detail>
            <S.DetailLabel>Peso Líquido</S.DetailLabel>
            <S.DetailBigValue>
              {volume?.transpvol_peso_l
                ? `${volume?.transpvol_peso_l} KG`
                : "----"}
            </S.DetailBigValue>
          </S.Detail>
          <S.Detail>
            <S.DetailLabel>Volume</S.DetailLabel>
            <S.DetailBigValue>{itemsCount}</S.DetailBigValue>
          </S.Detail>
        </S.TotalsGrid>
        <S.ListHeader>
          <S.ListHeaderLabel>Cód.</S.ListHeaderLabel>
          <S.ListHeaderLabel>Descrição</S.ListHeaderLabel>
          <S.ListHeaderLabel>Nº Lote</S.ListHeaderLabel>
          <S.ListHeaderLabel>Qtd. Lotes</S.ListHeaderLabel>
          <S.ListHeaderLabel>Validade</S.ListHeaderLabel>
          <S.ListHeaderLabel>Pedido</S.ListHeaderLabel>
          <S.ListHeaderLabel>Quantidade</S.ListHeaderLabel>
          <S.ListHeaderLabel>Valor Unit.</S.ListHeaderLabel>
          <S.ListHeaderLabel>Valor Total</S.ListHeaderLabel>
        </S.ListHeader>
        {items.map((item) => (
          <S.ListItem key={item.id}>
            <S.Column>{item.prodCprod}</S.Column>
            <S.Column>{item.prodXprod}</S.Column>
            <S.Column>{item.itemBatch[0]?.rastroNlote}</S.Column>
            <S.Column>{item.itemBatch[0]?.rastroQlote}</S.Column>
            <S.Column>{item.itemBatch[0]?.rastroDval ? Formatter.date(item.itemBatch[0].rastroDval) : "--/--/----"}</S.Column>
            <S.Column>{item.prodXped || "----"}</S.Column>
            <S.Column>
              [ {item.prodUcom} ] {item.prodQcom}
            </S.Column>
            <S.Column>
              {item.prodVuncom &&
                item.prodVuncom.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
            </S.Column>
            <S.Column>
              {item.prodVprod &&
                item.prodVprod.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
            </S.Column>
          </S.ListItem>
        ))}
      </>
    );
  }, [invoice]);

  useEffect(() => fetchInvoice(), [fetchInvoice]);
  useEffect(() => {
    return () => {
      dispatch(FetchActions.reset());
    };
  }, [dispatch]);

  return (
    <GuestScaffold width='full'>
      <S.PageContainer>
        <S.Wrapper>
          <S.ModalContent style={{ maxWidth: '100%' }}>
            <S.ModalContainer isLoading={loading}>
              {loading && <Loading />}
              {invoice && (
                <>
                  <ModalHeader />
                  <ModalInfos />
                  <ModalItems />
                </>
              )}
              {!invoice && !loading && (
                <S.DetailBigValue>Não foi possível encontrar a NF</S.DetailBigValue>
              )}
            </S.ModalContainer>
          </S.ModalContent>
        </S.Wrapper>
      </S.PageContainer>
    </GuestScaffold>
  );
};
