import { MouseEvent, useCallback, useContext, useEffect } from "react";
import type { SortingParams } from "contracts/Common";
import type { PaginatedInvoice } from "contracts/Invoice";
import { SortableHeader, SortableHeadersGroup } from "components/Shared";

import * as S from "./styles";
import { Formatter } from "utils/Formatter";
import { DeliveredContext } from "contexts/DeliveredContext";
import { Dialog, DialogTrigger } from "components/Shared/Dialog";
import { AppInvoiceSAPModal } from "../AddInvoiceSAPModal";
import { JustificationsAlerts } from "styles/components/JustificationAlerts";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { CheckboxRadix } from "components/Shared/Forms/CheckboxRadix";
import { useAuth, useJustifications } from "hooks";
import { usePermission } from "hooks/usePermission";
import { useNavigate } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Button } from "components/ui/button";
import { MoreVertical } from "lucide-react";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import { Delivered } from "pages/Invoices/PendingRelease/Delivered";
import { toast } from "sonner";

interface Props {
  onSort?: (sort: SortingParams) => void;
  onSelectInvoice?: (invoiceId: number) => void;
}

interface ItemProps {
  deliveredInvoice: PaginatedInvoice;
}

export const DeliveredInvoicesList = ({ onSort, onSelectInvoice }: Props) => {
  const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission();
  const navigate = useNavigate();
  const { fetchJustifications, justificationOptions } = useJustifications();
  const isSapVisible =
    userBelongsToAnyOf("admin") ||
    hasPermissionTo(["WAREHOUSE_MEMBERS.INDEX", "WAREHOUSE_MEMBERS.SHOW"]);
  const {
    query,
    deliveredInvoices,
    deliveredInvoiceNotDelayed,
    deliveredInvoicesDelayed,
    onSelectInvoice: selectSap,
    changeOpenModal,
    openedModal,
    updateFetch,
    selectedList,
    onToggleSelected,
    onRemoveSelecteds,
    onSelectInvoices,
  } = useContext(DeliveredContext);

  useEffect(() => {
    fetchJustifications();
  }, []);

  const { loading: loadingIgnore } = useSelector(
    (state: RootState) => state.ignoreInvoice
  );

  const { loading: loadingJustification } = useSelector(
    (state: RootState) => state.createInvoiceJustification
  );

  useEffect(() => {
    updateFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingIgnore, loadingJustification]);

  const Item = useCallback(
    ({ deliveredInvoice }: ItemProps): JSX.Element => {
      const {
        allowance,
        id,
        infnfeIdeNnf,
        infnfeIdeSerie,
        infnfeIdeDhemi,
        deadlineDate,
        supplier,
        company,
        carrier,
        releasedAt,
        deliveryDate,
        destEnderdestUf,
        isDelayedLeadTime,
      } = deliveredInvoice;

      const selectedIds = selectedList.map((invoice) => invoice.id);
      const isChecked = selectedList.some((invoice) => invoice.id === id);
      const hasSomeDelayed = deliveredInvoicesDelayed.some(
        (invoice) => selectedIds.includes(invoice.id)
      );
      const hasSomeNotDelayed = deliveredInvoiceNotDelayed.some(
        (invoice) => selectedIds.includes(invoice.id)
      );
      const isImport = destEnderdestUf
        ? ["EXTERIOR", "EX"].includes(destEnderdestUf)
        : false;
      const finalOrigin = isImport ? company : supplier;
      const finalDestiny = isImport ? supplier : company;

      const showCheckbox = () => {
        if(selectedList.length === 0){
          return true
        }
        if(isDelayedLeadTime && hasSomeNotDelayed){
          return false
        }else if(!isDelayedLeadTime && hasSomeDelayed){
          return false
        } else {
          return true
        }
      };
      const handleInvoiceSAP = (event: MouseEvent) => {
        event.preventDefault();
        selectSap(deliveredInvoice);
        changeOpenModal("sap");
      };

      return (
        <S.ListItem>
          <S.Column>
            {infnfeIdeNnf}-{infnfeIdeSerie}
          </S.Column>
          <S.Column>{Formatter.date(infnfeIdeDhemi)}</S.Column>
          <S.Column>
            {(deadlineDate && Formatter.date(deadlineDate)) || "---"}
          </S.Column>
          <S.Column>{finalOrigin?.tradeName}</S.Column>
          <S.Column>{finalDestiny?.tradeName}</S.Column>
          <S.Column>{carrier?.tradeName}</S.Column>
          <S.Column>
            {(releasedAt &&
              Formatter.date(releasedAt, { format: "dd/MM/yyyy HH:mm" })) ||
              "---"}
          </S.Column>
          <S.Column>
            {(deliveryDate &&
              Formatter.date(deliveryDate, { format: "dd/MM/yyyy HH:mm" })) ||
              "---"}
          </S.Column>
          <S.Column>{JustificationsAlerts[allowance]}</S.Column>
          <S.Column>
            {isDelayedLeadTime ? (
              <S.ErrorTooltip>
                <span>Em atraso efetivação SAP</span>
                <S.ErrorIcon />
              </S.ErrorTooltip>
            ) : (
              <></>
            )}
          </S.Column>
          <S.ActionsColumn>
            <S.ActionButton
              mood="void"
              onClick={() => navigate(`${id}`)}
              title="Visualizar NF"
            >
              <S.ChevronRightCircleIcon />
            </S.ActionButton>
            {isSapVisible && (
              <Dialog
                open={openedModal === "sap"}
                key="liberar"
                onOpenChange={(open) => !open && changeOpenModal()}
              >
                <DialogTrigger asChild>
                  <S.ActionButtonSAP
                    mood="void"
                    onClick={(e) => handleInvoiceSAP(e)}
                    title="Adicionar Código SAP"
                  >
                    <S.IconSAP />
                  </S.ActionButtonSAP>
                </DialogTrigger>
                {/* <AppInvoiceSAPModal options={justificationOptions} /> */}
              </Dialog>
            )}
            {showCheckbox() ? (
              <CheckboxRadix
                id={id}
                handleCheck={() => onToggleSelected(deliveredInvoice)}
                isChecked={isChecked}
              />
            ) : (
              <CheckboxRadix 
              disabled
              handleCheck={() => {}}
              id={Number.MAX_SAFE_INTEGER}/>
            )}
          </S.ActionsColumn>
        </S.ListItem>
      );
    },
    [
      selectedList,
      deliveredInvoicesDelayed,
      deliveredInvoiceNotDelayed,
      isSapVisible,
      openedModal,
      selectSap,
      changeOpenModal,
      navigate,
      onToggleSelected,
    ]
  );

  const selectedListIDs = selectedList.map((invoice: any) => invoice.id);
  const allSelected =
    Boolean(deliveredInvoicesDelayed.length) &&
    deliveredInvoicesDelayed.every((invoice) =>
      selectedListIDs.includes(invoice.id)
    );

  const allNotDelayed =
    Boolean(deliveredInvoiceNotDelayed.length) &&
    deliveredInvoiceNotDelayed.every((invoice) =>
      selectedListIDs.includes(invoice.id)
    );

  const handleToggleAllInvoices = () => {
    if (allSelected) {
      onRemoveSelecteds(deliveredInvoicesDelayed.map((invoice) => invoice.id));
    } else {
      onRemoveSelecteds(
        deliveredInvoiceNotDelayed.map((invoice) => invoice.id)
      );
      onSelectInvoices(deliveredInvoicesDelayed);
    }
  };

  const handleToggleAllNotDelayedInvoices = () => {
    if (allNotDelayed) {
      onRemoveSelecteds(
        deliveredInvoiceNotDelayed.map((invoice) => invoice.id)
      );
    } else {
      onRemoveSelecteds(deliveredInvoicesDelayed.map((invoice) => invoice.id));
      onSelectInvoices(deliveredInvoiceNotDelayed);
    }
  };

  return (
    <S.List>
      <Dialog
        open={openedModal === "sap"}
        key="liberar"
        onOpenChange={(open) => !open && changeOpenModal()}
      >
        <AppInvoiceSAPModal options={justificationOptions} />
      </Dialog>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={query}>
          <SortableHeader column="infnfeIdeNnf" label="NF" />
          <SortableHeader column="infnfeIdeDhemi" label="DT.EMISSÃO" />
          <SortableHeader column="deadlineDate" label="PRAZO" />
          <SortableHeader column="supplierTradeName" label="ORIGEM" />
          <SortableHeader column="companyTradeName" label="DESTINO" />
          <SortableHeader column="carrierTradeName" label="TRANSPORTADORA" />
          <SortableHeader column="releasedAt" label="LIBERADO EM" />
          <SortableHeader column="deliveryDate" label="ENTREGUE EM" />
          <span />
          <span />
          <S.ActionsColumn>
            <DropdownMenu>
              <DropdownMenuTrigger
                className="cursor-pointer focus-visible:ring-primary-500"
                asChild
              >
                <MoreVertical className="h-5 w-5 text-slate-500" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="bg-white">
                <DropdownMenuLabel className="font-GilroySemibold">
                  Selecionar:
                </DropdownMenuLabel>
                <DropdownMenuItem>
                  <DropdownMenuCheckboxItem
                    checked={allSelected}
                    onCheckedChange={handleToggleAllInvoices}
                  >
                    <span className="hover:text-primary-500 text-slate-700">
                      NFs em atraso
                    </span>
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuCheckboxItem
                    checked={allNotDelayed}
                    onCheckedChange={handleToggleAllNotDelayedInvoices}
                  >
                    <span className="hover:text-primary-500 text-slate-700">
                      NFs no prazo
                    </span>
                  </DropdownMenuCheckboxItem>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </S.ActionsColumn>
        </SortableHeadersGroup>
      </S.ListHeader>
      {deliveredInvoices.map((deliveredInvoice) => (
        <Item key={deliveredInvoice.id} deliveredInvoice={deliveredInvoice} />
      ))}
    </S.List>
  );
};
