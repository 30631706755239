import * as Yup from "yup";

export const getProductShape = () => ({
  description: Yup.string().nullable(),
  invoiceId: Yup.number().required(
    "Necessário identificar a NF para criar justificativa"
  ),
  justificationId: Yup.number()
    .required("Informe uma justificativa")
    .typeError("Informe uma justificativa"),
});
