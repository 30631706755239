import { ListOperationTypesActions as MainActions } from "./../../store/ducks/operationTypes/listOperationTypes";
import { SelectOption } from "contracts/Common";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "store";

export const useOperationTypes = () => {
  const dispatch: AppDispatch = useDispatch();

  const [options, setOptions] = useState<SelectOption[]>([]);

  const { data: operationTypes, loading: loadingOperationTypes } = useSelector(
    (state: RootState) => state.listOperationTypes
  );

  const fetchOperationTypes = useCallback((): void => {
    dispatch(MainActions.request());
  }, [dispatch]);

  const onOperationTypesLoad = useCallback((): void => {
    setOptions(
      operationTypes.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    );
  }, [operationTypes]);

  useEffect(() => {
    onOperationTypesLoad();
  }, [onOperationTypesLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    operationTypes,
    operationTypesOptions: options,
    loadingOperationTypes,
    fetchOperationTypes,
  };
};

export type OperationTypesHook = ReturnType<typeof useOperationTypes>;
