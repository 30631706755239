import { useLocation } from 'react-router-dom';

import * as S from './styles';
import { useMemo } from 'react';
import { useAuth } from 'hooks';
import { UserType } from 'contracts/Auth';
import { usePermission } from 'hooks/usePermission';

interface RouteBase {
  title: string
  path: string
  blockedRoles?: string[]
}

export const NavigationDashboard = () => {
  const location = useLocation();
  const { hasPermissionTo } = usePermission()
//   const { userBelongsToAnyOf } = useAuth()

  const routesWithActived = useMemo(() => {

    const routes: RouteBase[] = [
      { 
        title: 'Tracking', 
        path: '#tracking'
      },
      {
        title: 'Retrabalho',
        path: '#rework',
        blockedRoles: ['ADMINS.FINDADMINS', 'ADMINS.FINDADMIN']
      },
      {
        title: 'Warehouse',
        path: '#warehouse',
        blockedRoles: ['carrierMember', 'companyMember', 'supplierMember']
      },
    ]

    return routes
        .filter(route => !route.blockedRoles || hasPermissionTo(route.blockedRoles))
        .map(route => {
          const path = `/dashboard${route.path}`
          const isEmptyHash = location.hash === '' && path === `/dashboard${routes[0].path}`
          const isSamePath = path === `/dashboard${location.hash}`

          return {
            ...route,
            isActived: isSamePath || isEmptyHash
          }
        })

  }, [location.hash, hasPermissionTo])

  return (
    <S.Container>
      {routesWithActived.map(route => (
        <S.Button
          key={route.path}
          to={`/dashboard${route.path}`}
          className={route.isActived ? 'actived': ''}
        >
          {route.title}
        </S.Button>
      ))}
    </S.Container>
  );
};
