import React, { useEffect, useState } from "react";
import { UsersInnerOptions } from "../UsersInnerOptions";
import { useQuery } from "react-query";
import { getCarrierMembers } from "hooks/api/Users/getCarrierMembers";
import { useErrorHandler } from "Exceptions/ErrorHandler";
import { SettingLayout } from "layouts/SettingLayout";
import { useColumns } from "./columns";
import { DataTable } from "components/ui/DataTable";
import { CarrierMember } from "contracts/CarrierMembers";

const ListCarrierMember = () => {
  const { options } = UsersInnerOptions();
  const [localData, setLocalData] = useState<CarrierMember[]>([]);
  const { errorHandler } = useErrorHandler();
  const { columns } = useColumns();
  const { data, isLoading } = useQuery({
    queryKey: "carrierMembers",
    queryFn: getCarrierMembers,
    onError: errorHandler,
  });

  useEffect(() => {
    if(data) {
        setLocalData(data);
    }
    return () => {
        setLocalData([]);
    };
}, [data]);
  return (
    <SettingLayout options={options}>
      <DataTable
        isLoading={isLoading}
        data={localData ?? []}
        columns={columns}
        filterBy={'Nome'}
        showControlColumns={false}
      />
    </SettingLayout>
  );
};

export default ListCarrierMember;
