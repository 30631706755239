import { usePermission } from "hooks/usePermission";
import { ListInvoiceJustificationsActions as MainActions } from "./../../store/ducks/invoiceJustifications/listInvoiceJustifications";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "store";
import { JUSTIFICATION_TYPES } from "./justifications";

export const useInvoiceJustifications = () => {
  const dispatch: AppDispatch = useDispatch();
  const { hasPermissionTo } = usePermission();

  const { data: invoiceJustifications, loading: loadingJustifications } = useSelector(
    (state: RootState) => state.listInvoiceJustifications
  );

  const fetchInvoiceJustifications = useCallback(
    async (invoiceId?: number | null) => {
      
      if(hasPermissionTo(['INVOICE-JUSTIFICATIONS.FINDALLINVOICEJUSTIFICATIONS'])){
        dispatch(MainActions.request({search: invoiceId}));
      } else if (hasPermissionTo(['INVOICE-JUSTIFICATIONS.FINDTRACKINGJUSTIFICATIONS'])){
        dispatch(MainActions.request({ search: invoiceId, type: JUSTIFICATION_TYPES.TRACKING }));
      } else if (hasPermissionTo(['INVOICE-JUSTIFICATIONS.FINDWAREHOUSEJUSTIFICATIONS'])){
        dispatch(MainActions.request({ search: invoiceId, type: JUSTIFICATION_TYPES.WAREHOUSE }));
      }
    },
    []
  );

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    invoiceJustifications,
    loadingJustifications,
    fetchInvoiceJustifications,
  };
};

export type InvoiceJustificationsHook = ReturnType<
  typeof useInvoiceJustifications
>;
