import * as S from "./styles";
import { useCallback, useEffect, useState, useMemo } from "react";
import { Geolocations, Invoice } from "contracts/Invoice";
import { Formatter } from "utils";
import { format } from "date-fns";
import markerWarehouse from "assets/images/marker-warehouse.png";
import markerTruck from "assets/images/markerTruck.png";
import markerClient from "assets/images/marker-client.png";

import {
  DirectionsServiceProps,
  DirectionsRenderer,
  DirectionsService,
  useJsApiLoader,
  OverlayViewF,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";

interface IDirectionOptions extends google.maps.DirectionsRequest {
  origin:
    | string
    | google.maps.LatLng
    | google.maps.Place
    | google.maps.LatLngLiteral;
  destination:
    | string
    | google.maps.LatLng
    | google.maps.Place
    | google.maps.LatLngLiteral;
  travelMode: any;
}

interface StartEnd {
  startLocation: google.maps.LatLngLiteral;
  endLocation: google.maps.LatLngLiteral;
  truckLocation: google.maps.LatLng;
}

interface IMap {
  invoice: Invoice;
  setDistance: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}

interface WaiPoint {
  location: google.maps.LatLngLiteral;
  stopover: boolean;
}

const KEY = process.env.REACT_APP_GOOGLE_API_KEY_SINERGIA;
const MAX_WAYPOINTS = 24;

const containerMapStyle = {
  width: "100%",
  height: "100%",
};

const LatLng = ({ latitude, longitude }: any) => ({
  lat: Number(latitude),
  lng: Number(longitude),
});

export const Map = ({ invoice, setDistance }: IMap) => {
  const [origin, setOrigin] = useState<string>("");
  const [destination, setDestination] = useState<string>("");
  const [startEnd, setStartEnd] = useState<StartEnd>();

  const [currentLocation, setCurrentLocation] = useState<any>();
  const [firstLocation, setFirstLocation] = useState<any>();
  const [truckCurrent, setTruckCurrent] = useState<any>();

  const [directions, setDirections] = useState<
    google.maps.DirectionsResult | null | undefined
  >();
  const [truckPoints, setTruckPoints] = useState<
    google.maps.DirectionsResult | null | undefined
  >();

  const [geolocation, setGeolocation] = useState<Geolocations[]>();

  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [endOpen, setEndOpen] = useState<boolean>(false);
  const [truckOpen, setTruckOpen] = useState<boolean>(false);
  const [startOpen, setStartOpen] = useState<boolean>(false);

  const directionsTruck: WaiPoint[] | undefined = useMemo(() => {
    if (!invoice || !invoice.geolocation?.length) return;

    const { geolocation } = invoice;

    if (geolocation["length"] > MAX_WAYPOINTS) {
      const steps = Math.ceil(geolocation["length"] / MAX_WAYPOINTS);

      return geolocation
        .filter((_, index) => index % steps === 0)
        .map((item: any) => ({
          location: LatLng(item),
          stopover: false,
        }));
    }

    return geolocation.map((item: any) => ({
      location: LatLng(item),
      stopover: false,
    }));
  }, [invoice]);

  const directionOptions = useMemo<IDirectionOptions | null>(
    () => ({
      origin,
      destination,
      travelMode: "DRIVING",
    }),
    [origin, destination]
  );

  const directionTruckOptions = useMemo<IDirectionOptions | null>(
    () =>
      currentLocation &&
      firstLocation && {
        origin: firstLocation,
        destination: LatLng(currentLocation),
        waypoints: directionsTruck,
        travelMode: "DRIVING",
      },
    [origin, currentLocation, directionsTruck]
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: KEY ? KEY : "",
    mapIds: ["87412a572f003751"],
  });

  const scroll = useCallback(() => {
    detailsOpen
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
  }, [detailsOpen]);

  const directionsCallback = useCallback<DirectionsServiceProps["callback"]>(
    (res, status) => {
      if (res !== null && status === "OK") {
        const startLocation = {
          lat: res.routes[0].legs[0].start_location.lat(),
          lng: res.routes[0].legs[0].start_location.lng(),
        };
        const endLocation = {
          lat: res.routes[0].legs[0].end_location.lat(),
          lng: res.routes[0].legs[0].end_location.lng(),
        };
        const viaWaypoints = res.routes[0].legs[0].via_waypoints;
        const truckLocation = viaWaypoints[viaWaypoints.length - 1];

        setDirections(res);
        setStartEnd({ startLocation, truckLocation, endLocation });
        setDistance(res.routes[0].legs[0].distance?.text);
      }
    },
    [setDistance]
  );

  const truckCallback = useCallback<DirectionsServiceProps["callback"]>(
    (res, status) => {
      if (res !== null && status === "OK") {
        const viaWaypoints = res.routes[0].legs[0].via_waypoints;
        const truckLocation = viaWaypoints[viaWaypoints.length - 1];
        setTruckPoints(res);
        setTruckCurrent(truckLocation);
      }
    },
    [setDistance]
  );

  const setLocations = useCallback(() => {
    if (!invoice) return null;

    const { supplier, company, geolocation } = invoice;

    setOrigin(`
      ${supplier.addressStreet},
      ${supplier.addressNumber === "SN" ? "" : supplier.addressNumber},
      ${supplier.addressNeighborhood},
      ${supplier.addressCity},
      ${supplier.addressState},
    `);
    setDestination(`
      ${company.addressStreet},
      ${company.addressNumber === "SN" ? "" : company.addressNumber},
      ${company.addressNeighborhood},
      ${company.addressCity},
      ${company.addressState},
    `);
    if (geolocation.length) {
      setGeolocation(geolocation);
      setFirstLocation(LatLng(geolocation[0]));
      setCurrentLocation(geolocation[geolocation.length - 1]);
    }
  }, [invoice]);

  const InfoStartLocation = useCallback(() => {
    const { emitXnome, emitCnpj, emitXfant, supplier } = invoice;
    const { address } = supplier;
    return (
      <S.Box>
        <S.Close onClick={() => setStartOpen(false)}></S.Close>
        <S.Label>Remetente</S.Label>
        <S.Value>
          {emitXfant ? emitXfant : emitXnome} - {Formatter.cnpj(emitCnpj)}
        </S.Value>
        <S.Label>Endereço</S.Label>
        <S.Value>{address}</S.Value>
      </S.Box>
    );
  }, [invoice]);

  const EndOpenLocation = useCallback(() => {
    const { destXnome, destCnpj, company } = invoice;
    const { address } = company;
    return (
      <S.Box>
        <S.Close onClick={() => setEndOpen(false)}></S.Close>
        <S.Label>Destinatário</S.Label>
        <S.Value>
          {destXnome} - {Formatter.cnpj(destCnpj)}
        </S.Value>
        <S.Label>Endereço</S.Label>
        <S.Value>{address}</S.Value>
      </S.Box>
    );
  }, [invoice]);

  const InfoTruckLocation = useCallback(() => {
    const { carrier } = invoice;
    const { tradeName } = carrier;
    return (
      <S.Box>
        <S.Close onClick={() => setTruckOpen(false)}></S.Close>
        <S.Label>Transportadora</S.Label>
        <S.Value>{tradeName}</S.Value>
      </S.Box>
    );
  }, [invoice]);

  const ModalMore = useCallback(() => {
    if (!geolocation?.length) return <></>;

    const geoDescending = [...geolocation].sort((a, b) =>
      a.geolocationDate > b.geolocationDate ? -1 : 1
    );

    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <S.MapPinIcon />
            Localizações GPS
            <S.CloseModal onClick={() => setDetailsOpen(false)} />
          </S.ModalTitle>
          <S.Grid>
            {geoDescending.map(
              ({ latitude, longitude, geolocationDate, description }: any) => {
                const date = format(
                  new Date(geolocationDate),
                  "dd/MM/yyyy HH:mm"
                );

                return (
                  <S.ModalGrid>
                    <S.Column>{date}</S.Column>
                    <S.Column>
                      {latitude} {longitude}
                    </S.Column>
                    <S.Column>{description}</S.Column>
                  </S.ModalGrid>
                );
              }
            )}
          </S.Grid>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [detailsOpen, geolocation]);

  const Header = useCallback(() => {
    if (!geolocation?.length) return null;

    const lastLocation = geolocation.at(-1);
    const latitude = lastLocation?.latitude;
    const longitude = lastLocation?.longitude;
    const geolocationDate = lastLocation?.geolocationDate;
    const date = geolocationDate
      ? format(new Date(lastLocation.geolocationDate), "dd/MM/yyyy HH:mm")
      : "";

    return (
      <S.MapHeader>
        <S.Info>{date}</S.Info>
        <S.Info>
          {latitude} {longitude}
        </S.Info>
        <S.Info>{lastLocation?.description}</S.Info>
        <S.Button onClick={() => setDetailsOpen(true)}>
          <S.ListIcon height={"10px"} /> Ver Mais
        </S.Button>
      </S.MapHeader>
    );
  }, [geolocation]);

  useEffect(() => {
    setLocations();
  }, [setLocations]);

  useEffect(() => {
    scroll();
  }, [detailsOpen]);

  return (
    <S.Container>
      <S.Map>
        {detailsOpen && <ModalMore />}
        <Header />
        <S.Content>
          {KEY && isLoaded && invoice && (
            <GoogleMap
              mapContainerStyle={containerMapStyle}
              zoom={10}
              clickableIcons={false}
              options={{ mapId: "87412a572f003751" }}
            >
              {directionOptions && (
                <>
                  <DirectionsService
                    options={directionOptions}
                    callback={directionsCallback}
                  />

                  {directionTruckOptions && (
                    <DirectionsService
                      options={directionTruckOptions}
                      callback={truckCallback}
                    />
                  )}
                </>
              )}

              <>
                {truckPoints && (
                  <DirectionsRenderer
                    options={{
                      directions: truckPoints,
                      suppressMarkers: false,
                      polylineOptions: {
                        strokeColor: "#FF4D00",
                        strokeWeight: 5,
                        strokeOpacity: 0.7,
                      },
                      markerOptions: {
                        opacity: 0,
                      },
                    }}
                  />
                )}
                {truckOpen && (
                  <OverlayViewF mapPaneName="floatPane" position={truckCurrent}>
                    <InfoTruckLocation />
                  </OverlayViewF>
                )}

                {truckCurrent && (
                  <Marker
                    position={truckCurrent}
                    icon={markerTruck}
                    zIndex={993}
                    clickable
                    onClick={() => setTruckOpen(true)}
                  />
                )}
              </>

              {directions && directionOptions && startEnd && (
                <>
                  <DirectionsRenderer
                    options={{
                      directions: directions,
                      suppressMarkers: false,
                      polylineOptions: {
                        strokeColor: "#FFC100",
                        strokeWeight: 8,
                        strokeOpacity: 0.5,
                      },
                    }}
                  />
                  {startOpen && (
                    <OverlayViewF
                      mapPaneName="floatPane"
                      position={startEnd.startLocation}
                    >
                      <InfoStartLocation />
                    </OverlayViewF>
                  )}
                  <Marker
                    position={startEnd.startLocation}
                    icon={markerWarehouse}
                    zIndex={990}
                    clickable
                    onClick={() => setStartOpen(true)}
                  />
                  {endOpen && (
                    <OverlayViewF
                      mapPaneName="floatPane"
                      position={startEnd.endLocation}
                    >
                      <EndOpenLocation />
                    </OverlayViewF>
                  )}
                  <Marker
                    position={startEnd.endLocation}
                    icon={markerClient}
                    zIndex={991}
                    clickable
                    onClick={() => setEndOpen(true)}
                  />
                </>
              )}
            </GoogleMap>
          )}
        </S.Content>
      </S.Map>
    </S.Container>
  );
};
