import { JustificationCreationForm } from 'components/Pages/ReverseLogistics/Configuration/Justifications';
import { Scaffold } from 'layouts';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const JustificationCreationPage: React.FC = () => {
  const navigate = useNavigate();

  const onCreate = useCallback((): void => {
    navigate('/configuracoes/logistica-reversa/justificativas');
  }, [navigate]);

  return (
    <Scaffold>
      <JustificationCreationForm onCreate={onCreate} />
    </Scaffold>
  );
};
