import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { notify } from "services";
import { AuthActions, LoginActions } from 'store/ducks/auth';
export function useErrorHandler() {
    const navigate = useNavigate();
    const disptach = useDispatch();
    const errorHandler = (error: any | Error) => {
        if (error?.code === 'ERR_NETWORK') {
            console.log('Network error');
            toast('Erro de rede', {
                description: 'Verifique sua conexão com a internet',
            });
        }

        if (error?.code === 'ECONNABORTED') {
            console.log('Timeout error');
            toast('Erro de tempo limite', {
                description: 'Verifique sua conexão com a internet',
            });
        }

        if (error?.code === 'ERR_BAD_REQUEST') {
            if (error?.response) {
                if (error?.response.status === 401) {
                    disptach(AuthActions.reset());
                    disptach(LoginActions.reset());
                    navigate('/login', { replace: true });
                    return;
                }

                if (error?.response?.data?.errors?.length > 0) {
                    const errors: string[] = [];
                    error.response.data.errors.forEach((error: any) => {
                        errors.push(error.message);
                    });

                    toast('Erro na requisição', {
                        description: errors.toString(),
                    });
                }

                if (error?.response?.data?.error) {
                    const err = error.response.data.error;

                    if (err.code === 'E_TOKEN_REVOKED') {
                        toast('Sessão expirada', {
                            description: 'Faça login novamente',
                        });
                    }

                    if (err.code === 'E_APP' && error.response.status === 401) {
                        toast('Não autorizado', {
                            description: err.message,
                        });
                    }
                }
            }
        }
        if (error?.code ===  'ERR_BAD_RESPONSE') {
            // toast('Erro na requisição', {
            //     description: error?.response?.data?.message || 'Erro na requisição',
            // })
            notify('error', error?.response?.data?.message || 'Erro na requisição');
        }
       

        return <></>;
    };

    return { errorHandler };
}
