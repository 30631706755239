import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import { Input, Select } from "components/Shared";
import { DialogContent } from "components/Shared/Dialog";
import { ReleasedContext } from "contexts/ReleasedContext";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Status } from "components/Pages/Invoices";

import * as S from "./styles";
import { Formatter } from "utils";
import { UpdateInvoiceJustificationsPayload } from "contracts/Invoice";
import { JUSTIFICATION_TYPES, useAuth, useJustifications } from "hooks";
import { CreateManyInvoiceJustificationsActions as MainActions } from "store/ducks/invoiceJustifications";
import { FinishedContext } from "contexts/FinishedContext";
import { usePermission } from "hooks/usePermission";

interface FormData {
  justificationId: number;
  description?: string | null;
}

const AddJustificationsModal = () => {
  const {
    refModal,
    selectedList,
    onQueryChange,
    clearSelectList,
    changeOpenModal,
  } = useContext(FinishedContext);
  const formRef = useRef<FormHandles>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const { hasPermissionTo } = usePermission()
  const isCarrierMember = hasPermissionTo(['CARRIER-MEMBERS.FINDMEMBERS', 'CARRIER-MEMBERS.FINDMEMBER'])
  const isAdmin =  hasPermissionTo(['ADMINS.FINDADMIN'])

  const { justificationOptions, fetchJustifications, loadingJustifications } =
    useJustifications();

  const { loading } = useSelector(
    (state: RootState) => state.updateInvoiceStatus
  );

  const formattedList = useMemo(() => {
    return selectedList.map((invoice) => ({
      id: invoice.id,
      nf: `${invoice.infnfeIdeNnf}-${invoice.infnfeIdeSerie}`,
      dhEmi: Formatter.date(invoice.infnfeIdeDhemi, {
        format: "dd/MM/yyyy HH:mm",
      }),
      prev: invoice.deadlineDate
      ? Formatter.date(invoice.deadlineDate, { format: "dd/MM/yyyy HH:mm" })
      : "---",
      dtEntrega: invoice.deliveryDate ? Formatter.date(invoice.deliveryDate, { format: "dd/MM/yyyy HH:mm" })
      : "---",
      status:
        invoice.status.length > 0
          ? {
              name: invoice.status[0].name,
              color: invoice.status[0].color,
            }
          : null,
      dtGr: invoice.returnDate ? Formatter.date(invoice.returnDate, { format: "dd/MM/yyyy HH:mm" }) : "---",
      codSap: invoice.sapCode
    }));
  }, [selectedList]);

  const handleSubmit = useCallback<SubmitHandler>(
    (data: FormData) => {
      setIsDisabled(true)
      const payloadData: UpdateInvoiceJustificationsPayload = {
        justificationId: data.justificationId,
        ids: selectedList.map((invoice) => invoice.id),
        description: data.description,
      };

      dispatch(
        MainActions.request(payloadData, () => {
          onQueryChange({}, true);
          clearSelectList();
          changeOpenModal();
        })
      );
    },
    [changeOpenModal, clearSelectList, dispatch, onQueryChange, selectedList]
  );

  useEffect(() => {
      fetchJustifications();
  }, []);

  return (
    <DialogContent
      container={refModal}
      title="Adicionar justificativa nas notas listadas abaixo"
      icon={<S.MessageDetailIcon />}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.Content>
          <S.ListHeader>
            <span>NF</span>
            <span>DT. Emissão</span>
            <span>P. Contratado</span>
            <span>DT. Entrega</span>
            {!isCarrierMember && (<span>Data GR</span>)}
            {!isCarrierMember && (<span>Cod. SAP</span>)}
            <span>Status</span>
          </S.ListHeader>
          {formattedList.map((invoice) => (
            <S.ListItem key={invoice.id}>
              <span>{invoice.nf}</span>
              <span>{invoice.dhEmi}</span>
              <span>{invoice.prev}</span>
              <span>{invoice.dtEntrega}</span>
              {!isCarrierMember && (<span>{invoice.dtGr}</span>)}
              {!isCarrierMember && (<span>{invoice.codSap}</span>)}
              <span>
                {invoice.status ? (
                  <Status color={invoice.status.color}>
                    {invoice.status.name}
                  </Status>
                ) : (
                  <Status>Sem status</Status>
                )}
              </span>
            </S.ListItem>
          ))}
        </S.Content>
        <S.Footer>
          <Select
            name="justificationId"
            options={justificationOptions}
            isLoading={loadingJustifications}
            placeholder="Justificativa"
            label="Justificativa"
            onChange={(opt) => setIsDisabled(opt?.value ? false : true)}
            maxMenuHeight={120}
          />
          <Input name="description" label="Comentário" />
          <S.Button type="submit" disabled={isDisabled || loading} >
            {loading ? `Adicionando` : `Adicionar`}
          </S.Button>
        </S.Footer>
      </Form>
    </DialogContent>
  );
};

export { AddJustificationsModal };
