import React from 'react';
import * as S from './styles';

interface Props extends React.PropsWithChildren<{}> {
  color?: string;
}
export const Status: React.FC<Props> = ({ children, color }) => {
  return (
    <S.Tag color={color}>
      <span title={children?.toString()}>{children}</span>
    </S.Tag>
  );
};
