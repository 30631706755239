import { useCallback, useContext } from 'react';
import type { SortingParams } from 'contracts/Common';
import type { PaginatedInvoice } from 'contracts/Invoice';
import { SortableHeader, SortableHeadersGroup } from 'components/Shared';

import * as S from './styles';
import { Formatter } from 'utils/Formatter';
import { FinishedContext } from 'contexts/FinishedContext';
import { CheckboxRadix } from 'components/Shared/Forms/CheckboxRadix';
import { useNavigate } from 'react-router-dom';

interface Props {
  onSort?: (sort: SortingParams) => void;
  onSelectInvoice?: (invoiceId: number) => void;
}

interface ItemProps {
  finishedInvoice: PaginatedInvoice;
}

export const FinishedInvoicesList = ({
  onSort,
  onSelectInvoice,
}: Props) => {
  const {
    query,
    finishedInvoices,
    selectedList,
    onToggleSelected,
    onRemoveSelecteds,
    onSelectInvoices,
  } = useContext(FinishedContext)
  const navigate = useNavigate()


  
  const Item = useCallback(
    ({ finishedInvoice }: ItemProps): JSX.Element => {
      const {
        id,
        infnfeIdeNnf,
        infnfeIdeSerie,
        infnfeIdeDhemi,
        deadlineDate,
        supplier,
        company,
        carrier,
        returnDate,
        deliveryDate,
        sapCode,
        destEnderdestUf,
        isDelayedLeadTime
      } = finishedInvoice;
      
      const isChecked = selectedList.some((invoice) => invoice.id === id);
      const isImport = destEnderdestUf ? ['EXTERIOR', 'EX'].includes(destEnderdestUf) : false;
      const finalOrigin = isImport ? company : supplier;
      const finalDestiny = isImport ? supplier : company;
 
      return (
        <S.ListItem>
          <S.Column>
            {infnfeIdeNnf}-{infnfeIdeSerie}
          </S.Column>
          <S.Column>{Formatter.date(infnfeIdeDhemi)}</S.Column>
          <S.Column>
            {(deadlineDate && Formatter.date(deadlineDate)) || '---'}
          </S.Column>
          <S.Column>{finalOrigin?.tradeName}</S.Column>
          <S.Column>{finalDestiny?.tradeName}</S.Column>
          <S.Column>{carrier?.tradeName}</S.Column>
          <S.Column>
            {(deliveryDate && Formatter.date(deliveryDate, { format: 'dd/MM/yyyy HH:mm'})) || '---'}
          </S.Column>
          <S.Column>
            {(returnDate && Formatter.date(returnDate, { format: 'dd/MM/yyyy HH:mm'})) || '---'}
          </S.Column>
          <S.Column>
            {sapCode || '---'}
          </S.Column>
            <S.ActionsColumn>
              <S.ActionButton mood="void" onClick={() => navigate(`${id}`)}>
                <S.ChevronRightCircleIcon />
              </S.ActionButton>
                <CheckboxRadix
                  id={id}
                  handleCheck={() => onToggleSelected(finishedInvoice)}
                  isChecked={isChecked}
                />
            </S.ActionsColumn>
        </S.ListItem>
      );
    },
    [onSelectInvoice]
  );
  
  const selectedListIDs = selectedList.map((invoice: any) => invoice.id);

  const allSelected = finishedInvoices.every((invoice) =>
    selectedListIDs.includes(invoice.id)
  );

const handleToggleAllInvoices = () => {
  if (allSelected) {
    onRemoveSelecteds(finishedInvoices.map((invoice) => invoice.id));
  } else {
    onSelectInvoices(finishedInvoices);
  }
};


//   const allSelected = finishedInvoices.every((invoice) =>
//   selectedListIDs.includes(invoice.id)
// );

// const handleToggleAllInvoices = () => {
//   if (allSelected) {
//     onRemoveSelecteds(finishedInvoices.map((invoice) => invoice.id));
//   } else {
//     onSelectInvoices(finishedInvoices);
//   }
// };

// console.log("allSelected", allSelected, selectedList, selectedListIDs);


  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={query}>
          <SortableHeader column="infnfeIdeNnf" label="NF" />
          <SortableHeader column="infnfeIdeDhemi" label="DT.EMISSÃO" />
          <SortableHeader column="deadlineDate" label="PRAZO" />
          <SortableHeader column="supplierTradeName" label="ORIGEM" />
          <SortableHeader column="companyTradeName" label="DESTINO" />
          <SortableHeader column="carrierTradeName" label="TRANSPORTADORA" />
          <SortableHeader column="deliveryDate" label="ENTREGUE EM" />
          <SortableHeader column="returnDate" label="DATA GR" />
          <SortableHeader column="sapCode" label="CÓD. SAP" />
          <S.ActionsColumn>
            <CheckboxRadix
              id={0}
              handleCheck={handleToggleAllInvoices}
              isChecked={allSelected}
            />
          </S.ActionsColumn>
        </SortableHeadersGroup>
      </S.ListHeader>
      {finishedInvoices.map((finishedInvoice) => (
        <Item key={finishedInvoice.id} finishedInvoice={finishedInvoice} />
      ))}
    </S.List>
  );
};
