import { useContext, useRef } from "react";
import { Paginator } from "components/Shared";
import { Scaffold } from "layouts";
import * as S from "./styles";
import {
  FinishedInvoicesList,
  FinishedInvoicesFilter,
  Navigation,
  InvoiceModal,
  Ref as InvoiceModalRef,
} from "components/Pages/Invoices";
import { FinishedContext } from "contexts/FinishedContext"; 
import { DropdownMenuInvoices } from "components/Pages/Invoices/FinishedInvoices/DropdownMenu";
import { Loading } from "components/Shared/Loading";
import { DownloadXLS } from "components/Shared/DownloadXLS";

export const ListFinished = () => {
  const invoiceModalRef = useRef<InvoiceModalRef>(null);
  const { pagination, onPageChange, onSort, loading, query } =
    useContext(FinishedContext);

  return (
    <Scaffold>
      <S.Container>
        <Navigation />
        <S.Panel>
          <InvoiceModal ref={invoiceModalRef} />
          <S.FilterContainer>
            <FinishedInvoicesFilter />
            {loading && <Loading />}
            <DownloadXLS
              archiveName="RELATORIO-EFETIVADAS"
              query={query}
              type="Finalizada"
            />
            <DropdownMenuInvoices />
          </S.FilterContainer>
          <FinishedInvoicesList
            onSort={onSort}
            onSelectInvoice={(invoiceId) =>
              invoiceModalRef.current?.selectInvoice(invoiceId)
            }
          />
          <Paginator onPageChange={onPageChange} pagination={pagination} />
        </S.Panel>
      </S.Container>
    </Scaffold>
  );
};
