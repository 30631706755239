import styled from "styled-components";
import { Colors, Fonts, getScrollbarStyle } from 'styles/constants';

export const Container = styled.div`
    display: flex;
    padding: 0 30px;
    margin: 50px 0;
    gap: 50px;
    align-items: center;
`

export const StatusText = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 32px;
    line-height: 1em;
    gap: 10px;
`

interface StatusProps {
    collectStatus: number;
}

export const StatusBar = styled.div<StatusProps>`
    height: 8px;
    border-radius: 2px;
    background-color: ${Colors.Gray40};
    position: relative;

    span {
        height: 8px;
        width: ${props => props.collectStatus}%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${Colors.Green};
    }
`

export const StatusProgress = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
`;

export const StatusCheckpoint = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    position: relative;
`;

export const StatusTitle = styled.div`
    font-size: 11px;
    font-weight: 900;
    text-transform: uppercase;
`;

export const StatusBullet = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: ${Colors.White};
    outline: 4px ${Colors.Gray50} solid;
    position: relative;
    z-index: 1;

    &.active {
        outline-color: ${Colors.Green};
    }
`;

export const StatusLine = styled.span`
    height: 2px;
    width: 100%;
    background-color: ${Colors.Gray50};   
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    z-index: 0;

    &.active {
        background-color: ${Colors.Green};
    }
`;

export const StatusDate = styled.div`
    font-size: 11px;
`;
