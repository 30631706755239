import { RouteObject, Outlet, Navigate } from "react-router-dom";
import type { RouterProps } from "contracts/Router";
import {
  TransitTimeUpdatePage,
  TransitTimesListPage,
  TransitTimeCreationPage,
} from "pages/TransitTimes";

const transitTimesRoutes = ({
  location,
  userBelongsToAnyOf,
  hasPermissionTo
}: RouterProps): RouteObject => ({
  path: "configuracoes/transit-time",
  element: hasPermissionTo(['TRANSIT-TIME.FINDTRANSITTIMES']) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: "",
      element: <TransitTimesListPage />,
    },
    {
      path: "criar",
      element: <TransitTimeCreationPage />,
    },
    {
      path: ":id/editar",
      element: <TransitTimeUpdatePage />,
    },
  ],
});

export default transitTimesRoutes;
