import * as S from './styles';
import { Formatter } from 'utils';

export const StatusBox = ({ collect }: any) => {
    let collectStatus = 25;
    if (collect.deliveryDate) {
        collectStatus = 100;
    } else if (collect.collectDate) {
        collectStatus = 75;
    } else if (collect.departureDate) {
        collectStatus = 50;
    } else {
        collectStatus = 25;
    }

    return (
        <S.Container>
            <S.StatusText>
                {collectStatus}%
                <S.StatusBar collectStatus={collectStatus}><span></span></S.StatusBar>
            </S.StatusText>
            <S.StatusProgress>
                <S.StatusCheckpoint>
                    <S.StatusTitle>Solicitação</S.StatusTitle>
                    <S.StatusBullet className='active' />
                    <S.StatusLine className='active' />
                    <S.StatusDate>{(collect.requestDate && Formatter.date(collect.requestDate)) || ""}</S.StatusDate>
                </S.StatusCheckpoint>
                <S.StatusCheckpoint>
                    <S.StatusTitle>Saída</S.StatusTitle>
                    <S.StatusBullet className={collect.departureDate ? 'active' : ''} />
                    <S.StatusLine className={collect.departureDate ? 'active' : ''} />
                    <S.StatusDate>{(collect.departureDate && Formatter.date(collect.departureDate)) || ""}</S.StatusDate>
                </S.StatusCheckpoint>
                <S.StatusCheckpoint>
                    <S.StatusTitle>Coleta</S.StatusTitle>
                    <S.StatusBullet className={collect.collectDate ? 'active' : ''} />
                    <S.StatusLine className={collect.collectDate ? 'active' : ''} />
                    <S.StatusDate>{(collect.collectDate && Formatter.date(collect.collectDate)) || ""}</S.StatusDate>
                </S.StatusCheckpoint>
                <S.StatusCheckpoint>
                    <S.StatusTitle>Entrega</S.StatusTitle>
                    <S.StatusBullet className={collect.deliveryDate ? 'active' : ''} />
                    <S.StatusLine className={collect.deliveryDate ? 'active' : ''} />
                    <S.StatusDate>{(collect.deliveryDate && Formatter.date(collect.deliveryDate)) || ""}</S.StatusDate>
                </S.StatusCheckpoint>
            </S.StatusProgress>
        </S.Container>
    )
}