import * as Yup from 'yup';
import {
  getSupplierLogoShape,
  getSupplierShape,
  Props,
} from 'validators/Common/baseSupplierValidator';

export class UpdateSupplierValidator {
  constructor(protected readonly props: Props) {}

  public get schema() {
    return Yup.object().shape({
      ...getSupplierShape(this.props),
      ...getSupplierLogoShape(),
    });
  }
}
