import styled, { css } from 'styled-components';
import {
  BasePaginatorHeader,
  BasePaginatorListItem,
  PaginatorActionsColumn
} from 'styles/components';
import { getScrollbarStyle } from 'styles/constants';
export {
  ActionButton,
  PaginatorColumn as Column,
  // PaginatorActionsColumn as ActionsColumn,
  LinkActionButton,
  EditIcon,
  ChevronRightCircleIcon,
} from 'styles/components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1536px) { 
    overflow-x: auto;
    ${getScrollbarStyle()};
  }
`;

const baseGridCss = css`
  grid-template-columns:
    60px repeat(2, 100px) repeat(3, 180px) 120px
    120px 120px minmax(0px, auto);
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`;

export const ActionsColumn = styled(PaginatorActionsColumn)`
  align-items: center;
`;

export const GhostDiv = styled.div`
  width: 20px;
  height: 20px;
  display: block;
`;