import {
  useRef,
  useEffect,
  InputHTMLAttributes,
  ChangeEvent,
  useCallback,
  useState,
} from "react";
import { useField } from "@unform/core";
import * as S from "./styles";

type InputFileProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  id?: string;
  label?: string;
  placeholder?: string;
  isLoading?: boolean;
  compact?: boolean;
  classContainer?: string;
};

interface InputRefProps extends HTMLInputElement {
  acceptedFiles: FileList | null;
}

export const InputFile = ({
  name,
  id,
  label,
  type,
  placeholder = " ",
  isLoading = false,
  classContainer = "",
  compact = false,
  ...rest
}: InputFileProps) => {
  const inputRef = useRef<InputRefProps>(null);
  const [fileName, setFileName] = useState<string | null | undefined>(null);
  const [length, setLength] = useState<number | null>(null);
  const { fieldName, defaultValue = [], registerField, error } = useField(name);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList && fileList.length > 0) {
      if (inputRef && inputRef.current) {
        inputRef.current.acceptedFiles = fileList;
        if (fileList.length === 1) {
          setFileName(fileList?.item(0)?.name);
          setLength(fileList.length);
        } else {
          setLength(fileList.length);
        }
      }
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      clearValue(ref: InputRefProps) {
        ref.acceptedFiles = null;
      },
      getValue: (ref: InputRefProps) => {
        return ref.acceptedFiles;
      },
      setValue: (ref: InputRefProps, value) => {
        ref.acceptedFiles = value;
      },
    });
  }, [fieldName, registerField]);

  return (
    <S.Container
      className={
        classContainer ? `field-container ${classContainer}` : "field-container"
      }
    >
      {label && !compact ? (
        <S.FieldLabel htmlFor={id || fieldName}>{label}</S.FieldLabel>
      ) : null}
      <S.RelativeWrapper>
        {compact && (
            <S.CompactContent
              htmlFor={id || fieldName}
              title={fileName ? fileName : "Anexar arquivo"}

            >
              {length && <S.LengthIndicator>{length}</S.LengthIndicator>}
              <S.FileIcon />
            </S.CompactContent>
        )}
        <input
          name={fieldName}
          id={id || fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          type="file"
          onChange={handleChange}
          style={{ display: compact ? "none" : "block" }}
        />
        {isLoading && (
          <S.ActivityIndicatorContainer>
            <S.ActivityIndicator />
          </S.ActivityIndicatorContainer>
        )}
      </S.RelativeWrapper>
      {error ? <S.FieldError>{error}</S.FieldError> : null}
    </S.Container>
  );
};
