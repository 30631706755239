import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FormType, formSchema } from "../../formSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem } from "components/ui/form";
import { InputGs, SelectGs } from "components/ui/Forms";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useErrorHandler } from "Exceptions/ErrorHandler";
import { useNavigate } from "react-router-dom";
import { Carrier } from "contracts/Carriers";
import { getCarriers } from "hooks/api/Users/Options/getCarriers";
import { Company } from "contracts/Companies";
import { getCompanies } from "hooks/api/Users/Options/getCompanies";
import { Button } from "components/ui/ButtonGs";
import { createUser } from "hooks/api/Users/createUser";
import { notify } from "services";
import { ActivityIndicator } from "styles/components";
import MultipleSelector from "components/ui/multi-select";

interface SelectOption {
  name: string;
  value: string;
  label: string;
}
interface OptionsType {
  [key: string]: SelectOption[];
}
type UserTypes = "carrierMember" | "warehouseMember" | "admin" | "clientMember";
const userOptions = [
  {
    name: "Usuário de transportadora",
    value: "carrierMember",
  },
  {
    name: "Usuário de armazém",
    value: "warehouseMember",
  },
  {
    name: "Usuário de cliente",
    value: "clientMember",
  },
  {
    name: "Administrador",
    value: "admin",
  },
];

const fieldNameDict = {
  carrierMember: "carrierId",
  warehouseMember: "companyId",
  clientMember: "companyId",
};

const routeDictionary = {
  carrierMember: "transportadora",
  warehouseMember: "armazem",
  clientMember: "clientes",
  admin: "",
};

const CreateUsuarioForm = () => {
  const { errorHandler } = useErrorHandler();
  const navigate = useNavigate();
  const [userType, setUserType] = useState<UserTypes | null>(null);
  const { data: carriers, isLoading: carrierLoading } = useQuery<Carrier[]>({
    queryKey: "carriers",
    queryFn: getCarriers,
    onError: errorHandler,
  });
  const { data: companies, isLoading: companiesLoading } = useQuery<Company[]>({
    queryKey: "companies",
    queryFn: getCompanies,
    onError: errorHandler,
  });
  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
  });
  const options: OptionsType = useMemo(() => {
    if (!carriers || !companies)
      return { carrierMember: [], warehouseMember: [], clientMember: [] };
    return {
      carrierMember: carriers.map((item) => ({
        name: item.tradeName,
        label: item.tradeName,

        value: `${item.id}`,
      })),
      warehouseMember: companies.map((item) => ({
        name: item.tradeName,
        label: item.tradeName,
        value: `${item.id}`,
      })),
      clientMember: companies.map((item) => ({
        name: item.tradeName,
        label: item.tradeName,
        value: `${item.id}`,
      })),
    };
  }, [companies, carriers]);

  const queryClient = useQueryClient();

  const mutation = useMutation(createUser, {
    onSuccess: () => {
      notify("success", "Usuario cadastrado com sucesso");
      navigate(
        `/configuracoes/usuarios/${
          routeDictionary[userType as keyof typeof routeDictionary]
        }`
      );
    },
    onError: (error) => {
      errorHandler(error);
    },
  });
  function handleSubmit(data: FormType) {
    const field = fieldNameDict[userType as keyof typeof fieldNameDict] as
      | "carrierId"
      | "companyId";
    let formatData = {
      ...data,
      [field]: data[field]?.map((item) => item.value),
    };

    mutation.mutate(formatData);
  }
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="grid grid-cols-4 gap-4"
      >
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => {
            return (
              <SelectGs
                {...field}
                onValueChange={(e: UserTypes) => {
                  setUserType(e);
                  form.setValue("type", e);
                }}
                label="Tipo de usuário"
                content={userOptions}
              />
            );
          }}
        />
        <FormField
          control={form.control}
          name="user.name"
          render={({ field }) => {
            return <InputGs {...field} label="Nome do usuário" />;
          }}
        />
        <FormField
          control={form.control}
          name="user.email"
          render={({ field }) => {
            return <InputGs {...field} label="E-mail" />;
          }}
        />
        <FormField
          control={form.control}
          name="user.password"
          render={({ field }) => {
            return <InputGs {...field} type="password" label="Senha" />;
          }}
        />
        <div className="col-span-full">
          {userType && userType !== "admin" && (
            <FormField
              control={form.control}
              name={fieldNameDict[userType] as any}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormControl>
                      <>
                        <label className="font-GilroySemibold text-sm text-slate-800">
                          Selecione...
                        </label>
                        <MultipleSelector
                          onChange={(e) => {
                            form.setValue(fieldNameDict[userType] as any, e);
                          }}
                          options={
                            options[userType] as unknown as {
                              label: string;
                              value: string;
                            }[]
                          }
                          inputProps={{
                            autoFocus: false,
                            ref: field.ref,
                          }}
                        />
                      </>
                    </FormControl>
                  </FormItem>
                );
              }}
            />
          )}
        </div>
        <div className="col-span-4">
          <div className="flex gap-4 justify-end">
            <Button.Root
              className="bg-orange-100 text-orange-600"
              size="sm"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button.Root>
            <Button.Root className="bg-orange-500" size="sm" type="submit">
              {mutation.isLoading ? <ActivityIndicator /> : "Salvar"}
            </Button.Root>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default CreateUsuarioForm;
