import { call, put } from '@redux-saga/core/effects';
import { api, notify, formDataBuilder, apiErrorHandler } from 'services';
import {
  CreateManyInvoiceJustificationsActions as Actions,
  CreateManyInvoiceJustificationsRequestAction as RequestAction,
} from 'store/ducks/invoiceJustifications';

export function* createManyInvoiceJustificationsRequest(action: any) {
  const { postData, onSuccess, onFailure }: RequestAction = action;
  try {

    yield call(api.post, `invoice/many-justifications`, postData);
    onSuccess && onSuccess();
    notify('success', 'Justificativas criadas com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
