import styled from "styled-components";
import { BasePanel } from 'styles/components';
import { Colors, ColorScheme, Fonts } from "styles/constants";
import { getScrollbarStyle } from "styles/constants";
import { BaseButtonStyle } from "styles/components";
import { Select, Textarea } from "components/Shared";
import { CloseIcon, FormActions } from 'styles/components';
import { DotIcon as Dot, CheckCircleIcon } from "./../../../styles/components/icons";
export {
  DotIcon,
  UserCircleIcon,
  MessageAddIcon,
  MessageCheckIcon,
  CommentIcon,
} from "styles/components";


export const StatusGrid = styled.div`
  width: 100%;
  height: 350px;
  min-height: 200px;
  max-height: 300px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Gray10};
  border-top: 1px solid ${Colors.Gray40};
  margin-top: 32px;
  margin-bottom: 32px;
  overflow-y: auto;
  ${getScrollbarStyle()};
  .none {
    display: none;
  }
`;

export const Close = styled(CloseIcon).attrs({
  height: "20px",
  color: Colors.Gray50,
})`
  position: absolute;
  right: 12px;
  top: 16px;
  cursor: pointer;
  justify-self: end;
`;

export const FormRow = styled(FormActions)`
  justify-content: start;
`;

export const StatusTitle = styled.h2`
  display: flex;
  font-family: ${Fonts.GilroyBold};
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-size: 16px;
  margin-bottom: 32px;
  padding-left: 32px;
  z-index: 3;
`;

export const Button = styled.button`
  display: flex;
  ${BaseButtonStyle};
  background-color: ${Colors.Blue};
  border-radius: 5px;
  color: ${Colors.White};
  padding: 3px;
  font-size: 0.7rem;
  gap: 3px;
  font-family: ${Fonts.GilroySemiBold};
  padding-right: 8px;
`;

export const ButtonCheck = styled(Button)`
  background-color: ${Colors.Green};
  width: 25%;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 5px;
`;

export const StatusWrapper = styled.div`
  position: relative;
  padding-left: 32px;
  border-left: 1px solid ${Colors.Gray40};
`;

export const StatusBox = styled.div`
  width: 100%;
  max-width: 80%;
  padding: 16px;
  word-break: break-word;
  border-radius: 4px;
  box-shadow: #00000025 0 20px 10px -16px;
  background-color: ${Colors.White};
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
  margin-bottom: 40px;

  &.new {
    animation: slideDown 0.4s;
    z-index: 0;

    @keyframes slideDown {
      from {
        opacity: 0;
        transform: translateY(-100px);
      }

      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }

  svg.user {
    align-self: flex-start;
  }

  svg.dot {
    position: absolute;
    left: -39px;
  }

  div.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  :before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: ${Colors.White};
    transform: rotate(45deg);
    position: absolute;
    left: -5px;
  }
`;

export const UserName = styled.h5`
  display: flex;
  font-family: ${Fonts.GilroySemiBold};
  font-size: 14px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  color: ${Colors.Gray70};
`;

export const StatusDescription = styled.article`
  font-family: ${Fonts.OpenSans};
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.Gray60};
  margin-bottom: 16px;
`;

export const TextArea = styled(Textarea)`
  resize: none;
  font-family: ${Fonts.OpenSans};
  font-size: 14px;
  line-height: 22px;
  border-style: solid;
  width: 100%;
  height: 30%;
  color: ${Colors.Gray60};
  border-radius: 5px;
  border-color: ${Colors.Gray50};
  ${getScrollbarStyle()};
`;

export const StatusDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const StatusImg = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0rem 0rem 0rem 0.5rem;
`

export const StatusDate = styled.span`
  font-family: ${Fonts.OpenSans};
  font-size: 12px;
  color: ${Colors.Gray50};
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background-color: ${Colors.Black}cc;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  z-index: 999;
  overflow: hidden;
`;

export const ModalContainer = styled(BasePanel)`
  display: flex;
  position: relative;
  width: 600px;
  flex-direction: column;
  justify-content: start;
  padding: 24px;
  font-size: 14px;
  z-index: 1000;
  font-family: ${Fonts.GilroySemiBold};
  color: ${ColorScheme.Text};
  animation: contentShow 150ms;
  
  @keyframes contentShow {
    from { opacity: 0; transform: scale(.96) }
    to { opacity: 1; transform: scale(1) }
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  align-self: start;
  font-family: ${Fonts.GilroyBold};
  font-size: 1.25rem;
  width: 100%;
  align-items: center;
  justify-content: start;
  gap: 7px;
  border-bottom: 1px solid ${Colors.Gray30};
  padding-bottom: 13px;
  margin-bottom: 13px;
`;

export const SelectJustification = styled(Select)`
  width: 100%;
`;

export const FormButton = styled(Button)<{theme?: string}>`
  background-color: ${({theme})=> theme === 'accept' ? Colors.Orange : Colors.Gray60};
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 45px;
  padding: 0;
  width: 20%;
`;

export const RecognizeButton = styled.button`
  ${BaseButtonStyle};
  color: ${Colors.White};
  margin: 1rem 0rem 1rem 0rem;
  padding: 0.5rem 0rem;
  height: 60%;
  width: 80%;
  background-color: ${Colors.Orange};
  align-self: center;
`;

export const ButtonAlert = styled.button`
  ${BaseButtonStyle};
  color: ${Colors.White};
  margin: 1rem 0rem 1rem 0rem;
  padding: 0.5rem 0rem;
  height: 50%;
  width: 30%;
  background-color: ${Colors.Orange};
  align-self: center;
`;

export const SucessIcon = styled(Dot)`
 &.aboned {
   #Elipse_3{
     fill: ${Colors.Green};
   }
 }
`

export const Check = styled(CheckCircleIcon)`
  color: ${Colors.Green};
  width: 22px;
  height: 22px;
`

export const ModalContent = styled.div.attrs({
  className: 'modal-content',
})`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-self: stretch;
  max-height: calc(100vh - 4rem);
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  @media screen and (min-width: 800px) {
    max-width: 460px;
  }
`;

export const ModalContainerConfirm = styled.section`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  color: ${Colors.Gray70};
  overflow-y: auto;
  ${getScrollbarStyle()};
`;

export const MensageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ButtonAlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

export const MensageAlert = styled.h1`
  margin: 1rem 0rem 1rem 0rem;
`