import { CompanyUpdateForm } from 'components/Pages/Companies';
import { Scaffold } from 'layouts';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const CompanyUpdatePage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();

  const onUpdate = useCallback((): void => {
    navigate('/configuracoes/empresas');
  }, [navigate]);

  return (
    <Scaffold>
      <CompanyUpdateForm companyId={id} onUpdate={onUpdate} />
    </Scaffold>
  );
};
