import { useState, useCallback, useEffect, useRef } from "react";
import { Formatter } from "utils";
import { Loading } from "components/Shared/Loading/styles";
import { api, notify, formDataBuilder } from "services";
import * as S from "./styles";
import { FetchDocumentActions, FetchCollectActions as FetchActions } from "store/ducks/collects";
import { useDispatch } from "react-redux";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { ConfirmationDialog, ConfirmationDialogRef } from 'components/Shared';
import { usePermission } from "hooks/usePermission";
import * as Yup from 'yup';
import { useValidation } from 'hooks';
import { useMutation } from "react-query";
import { InputFile } from "components/Shared/Forms/InputFile";
import { createDocument } from 'hooks/api/ReverseLogistcsDocument/createDocument';
import { deleteDocument } from "hooks/api/ReverseLogistcsDocument/deleteDocumente";

export const Documents = ({ collect }: any) => {
  const [items, setItems] = useState([]);
  const [isOpenCreateDocument, setIsOpenCreateDocument] = useState<boolean>(false);
  const formRef = useRef<FormHandles>(null);
  const dialogRef = useRef<ConfirmationDialogRef>(null);
  const [documentType, setDocumentType] = useState('Nota Fiscal')
  const { hasPermissionTo } = usePermission()
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();

  const mutateCreateDocument = useMutation(createDocument, {
    onSuccess: () => {
      notify("success", "Documento cadastrado com sucesso");
      setIsOpenCreateDocument(false);
      dispatch(FetchActions.request(collect.id));
    },
    onError: (error) => {
      notify("error", `${error}`);
      setIsOpenCreateDocument(false);
    },
  });

  const { data: dataCreateDocument, isLoading: loading } = mutateCreateDocument;

  const mutateDeleteDocument = useMutation(deleteDocument, {
    onSuccess:() => {
      dispatch(FetchActions.request(collect.id));
      notify("success", "Documento removido com sucesso");
  },
    onError: (error) => {
      notify("error", `${error}`);
    }
  })

  const { data: dataDeleteDocument, isLoading: loadingDelete } = mutateDeleteDocument;

  const scroll = useCallback(() => {
    isOpenCreateDocument
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
  }, [isOpenCreateDocument]);

  const handleDownloadFile = useCallback((docId: number) => {
    dispatch(FetchDocumentActions.request(docId, (url: string) => {
      window.open(url, '_blank', 'noopener,noreferrer');
    }));
  }, [dispatch]);

  const ListDocs = useCallback(() => {
    
    const handleDeleteDoc = async (docId: number) => {
      if(!hasPermissionTo(["REVERSE-LOGISTICS.DELETEREVERSELOGISTICDOCUMENT"])) {
        notify('error', 'Sem permissão para excluir.');
        return
      }
      const confirmed = await dialogRef.current?.openDialog({
        title: 'Deseja remover o documento da coleta?',
      });

      if (confirmed) {

        const formatDeleteDocument = {
          docId: docId,
          collectId: collect.id
        }

        mutateDeleteDocument.mutate(formatDeleteDocument);
      }
    }

    if (!items.length) {
      return <></>;
    }

    interface Item {
      id: number;
      description: string;
      documentType: string;
      fileUrl: string;
      createdAt: string;
    }

    return (
      <S.MainGrid>
        <ConfirmationDialog ref={dialogRef} />
        <S.BoxHeader>
          <S.BoxTitle>Nome do Documento</S.BoxTitle>
          <S.BoxTitle>Tipo do Documento</S.BoxTitle>
          <S.BoxTitle>Arquivo</S.BoxTitle>
          <S.BoxTitle>Data</S.BoxTitle>
          <S.BoxTitle></S.BoxTitle>
        </S.BoxHeader>
        {
        
        items.map((item: Item) => {
          const {
            id,
            description,
            documentType,
            fileUrl,
            createdAt
          } = item;

          const getFileName = () => {
            const index = fileUrl?.lastIndexOf('/');
            if (index !== -1) {
              return fileUrl?.substring(index + 1);
            }
            return fileUrl;
          };

          return (
            <S.Grid key={id}>
              <S.Column><a onClick={() => handleDownloadFile(id)}>{description}</a></S.Column>
              <S.Column>{documentType}</S.Column>
              <S.Column>{getFileName()}</S.Column>
              <S.Column>{Formatter.date(createdAt)}</S.Column>
              <S.Column>
                {!collect.deletedAt && !collect.finishDate && <S.Trash onClick={() => handleDeleteDoc(id)}/>}
              </S.Column>
            </S.Grid>
          );
        })}
      </S.MainGrid>        
    );
  }, [collect.deletedAt, collect.id, dispatch, handleDownloadFile, items]);

  useEffect(() => {
    if (collect) {
      setItems(collect.documents);
    }
  }, [collect]);

  interface FormData {
    description: string;
    documentType: string;
    collectNfeNumber?: string;
    freightValue?: number;
    file: FileList;
  }

  const CreateDocument = useCallback(() => {

    const documentTypeOptions = [
      {"value": "Nota Fiscal", "label": 'Nota Fiscal'},
      {"value": "Documento de Transporte", "label": 'Documento de Transporte'},
      {"value": "Outros", "label": 'Outros'}
    ];

    const handleDocumentType = (docType: any) => {
      setDocumentType(docType)
    }

    const onSubmit = async (data: FormData) => {
      
      formRef.current?.setErrors({});

      const description = data.description;
      const documentType = data.documentType;
      const collectNfeNumber = data.collectNfeNumber;
      const freightValue = data.freightValue ? data.freightValue.toString().replace(',', '.') : '';
      const file = data.file?.[0] || null;
      
      const formData = {
        description: description,
        documentType: documentType,
        collectNfeNumber: collectNfeNumber,
        freightValue: freightValue,
        file: file,
        reverseLogisticId: collect.id
      }

      try {
        const schema = Yup.object().shape({
          description: Yup.string().required('Informe o Nome do Documento'),
          documentType: Yup.string().required('Informe o Tipo do Documento'),
          collectNfeNumber: Yup.string().nullable(),
          freightValue: Yup.string().nullable(),
          file: Yup.mixed().required('Informe o File'),
          reverseLogisticId: Yup.number().required('Informe o id da Logistica Reversa'),
        });

        const validData = await schema.validate(formData, {
          abortEarly: false,
        });
        
        const formDataPost = formDataBuilder(validData)

        mutateCreateDocument.mutate(formDataPost);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    };

    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <S.TruckIcon />
            Adicionar Documento
            <S.Close onClick={() => setIsOpenCreateDocument(false)} />
          </S.ModalTitle>
          <Form ref={formRef} onSubmit={onSubmit}>
            <S.SelectCarrier
              name="documentType"
              label="Tipo do Documento"
              options={documentTypeOptions}
              onChange={(e: any) => {handleDocumentType(e?.value)}}
              defaultValue={documentTypeOptions.find((o) => o.value === documentType)}
            />
            {documentType === 'Nota Fiscal' && (
              <S.InputModal
                name="collectNfeNumber"
                label="Número da Nota Fiscal:"
              />
            )}
            {documentType === 'Documento de Transporte' && (
              <S.InputModal
                name="freightValue"
                label="Valor do Frete:"
              />
            )}
            <S.InputModal
              name="description"
              label="Nome do Documento:"
            />
            <InputFile type="file" name="file" multiple={false} />
            <S.FormActions>
              <S.FormButton onClick={() => setIsOpenCreateDocument(false)}>
                {loading && <Loading color="#fff" />}
                Voltar
              </S.FormButton>
              <S.FormButton type="submit" theme="accept" disabled={loading}>
                {loading && <Loading color="#fff" />}
                Salvar
              </S.FormButton>
            </S.FormActions>
          </Form>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [collect.id, dispatch, documentType, loading]);

  useEffect(() => {
    scroll();
  }, [isOpenCreateDocument, scroll]);

  if (!collect) {
    return <></>;
  }

  return (
    <S.Container>
      {isOpenCreateDocument && <CreateDocument />}
      <S.Header>
        <S.GridTitle>Documentos</S.GridTitle>
        <S.Button disabled={collect.deletedAt || collect.finishDate || !hasPermissionTo(['REVERSE-LOGISTICS.CREATEDOCUMENT'])} onClick={() => setIsOpenCreateDocument(true)}><S.Plus />Adicionar</S.Button>
      </S.Header>
      <ListDocs />
    </S.Container>
  );
};
