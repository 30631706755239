import { call, put } from "@redux-saga/core/effects";
import { api, notify, apiErrorHandler } from "services";
import {
  IgnoreInvoiceActions as Actions,
  IgnoreInvoiceRequestAction as RequestAction,
} from "store/ducks/deliveredInvoices";

export function* IgnoreInvoiceRequest(action: any) {
  const { postData, onSuccess, onFailure }: RequestAction = action;
  try {
    const { data } = yield call(api.post, `invoices/ignore`, postData);
    onSuccess && onSuccess();
    notify("success", "NF abonada com sucesso");
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify("error", errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
