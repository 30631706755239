import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import type { AppDispatch, RootState } from "store";
import { PaginateVehicleTypesActions as PaginateActions } from "store/ducks/vehicleTypes";
import { usePaginationCache } from "hooks";
import { Scaffold } from "layouts";
import {
  VehicleTypesList,
  VehicleTypeFilters,
  FindVehicleTypes,
} from "components/Pages/VehicleTypes";
import { ListingPageHeader, Paginator, SettingsMenu } from "components/Shared";
import * as S from "./styles";

export const VehicleTypesListPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { paginationCache, updatePaginationCache, handleFilter, handleSort } =
    usePaginationCache("VehicleTypes");

  const {
    data: vehicleType,
    pagination,
    loading,
  } = useSelector((state: RootState) => state.paginateVehicleTypes);

  const [query, setQuery] = useState<FindVehicleTypes>({
    search: "",
    page: 1,
    limit: 10,
    ...paginationCache,
  });

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback((): void => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
      updatePaginationCache(query);
    };
  }, [dispatch, query, updatePaginationCache]);

  return (
    <Scaffold>
      <SettingsMenu />
      <S.MainPanel>
        <ListingPageHeader
          icon={<S.TruckIcon />}
          title="Tipos de Veículo"
          isLoading={loading}
          actions={
            <S.LinkButton
              to={"/configuracoes/tipos-veiculo/criar"}
              size="small"
            >
              <S.PlusIcon /> Novo Tipo
            </S.LinkButton>
          }
        />
        <VehicleTypeFilters
          currentFilter={query}
          onFilter={(filter) => handleFilter(query, filter, setQuery)}
        />
        <VehicleTypesList
          vehicleTypes={vehicleType}
          currentSort={query}
          onSort={(sort) => handleSort(query, sort, setQuery)}
        />
        <Paginator onPageChange={onPageChange} pagination={pagination} />
      </S.MainPanel>
    </Scaffold>
  );
};
