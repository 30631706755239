import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Input } from 'components/Shared';
import { useValidation } from 'hooks';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { LoginActions as MainActions } from 'store/ducks/auth';
import * as Yup from 'yup';
import * as S from './styles';

export const LoginForm: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const formRef = useRef<FormHandles>(null);
    const { handleFormErrors, handleApiErrors } = useValidation();

    const { loading, validationErrors } = useSelector(
        (state: RootState) => state.login
    );

    const onFailure = (messagError: string) => {
        formRef.current?.setFieldError('password', messagError);
    };

    const onSubmit = useCallback(
        async (data: any): Promise<void> => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    email: Yup.string()
                        .email('E-mail inválido')
                        .required('E-mail obrigatório'),
                    password: Yup.string().required('Senha obrigatória'),
                });
                const validData = await schema.validate(data, {
                    abortEarly: false,
                });

                dispatch(MainActions.request(validData, null, onFailure));
            } catch (error) {
                handleFormErrors(error, formRef);
            }
        },
        [dispatch, handleFormErrors]
    );

    useEffect(() => {
        handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            formRef.current?.setFieldValue(
                'email',
                'bruno@gerasinergia.com.br'
            );
            formRef.current?.setFieldValue('password', 'foco');
        }
    });

    useEffect(() => {
        return () => {
            dispatch(MainActions.reset());
        };
    }, [dispatch]);

    return (
        <S.Container>
            <Form ref={formRef} onSubmit={onSubmit}>
                <Input name="email" label="E-mail" />
                <Input name="password" label="Senha" type="password" />
                <S.FormActions>
                    <S.Button type="submit">
                        {loading ? <S.ActivityIndicator /> : 'Entrar'}
                    </S.Button>
                    <S.ForgotMyPwsLink to="/esqueci-minha-senha">
                        Esqueceu sua senha?
                    </S.ForgotMyPwsLink>
                </S.FormActions>
            </Form>
        </S.Container>
    );
};
