import styled from "styled-components";
import { BasePanel } from "styles/components";
import { Colors } from "styles/constants";
import { Fullscreen, ExitFullscreen } from "@styled-icons/boxicons-regular";
export { UserPinIcon } from "styles/components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const MainPanel = styled(BasePanel)`
  flex: 1;
  flex-direction: column;
  background: ${Colors.White};
  padding: 24px;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: auto;
  border: none;
  border-radius: 8px;
  min-height: 100vh;
`;

export const Button = styled.button.attrs({ type: "button" })<{
  isFull?: boolean;
}>`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 50%;
  position: absolute;
  right: 48px;
  top: ${({ isFull }) => (isFull ? "48px" : "175px")};
  background-color: #121212;
  color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  transition: 300ms ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const IconFullscreen = styled(Fullscreen).attrs({ size: 24 })`
  color: #fff;
`;

export const IconExitFullscreen = styled(ExitFullscreen).attrs({ size: 24 })`
  color: #fff;
`;
