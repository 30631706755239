import styled from "styled-components";
import { Header, HeaderInfo } from '../../../Invoices/TrackingDetails/styles';
export { 
  Title, 
  Invoice, 
  InvoiceIcon, 
  Box, 
  BoxContent, 
  BoxHeader, 
  BoxBody, 
  CompanyIconOutline, 
  ClientIcon, 
  ArrowLeftIcon, 
  Back,
  HeaderInfo,
  Item,
  Info,
  EditAltIcon,
  Button
} from '../../../Invoices/TrackingDetails/styles';
export { Detail } from '../../../Invoices/TrackingDetails/Boxes/styles';
export * from '../Boxes/styles'

export const HeaderDeadline = styled(HeaderInfo)`
  display: flex;
  padding: 0;
`;

//DeadlineBox
export const DeadlinePanel = styled(Header)`
  width: 50%;
  height: 100%;
  margin: 0;
  border-radius: 0;
  gap: 28px;
  font-size: 12px;
`;

export const DeadlineLeftPanel = styled(DeadlinePanel)`
  border-radius: 6px 0px 0px 6px;
`;

export const DeadlineRightPanel = styled(DeadlinePanel)`
  background-color: #e9e9e9;
  border-radius: 0px 6px 6px 0px;
`;
//End DeadlineBox