import { CloseIcon } from "./../../../styles/components/icons";
import styled from "styled-components";
import { Colors, Fonts } from "styles/constants";
import { getScrollbarStyle } from "styles/constants";
import { BaseButtonStyle } from "styles/components";
import { DotIcon as Dot, CheckCircleIcon } from "./../../../styles/components/icons";
export {
  UserCircleIcon,
  MessageAddIcon,
  MessageCheckIcon,
} from "styles/components";

export const DotIcon = styled(Dot)`
 &.aboned {
   #Elipse_3{
     fill: ${Colors.Green};
   }
 }
`

export const Check = styled(CheckCircleIcon)`
  color: ${Colors.Green};
  width: 22px;
  height: 22px;
`

export const StatusGrid = styled.div`
  width: 100%;
  height: 350px;
  min-height: 200px;
  max-height: 300px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Gray10};
  border-top: 1px solid ${Colors.Gray40};
  margin-top: 32px;
  margin-bottom: 32px;
  overflow-y: auto;
  ${getScrollbarStyle()};
  .none {
    display: none;
  }
`;

export const Close = styled(CloseIcon).attrs({
  height: "18px",
  color: Colors.Gray50,
})`
  cursor: pointer;
`;

export const StatusTitle = styled.h2`
  display: flex;
  font-family: ${Fonts.GilroyBold};
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-size: 16px;
  margin-bottom: 32px;
  padding-left: 32px;
`;

export const Button = styled.button`
  display: flex;
  ${BaseButtonStyle};
  background-color: ${Colors.Blue};
  border-radius: 5px;
  color: ${Colors.White};
  padding: 3px;
  font-size: 0.7rem;
  gap: 3px;
  font-family: ${Fonts.GilroySemiBold};
  padding-right: 8px;
`;

export const ButtonCheck = styled(Button)`
  background-color: ${Colors.Green};
  width: 25%;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 5px;
`;

export const StatusWrapper = styled.div`
  position: relative;
  padding-left: 32px;
  border-left: 1px solid ${Colors.Gray40};
`;

export const StatusBox = styled.div`
  width: 100%;
  max-width: 80%;
  padding: 16px;
  border-radius: 4px;
  box-shadow: #00000025 0 20px 10px -16px;
  background-color: ${Colors.White};
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
  margin-bottom: 40px;
  transition: all ease 2s;

  &.new {
    opacity: 1;
  }
  &.nonew {
    opacity: 0;
  }

  svg.user {
    align-self: flex-start;
  }

  svg.dot {
    position: absolute;
    left: -39px;
  }

  div.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  :before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: ${Colors.White};
    transform: rotate(45deg);
    position: absolute;
    left: -5px;
  }
`;

export const UserName = styled.h5`
  display: flex;
  font-family: ${Fonts.GilroySemiBold};
  font-size: 14px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  color: ${Colors.Gray70};
`;

export const StatusDescription = styled.article`
  font-family: ${Fonts.OpenSans};
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.Gray60};
  margin-bottom: 16px;

  &.aboned {
    margin-bottom: 0;
  }
`;

export const Input = styled.textarea`
  font-family: ${Fonts.OpenSans};
  font-size: 14px;
  line-height: 22px;
  border-style: solid;
  resize: none;
  width: 90%;
  color: ${Colors.Gray60};
  margin-bottom: 16px;
  border-radius: 5px;
  border-color: ${Colors.Gray50};
  padding: 5px;
  ${getScrollbarStyle()};
`;

export const StatusDate = styled.span`
  font-family: ${Fonts.OpenSans};
  font-size: 12px;
  color: ${Colors.Gray50};
`;
