import * as Yup from 'yup';
import { getProductShape } from 'validators/Common/baseVehicleTypeValidator';

export class CreateVehicleTypeValidator {
  public get schema() {
    return Yup.object().shape({
      ...getProductShape()
    });
  }
}
