import { UserType } from 'contracts/Auth';
import { useAuth } from 'hooks';
import React from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './styles';
import { usePermission } from 'hooks/usePermission';

interface RouteBase {
  title: string;
  path: string;
  blockedRoles?: string[];
}

export const Navigation = () => {
  const location = useLocation();
  const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission()

  const rootPath = '/inbound/notas-fiscais/';

  const routesBase: RouteBase[] = [
    {
      title: 'Notas fiscais pendentes de liberação',
      path: 'pendentes-de-liberacao',
      blockedRoles:  ['PENDINGINVOICES.VISUALIZE'],
    },
    {
      title: 'Tracking',
      path: 'tracking',
      blockedRoles: ['TRACKING.VISUALIZE'],
    },
    {
      title: 'Notas fiscais entregues',
      path: 'entregues',
      blockedRoles: ['DELIVEREDINVOICES.VISUALIZE'],
    },
    {
      title: 'Notas fiscais efetivadas SAP',
      path: 'finalizadas',
      blockedRoles: ['EFFECTIVEINVOICES.VISUALIZE'],
    },
  ];

  const isActive = (path: string) => path === location.pathname;

  const routes = routesBase.filter(
    (item) => {

     return !item.blockedRoles || hasPermissionTo(item.blockedRoles)
    }
  );

  return (
    <S.Container>
      {routes.map((route) => (
        <S.Button
          key={route.path}
          to={`${rootPath}${route.path}`}
          className={isActive(`${rootPath}${route.path}`) ? 'actived' : ''}
        >
          {route.title}
        </S.Button>
      ))}
    </S.Container>
  );
};
