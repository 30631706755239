import { RouteObject, Outlet, Navigate } from "react-router-dom";
import type { RouterProps } from "contracts/Router";
import {
  JustificationUpdatePage,
  JustificationsListPage,
  JustificationCreationPage,
} from "pages/Justifications";

const justificationsRoutes = ({
  location,
  userBelongsToAnyOf,
  hasPermissionTo
}: RouterProps): RouteObject => ({
  path: "configuracoes/justificativas",
  element: hasPermissionTo(['JUSTIFICATIONS.FINDJUSTIFICATIONS']) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: "",
      element: <JustificationsListPage />,
    },
    {
      path: "criar",
      element: <JustificationCreationPage />,
    },
    {
      path: ":id/editar",
      element: <JustificationUpdatePage />,
    },
  ],
});

export default justificationsRoutes;
