import {
  useState,
  useEffect
} from "react";

import { Formatter } from "utils";
import * as S from "./styles";
import { Invoice } from "contracts/Invoice";


interface ModalProps {
  invoice?: Invoice | null;
  title: string;
}

export const ModalStatus = ({ invoice, title }: ModalProps) => {
  
  const [justificationAbonedArray, setJustificationAbonedArray] = useState<any[]>([]);

  useEffect(() => {
    const justificationAboned = invoice?.justifications.filter((element) => element.aboned_at);
    const sortJustificationAboned = justificationAboned?.sort((a: any, b: any) => {
      const dateA = new Date(a.aboned_at).getTime();
      const dateB = new Date(b.aboned_at).getTime();
      return dateA < dateB ? 1 : -1;
    });
    setJustificationAbonedArray(sortJustificationAboned || []);
  }, [invoice]);

  if (!invoice) return <></>;
  
  const { statuses } = invoice;

  return (
    <S.StatusGrid>
      <S.StatusTitle>
        {statuses.length > 0
          ? title
            ? title
            : "Últimos status"
          : "Nenhum status encontrado"}
      </S.StatusTitle>
      <S.StatusWrapper>
        <>
          {justificationAbonedArray.map((element, index) => {
            return(
            <S.StatusBox key={index}>
              <S.DotIcon className="dot aboned" color="green" />
              <S.Check />
              <div className="wrapper">
                <S.StatusDescription className="aboned">
                  {element?.justification?.description && `Justificativa: ${element?.justification?.description}`}
                </S.StatusDescription>
                <S.StatusDescription className="aboned">
                  Abonado em:{" "}
                  {Formatter.date(element.aboned_at, {
                    format: "dd/MM/yyyy HH:mm",
                  })}
                </S.StatusDescription>
                <S.StatusDescription className="aboned">
                  Usuario: {element?.user?.name}
                </S.StatusDescription>
              </div>
            </S.StatusBox>
            );
          })}
          {statuses.map((item: any) => (
            <S.StatusBox key={item.id}>
              <S.DotIcon className="dot" />
              <S.UserCircleIcon className="user" />
              <div className="wrapper">
                <S.UserName>{item.user ? item.user.name : "Sistema"}</S.UserName>
                <S.StatusDescription>
                  Mudança de status para "{item.status.name}"
                </S.StatusDescription>
                <S.StatusDate>
                  {Formatter.date(item.createdAt, {
                    format: "dd/MM/yyyy HH:mm",
                  })}
                </S.StatusDate>
              </div>
            </S.StatusBox>
          ))}
        </>
      </S.StatusWrapper>
    </S.StatusGrid>
  );
};
