import { SortableHeader, SortableHeadersGroup } from "components/Shared"
import { EMPTY_COLUMN_VALUE } from "constants/Common"

import { SortingParams } from "contracts/Common"
import React, { useCallback } from "react"
import { Formatter } from "utils"
import * as S from "./styles"
import { PaginatedWarehouseMember } from "contracts/WarehouseMembers"

interface Props {
  members: PaginatedWarehouseMember[]
  onSort?: (sort: SortingParams) => void
  onImpersonate?: (email: string) => void
  currentSort: SortingParams
}

interface ItemProps {
  member: PaginatedWarehouseMember
}

export const MembersList: React.FC<Props> = ({
  members,
  onSort,
  onImpersonate,
  currentSort,
}) => {
  const Item = useCallback(
    ({ member }: ItemProps): JSX.Element => {
      const { id, name, email, loggedAt, blockedAt, companyUser } = member

      const companies = companyUser?.map((company) => company.tradeName)
        .join(", ")

      return (
        <S.ListItem>
          <S.Column>{id}</S.Column>
          <S.Column title={name}>{name}</S.Column>
          <S.Column title={email}>{email}</S.Column>
          <S.Column title={companies}>{companies ?? '---'}</S.Column>
          <S.Column>
            {loggedAt
              ? Formatter.date(loggedAt, {
                  format: "dd/MM/yyyy 'as' HH:mm",
                })
              : EMPTY_COLUMN_VALUE}
          </S.Column>
          <S.ActionsColumn>
            {!!blockedAt && <S.LockIcon title="Usuário bloqueado" />}
            <S.ActionButton
              mood="void"
              title="Fazer login como este usuário"
              onClick={() => onImpersonate?.(email)}
            >
              <S.LoginIcon />
            </S.ActionButton>
            <S.LinkActionButton
              to={`/configuracoes/armazem/usuarios/${id}/editar`}
            >
              <S.EditIcon />
            </S.LinkActionButton>
          </S.ActionsColumn>
        </S.ListItem>
      )
    },
    [onImpersonate]
  )

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="userName" label="NOME" />
          <SortableHeader column="userEmail" label="E-MAIL" />
          <SortableHeader column="carrierName" label="ARMAZÉM" />
          <SortableHeader column="userLoggedAt" label="ÚLTIMO LOGIN" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {members?.map((member) => (
        <Item key={member.id} member={member} />
      ))}
    </S.List>
  )
}
