import * as S from "./styles";
import { Loading } from "components/Shared/Loading/styles";
import NumberFormat from "react-number-format";
import { useState, useCallback, useRef, useEffect } from "react";
import { Form } from "@unform/web";
import { useAuth, useCarriers } from "hooks";
import { FormHandles } from "@unform/core";
import { api, notify } from "services";
import { useDispatch } from "react-redux";
import { FetchPendingReleaseActions as FetchActions } from "store/ducks/pendingRelease";
import { Invoice } from "contracts/Invoice";
import { usePermission } from "hooks/usePermission";

interface BoxesProps {
  invoice: Invoice;
}

export const Boxes = ({ invoice }: BoxesProps) => {
  const { hasPermissionTo } = usePermission()

  const hasChangeTransporterVisible = hasPermissionTo(['TRACKING.CHANGETRANSPORTER']);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { carrierOptions, loadingCarriers, fetchCarriers } = useCarriers();
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();

  const scroll = useCallback(() => {
    isOpen
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
  }, [isOpen]);

  const ChangeCarrier = useCallback(() => {
    const onSubmit = async () => {
      setLoading(true);
      const carrierId = formRef.current?.getFieldValue("changeCarrier");
      const data = {
        ids: [invoice.id],
        carrier_id: carrierId,
      };
      try {
        await api.post(`invoices/carrier`, data);
        notify("success", "Alteração feita com sucesso");
        setLoading(false);
        setIsOpen(false);
        dispatch(FetchActions.request(invoice.id));
      } catch (err) {
        notify("error", `${err}`);
        setLoading(false);
        setIsOpen(false);
      }
    };
    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <S.TruckIcon />
            Alterar Transportadora
            <S.Close onClick={() => setIsOpen(false)} />
          </S.ModalTitle>
          <Form ref={formRef} onSubmit={onSubmit}>
            <S.SelectCarrier
              name="changeCarrier"
              label="Transportadora"
              options={carrierOptions}
              isLoading={loadingCarriers}
              defaultValue={carrierOptions.find((o) => o.value === carrier.id)}
            />
            <S.FormActions>
              <S.FormButton onClick={() => setIsOpen(false)}>
                {loading && <Loading color="#fff" />}
                Voltar
              </S.FormButton>
              <S.FormButton type="submit" theme="accept">
                {loading && <Loading color="#fff" />}
                Salvar
              </S.FormButton>
            </S.FormActions>
          </Form>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [carrierOptions, invoice]);

  useEffect(() => {
    fetchCarriers();
  }, [fetchCarriers]);

  useEffect(() => {
    scroll();
  }, [isOpen]);

  if (!invoice) {
    return <></>;
  }

  const { carrier, company, supplier, destEnderdestUf } = invoice;

  const isImport = destEnderdestUf
    ? ["EXTERIOR", "EX"].includes(destEnderdestUf)
    : false;
  const finalOrigin = isImport ? company : supplier;
  const finalDestiny = isImport ? supplier : company;

  const {
    addressCity: cityOrigin,
    addressNeighborhood: NeighborhoodOrigin,
    addressNumber: numberOrigin,
    addressStreet: streetOrigin,
    addressUf: ufOrigin,
    document: cnpjOrigin,
    tradeName: nameOrigin,
  } = finalOrigin;

  const {
    addressCity: cityCarrier,
    addressNeighborhood: NeighborhoodCarrier,
    addressNumber: numberCarrier,
    addressStreet: streetCarrier,
    addressUf: ufCarrier,
    document: cnpjCarrier,
    tradeName: nameCarrier,
  } = carrier;

  const {
    addressCity: cityDestiny,
    addressNeighborhood: NeighborhoodDestiny,
    addressNumber: numberDestiny,
    addressStreet: streetDestiny,
    addressUf: ufDestiny,
    document: cnpjDestiny,
    tradeName: nameDestiny,
  } = finalDestiny;

  return (
    <S.Boxes>
      {isOpen && <ChangeCarrier />}
      <S.Box>
        <S.TitleInfo twoCols>
          <S.ClientIcon />
          {isImport ? "Remetente/Destinatário" : "Remetente"}
        </S.TitleInfo>
        <S.BoxBody>
          <S.Detail>
            Nome:
            <S.Info>{nameOrigin}</S.Info>
          </S.Detail>
          <S.Detail>
            CNPJ:
            <S.Info>
              <NumberFormat
                value={cnpjOrigin}
                displayType={"text"}
                format="##.###.###/####-##"
              />
            </S.Info>
          </S.Detail>
          <S.Detail>
            Endereço:
            <S.Info>
              {streetOrigin}, {numberOrigin}, {NeighborhoodOrigin}, {cityOrigin}
              /{ufOrigin}
            </S.Info>
          </S.Detail>
        </S.BoxBody>
      </S.Box>
      <S.Box>
        <S.TitleInfo>
          <S.TruckIcon />
          Transportadora
          {hasChangeTransporterVisible && (
            <S.Button onClick={() => setIsOpen(true)}>
              <S.EditAltIcon height={"18px"} />
              Alterar
            </S.Button>
          )}
        </S.TitleInfo>
        <S.BoxBody>
          <S.Detail>
            Nome:
            <S.Info>{nameCarrier}</S.Info>
          </S.Detail>
          <S.Detail>
            CNPJ:
            <S.Info>
              <NumberFormat
                value={cnpjCarrier}
                displayType={"text"}
                format="##.###.###/####-##"
              />
            </S.Info>
          </S.Detail>
          <S.Detail>
            Endereço:
            <S.Info>
              {streetCarrier}, {numberCarrier}, {NeighborhoodCarrier},{" "}
              {cityCarrier}/{ufCarrier}
            </S.Info>
          </S.Detail>
        </S.BoxBody>
      </S.Box>
      <S.Box>
        <S.TitleInfo>
          <S.CompanyIconOutline />
          {isImport ? "Remetente/Destinatário" : "Destinatário"}
        </S.TitleInfo>
        <S.BoxBody>
          <S.Detail>
            Nome:
            <S.Info>{nameDestiny}</S.Info>
          </S.Detail>
          <S.Detail>
            CNPJ:
            <S.Info>
              <NumberFormat
                value={cnpjDestiny}
                displayType={"text"}
                format="##.###.###/####-##"
              />
            </S.Info>
          </S.Detail>
          <S.Detail>
            Endereço:
            <S.Info>
              {streetDestiny}, {numberDestiny}, {NeighborhoodDestiny},{" "}
              {cityDestiny}/{ufDestiny}
            </S.Info>
          </S.Detail>
        </S.BoxBody>
      </S.Box>
    </S.Boxes>
  );
};
