import { useCallback, useContext } from "react";
import type { SortingParams } from "contracts/Common";
import type { PaginatedCollect } from "contracts/Collects";
import { SortableHeader, SortableHeadersGroup } from "components/Shared";

import * as S from "./styles";
import { Formatter } from "utils/Formatter";
import { ReverseLogisticContext } from "contexts/ReverseLogisticContext";
import { useAuth } from "hooks";
import { usePermission } from "hooks/usePermission";

interface Props {
  onSort?: (sort: SortingParams) => void;
}

interface ItemProps {
  collect: PaginatedCollect;
}

export const CollectsList = ({ onSort }: Props) => {
  const { collects, query } = useContext(ReverseLogisticContext);

  const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission();
  //   const isCarrierMember = userBelongsToAnyOf("carrierMember");
  const isCarrierMember = hasPermissionTo([
    "CARRIER-MEMBERS.FINDMEMBERS",
    "CARRIER-MEMBERS.FINDMEMBER",
  ]);

  const Item = useCallback(
    ({ collect }: ItemProps): JSX.Element => {
      const {
        id,
        carrier,
        requestRqps,
        client,
        company,
        requestDate,
        collectCarrierDeadline,
        collectOficialDeadline,
        incineration,
      } = collect;

      // Aguardando coleta (quando a data de coleta não foi preenchido)
      // Coletado
      // Entregue
      // Finalizado (quando o usuário ADM validar as informações e "der um de acordo" para as informações lançadas - Função inexistente hoje).
      // Essa etapa é fundamental para garant siga nos conformes e não tenhamos surpresas no futuro.

      let collectStage = "Aguardando Coleta";
      if (collect.deletedAt) {
        collectStage = "Cancelada";
      } else if (collect.collectDate && !collect.deliveryDate) {
        collectStage = "Coletado";
      } else if (collect.deliveryDate) {
        collectStage = "Entregue";
      } else {
        collectStage = "Aguardando Coleta";
      }

      // Coleta No prazo (hoje <= que prazo para coleta Transp);
      // Coleta em Atraso: (hoje> prazo para coleta Transp)
      // Coleta comprometida Fiscalmente ( hoje > prazo Oficial)
      // Entrega em atraso (hoje > prazo entrega, se e somente se data de coleta estiver preenchida, se não assume coleta em atraso ou coleta comprometida fiscalmente);
      // Entrega no Prazo (hoje <= prazo para entrega, se e somente se data da coleta preenchida, se não assumir status da coleta (Coleta em atraso ou coleta Comprometida fiscalmente)

      let collectStatus = "Coleta no prazo";
      if (collect.deletedAt) {
        collectStatus = "Coleta cancelada";
      } else if (
        new Date() > new Date(collect.collectCarrierDeadline) &&
        !collect.collectDate
      ) {
        collectStatus = "Coleta em atraso";
      } else if (
        new Date() > new Date(collect.collectOficialDeadline) &&
        !collect.collectDate
      ) {
        collectStatus = "Coleta comprometida fiscalmente";
      } else if (
        new Date() > new Date(collect.collectDeliveryDeadline) &&
        collect.collectDate
      ) {
        collectStatus = "Entrega em atraso";
      } else if (
        (new Date() <= new Date(collect.collectDeliveryDeadline) &&
          collect.collectDate) ||
        (collect.deliveryDate &&
          collect.deliveryDate <= collect.collectDeliveryDeadline)
      ) {
        if (collect.collectDate) {
          // Converter a string para um objeto Date
          const dataColeta = new Date(collect.collectDate);

          // Adicionar 20 dias à data original
          dataColeta.setDate(dataColeta.getDate() + 20);

          // Verificar se a data atual é maior do que a data original + 20 dias
          if (new Date() > dataColeta) {
            collectStatus = "Entrega em atraso";
          } else {
            collectStatus = "Entrega no prazo";
          }
        } else {
          collectStatus = "Entrega no prazo";
        }
      } else {
        collectStatus = "Coleta no prazo";
      }

      return (
        <S.ListItem>
          <S.Column>{id}</S.Column>
          <S.Column title={carrier.tradeName}>{carrier.tradeName}</S.Column>
          <S.Column title={requestRqps}>{requestRqps}</S.Column>
          <S.Column title={client.tradeName}>{client.tradeName}</S.Column>
          <S.Column title={company.tradeName}>{company.tradeName}</S.Column>
          <S.Column title={collectStage}>{collectStage}</S.Column>
          <S.Column title={collectStatus}>{collectStatus}</S.Column>
          <S.Column title={incineration ? "Sim" : "Não"}>
            {incineration ? "Sim" : "Não"}
          </S.Column>
          <S.Column>
            {(requestDate && Formatter.date(requestDate)) || "---"}
          </S.Column>
          <S.Column>
            {(collectCarrierDeadline &&
              Formatter.date(collectCarrierDeadline)) ||
              "---"}
          </S.Column>
          {!isCarrierMember && (
            <S.Column>
              {(collectOficialDeadline &&
                Formatter.date(collectOficialDeadline)) ||
                "---"}
            </S.Column>
          )}
          <S.ActionsColumn>
            {hasPermissionTo([
              "REVERSE-LOGISTICS.FINDREVERSELOGISTIC",
            ]) && (
              <S.LinkActionButton to={`/logistica-reversa/coleta/${id}`}>
                <S.EditIcon />
              </S.LinkActionButton>
            )}
          </S.ActionsColumn>
        </S.ListItem>
      );
    },
    [userBelongsToAnyOf]
  );

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={query}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="carrierTradeName" label="TRANSPORTADORA" />
          <SortableHeader column="requestRqps" label="RQPS" />
          <SortableHeader column="clientTradeName" label="CLIENTE" />
          <SortableHeader column="companyTradeName" label="FILIAL DESTINO" />
          <SortableHeader
            column="collectStage"
            label="ANDAMENTO"
            unsortable={true}
          />
          <SortableHeader
            column="collectStatus"
            label="STATUS"
            unsortable={true}
          />
          <SortableHeader column="incineration" label="INCINERAR?" />
          <SortableHeader column="requestDate" label="DT. EMISSAO" />
          <SortableHeader column="collectCarrierDeadline" label="PR. TRANSP" />
          {!isCarrierMember && (
            <SortableHeader
              column="collectOficialDeadline"
              label="PR. OFICIAL"
            />
          )}
        </SortableHeadersGroup>
      </S.ListHeader>
      {collects.map((collect) => (
        <Item key={collect?.id} collect={collect} />
      ))}
    </S.List>
  );
};
