import { FormHandles } from '@unform/core';
import { Input, Select } from 'components/Shared';
import { ReverseLogisticContext } from 'contexts/ReverseLogisticContext';
import { useCarriers, useClients, useCompanies } from 'hooks';
import { useDebounce } from 'hooks/useDebounce';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FetchStatusListActions } from 'store/ducks/status';
import * as S from './styles';
import { Sheet } from 'lucide-react';

const CollectFilter = () => {
  const dispatch = useDispatch();
  const { onQueryChange } = useContext(ReverseLogisticContext);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const formRef = useRef<FormHandles>(null);
  const debouncedSearchTerm: string | undefined = useDebounce<
    string | undefined
  >(search, 500);
  const { carrierOptions, loadingCarriers, fetchCarriers } = useCarriers();
  const { clientOptions, loadingClients, fetchClients } = useClients();
  const { companyOptions, loadingCompanies, fetchCompanies } = useCompanies();

  const progressOptions = [
    { value: 'expirando', label: 'A Expirar' },
    { value: 'expirado', label: 'Expirado' },
    { value: 'no-prazo', label: 'No prazo' },
    { value: 'pendente-transporte', label: 'Aguardando coleta' },
    { value: 'saiu-coleta', label: 'Saiu para coleta' },
    { value: 'coletado', label: 'Coletado' },
    { value: 'entregue', label: 'Entregue' },
    { value: 'conc-dentro', label: 'Concluído no prazo' },
    { value: 'conc-fora', label: 'Concluído fora do prazo' },
    // { value: 'cancelada', label: 'Cancelada' },
  ];

  const handleChangeCarrier = useCallback(
    (carrierId?: string | number) => {
      onQueryChange({ carrierId: carrierId ? Number(carrierId) : undefined });
    },
    [onQueryChange]
  );

  const handleChangeClient = useCallback(
    (clientId?: string | number) => {
      onQueryChange({ clientId: clientId ? Number(clientId) : undefined });
    },
    [onQueryChange]
  );

  const handleChangeCompany = useCallback(
    (companyId?: string | number) => {
      onQueryChange({ companyId: companyId ? Number(companyId) : undefined });
    },
    [onQueryChange]
  );

  const handleChangeStatus = useCallback(
    (status?: string | number) => {
      onQueryChange({ status: status ? String(status) : undefined });
    },
    [onQueryChange]
  );

  useEffect(() => {
    onQueryChange({
      search: debouncedSearchTerm ? debouncedSearchTerm : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchCarriers();
    fetchClients();
    fetchCompanies();
    dispatch(FetchStatusListActions.request({ asList: true, notFlow: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.ContainerFilter>
      <S.Content ref={formRef} onSubmit={() => {}}>
        <Select
          name="carrierId"
          options={carrierOptions}
          placeholder="Transportadora"
          onChange={(opt) => handleChangeCarrier(opt?.value)}
          isLoading={loadingCarriers}
          isClearable
        />
        <Select
          name="clientId"
          options={clientOptions}
          placeholder="Cliente"
          onChange={(opt) => handleChangeClient(opt?.value)}
          isLoading={loadingClients}
          isClearable
        />
        <Select
          name="companyId"
          options={companyOptions}
          placeholder="Destinatário"
          onChange={(opt) => handleChangeCompany(opt?.value)}
          isLoading={loadingCompanies}
          isClearable
        />
        <Select
          name="status"
          options={progressOptions}
          placeholder="Andamento"
          onChange={(opt) => handleChangeStatus(opt?.value)}
          isClearable
        />
        <Input
          name="search"
          type="search"
          placeholder="Buscar..."
          defaultValue={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </S.Content>
    </S.ContainerFilter>
  );
};

export { CollectFilter };
