import * as S from './styles';
import React from 'react';
import { Loading } from "components/Shared/Loading";
import { TracePoint } from "components/Shared/TracePoint";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Status } from "../../Invoices/Status";
import { Boxes } from "./Boxes";
import { HeaderDeadline } from "./HeaderDeadline";
import { HeaderInfo } from "./HeaderInfo";
import { ItemInfo } from "./ItemInfo";
import { Documents } from "./Documents";
import { SendPdf } from "./SendPdf";
import { JustificationsBox } from "./Justifications";
import { StatusBox } from './Status'
import { Allowance } from './Allowance'
import type { Collect } from 'contracts/Collects';
import { Formatter } from 'utils';
import { usePermission } from 'hooks/usePermission';

interface Props {
  collect: Collect;
}

export const CollectView: React.FC<Props> = ({ collect }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const onBack = useCallback(() => {
    navigate("/logistica-reversa/coletas");
  }, [navigate]);
  const {hasPermissionTo} = usePermission()

  let collectStage = 'Aguardando Coleta';
  let collectColor = 'orange';
  if (collect.deletedAt) {
    collectStage = 'Cancelada';
    collectColor = 'red'
  } else if (collect.finishDate) {
    collectStage = 'Finalizada';
    collectColor = 'green'
  } else if (collect.collectDate && !collect.deliveryDate) {
    collectStage = 'Coletado';
    collectColor = '#1AB1F3'
  } else if (collect.deliveryDate) {
      collectStage = 'Entregue';
      collectColor = 'green'
  } else {
      collectStage = 'Aguardando Coleta';
      collectColor = 'orange'
  }

  return (
    <S.CollectView>
      {collect.deletedAt && 
      <S.CancelInfo>
        <div>CANCELADA</div>
        <div>Cancelada em {Formatter.date(collect.deletedAt)}</div>
      </S.CancelInfo>
      }
      <S.HeaderCollect>
        {loading && (
          <S.Title>
            <S.InvoiceIcon />
            <Loading />
          </S.Title>
        )}
        {!loading && (
          <S.Invoice>
            <S.Title>
              <S.InvoiceIcon />
              {collect.id}
            </S.Title>
            <Status color={collectColor}>
              {collectStage}
            </Status>
          </S.Invoice>
        )}
        <S.Box>
          <S.BoxContent>
            <S.BoxHeader>            
              {collect.client.tradeName}
              <S.CompanyIconOutline />
            </S.BoxHeader>
            <S.BoxBody justify="end" className='city'>
              {collect.client.addressCity}/{collect.client.addressUf}
            </S.BoxBody>
          </S.BoxContent>
          <TracePoint text='' />
          <S.BoxContent>
            <S.BoxHeader>
              <S.ClientIcon />
              {collect.company.tradeName}
            </S.BoxHeader>
            <S.BoxBody justify="start" className='city'>
              {collect.company.addressCity}/{collect.company.addressUf}
            </S.BoxBody>
          </S.BoxContent>
        </S.Box>
        <S.Back onClick={onBack}>
          <S.ArrowLeftIcon />
          Voltar
        </S.Back>
      </S.HeaderCollect>

      <HeaderInfo collect={collect} />

      <HeaderDeadline collect={collect} />

      <Allowance collect={collect} />

      <StatusBox collect={collect} />

      <Boxes collect={collect} />

      <ItemInfo collect={collect} />
      
      <Documents collect={collect} />

      {hasPermissionTo(['REVERSE-LOGISTICS.PDFEMAIL']) && <SendPdf collectId={collect.id} />}
      
      <JustificationsBox collect={collect} />

      
    </S.CollectView>
    
  )
}