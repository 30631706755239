import * as S from "./styles";
import { Formatter } from 'utils';
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { api, notify } from "services";
import { FetchCollectActions as FetchActions } from "store/ducks/collects";
import { useCallback, useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Loading } from "components/Shared/Loading";
import { useReasons, useClassifications, useAuth } from "hooks";
import { usePermission } from "hooks/usePermission";

export const HeaderInfo = ({ collect }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { reasonOptions, loadingReasons, fetchReasons } = useReasons();
  const { classificationOptions, loadingClassifications, fetchClassifications } = useClassifications();
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
//   const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission()
//   const isCarrierMember = userBelongsToAnyOf("carrierMember");
  const isCarrierMember = hasPermissionTo(['REVERSE-LOGISTICS.UPDATEREVERSELOGISTICSHEADER']);


  //Modal
  const ChangeInfo = useCallback(() => {

    const incinerationOptions = [
      {"value": 0, "label": 'Não'},
      {"value": 1, "label": 'Sim'}
    ];

    const onSubmit = async () => {
      setLoading(true);
      const reverseLogisticsReasonId = formRef.current?.getFieldValue("changeReason");
      const reverseLogisticsClassificationId = formRef.current?.getFieldValue("changeClassification");
      const incineration = formRef.current?.getFieldValue("incineration");
      const collectDaysLeft = formRef.current?.getFieldValue("collectDaysLeft") || null;

      const data = {
          ...collect,
          reverseLogisticsReasonId,
          reverseLogisticsClassificationId,
          incineration: incineration ? 1 : 0,
          collectDaysLeft,
      };

      try {
          await api.put(`reverse-logistics/${collect.id}`, data);
          notify("success", "Alteração feita com sucesso");
          setLoading(false);
          setIsOpen(false);
          dispatch(FetchActions.request(collect.id));
      } catch (err) {
          notify("error", `${err}`);
          setLoading(false);
          setIsOpen(false);
      }
    }
    return (
      <S.ModalBackground>
        <S.ModalContainer>
            <S.ModalTitle>
            <S.TruckIcon />
            Alterar Informações
            <S.Close onClick={() => setIsOpen(false)} />
            </S.ModalTitle>
            <Form ref={formRef} onSubmit={onSubmit}>
              <S.SelectCarrier
                name="changeReason"
                label="Motivo:"
                options={reasonOptions}
                isLoading={loadingReasons}
                defaultValue={reasonOptions.find((o) => o.value === collect.reason.id)}
              />
              <S.SelectCarrier
                name="changeClassification"
                label="Classificação:"
                options={classificationOptions}
                isLoading={loadingClassifications}
                defaultValue={classificationOptions.find((o) => o.value === collect.classification.id)}
              />
              <S.InputModal
                name="collectDaysLeft"
                label="Dias para coleta:"
                type="number"
                defaultValue={collect.collectDaysLeft}
              />
              <S.SelectCarrier
                name="incineration"
                label="Incinerar?"
                options={incinerationOptions}
                defaultValue={incinerationOptions.find((o) => o.value === collect.incineration)}
              />
              <S.FormActions>
                  <S.FormButton onClick={() => setIsOpen(false)}>
                  {loading && <Loading color="#fff" />}
                  Voltar
                  </S.FormButton>
                  <S.FormButton type="submit" theme="accept">
                  {loading && <Loading color="#fff" />}
                  Salvar
                  </S.FormButton>
              </S.FormActions>
            </Form>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  },[reasonOptions, loadingReasons, classificationOptions, loadingClassifications, collect, loading, dispatch]);

  useEffect(() => {
    fetchReasons();
  }, [fetchReasons]);

  useEffect(() => {
    fetchClassifications();
  }, [fetchClassifications]);

  if (!collect) {
    return <></>;
  }

  return (
    <S.HeaderInfo>
      {isOpen && <ChangeInfo />}
      <S.Item>
        Motivo
        <S.Info>{collect.reason.description}</S.Info>
      </S.Item>
      <S.Item>
        Classificação
        <S.Info>{collect.classification.description}</S.Info>
      </S.Item>
      <S.Item>
        Dias para coleta
        <S.Info>{collect.collectDaysLeft || "---"}</S.Info>
      </S.Item>
      <S.Item>
        Nota Fiscal
        <S.Info>{collect.collectNfeNumber || "---"}</S.Info>
      </S.Item>
      <S.Item>
        Incinerar?
        <S.Info>{collect.incineration ? 'Sim' : 'Não'}</S.Info>
      </S.Item>
      <S.Item>
        Valor do Frete
        <S.Info>{collect.freightValue && Formatter.currency(collect.freightValue) || "---"}</S.Info>
      </S.Item>
      <S.Button disabled={collect.deletedAt || collect.finishDate || !isCarrierMember} onClick={() => setIsOpen(true)}><S.EditAltIcon height={"18px"} />Alterar</S.Button>
    </S.HeaderInfo>
  );
}