import React from 'react';

const elements = {
    1: {
        1: [],
        2: [],
        3: [],
        4: [],
    },
    2: {
        1: [],
        2: [],
        3: [],
    },
    3: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
    },
};

const Loading = () => {
    return (
        <div className="flex flex-col animate-pulse ">
            {Object.entries(elements).map(([_, value]) => {
                const el = Object.entries(value).map(() => (
                    <div className="h-[52px] bg-gray-200 rounded-sm dark:bg-gray-700 w-48 mb-4" />
                ));

                return (
                    <div className="w-full flex flex-col gap-4">
                        <div className="h-5 bg-gray-200 rounded-sm dark:bg-gray-700 w-48" />
                        <div className="w-full flex flex-row gap-2">{el}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default Loading;
