import * as S from "./styles";
import { useMemo } from "react";

interface FeatureTrace {
  color?: string;
  length?: number | string;
  text?: string | null;
  arrowLeft?: boolean;
  arrowRight?: boolean;
}

export const TracePoint = ({
  color,
  length,
  text,
  arrowLeft,
  arrowRight,
}: FeatureTrace) => {
  const txt = useMemo(() => {
    return text;
  }, [text]);

  return (
    <S.Container>
      {arrowLeft ? <S.Left dye={color}/> : <S.Circle dye={color} />}
      <S.Line dye={color} size={length}>
        <S.Box className={txt ? "animate" : ""} dye={color}>
          {txt}
        </S.Box>
      </S.Line>
      {arrowRight ? <S.Right dye={color}/> : <S.Circle dye={color} />}
    </S.Container>
  );
};
