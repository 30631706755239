import { DataTableColumnHeader } from 'components/ui/DataTable';
import { ColumnDef } from '@tanstack/react-table';
import {
    DataTableActions,
    IDataTableAction,
} from 'components/ui/DataTableActions';
import { Formatter } from 'utils';
import { Checkbox } from 'components/ui/checkbox';

import { modalNewJustificationRef } from '../../InvoiceDetails';
import { CheckedState } from '@radix-ui/react-checkbox';
import { useState } from 'react';
import { notify } from 'services';
import { useDispatch } from 'react-redux';
import { AbonedInvoiceJustificationsActions, PayloadAboned } from 'store/ducks/invoiceJustifications';
import { Check, Close } from './styles';
import { useInvoiceJustifications } from 'hooks';


export function useJustificationsColumns(justifications: any, invoice: any) {
    const [selected, setSelected] = useState<any[]>([]);
    const [removeds, setRemoveds] = useState<any[]>([])
    const dispatch = useDispatch()
    const {  fetchInvoiceJustifications } =
    useInvoiceJustifications();


    const handleAction = (justification: any) => {

        const isSelected = selected.some((a) => a.id === justification.id);
        if (isSelected) {
            const rest = selected.filter((a) => a.id !== justification.id);
            setSelected(rest);
            setRemoveds([...removeds, justification])
        } else {
           const rest = removeds.filter(a => a.id !== justification.id)
            setSelected([...selected, justification]);
            setRemoveds(rest)
        }
    };



    const selectAllActions = (toogle: CheckedState) => {
        if (toogle) {
            setSelected(justifications);
            setRemoveds([])
        } else {
            setSelected([]);
            setRemoveds(justifications)
        }
    };


    const isSelectedAll = justifications.every((justification: any) =>
        selected.map((s) => s.id).includes(justification.id)
    );

    const reset = (justifications: any[]) => {
        setSelected(justifications);
    };


    const onSuccessAbone = () => {
        notify("success", "Justificativa abonada com sucesso!")
        fetchInvoiceJustifications(invoice.id)
    }

    const onFailureAbone = () => {
        notify("error", "Não foi possível abonar justificativa.")
    }

    const handleAbonar = (justification: any) => {

        const payload: PayloadAboned = {
            invoiceId: invoice.id,
            justificationIds: [justification.id]
        }

        dispatch(AbonedInvoiceJustificationsActions.request(payload, onSuccessAbone, onFailureAbone))
    }

    const handleAbonarAll = () => {
    
        if(selected.length === 0) {
            notify("error", "Selecione uma ou mais justificativas")
            return
        }

        const pluckJustifications = selected.map(justification => justification.id)
        const payload: PayloadAboned = {
            invoiceId: invoice.id,
            justificationIds: pluckJustifications
        }

        dispatch(AbonedInvoiceJustificationsActions.request(payload, onSuccessAbone, onFailureAbone))
    }


    const hasAboned = justifications.some((justification: any) => justification.aboned_at)

    const columns: ColumnDef<any>[] = [
        {
            id: 'select',
            header: () => (
                <Checkbox
    
                    checked={isSelectedAll}
                    onCheckedChange={(value) =>
                       {
                        selectAllActions(value)
                       }
                    }
                    aria-label="Select all"
                />
            ),
            cell: ({ row }) => {
                const justification = row.original;
                const isChecked = selected.some((s) => s.id === justification.id);
                
                return (
                <Checkbox
                    checked={isChecked}
                    onCheckedChange={(value) => {
                        handleAction(justification)
                    }}
                    aria-label="Select row"
                />
            )},
            enableSorting: false,
            enableHiding: false,
        },
        {
            id: 'Data',
            accessorKey: 'created_at',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Data" />
            ),
            cell: ({ row }) => {
                return Formatter.date(row.original.created_at, {
                    format: 'dd/MM/yyyy HH:mm',
                });
            },
            enableHiding: false,
            enableSorting: false,
        },
        {
            id: 'Tipo',
            accessorKey: 'justification',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Tipo" />
            ),
            cell: ({ row }) => {
                return row.original.justification?.description ?? "---";
            },
            enableHiding: false,
            enableSorting: false,
        },
        {
            id: 'Comentario',
            accessorKey: 'description',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Comentário" />
            ),
            cell: ({ row }) => {
                return row.original?.description ?? "---";
            },
            enableHiding: false,
            enableSorting: false,
        },
        {
            accessorKey: 'status',
            header: 'Status',
            cell: ({ row }) => {
            
                if(row.original.aboned_at){
                    return <Check />
                } else {
                    return <Close/> 
                }
               
            },
        },
        {
            id: 'actions',
            header: () => {
                const actions: IDataTableAction[] = [
                    {
                        name: 'Nova justificativa',
                        route: '#',
                        onPress: () => modalNewJustificationRef.current?.open(),
                        hasPermission: true,
                    },
                    {
                        name: 'Abonar',
                        route: `#`,
                        hasPermission: true,
                        onPress: handleAbonarAll,
                        disable: hasAboned
                    },
                ];
                return <DataTableActions actions={actions} />;
            },
            cell: ({ row }) => {
                
                const actions: IDataTableAction[] = [
                    {
                        name: 'Abonar',
                        route: `#`,
                        hasPermission: true,
                        onPress: () => handleAbonar(row.original),
                        disable: hasAboned
                    },
                    {
                        name: '',
                        route: ``,
                        hasPermission: true,
                    },
                ];
                return <DataTableActions actions={actions} />;
            },
        },
    ];

    return { columns, selected, removeds, reset };
}
