import { TransitTimeCreationForm } from 'components/Pages/TransitTimes';
import { Scaffold } from 'layouts';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const TransitTimeCreationPage: React.FC = () => {
  const navigate = useNavigate();

  const onCreate = useCallback((): void => {
    navigate('/configuracoes/transit-time');
  }, [navigate]);

  return (
    <Scaffold>
      <TransitTimeCreationForm onCreate={onCreate} />
    </Scaffold>
  );
};
