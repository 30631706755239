import {
  useState,
  useCallback,
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import {
  FetchCorrectionLetterActions,
  FetchPendingReleaseActions as FetchActions,
} from "store/ducks/pendingRelease";
import { IgnoreInvoiceActions as IgnoreActions } from "store/ducks/deliveredInvoices";
import { Modal } from "components/Shared";
import { useLocation } from "react-router-dom";

import { Status } from "../Status";
import * as S from "./styles";
import { FileHelper, Formatter } from "utils";
import { ModalStatus } from "components/Shared/ModalStatus";
import { ModalJustifications } from "components/Shared/ModalJustifications";
import { useAuth } from "hooks";
import { DownloadIcon, FilePDFIcon, FileXMLIcon } from "styles/components";
import { usePermission } from "hooks/usePermission";

export interface Ref {
  selectInvoice: (invoiceId: number) => void;
}

interface IInvoiceModal extends ForwardRefExoticComponent<RefAttributes<Ref>> {}

export const InvoiceModal: IInvoiceModal = forwardRef((props, ref) => {
  const dispatch: AppDispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<number | null>(null);
  const location = useLocation();
  const nowLocation = location.pathname;
  const delivered = "/inbound/notas-fiscais/entregues";
  const finished = "/inbound/notas-fiscais/finalizadas";

  const {hasPermissionTo} = usePermission()

  const hasPermissions = hasPermissionTo(['JUSTIFICATIONS.SHOWNEWJUSTIFICATION'])

  const showOccurrences = [delivered, finished].includes(nowLocation);

  const { data: invoice, loading } = useSelector(
    (state: RootState) => state.fetchPendingRelease
  );

  const onClose = useCallback(() => {
    setIsOpen(false);
    setInvoiceId(null);
    dispatch(FetchActions.reset());
  }, [dispatch]);

  const fetchInvoice = useCallback((): void => {
    if (!invoiceId) return;
    dispatch(FetchActions.request(invoiceId));
  }, [dispatch, invoiceId]);

  const handleIgnore = useCallback(() => {
    if (invoice) {
      const data = {
        invoice_id: invoice.id,
        ignoredAt: new Date().toISOString(),
      };
      dispatch(IgnoreActions.request(data, fetchInvoice));
      setIsOpen(false);
    }
  }, [dispatch, fetchInvoice, invoice]);

  const handleDownloadFile = useCallback(
    (letterId: number) => {
      dispatch(
        FetchCorrectionLetterActions.request(letterId, (url: string) => {
          window.open(url, "_blank", "noopener,noreferrer");
        })
      );
    },
    [dispatch]
  );

  const ModalHeader = useCallback(() => {
    const status = invoice?.statuses?.length && invoice?.statuses[0];

    return (
      <S.ModalHeader>
        <div className="wrapper">
          <S.InvoiceIcon />
          {loading && <S.ActivityIndicator />}
          <S.InvoiceNumber>
            NF {invoice?.infnfeIdeNnf}-{invoice?.infnfeIdeSerie}
          </S.InvoiceNumber>
        </div>
        {status ? (
          <Status color={status["status"].color}>
            {status["status"].name}
          </Status>
        ) : (
          <Status>Sem status</Status>
        )}
        <S.CloseButton onClick={onClose}>
          <S.CloseIcon />
        </S.CloseButton>
      </S.ModalHeader>
    );
  }, [invoice, loading, onClose]);

  const ModalInfos = useCallback(() => {
    if (!invoice) return <></>;
    const {
      abonedAt,
      supplier,
      company,
      carrier,
      infnfeIdeDhemi,
      deadlineDate,
      releasedAt,
      deliveryDate,
      returnDate,
      sapCode,
      items,
      destEnderdestUf,
    } = invoice;

    const isImport = destEnderdestUf
      ? ["EXTERIOR", "EX"].includes(destEnderdestUf)
      : false;
    const importDeclaration =
      isImport && items.length > 0 ? items[0].importDeclaration : null;

    const finalOrigin = isImport ? company : supplier;
    const finalDestiny = isImport ? supplier : company;

    return (
      <S.MainGrid>
        <S.Detail>
          <S.DetailLabel>
            Origem {isImport ? "Internacional" : "Nacional"}
          </S.DetailLabel>
          <S.DetailValue>{finalOrigin?.tradeName}</S.DetailValue>
          <S.DetailValue>
            {finalOrigin?.addressCity ? finalOrigin.addressCity : "----"}
          </S.DetailValue>
          <S.DetailValue>
            {finalOrigin?.document && !isImport
              ? Formatter.cnpj(finalOrigin?.document)
              : "----"}
          </S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Destino</S.DetailLabel>
          <S.DetailValue>{finalDestiny?.tradeName}</S.DetailValue>
          <S.DetailValue>
            {finalDestiny?.addressCity ? finalDestiny.addressCity : "----"}
          </S.DetailValue>
          <S.DetailValue>
            {finalDestiny?.document
              ? Formatter.cnpj(finalDestiny.document)
              : "----"}
          </S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Transportadores</S.DetailLabel>
          <S.DetailValue>{carrier?.tradeName || "----"}</S.DetailValue>
          <S.DetailValue>
            {carrier?.addressCity ? carrier.addressCity : "----"}
          </S.DetailValue>
          <S.DetailValue>
            {carrier?.document ? Formatter.cnpj(carrier.document) : "----"}
          </S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Data de emissão</S.DetailLabel>
          <S.DetailValue>{Formatter.date(infnfeIdeDhemi)}</S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Prazo contratado</S.DetailLabel>
          <S.DetailValue>
            {deadlineDate ? Formatter.date(deadlineDate) : "--/--/----"}
          </S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Data liberação</S.DetailLabel>
          <S.DetailValue>
            {releasedAt ? Formatter.date(releasedAt) : "--/--/----"}
          </S.DetailValue>
        </S.Detail>
        {deliveryDate && (
          <S.Detail>
            <S.DetailLabel>Data da entrega</S.DetailLabel>
            <S.DetailValue>
              {deliveryDate ? Formatter.date(deliveryDate) : "--/--/----"}
            </S.DetailValue>
          </S.Detail>
        )}
        {abonedAt && (
          <S.Detail>
            <S.DetailLabel>Data do abono</S.DetailLabel>
            <S.DetailValue>{Formatter.date(abonedAt)}</S.DetailValue>
          </S.Detail>
        )}
        {returnDate && (
          <S.Detail>
            <S.DetailLabel>Data GR</S.DetailLabel>
            <S.DetailValue>
              {returnDate ? Formatter.date(returnDate) : "--/--/----"}
            </S.DetailValue>
          </S.Detail>
        )}
        {sapCode && (
          <S.Detail>
            <S.DetailLabel>Código SAP</S.DetailLabel>
            <S.DetailValue>{sapCode || "---"}</S.DetailValue>
          </S.Detail>
        )}
        {importDeclaration && (
          <S.Detail>
            <S.DetailLabel>Local de Coleta</S.DetailLabel>
            <S.DetailValue>
              {importDeclaration.diXlocdesemb} - {importDeclaration.diUfdesemb}
            </S.DetailValue>
          </S.Detail>
        )}
      </S.MainGrid>
    );
  }, [invoice]);

  const ModalItems = useCallback(() => {
    if (!invoice) return <></>;
    const { items, volume } = invoice;
    const totalPrice = items
      .reduce((acc, item) => acc + item.prodVprod, 0)
      .toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    const itemsCount = `${items.length}`.padStart(2, "0");
    return (
      <>
        <S.TotalsGrid>
          <S.Detail>
            <S.DetailLabel>Valor total</S.DetailLabel>
            <S.DetailBigValue>{totalPrice}</S.DetailBigValue>
          </S.Detail>
          <S.Detail>
            <S.DetailLabel>Peso bruto</S.DetailLabel>
            <S.DetailBigValue>
              {volume?.transpvol_peso_b
                ? `${volume?.transpvol_peso_b} KG`
                : "----"}
            </S.DetailBigValue>
          </S.Detail>
          <S.Detail>
            <S.DetailLabel>Volume</S.DetailLabel>
            <S.DetailBigValue>{itemsCount}</S.DetailBigValue>
          </S.Detail>
        </S.TotalsGrid>
        <S.ListHeader>
          <S.ListHeaderLabel>Código</S.ListHeaderLabel>
          <S.ListHeaderLabel>Descrição</S.ListHeaderLabel>
          <S.ListHeaderLabel>Pedido</S.ListHeaderLabel>
          <S.ListHeaderLabel>Quantidade</S.ListHeaderLabel>
          <S.ListHeaderLabel>Valor Unitário</S.ListHeaderLabel>
          <S.ListHeaderLabel>Valor Total</S.ListHeaderLabel>
        </S.ListHeader>
        {items.map((item) => (
          <S.ListItem key={item.id}>
            <S.Column>{item.prodCprod}</S.Column>
            <S.Column>{item.prodXprod}</S.Column>
            <S.Column>{item.prodXped || "----"}</S.Column>
            <S.Column>
              [ {item.prodUcom} ] {item.prodQcom}
            </S.Column>
            <S.Column>
              {item.prodVuncom &&
                item.prodVuncom.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
            </S.Column>
            <S.Column>
              {item.prodVprod &&
                item.prodVprod.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
            </S.Column>
          </S.ListItem>
        ))}
      </>
    );
  }, [handleIgnore, invoice]);

  const ModalCorrectionLetters = useCallback(() => {
    if(invoice && invoice.correctionLetters && invoice.correctionLetters.length > 0) {
      const { correctionLetters } = invoice;
      return (
        <S.CorrectionGrid>
          <S.DetailHeader>Carta de Correção de Transportadoras</S.DetailHeader>
          <ul>
            {correctionLetters.map((letter) => (
              <S.CorrectionItem key={letter.id}>
                <span>
                  {letter.file_ext === "xml" ? (
                    <FileXMLIcon />
                  ) : (
                    <FilePDFIcon />
                  )}
                </span>
                <span>{FileHelper.getFileNameByUrl(letter.file_url)}</span>
                <S.AnchorButton
                  href="#"
                  onClick={() => handleDownloadFile(letter.id)}
                  size="small"
                  mood="void"
                  title="Fazer download"
                >
                  <DownloadIcon />
                </S.AnchorButton>
              </S.CorrectionItem>
            ))}
          </ul>
        </S.CorrectionGrid>
      );
    }
    return <></>
  }, [handleDownloadFile, invoice]);

  const ModalOccurrences = useCallback(() => {
    if(showOccurrences && invoice && invoice.occurrences.length > 0) {
      return (
        <S.OccurrencesGrid>
          <S.DetailHeader>Ocorrências</S.DetailHeader>
          <ul>
            <S.OccurrenceItem>
              <strong>#</strong>
              <strong>Tipo de Ocorrência</strong>
              <strong>Data</strong>
            </S.OccurrenceItem>
            {invoice.occurrences.map((occurrence, index) => (
              <S.OccurrenceItem key={occurrence.id}>
                <span>{`#${index + 1}`}</span>
                <span>{occurrence.occurrenceType.name}</span>
                <span>{occurrence.createdAt ? Formatter.dateFull(occurrence.createdAt) : "--/--/----"}</span>
              </S.OccurrenceItem>
            ))}
          </ul>
        </S.OccurrencesGrid>
      )
    }

    return null
  }, [invoice, showOccurrences])

  useImperativeHandle(
    ref,
    () => ({
      selectInvoice: (invoiceId: number): void => {
        setInvoiceId(invoiceId);
        setIsOpen(true);
      },
    }),
    []
  );

  useEffect(() => fetchInvoice(), [fetchInvoice]);
  useEffect(() => {
    return () => {
      dispatch(FetchActions.reset());
    };
  }, [dispatch]);

  return (
    <Modal isOpen={isOpen} onClickOutside={onClose}>
      <S.ModalContent style={{ maxWidth: "960px" }}>
        <S.ModalContainer>
          <ModalHeader />
          <ModalInfos />
          <ModalCorrectionLetters />
          <ModalOccurrences />
          <ModalItems />
          <S.Box>
            <ModalStatus invoice={invoice} title={"Timeline Atividades"} />
            {(nowLocation === delivered || nowLocation === finished) && (
              <ModalJustifications
                invoice={invoice}
                title={"Timeline Justificativas"}
                showAdd={hasPermissions && ((!invoice?.isDelayedLeadTime && nowLocation === finished) || nowLocation === delivered)}
                onNew={fetchInvoice}
              />
            )}
          </S.Box>
        </S.ModalContainer>
      </S.ModalContent>
    </Modal>
  );
});
