import React, { ReactNode } from "react";
import LeftPanel from "./LeftPanel";
import TopPanel from "./TopPanel";
import * as S from "./styles";

interface ScaffoldProps {
  children: ReactNode;
  showComponents?: boolean;
}

export const Scaffold = ({
  children,
  showComponents = true,
}: ScaffoldProps) => {
  return (
    <S.PageContainer>
      <S.Backdrop>
        <S.MainContainer>
          {showComponents && (
            <S.LeftPanelSlot>
              <LeftPanel key={"@left-panel"} />
            </S.LeftPanelSlot>
          )}

          <S.MainSlot>
            {showComponents && <TopPanel />}
            <S.ChildrenSlot>{children}</S.ChildrenSlot>
          </S.MainSlot>
        </S.MainContainer>
      </S.Backdrop>
    </S.PageContainer>
  );
};
