import * as S from "./styles";
import { useRef, useState } from "react";
import { FormHandles } from "@unform/core";

import { Loading } from "components/Shared/Loading/styles";
import { api, notify } from "services";

export const SendPdf = ({ collectId }: any) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);

  if (!collectId) {
    return <></>;
  }

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const formData = {
        ...data,
        reverseLogisticId: collectId,
      }

      if (!formData.email) {
        notify("error", "Informe o e-mail do destinatário!");
        setLoading(false);
        return;
      }

      await api.post(`reverse-logistics/sendPdf`, formData);
      notify("success", "PDF enviado com sucesso!");
      formRef.current?.reset();
    } catch (error) {
      notify("error", `${error}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <S.Container>
      <S.Header>
        <S.GridTitle>Enviar PDF</S.GridTitle>
      </S.Header>
      <S.FormContainer ref={formRef} onSubmit={onSubmit}>
        <S.InputModal
            name="email"
            placeholder="E-mail"
          />
          <S.FormButton type="submit" theme="accept">
            {loading && <Loading color="#fff" />}
            Enviar
          </S.FormButton>
      </S.FormContainer>
    </S.Container>
  );
}