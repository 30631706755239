import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormField } from 'components/ui/form';
import { InputGs } from 'components/ui/Forms';
import { Button } from 'components/ui/ButtonGs';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useErrorHandler } from 'Exceptions/ErrorHandler';
import { toast } from 'sonner';
import { formSchema } from '../../formSchema';

import { getActions } from 'hooks/api/Permissions/getActions';

import { Action } from 'contracts/Action';
import Loading from './Loading';
import { ActivityIndicator } from 'styles/components';
import { showPerfil } from 'hooks/api/Permissions/showPerfil';
import { Role } from 'contracts/Roles';
import ActionsModal, { ActionsModalHandle } from '../../ActionsModal';
import { updatePerfil } from 'hooks/api/Permissions/updatePerfil';
import { notify } from 'services';

export type ActionGroupType = {
    [group: string]: Action[];
};
type ActionType = {
    [module: string]: ActionGroupType;
};

const UpdatePerfilForm = () => {
    const { id } = useParams();
    const { errorHandler } = useErrorHandler();
    const navigate = useNavigate();

    const modalActionRef = useRef<ActionsModalHandle | null>(null);
    const [actions, setActions] = useState<ActionType>({});
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
    });
    const actionsSelecteds = form.watch('actions') ?? [];

    const { data: perfil, isLoading: perfilIsLoadng } = useQuery<Role>({
        queryKey: 'showPerfil',
        queryFn: () => showPerfil(id!),
        onError: errorHandler,
        retry: 1,
    });

    const { data, isLoading, refetch } = useQuery<Action[]>({
        queryKey: 'actions',
        queryFn: getActions,
        onError: errorHandler,
        retry: 1,
    });

    const queryClient = useQueryClient();

    const mutation = useMutation(updatePerfil, {
        onSuccess: () => {
            queryClient.invalidateQueries('roles');
            refetch();
            notify('success', 'Perfil atualizado com sucesso.');
        },
        onError: (error) => {
            errorHandler(error);
            notify('error', 'Não foi possivel atualizar perfil.');
        },
    });

    const onSubmit = (data: z.infer<typeof formSchema>) => {
        if (!id) return;
        mutation.mutate({
            id,
            perfil: data,
        });
    };

    const selectActions = (selecteds: Action[], removeds: Action[]) => {
        const actionIds = selecteds.map((action) => action.id);
        const currentActions = form.getValues('actions');

        const actionsArr = currentActions.filter(
            (action) => !removeds.some((r) => r.id === action)
        );

        form.setValue('actions', [...actionsArr, ...actionIds]);
        modalActionRef.current?.close();
    };

    const handleActions = (actions: ActionGroupType) => {
        modalActionRef.current?.open(actions as unknown as Action[]);
    };

    const countActionSelecteds = (items: Action[]) => {
        const selecteds = items.filter((item) =>
            actionsSelecteds.includes(item.id)
        );

        return selecteds.length;
    };

    useEffect(() => {
        if (data) {
            const actionsArr = data.reduce((prev, curr) => {
                if (curr.module_id) {
                    if (curr.module.name in prev) {
                        if (curr.group in prev[curr.module.name]) {
                            prev[curr.module.name][curr.group].push(curr);
                        } else {
                            prev[curr.module.name][curr.group] = [curr];
                        }
                    } else {
                        prev[curr.module.name] = {
                            [curr.group]: [curr],
                        };
                    }
                }
                return prev;
            }, {} as ActionType);

            setActions(actionsArr);
        }

        return () => {
            setActions({});
        };
    }, [data]);

    useEffect(() => {
        if (perfil) {
            const perfilActions = perfil?.action.map((a) => a.id);

            form.setValue('name', perfil?.name);
            form.setValue('description', perfil?.description);
            form.setValue('actions', perfilActions);
        }
    }, [perfil, form]);

    return (
        <>
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="flex flex-col gap-6"
                >
                    <div className="grid grid-cols-1 gap-4">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <InputGs
                                    {...field}
                                    label="Nome do perfil"
                                    disabled
                                />
                            )}
                        />
                    </div>

                    <div className="grid grid-cols-1 gap-4">
                        <FormField
                            control={form.control}
                            name="description"
                            render={({ field }) => (
                                <InputGs {...field} label="Descrição" />
                            )}
                        />
                    </div>

                    <div className="flex w-full flex-wrap gap-6 bg-gray-100 rounded-sm p-6 my-6 ">
                        {(isLoading || perfilIsLoadng) && <Loading />}
                        {!(isLoading || perfilIsLoadng) &&
                            Object.entries(actions).map(
                                ([module, groupAction]) => {
                                    const action = Object.entries(
                                        groupAction
                                    ).map(([group, action], index) => (
                                        <Button.Root
                                            key={index.toString()}
                                            type="button"
                                            variant="primary"
                                            className="outline outline-1  outline-slate-300 rounded-sm bg-white text-slate-600"
                                            onClick={() => {
                                                handleActions(action as any);
                                            }}
                                        >
                                            {`${group} (${countActionSelecteds(
                                                action as any
                                            )}/${action.length})`}
                                        </Button.Root>
                                    ));

                                    return (
                                        <div
                                            className="w-full flex flex-col gap-4"
                                            key={module}
                                        >
                                            <h2>{`Módulo ${module}`}</h2>
                                            <div className="w-full flex flex-row flex-wrap gap-2">
                                                {action}
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                    </div>
                    <div className="flex justify-end w-full gap-4">
                        <Button.Root
                            className="bg-orange-100 text-orange-600"
                            size="sm"
                            type="button"
                            onClick={() => navigate('/configuracoes/perfis')}
                            disabled={mutation.isLoading}
                        >
                            Cancelar
                        </Button.Root>
                        <Button.Root
                            className="bg-orange-500"
                            size="sm"
                            type="submit"
                            disabled={mutation.isLoading}
                        >
                            {mutation.isLoading ? (
                                <ActivityIndicator />
                            ) : (
                                'Salvar'
                            )}
                        </Button.Root>
                    </div>
                </form>
            </Form>
            <ActionsModal
                ref={modalActionRef}
                onSubmit={selectActions}
                isAdded={form.getValues('actions')}
            />
        </>
    );
};

export default UpdatePerfilForm;
