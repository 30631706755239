import styled from 'styled-components';
import { Colors, Fonts } from 'styles/constants';
import { Link } from 'react-router-dom';

export const Container = styled.nav`
  width: 100%;
  display: flex;
  padding: 16px 24px;
  border-radius: 6px 6px 0 0;
  background-color: ${Colors.White};
  column-gap: 48px;
  border-bottom: 2px solid ${Colors.Gray40};
`;

export const Button = styled(Link)`
  font-family: ${Fonts.GilroySemiBold};
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.Gray50};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 300ms ease;

  &:hover {
    color: ${Colors.Gray70};
  }

  ::after {
    content: '';
    display: block;
    width: calc(100% + 48px);
    height: 2px;
    background-color: ${Colors.Gray40};
    position: absolute;
    bottom: -18px;
  }

  &.actived {
    color: ${Colors.Gray70};
    ::after {
      background-color: ${Colors.Orange};
    }
  }
`;
