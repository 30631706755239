import { forwardRef, ReactNode, RefObject } from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";

import * as S from "./styles"

interface DialogContentProps extends DialogPrimitive.DialogContentProps {
  children: ReactNode
  title?: string
  icon?: ReactNode
  container?: RefObject<HTMLDivElement>
}

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  ({ children, container, title = 'Titulo Modal', icon, ...props }, forwardRef) => {
    return (
      <DialogPrimitive.Portal container={container?.current}>
        <S.DialogContent {...props} ref={forwardRef}>
          <S.DialogHeader>
            <S.DialogTitle>
              {icon} {title}
            </S.DialogTitle>
            <DialogPrimitive.Close aria-label="Close">
              <S.CloseIcon />
            </DialogPrimitive.Close>
          </S.DialogHeader>
          {children}
        </S.DialogContent>
        <S.DialogOverlay />
      </DialogPrimitive.Portal>
    )
  })

export const Dialog = DialogPrimitive.Root
export const DialogTrigger = DialogPrimitive.Trigger