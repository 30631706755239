import { DataTableColumnHeader } from 'components/ui/DataTable';
import { ColumnDef } from '@tanstack/react-table';
// import { usePermission } from 'hooks/usePermission';

import { Action } from 'contracts/Action';
import { Checkbox } from 'components/ui/checkbox';
import { useState } from 'react';
import { CheckedState } from '@radix-ui/react-checkbox';

export function useColumns(actions: Action[]) {
    const [selected, setSelected] = useState<Action[]>([]);
    const [removeds, setRemoveds] = useState<Action[]>([])

    const handleAction = (action: Action) => {

        const isSelected = selected.some((a) => a.id === action.id);
        if (isSelected) {
            const rest = selected.filter((a) => a.id !== action.id);
            setSelected(rest);
            setRemoveds([...removeds, action])
        } else {
           const rest = removeds.filter(a => a.id !== action.id)
            setSelected([...selected, action]);
            setRemoveds(rest)
        }
    };

    const selectAllActions = (toogle: CheckedState) => {
        if (toogle) {
            setSelected(actions);
            setRemoveds([])
        } else {
            setSelected([]);
            setRemoveds(actions)
        }
    };

    const isSelectedAll = actions.every((action) =>
        selected.map((s) => s.id).includes(action.id)
    );

    const reset = (actions: Action[]) => {
        setSelected(actions);
    };

    const columns: ColumnDef<Action>[] = [
        {
            id: 'select',
            header: () => {
                return (
                    <Checkbox
                        className="outline outline-offset-1 outline-1 outline-slate-300 data-[state=checked]:outline-none"
                        checked={isSelectedAll}
                        onCheckedChange={(value) => {
                            selectAllActions(value);
                        }}
                    />
                );
            },
            cell: ({ row }) => {
                const action = row.original;
                const isChecked = selected.some((s) => s.id === action.id);
                return (
                    <Checkbox
                        className="outline outline-offset-1 outline-1 outline-slate-300 data-[state=checked]:outline-none"
                        checked={isChecked}
                        onCheckedChange={() => {
                            handleAction(action);
                        }}
                    />
                );
            },

            enableSorting: false,
            enableHiding: false,
        },
        {
            id: 'Id',
            accessorKey: 'id',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Id" />
            ),
            cell: ({ row }) => {
                return `${row.original.id}`;
            },
            enableSorting: true,
            enableHiding: true,
        },
        {
            id: 'Permissão',
            accessorKey: 'permissao',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Permissão" />
            ),
            cell: ({ row }) => {
                return `${row.original.description}`;
            },
            enableSorting: false,
            enableHiding: false,
        },
    ];

    return { columns, selected, removeds, reset };
}
