import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { EMPTY_COLUMN_VALUE } from 'constants/Common';
import { SortingParams } from 'contracts/Common';
import type { PaginatedSupplier } from 'contracts/Suppliers';
import React, { useCallback } from 'react';
import { Formatter } from 'utils';
import * as S from './styles';

interface Props {
  suppliers: PaginatedSupplier[];
  onSort?: (sort: SortingParams) => void;
  currentSort: SortingParams;
}

interface ItemProps {
  supplier: PaginatedSupplier;
}

export const SuppliersList: React.FC<Props> = ({
  suppliers,
  onSort,
  currentSort,
}) => {
  const Item = useCallback(({ supplier }: ItemProps): JSX.Element => {
    const { id, document, documentType, tradeName, addressCity, addressState } =
      supplier;

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column>{Formatter.document(document, documentType)}</S.Column>
        <S.Column title={tradeName}>{tradeName}</S.Column>
        <S.Column title={addressCity}>{addressCity}</S.Column>
        <S.Column>{addressState || EMPTY_COLUMN_VALUE}</S.Column>
        <S.ActionsColumn>
          <S.LinkActionButton to={`/configuracoes/fornecedores/${id}/editar`}>
            <S.EditIcon />
          </S.LinkActionButton>
        </S.ActionsColumn>
      </S.ListItem>
    );
  }, []);

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="document" label="DOCUMENTO" />
          <SortableHeader column="tradeName" label="NOME" />
          <SortableHeader column="addressCity" label="CIDADE" />
          <SortableHeader column="addressState" label="ESTADO" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {suppliers.map((supplier) => (
        <Item key={supplier.id} supplier={supplier} />
      ))}
    </S.List>
  );
};
