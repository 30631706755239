import styled from "styled-components";
import { Colors, Fonts, getScrollbarStyle } from 'styles/constants';
import { BaseButtonStyle, BasePanel, CloseIcon, TrashIcon, PlusIcon, Check } from 'styles/components';
import { Select, Input, Textarea } from "components/Shared";
import { FormActions as _FormActions } from 'styles/components';
export { PlusIcon, DotIcon, CommentIcon, Check } from 'styles/components';


export const Container = styled.div`
    width: 80%;
`

export const Header = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: ${Colors.Gray70};
    padding-left: 30px;
    margin-bottom: 20px;
`

export const Button = styled.button`
  display: flex;
  ${BaseButtonStyle}
  background-color: ${Colors.Orange};
  color: ${Colors.White};
  font-family: ${Fonts.GilroySemiBold};
  font-size: 12px;
  padding: 3px 10px 3px 3px;
  justify-self: flex-end;
  border-radius: 4px;
  gap: 4px;
  white-space: nowrap;
`;

export const List = styled.div`
    display: block;
    padding-left: 10px;
`;

export const Box = styled.div`
    display: flex;
`;

export const BoxSide = styled.div`
    width: 20px;
    border-left: 1px ${Colors.Gray40} solid;
    position: relative;

    svg.dot {
        position: absolute;
        left: -7px;
        top: calc(50% - 16px);
    }
`;

export const BoxContent = styled.div`
    background-color: ${Colors.White};
    width: 100%;
    padding: 32px 48px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.145) 0px 20px 10px -16px;

    :before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: ${Colors.White};
        transform: rotate(45deg);
        position: absolute;
        left: -5px;
        top: calc(50% - 5px);
    }
    
`;

export const BoxHeader = styled.div`
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const CreatedData = styled.div`
    font-size: 12px;
    color: ${Colors.Gray50};
`;

export const BoxType = styled.div`
    text-transform: uppercase;
    font-size: 14px;
    color: ${Colors.Gray70};
    margin: 15px 0;
`;

export const BoxText = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    color: ${Colors.Gray60};
    line-height: 1.5em;
    margin-bottom: 25px;
`;

export const BoxFooter = styled.div`
    padding-top: 25px;
    border-top: 1px ${Colors.Gray40} solid;
`;

export const BtnBox = styled.div`
    display: flex;
    gap: 15px;
`

export const BtnReject = styled(Button)`
    background-color: ${Colors.Magenta};
`;

export const BtnFinish = styled(Button)`
    background-color: ${Colors.Green};
`;

export const RejectIcon = styled(PlusIcon)`
    transform: rotate(45deg);
`;

export const CheckIcon = styled(Check)`
    width: 24px;
    height: 24px;
`;

export const BtnApprove = styled(Button)`
    background-color: ${Colors.Green};
`;

export const Status = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 12px;

    span {
        color: ${Colors.Gray50};
    }
`;

export const StatusFlag = styled.div`
    background-color: ${Colors.Magenta};
    color: ${Colors.White};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 4px;
`

export const StatusFlagApproved = styled(StatusFlag)`
    background-color: ${Colors.Green};
`;

//Modal
export const ModalTitle = styled.div`
  display: flex;
  gap: 8px;
  font-family: ${Fonts.GilroyBold};
  font-size: 1.10rem;
  width: 100%;
  align-items: center;
  justify-content: start;
  border-bottom: 1px solid ${Colors.Gray30};
  padding-bottom: 13px;
  padding-right: 13px;
  margin-bottom: 13px;
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background-color: ${Colors.Black}cc;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  overflow: hidden;
`;

export const ModalContainer = styled(BasePanel)`
  position: relative;
  min-width: 650px;
  justify-content: start;
  flex-direction: column;
  padding: 24px;
  animation: contentShow 150ms;
  
  @keyframes contentShow {
    from { opacity: 0; transform: scale(.96) }
    to { opacity: 1; transform: scale(1) }
  }
`;

export const Close = styled(CloseIcon)`
  display: flex;
  position: absolute;
  right: 9px;
  top: 13px;
  cursor: pointer;
  height: 20px;
  color: ${Colors.Gray50};
`

export const Cancel = styled.div`
  width: 100%;
  padding: 10px 0 10px 10px;
  margin-top: 20px;
  border-top: 2px #000 dotted;
  display: flex;
  gap: 10px;
`

//Modal Form
export const InputDiv = styled.div`
  display: flex;
  gap: 16px;
`

export const InputModal = styled(Input)`
  width: 100%;
`

export const FormActions = styled(_FormActions)`
  justify-content: center;
`;

export const FormButton = styled(Button)<{theme?: string}>`
  background-color: ${({theme})=> theme === 'accept' ? Colors.Orange : Colors.Gray60};
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 35px;
  padding: 10px;
  width: 50%;
`;

export const SelectJustification = styled(Select)`
    max-width: 600px;
`;

export const TextArea = styled(Textarea)`
  resize: none;
  font-family: ${Fonts.OpenSans};
  font-size: 14px;
  line-height: 22px;
  border-style: solid;
  width: 100%;
  height: 30%;
  color: ${Colors.Gray60};
  border-radius: 5px;
  border-color: ${Colors.Gray50};
  ${getScrollbarStyle()};
`;