import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch } from 'store';
import { ImpersonateActions } from 'store/ducks/auth';
import { ConfirmationDialogRef, ConfirmationDialog } from '../ConfirmationDialog';


export const ImpersonateUser: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const dialogRef = useRef<ConfirmationDialogRef>(null);
    const {email} = useParams()

    const onImpersonate = useCallback(
        async (): Promise<void> => {
          const confirmed = await dialogRef.current?.openDialog({
            title: 'Logar como outro usuário?',
            message:
              'Você está prestes a logar como outro usuário. Deseja continuar?',
          });
    
          if (confirmed) {
            const onSuccess = ()=>{
              navigate('/')
            };
            dispatch(ImpersonateActions.request({ email }, onSuccess));
          }
          else {
            navigate('/configuracoes/usuarios')
          }
        },
        [dispatch, navigate]
      );
      useEffect(()=>{
        onImpersonate()
      },[email])
  return (
    <>
         <ConfirmationDialog ref={dialogRef} />
    </>
  );
};
