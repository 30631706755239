import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  FetchCarriersListActions as Actions,
  FetchCarriersListRequestAction as RequestAction,
} from 'store/ducks/carriers';

export function* fetchCarriersListRequest(action: any) {
  const { params, onSuccess, onFailure }: RequestAction = action;

  const paramsString = new URLSearchParams(params).toString();
  
  try {
    const { data } = yield call(api.get, `carriers?${paramsString}`);
    onSuccess && onSuccess();
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
