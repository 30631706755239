import * as S from "./styles";
import { Formatter } from "utils";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { api, notify } from "services";
import { FetchCollectActions as FetchActions } from "store/ducks/collects";
import { useCallback, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useValidation, useAuth } from "hooks";
import { Loading } from "components/Shared/Loading";
import { UpdateCollectValidator } from "validators/ReverseLogistics";
import { usePermission } from "hooks/usePermission";

export const HeaderDeadline = ({ collect }: any) => {
  const [isOpenDeadlines, setIsOpenDeadlines] = useState<boolean>(false);
  const [isOpenDates, setIsOpenDates] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dateRef = useRef<FormHandles>(null);
  const deadlineRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
//   const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission()
//   const isCarrierMember = userBelongsToAnyOf("carrierMember");
  const isCarrierMember =  hasPermissionTo(['REVERSE-LOGISTICS.UPDATEREVERSELOGISTICSHEADER'])

  //MODAL

  const onSubmitDeadline = async (formData: any) => {
    setLoading(true);
    deadlineRef?.current?.setErrors({});

    const data = {
      ...collect,
      ...formData,
    };

    try {
      const { schemaDeadline } = new UpdateCollectValidator();

      const validData = await schemaDeadline.validate(data, {
        abortEarly: false,
      });

      await api.put(`reverse-logistics/${collect.id}`, data);
      notify("success", "Alteração feita com sucesso");
      setLoading(false);
      setIsOpenDeadlines(false);
      dispatch(FetchActions.request(collect.id));
    } catch (error) {
      handleFormErrors(error, deadlineRef);
      setLoading(false);
    }
  };

  const onSubmitDate = async (formData: any) => {
    setLoading(true);
    dateRef?.current?.setErrors({});
    const data = {
      ...collect,
      ...formData,
    };

    try {
      const { schemaDate } = new UpdateCollectValidator();

      const validData = await schemaDate.validate(data, {
        abortEarly: false,
      });

      await api.put(`reverse-logistics/${collect.id}`, data);
      notify("success", "Alteração feita com sucesso");
      setLoading(false);
      setIsOpenDates(false);
      dispatch(FetchActions.request(collect.id));
    } catch (error) {
      handleFormErrors(error, dateRef);
      setLoading(false);
    }
  };

  if (!collect) {
    return <></>;
  }

  return (
    <S.HeaderDeadline>
      {isOpenDeadlines && (
        <S.ModalBackground>
          <S.ModalContainer>
            <S.ModalTitle>
              <S.TruckIcon />
              Alterar Prazos
              <S.Close onClick={() => setIsOpenDeadlines(false)} />
            </S.ModalTitle>
            <Form ref={deadlineRef} onSubmit={onSubmitDeadline}>
              <S.InputModal
                name="collectCarrierDeadline"
                label="Prazo Transportadora:"
                type="date"
                defaultValue={
                  (collect.collectCarrierDeadline &&
                    Formatter.datePicker(collect.collectCarrierDeadline)) ||
                  null
                }
              />
              <S.InputModal
                name="collectDeliveryDeadline"
                label="Prazo Entrega:"
                type="date"
                defaultValue={
                  (collect.collectDeliveryDeadline &&
                    Formatter.datePicker(collect.collectDeliveryDeadline)) ||
                  null
                }
              />
              <S.InputModal
                name="collectNotificationDate"
                label="Data Notificação:"
                type="date"
                defaultValue={
                  (collect.collectNotificationDate &&
                    Formatter.datePicker(collect.collectNotificationDate)) ||
                  null
                }
              />

              <S.FormActions>
                <S.FormButton onClick={() => setIsOpenDeadlines(false)}>
                  {loading && <Loading color="#fff" />}
                  Voltar
                </S.FormButton>
                <S.FormButton type="submit" theme="accept">
                  {loading && <Loading color="#fff" />}
                  Salvar
                </S.FormButton>
              </S.FormActions>
            </Form>
          </S.ModalContainer>
        </S.ModalBackground>
      )}
      {isOpenDates && (
        <S.ModalBackground>
          <S.ModalContainer>
            <S.ModalTitle>
              <S.TruckIcon />
              Alterar Datas
              <S.Close onClick={() => setIsOpenDates(false)} />
            </S.ModalTitle>
            <Form ref={dateRef} onSubmit={onSubmitDate}>
              <S.InputModal
                name="departureDate"
                label="Data Saída:"
                type="date"
                defaultValue={
                  (collect.departureDate &&
                    Formatter.datePicker(collect.departureDate)) ||
                  null
                }
              />
              <S.InputModal
                name="collectDate"
                label="Data Coleta:"
                type="date"
                defaultValue={
                  (collect.collectDate &&
                    Formatter.datePicker(collect.collectDate)) ||
                  null
                }
              />
              <S.InputModal
                name="deliveryDate"
                label="Data Entrega:"
                type="date"
                defaultValue={
                  (collect.deliveryDate &&
                    Formatter.datePicker(collect.deliveryDate)) ||
                  null
                }
              />
              <S.FormActions>
                <S.FormButton onClick={() => setIsOpenDates(false)}>
                  {loading && <Loading color="#fff" />}
                  Voltar
                </S.FormButton>
                <S.FormButton type="submit" theme="accept">
                  {loading && <Loading color="#fff" />}
                  Salvar
                </S.FormButton>
              </S.FormActions>
            </Form>
          </S.ModalContainer>
        </S.ModalBackground>
      )}
      <S.DeadlineLeftPanel>
        <S.Detail>
          Prazo Transp.
          <S.Info>
            {(collect.collectCarrierDeadline &&
              Formatter.date(collect.collectCarrierDeadline)) ||
              "---"}
          </S.Info>
        </S.Detail>
        {isCarrierMember && (
          <S.Detail>
            Prazo Oficial
            <S.Info>
              {(collect.collectOficialDeadline &&
                Formatter.date(collect.collectOficialDeadline)) ||
                "---"}
            </S.Info>
          </S.Detail>
        )}
        <S.Detail>
          Prazo Entrega
          <S.Info>
            {(collect.collectDeliveryDeadline &&
              Formatter.date(collect.collectDeliveryDeadline)) ||
              "---"}
          </S.Info>
        </S.Detail>
        <S.Detail>
          Notificação
          <S.Info>
            {(collect.collectNotificationDate &&
              Formatter.date(collect.collectNotificationDate)) ||
              "---"}
          </S.Info>
        </S.Detail>
        <S.Button
          disabled={collect.deletedAt || collect.finishDate || !isCarrierMember}
          onClick={() => setIsOpenDeadlines(true)}
        >
          <S.EditAltIcon height={"18px"} />
          Alterar
        </S.Button>
      </S.DeadlineLeftPanel>
      <S.DeadlineRightPanel>
        <S.Detail>
          Data Saída
          <S.Info>
            {(collect.departureDate && Formatter.date(collect.departureDate)) ||
              "---"}
          </S.Info>
        </S.Detail>
        <S.Detail>
          Data Coleta
          <S.Info>
            {(collect.collectDate && Formatter.date(collect.collectDate)) ||
              "---"}
          </S.Info>
        </S.Detail>
        <S.Detail>
          Data Entrega
          <S.Info>
            {(collect.deliveryDate && Formatter.date(collect.deliveryDate)) ||
              "---"}
          </S.Info>
        </S.Detail>
        <S.Button
          disabled={collect.deletedAt || collect.finishDate || !hasPermissionTo(["REVERSE-LOGISTICS.UPDATEREVERSELOGISTIC"])}
          onClick={() => setIsOpenDates(true)}
        >
          <S.EditAltIcon height={"18px"} />
          Alterar
        </S.Button>
      </S.DeadlineRightPanel>
    </S.HeaderDeadline>
  );
};
