import { SettingsMenu } from 'components/Shared';
import {
    InnerNavigator,
    InnerNavigatorOption,
  } from 'components/ui/InnerNavigator';
  import React from 'react';
  
  type LayoutProps = {
    children: React.ReactNode;
    options?: InnerNavigatorOption[];
  };
  
  export const SettingLayout: React.FC<LayoutProps> = ({ children, options }) => {
    return (
      <div>
        {options && <InnerNavigator options={options} />}
        <section className="py-6">{children}</section>
      </div>
    );
  };