import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { SortingParams } from 'contracts/Common';
import type { OperationTypeCfop } from 'contracts/OperationTypesCfop';
import { useCallback } from 'react';
import * as S from './styles';

interface Props {
  operations: OperationTypeCfop[];
  onSort?: (sort: SortingParams) => void;
  onToggle: (id: number) => void;
  currentSort: SortingParams;
}

interface ItemProps {
  operation: OperationTypeCfop;
}

export const OperationTypesCfopList = ({
  operations,
  onSort,
  currentSort,
  onToggle
}: Props) => {
  const Item = useCallback(({ operation }: ItemProps): JSX.Element => {
    const { carrier, company, cfop, id, supplier, operationType, blockedAt } = 
      operation;

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column title={company?.tradeName || '---'}>{company?.tradeName || '---'}</S.Column>
        <S.Column title={supplier?.tradeName || '---'}>{supplier?.tradeName || '---'}</S.Column>
        <S.Column title={carrier?.tradeName|| '---'}>{carrier?.tradeName || '---'}</S.Column>
        <S.Column title={operationType?.name|| '---'}>{operationType?.name || '---'}</S.Column>
        <S.Column title={cfop.toString()}>{cfop}</S.Column>
        <S.Column title={blockedAt || 'Não bloqueado'}>
          <S.ToggleContainer>
            <S.Input
              type="checkbox"
              id={`${id}_block`}
              defaultChecked={!!blockedAt}
            />
            <S.Toggler onClick={() => onToggle(id)}>
              <S.TogglerTrack>
                <S.TogglerThumb />
              </S.TogglerTrack>
            </S.Toggler>
          </S.ToggleContainer>
        </S.Column>
      </S.ListItem>
    );
  }, [onToggle]);

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="companyId" label="EMPRESA" />
          <SortableHeader column="supplierId" label="FORNECEDOR" />
          <SortableHeader column="carrierId" label="TRANSPORTADORA" />
          <SortableHeader column="operationTypeId" label="OPERAÇÃO" />
          <SortableHeader column="cfop" label="CFOP" />
          <SortableHeader column="blockedAt" label="BLOQUEIO" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {operations.map((operation) => (
        <Item key={operation?.id} operation={operation} />
      ))}
    </S.List>
  );
};
