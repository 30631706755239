import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListClassificationsActions as MainActions } from 'store/ducks/classifications';
import { Formatter } from 'utils';

export const useClassifications = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: classifications, loading: loadingClassifications } = useSelector(
    (state: RootState) => state.listClassifications
  );

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchClassifications = useCallback((): void => {
    dispatch(MainActions.request());
  }, [dispatch]);

  const onClassificationsLoad = useCallback((): void => {
    setOptions(
      classifications.map(({ id, description }) => ({
        value: id,
        label: `${description}`,
      }))
    );
  }, [classifications]);

  useEffect(() => {
    onClassificationsLoad();
  }, [onClassificationsLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    classifications,
    classificationOptions: options,
    loadingClassifications,
    fetchClassifications,
  };
};

export type ClassificationsHook = ReturnType<typeof useClassifications>;
