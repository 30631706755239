import * as Yup from 'yup';


export const getOperationShape = () => {

  return {
    companyId: Yup
      .string()
      .required('Selecione a Empresa')
      .typeError('Selecione a Empresa')
      .transform((value) => {
        return value ? value.trim() : null;
      }),
    supplierId: Yup
      .string()
      .required('Selecione o Fornecedor')
      .typeError('Selecione o Fornecedor')
      .transform((value) => {
        return value ? value.trim() : null;
      }),
    carrierId: Yup
      .string()
      .required('Selecione a Transportadora')
      .typeError('Selecione a Transportadora')
      .transform((value) => {
        return value ? value.trim() : null;
      }),
    operationTypeId: Yup
      .string()
      .required('Selecione o Tipo de Operação')
      .typeError('Selecione o Tipo de Operação')
      .transform((value) => {
        return value ? value.trim() : null;
      }),
    cfop: Yup
    .string()
    .required('Informe o CFOP')
    .typeError('Informe o CFOP')
    .transform((value) => {
        return value ? value.trim() : null;
      }),
  };
};
