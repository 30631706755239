import React, { useRef, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { SelectOption } from 'contracts/Common';
import Select from './Select';
import * as S from './styles';
import { usePermission } from 'hooks/usePermission';

const options: SelectOption[] = [
  {
    value: '/configuracoes/empresas',
    label: 'Empresas',
  },
  {
    value: '/configuracoes/usuarios',
    label: 'Usuarios',
  },
  {
    value: '/configuracoes/fornecedores',
    label: 'Fornecedores',
  },
  {
    value: '/configuracoes/clientes',
    label: 'Clientes',
  },
  // {
  //   value: '/configuracoes/produtos',
  //   label: 'Produtos',
  // },
  // {
  //   value: '/configuracoes/tipos-produto',
  //   label: 'Tipos de Produtos',
  // },
  {
    value: '/configuracoes/transportadoras',
    label: 'Transportadoras',
  },
  // {
  //   value: '/configuracoes/transportadoras/usuarios',
  //   label: 'Usuários de Transportadoras',
  // },
  {
    value: '/configuracoes/justificativas',
    label: 'Justificativas - Tracking',
  },
  {
    value: '/configuracoes/fiscais',
    label: 'Fiscais',
  },
  {
    value: '/configuracoes/tipos-veiculo',
    label: 'Tipos de Veículo',
  },
  {
    value: '/configuracoes/transit-time',
    label: 'Transit Time',
  },
  {
    value: '/configuracoes/logistica-reversa/justificativas',
    label: 'Justificativas - Logística Reversa',
  },
  {
    value: '/configuracoes/logistica-reversa/motivos',
    label: 'Motivos - Logística Reversa',
  },
  {
    value: '/configuracoes/logistica-reversa/classificacoes',
    label: 'Classificações - Logística Reversa',
  },
  // {
  //   value: '/configuracoes/administradores',
  //   label: 'Administradores',
  // },
  {
    value: '/configuracoes/rotas',
    label: 'Rotas',
  },
  // {
  //   value: '/configuracoes/armazem/usuarios',
  //   label: 'Usuários de Armazém',
  // },
  {
    value: '/configuracoes/perfis',
    label: 'Perfis',
  },
];

export const SettingsMenu: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { hasPermissionTo } = usePermission()

  const onDestinationChange = useCallback(
    (option: SelectOption | null): void => {
      if (!option?.value) return;
      if (option.value === location.pathname) return;
      navigate(option.value as string);
    },
    [location.pathname, navigate]
  );

  const onNavigate = useCallback((): void => {
    const currentOption = options.find((o) => o.value === location.pathname);
    if (currentOption) {
      formRef.current?.setFieldValue('destination', currentOption);
    }
  }, [location.pathname]);

  useEffect(() => {
    onNavigate();
  }, [onNavigate]);


  const optionsWithFilterByPermission = options.filter(op => {
    if(!hasPermissionTo(['CONFIG_LIST_USERS'])) {
        return op.value !== "/configuracoes/usuarios"
    }
    
    return op
  })

  return (
    <S.MenuContainer>
      <S.Label>
        <S.SettingsIcon /> Configurações:
      </S.Label>
      <Form ref={formRef} onSubmit={() => {}}>
        <Select
          name="destination"
          options={optionsWithFilterByPermission}
          onChange={onDestinationChange}
        />
      </Form>
    </S.MenuContainer>
  );
};
