import { InnerNavigatorOption } from "components/ui/InnerNavigator";
import { usePermission } from "hooks/usePermission";

export function UsersInnerOptions() {
  const { hasPermissionTo } = usePermission();
  const options: InnerNavigatorOption[] = [
    {
      title: "Administradores",
      route: "/configuracoes/usuarios",
      hasPermission: hasPermissionTo(['ADMINS.FINDADMINS']),
    },
    {
      title: "Usuários de transportadora",
      route: "/configuracoes/usuarios/transportadora",
      hasPermission: hasPermissionTo(['CARRIER-MEMBERS.FINDMEMBERS']),
    },
    {
      title: "Usuários de clientes",
      route: "/configuracoes/usuarios/clientes",
      hasPermission: hasPermissionTo(['CLIENT-MEMBERS.INDEX']),
    },
    {
      title: "Usuários de armazém",
      route: "/configuracoes/usuarios/armazem",
      hasPermission:  hasPermissionTo(['WAREHOUSE_MEMBERS.INDEX']),
    },
    {
      title: "Novo usuário",
      route: "/configuracoes/usuarios/criar",
      hasPermission: hasPermissionTo(['AUTH.REGISTERADMIN']),
    },
    // {
    //   title: "",
    //   route: "/configuracoes/usuarios/editar/",
    //   hasPermission: false,
    // },
  ];
  return { options };
}
