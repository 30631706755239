import { FormHandles} from '@unform/core';
import { Form } from '@unform/web';
import { Input} from 'components/Shared';
import { useValidation } from 'hooks';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_BACK_ACTION } from 'constants/Common';
import type { AppDispatch, RootState } from 'store';
import { CreateJustificationReverseLogisticsActions as CreateActions } from 'store/ducks/justificationsReverseLogistics';
import * as S from './styles';

interface Props {
  onCreate?: () => void;
}

export const JustificationCreationForm: React.FC<Props> = ({ onCreate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();

  const { loading: creatingJustification, validationErrors } = useSelector(
    (state: RootState) => state.createJustificationReverseLogistics
  );

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onCreate && onCreate();
  }, [onCreate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});
        // const { schema } = new CreateJustificationValidator();

        // const validData = await schema.validate(data, {
        //   abortEarly: false,
        // });

        dispatch(CreateActions.request(data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess]
  );
  
  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(CreateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <S.Content>
        <Form ref={formRef} onSubmit={onSubmit}>
            <S.FormRowCreate>
              <Input name="description" label="Justificativa" />
            </S.FormRowCreate>
          <S.FormActions>
            <S.LinkButton mood="light" to="/configuracoes/logistica-reversa/justificativas">
              {FORM_BACK_ACTION}
            </S.LinkButton>
            <S.Button type="submit">
              {creatingJustification ? <S.ActivityIndicator /> : 'Salvar'}
            </S.Button>
          </S.FormActions>
        </Form>
      </S.Content>
    </S.MainPanel>
  );
};
