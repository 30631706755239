import { DataTableColumnHeader } from 'components/ui/DataTable';
import { ColumnDef } from '@tanstack/react-table';
import {
    DataTableActions,
    IDataTableAction,
} from 'components/ui/DataTableActions';
import { Role } from 'contracts/Roles';
import { Formatter } from 'utils';
import { getActions } from 'hooks/api/Permissions/getActions';
import { apiErrorHandler } from 'services';
import { useQuery } from 'react-query';

export function useColumns() {
    const { data, isLoading } = useQuery<any[]>({
        queryKey: 'actions',
        queryFn: getActions,
        onError: apiErrorHandler,
    });

    const columns: ColumnDef<Role>[] = [
        {
            id: 'Código',
            accessorKey: 'id',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Código" />
            ),
            cell: ({ row }) => {
                return `${row.original.id}`;
            },
            enableSorting: true,
            enableHiding: true,
        },
        {
            id: 'Dt.Criação',
            accessorKey: 'dt-criacao',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Dt.Criação" />
            ),
            cell: ({ row }) => {
                return `${Formatter.datePicker(row.original.created_at, {
                    format: 'dd/MM/yyyy',
                })}`;
            },
            enableSorting: true,
            enableHiding: true,
        },
        {
            id: 'Permissões',
            accessorKey: 'permissoes',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Permissões" />
            ),
            cell: ({ row }) => {
                return `${row.original.action.length}/${data?.length ?? 0}`;
            },
            enableSorting: true,
            enableHiding: true,
        },
        {
            id: 'Nome',
            accessorKey: 'nome',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Nome" />
            ),
            cell: ({ row }) => {
                return `${row.original.description ?? '---'}`;
            },
            enableSorting: false,
        },

        {
            id: 'actions',
            cell: ({ row }) => {
                const actions: IDataTableAction[] = [
                    {
                        name: 'Editar',
                        route: `/configuracoes/perfis/edit/${row.original.id}`,
                        hasPermission: true,
                    },
                ];
                return <DataTableActions actions={actions} />;
            },
        },
    ];

    return { columns };
}
