import { RouteObject, Outlet, Navigate } from "react-router-dom";
import type { RouterProps } from "contracts/Router";
import ListCarrierMembers from "pages/Settings/Usuários/CarrierMemberList";
import { Scaffold } from "layouts";
import ListWarehouseMember from "pages/Settings/Usuários/WarehouseMemberList";
import ListAdmin from "pages/Settings/Usuários/AdminList";
import CreateUsuario from "pages/Settings/Usuários/Create";
import ListClientMember from "pages/Settings/Usuários/ClientMembersList";
import UpdateUsuario from "pages/Settings/Usuários/Update";
import { ImpersonateUser } from "components/Shared/ImpersonateUser";

const usersRoutes = ({
  location,
  userBelongsToAnyOf,
  hasPermissionTo,
}: RouterProps): RouteObject => ({
  path: "/configuracoes/usuarios/",
  element: hasPermissionTo(["ADMINS.FINDADMINS", "ADMINS.FINDADMIN"]) ? (
    <Scaffold>
      <Outlet />
    </Scaffold>
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: "",
      element: <ListAdmin />,
    },
    {
      path: "transportadora",

      element: <ListCarrierMembers />,
    },
    {
      path: "armazem",
      element: <ListWarehouseMember />,
    },
    {
      path: 'clientes',
      element: <ListClientMember />
    },
    {
        path: "criar",
        element: <CreateUsuario />
    },
    {
      path: "editar/:id",
      element: <UpdateUsuario />
    },
    {
      path: "impersonate/:email",
      element: <ImpersonateUser/>
    }
  ],
});

export default usersRoutes;
