import * as Yup from 'yup';
import { getProductShape } from 'validators/Common/baseReleaseToTrackingValidator';

export class ReleaseToTrackingValidator {
  public get schema() {
    return Yup.object().shape({
      ...getProductShape()
    });
  }
}
