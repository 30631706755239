import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { HiddenInput, Input } from 'components/Shared';
import { FORM_BACK_ACTION } from 'constants/Common';
import { useValidation } from 'hooks';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import {
  FetchJustificationReverseLogisticsActions as FetchActions,
  UpdateJustificationReverseLogisticsActions as UpdateActions,
} from 'store/ducks/justificationsReverseLogistics';
import { UpdateJustificationValidator } from 'validators/Justifications';
import * as S from './styles';

interface Props {
  justificationId: string | number;
  onUpdate?: () => void;
}

export const JustificationUpdateForm: React.FC<Props> = ({ justificationId, onUpdate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();

  const { data: justification } = useSelector((state: RootState) => state.fetchJustificationReverseLogistics);

  const { loading: updatingJustification, validationErrors } = useSelector(
    (state: RootState) => state.updateJustificationReverseLogistics
  );

  const fetchJustification = useCallback((): void => {
    dispatch(FetchActions.request(justificationId));
  }, [dispatch, justificationId]);

  const onJustificationLoad = useCallback((): void => {
    if (!justification) return;

    const { id, description } = justification;

    if(id){
      formRef.current?.setFieldValue('justificationId', id);
    }
    if(description){
      formRef.current?.setFieldValue('description', description);
    }

  }, [justification]);

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onUpdate && onUpdate();
  }, [onUpdate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});

        const { schema } = new UpdateJustificationValidator();

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(UpdateActions.request(justificationId, validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [justificationId, dispatch, handleFormErrors, onSuccess]
  );

  useEffect(() => {
    fetchJustification();
  }, [fetchJustification]);

  useEffect(() => {
    onJustificationLoad();
  }, [onJustificationLoad]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(FetchActions.reset());
      dispatch(UpdateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={onSubmit}>
        <HiddenInput name="justificationId" />
          <S.FormRowUpdate>
            <Input name="description" label="Descrição" />
          </S.FormRowUpdate>
        <S.FormActions>
          <S.LinkButton mood="light" to="/configuracoes/logistica-reversa/justificativas">
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit">
            {updatingJustification ? <S.ActivityIndicator /> : 'Salvar'}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.Container>
  );
};
