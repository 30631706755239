import * as S from "./styles";
import { Loading } from "components/Shared/Loading/styles";
import NumberFormat from "react-number-format";
import { useState, useCallback, useRef, useEffect } from "react";
import { Form } from "@unform/web";
import { useAuth, useCarriers, useCompanies, useClients, useAuditors } from "hooks";
import { FormHandles } from "@unform/core";
import { api, notify } from "services";
import { useDispatch } from "react-redux";
import { FetchCollectActions as FetchActions } from "store/ducks/collects";
import { Formatter } from "utils/Formatter";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { usePermission } from "hooks/usePermission";

export const Boxes = ({ collect }: any) => {
//   const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission()
//   const isCarrierMember = userBelongsToAnyOf("carrierMember");
  const isCarrierMember =  hasPermissionTo(['CARRIER-MEMBERS.FINDMEMBERS', 'CARRIER-MEMBERS.FINDMEMBER']);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenCompany, setIsOpenCompany] = useState<boolean>(false);
  const [isOpenClient, setIsOpenClient] = useState<boolean>(false);
  const [isOpenRequester, setIsOpenRequester] = useState<boolean>(false);
  const [isOpenAuditor, setIsOpenAuditor] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { carrierOptions, loadingCarriers, fetchCarriers } = useCarriers();
  const { companyOptions, loadingCompanies, fetchCompanies } = useCompanies();
  const { clientOptions, loadingClients, fetchClients } = useClients();
  const { auditorOptions, loadingAuditors, fetchAuditors } = useAuditors();
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();

  const auditorFollowOptions = [
    {"value": 0, "label": 'Não'},
    {"value": 1, "label": 'Sim'}
  ];

  const scroll = useCallback(() => {
    if(isOpen || isOpenRequester || isOpenCompany || isOpenClient || isOpenAuditor) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen, isOpenRequester, isOpenCompany, isOpenClient, isOpenAuditor]);

  const ChangeCarrier = useCallback(() => {
    const onSubmit = async () => {
      setLoading(true);
      const carrierId = formRef.current?.getFieldValue("changeCarrier");
      const data = {
        ids: [collect.id],
        carrier_id: carrierId,
      };
      try {
        await api.post(`reverse-logistics/carrier`, data);
        notify("success", "Alteração feita com sucesso");
        setLoading(false);
        setIsOpen(false);
        dispatch(FetchActions.request(collect.id));
      } catch (err) {
        notify("error", `${err}`);
        setLoading(false);
        setIsOpen(false);
      }
    };
    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <S.TruckIcon />
            Alterar Transportadora
            <S.Close onClick={() => setIsOpen(false)} />
          </S.ModalTitle>
          <Form ref={formRef} onSubmit={onSubmit}>
            <S.SelectCarrier
              name="changeCarrier"
              label="Transportadora"
              options={carrierOptions}
              isLoading={loadingCarriers}
              defaultValue={carrierOptions.find((o) => o.value === carrier.id)}
            />
            <S.FormActions>
              <S.FormButton onClick={() => setIsOpen(false)}>
                {loading && <Loading color="#fff" />}
                Voltar
              </S.FormButton>
              <S.FormButton type="submit" theme="accept">
                {loading && <Loading color="#fff" />}
                Salvar
              </S.FormButton>
            </S.FormActions>
          </Form>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [carrierOptions, collect]);

  useEffect(() => {
    fetchCarriers();
  }, [fetchCarriers]);

  const ChangeCompany = useCallback(() => {
    const onSubmit = async () => {
      setLoading(true);
      const companyId = formRef.current?.getFieldValue("changeCompany");
      const data = {
        ids: [collect.id],
        company_id: companyId,
      };
      try {
        await api.post(`reverse-logistics/company`, data);
        notify("success", "Alteração feita com sucesso");
        setLoading(false);
        setIsOpenCompany(false);
        dispatch(FetchActions.request(collect.id));
      } catch (err) {
        notify("error", `${err}`);
        setLoading(false);
        setIsOpenCompany(false);
      }
    };
    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <S.ClientIcon />
            Alterar Destinatário
            <S.Close onClick={() => setIsOpenCompany(false)} />
          </S.ModalTitle>
          <Form ref={formRef} onSubmit={onSubmit}>
            <S.SelectCarrier
              name="changeCompany"
              label="Destinatário"
              options={companyOptions}
              isLoading={loadingCompanies}
              defaultValue={companyOptions.find((o) => o.value === company.id)}
            />
            <S.FormActions>
              <S.FormButton onClick={() => setIsOpenCompany(false)}>
                {loading && <Loading color="#fff" />}
                Voltar
              </S.FormButton>
              <S.FormButton type="submit" theme="accept">
                {loading && <Loading color="#fff" />}
                Salvar
              </S.FormButton>
            </S.FormActions>
          </Form>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [companyOptions, collect]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const ChangeClient = useCallback(() => {
    const onSubmit = async () => {
      setLoading(true);
      const clientId = formRef.current?.getFieldValue("changeClient");
      const data = {
        ids: [collect.id],
        client_id: clientId,
      };
      try {
        await api.post(`reverse-logistics/client`, data);
        notify("success", "Alteração feita com sucesso");
        setLoading(false);
        setIsOpenClient(false);
        dispatch(FetchActions.request(collect.id));
      } catch (err) {
        notify("error", `${err}`);
        setLoading(false);
        setIsOpenClient(false);
      }
    };
    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <S.CompanyIconOutline />
            Alterar Cliente
            <S.Close onClick={() => setIsOpenClient(false)} />
          </S.ModalTitle>
          <Form ref={formRef} onSubmit={onSubmit}>
            <S.SelectCarrier
              name="changeClient"
              label="Cliente"
              options={clientOptions}
              isLoading={loadingClients}
              defaultValue={clientOptions.find((o) => o.value === client.id)}
            />
            <S.FormActions>
              <S.FormButton onClick={() => setIsOpenClient(false)}>
                {loading && <Loading color="#fff" />}
                Voltar
              </S.FormButton>
              <S.FormButton type="submit" theme="accept">
                {loading && <Loading color="#fff" />}
                Salvar
              </S.FormButton>
            </S.FormActions>
          </Form>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [clientOptions, collect]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const ChangeAuditor = useCallback(() => {
    const onSubmit = async () => {
      setLoading(true);
      const auditorId = formRef.current?.getFieldValue("changeAuditor");
      const requestAuditorFollow = formRef.current?.getFieldValue("auditorFollow");

      const data = {
        ids: [collect.id],
        auditor_id: auditorId,
        request_auditor_follow: requestAuditorFollow ? 1 : 0
      };

      try {
        await api.post(`reverse-logistics/auditor`, data);
        notify("success", "Alteração feita com sucesso");
        setLoading(false);
        setIsOpenAuditor(false);
        dispatch(FetchActions.request(collect.id));
      } catch (err) {
        notify("error", `${err}`);
        setLoading(false);
        setIsOpenAuditor(false);
      }
    };

    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <S.AuditorIcon />
            Alterar Fiscal
            <S.Close onClick={() => setIsOpenAuditor(false)} />
          </S.ModalTitle>
          <Form ref={formRef} onSubmit={onSubmit}>
            <S.SelectCarrier
              name="changeAuditor"
              label="Fiscal"
              options={auditorOptions}
              isLoading={loadingAuditors}
              defaultValue={auditorOptions.find((o) => o.value === auditor.id)}
            />
            <S.SelectCarrier
              name="auditorFollow"
              label="Acompanha coleta?"
              options={auditorFollowOptions}
              defaultValue={auditorFollowOptions.find((o) => o.value === collect.requestAuditorFollow)}
            />
            <S.FormActions>
              <S.FormButton onClick={() => setIsOpenAuditor(false)}>
                {loading && <Loading color="#fff" />}
                Voltar
              </S.FormButton>
              <S.FormButton type="submit" theme="accept">
                {loading && <Loading color="#fff" />}
                Salvar
              </S.FormButton>
            </S.FormActions>
          </Form>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [auditorOptions, collect]);

  useEffect(() => {
    fetchAuditors();
  }, [fetchAuditors]);

  const ChangeRequester = useCallback(() => {
    const onSubmit = async () => {
      setLoading(true);
      const requestRqps = formRef.current?.getFieldValue("requestRqps");
      const requestDate = formRef.current?.getFieldValue("requestDate") || null;
      const requestRiv = formRef.current?.getFieldValue("requestRiv");
      
      const data = {
        ...collect,
        requestRqps:requestRqps,
        requestDate:requestDate,
        requestRiv:requestRiv,
      };
      try {
        await api.put(`reverse-logistics/${collect.id}`, data);
        notify("success", "Alteração feita com sucesso");
        setLoading(false);
        setIsOpenRequester(false);
        dispatch(FetchActions.request(collect.id));
      } catch (err) {
        notify("error", `${err}`);
        setLoading(false);
        setIsOpenRequester(false);
      }
    };
    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <S.UserRequestIcon />
            Alterar Solicitante
            <S.Close onClick={() => setIsOpenRequester(false)} />
          </S.ModalTitle>
          <Form ref={formRef} onSubmit={onSubmit}>
            <S.InputModal
              name="requestRqps"
              label="Nome:"
              defaultValue={collect.requestRqps}
            />
            <S.InputModal
              name="requestDate"
              label="Data:"
              type="date"
              defaultValue={(collect.requestDate && Formatter.datePicker(collect.requestDate)) || ""}
            />
            <S.InputModal
              name="requestRiv"
              label="RIV / Solicitante:"
              defaultValue={collect.requestRiv}
            />
            <S.FormActions>
              <S.FormButton onClick={() => setIsOpenRequester(false)}>
                {loading && <Loading color="#fff" />}
                Voltar
              </S.FormButton>
              <S.FormButton type="submit" theme="accept">
                {loading && <Loading color="#fff" />}
                Salvar
              </S.FormButton>
            </S.FormActions>
          </Form>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [collect]);

  useEffect(() => {
    scroll();
  }, [isOpen, isOpenCompany, isOpenClient, isOpenRequester, isOpenAuditor]);

  if (!collect) {
    return <></>;
  }

  const { carrier, company, client, auditor, requestDate, requestRqps, requestRiv, requestAuditorFollow } = collect;

  const {
    addressCity: cityCarrier,
    addressNeighborhood: NeighborhoodCarrier,
    addressNumber: numberCarrier,
    addressStreet: streetCarrier,
    addressUf: ufCarrier,
    document: cnpjCarrier,
    tradeName: nameCarrier,
  } = carrier;

  const {
    addressCity: cityClient,
    addressNeighborhood: NeighborhoodClient,
    addressNumber: numberClient,
    addressStreet: streetClient,
    addressUf: ufClient,
    document: cnpjClient,
    tradeName: nameClient,
  } = client;

  const {
    addressCity: cityCompany,
    addressNeighborhood: NeighborhoodCompany,
    addressNumber: numberCompany,
    addressStreet: streetCompany,
    addressUf: ufCompany,
    document: cnpjCompany,
    tradeName: nameCompany,
  } = company;

  const {
    auditorName: nameAuditor,
    auditorPhoneNumber: phoneAuditor
  } = auditor;

  return (
    <S.Boxes>
      {isOpen && <ChangeCarrier />}
      {isOpenCompany && <ChangeCompany />}
      {isOpenClient && <ChangeClient />}
      {isOpenRequester && <ChangeRequester />}
      {isOpenAuditor && <ChangeAuditor />}
      <Splide 
        hasTrack = { false }
        aria-label="Boxes" 
        options={{ perPage: 3, perMove: 3, gap: '24px', width: '100%', pagination: false, padding: { right: 100 } }}
      >
        <SplideTrack>
          <SplideSlide>
            <S.Box>
              <S.TitleInfo>
                <S.TitleInfoText>
                  <S.UserRequestIcon />
                  Solicitante
                </S.TitleInfoText>
                <S.Button disabled={collect.deletedAt || collect.finishDate || !hasPermissionTo(['REVERSE-LOGISTICS.UPDATEREVERSELOGISTICSREQUESTER'])} onClick={() => setIsOpenRequester(true)}>
                  <S.EditAltIcon height={"18px"} />
                  Alterar
                </S.Button>
              </S.TitleInfo>
              <S.BoxBody>
                <S.Detail>
                  Nome:
                  <S.Info>{requestRqps}</S.Info>
                </S.Detail>
                <S.Detail>
                  Data:
                  <S.Info>
                  {(requestDate && Formatter.date(requestDate)) || "---"}
                  </S.Info>
                </S.Detail>
                <S.Detail>
                  RIV / Solicitante:
                  <S.InfoLast>{requestRiv}</S.InfoLast>
                </S.Detail>
              </S.BoxBody>
            </S.Box>
          </SplideSlide>
          <SplideSlide>
            <S.Box>
              <S.TitleInfo>
                <S.TitleInfoText>
                  <S.CompanyIconOutline />
                  Cliente
                </S.TitleInfoText>
                  <S.Button disabled={collect.deletedAt || collect.finishDate || !hasPermissionTo(['REVERSE-LOGISTICS.UPDATEREVERSELOGISTICSCLIENT'])} onClick={() => setIsOpenClient(true)}>
                    <S.EditAltIcon height={"18px"} />
                    Alterar
                  </S.Button>
              </S.TitleInfo>
              <S.BoxBody>
                <S.Detail>
                  Nome:
                  <S.Info>{nameClient}</S.Info>
                </S.Detail>
                <S.Detail>
                  CNPJ:
                  <S.Info>{cnpjClient}</S.Info>
                </S.Detail>
                <S.Detail>
                  Endereço:
                  <S.InfoLast>
                    {streetClient}, {numberClient}, {NeighborhoodClient},{" "}
                    {cityClient}/{ufClient}
                  </S.InfoLast>
                </S.Detail>
              </S.BoxBody>
            </S.Box>
          </SplideSlide>
          <SplideSlide>
            <S.Box>
              <S.TitleInfo>
                <S.TitleInfoText>
                  <S.TruckIcon />
                  Transportadora
                </S.TitleInfoText>
                  <S.Button disabled={collect.deletedAt || collect.finishDate || !hasPermissionTo(['REVERSE-LOGISTICS.UPDATEREVERSELOGISTICSCARRIER'])} onClick={() => setIsOpen(true)}>
                    <S.EditAltIcon height={"18px"} />
                    Alterar
                  </S.Button>
              </S.TitleInfo>
              <S.BoxBody>
                <S.Detail>
                  Nome:
                  <S.Info>{nameCarrier}</S.Info>
                </S.Detail>
                <S.Detail>
                  CNPJ:
                  <S.Info>
                    <NumberFormat
                      value={cnpjCarrier}
                      displayType={"text"}
                      format="##.###.###/####-##"
                    />
                  </S.Info>
                </S.Detail>
                <S.Detail>
                  Endereço:
                  <S.InfoLast>
                    {streetCarrier}, {numberCarrier}, {NeighborhoodCarrier},{" "}
                    {cityCarrier}/{ufCarrier}
                  </S.InfoLast>
                </S.Detail>
              </S.BoxBody>
            </S.Box>
          </SplideSlide>
          <SplideSlide>
            <S.Box>
              <S.TitleInfo>
                <S.TitleInfoText>
                  <S.ClientIcon />
                  Destinatário
                </S.TitleInfoText>
                <S.Button disabled={collect.deletedAt || collect.finishDate || !hasPermissionTo(['REVERSE-LOGISTICS.UPDATEREVERSELOGISTICSCOMPANY'])} onClick={() => setIsOpenCompany(true)}>
                  <S.EditAltIcon height={"18px"} />
                  Alterar
                </S.Button>
              </S.TitleInfo>
              <S.BoxBody>
                <S.Detail>
                  Nome:
                  <S.Info>{nameCompany}</S.Info>
                </S.Detail>
                <S.Detail>
                  CNPJ:
                  <S.Info>
                    <NumberFormat
                      value={cnpjCompany}
                      displayType={"text"}
                      format="##.###.###/####-##"
                    />
                  </S.Info>
                </S.Detail>
                <S.Detail>
                  Endereço:
                  <S.InfoLast>
                    {streetCompany}, {numberCompany}, {NeighborhoodCompany},{" "}
                    {cityCompany}/{ufCompany}
                  </S.InfoLast>
                </S.Detail>
              </S.BoxBody>
            </S.Box>
          </SplideSlide>
          <SplideSlide>
            <S.Box>
              <S.TitleInfo>
                <S.TitleInfoText>
                  <S.AuditorIcon />
                  Fiscal
                </S.TitleInfoText>
                <S.Button disabled={collect.deletedAt || collect.finishDate || !hasPermissionTo(['REVERSE-LOGISTICS.UPDATEREVERSELOGISTICSAUDITOR'])} onClick={() => setIsOpenAuditor(true)}>
                  <S.EditAltIcon height={"18px"} />
                  Alterar
                </S.Button>
              </S.TitleInfo>
              <S.BoxBody>
                <S.Detail>
                  Nome:
                  <S.Info>{nameAuditor}</S.Info>
                </S.Detail>
                <S.Detail>
                  Telefone:
                  <S.Info>
                    <NumberFormat
                      value={phoneAuditor}
                      displayType={"text"}
                      format="## #####-####"
                    />
                  </S.Info>
                </S.Detail>
                <S.Detail>
                  Acompanha Coleta?
                  <S.InfoLast>
                    {requestAuditorFollow ? 'Sim' : 'Não'}
                  </S.InfoLast>
                </S.Detail>
              </S.BoxBody>
            </S.Box>
          </SplideSlide>
        </SplideTrack>
      </Splide>
    </S.Boxes>
  )
};
