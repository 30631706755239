import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Input, Select, FormPageHeader } from "components/Shared";
import { FORM_BACK_ACTION, SELECT_OPTIONS } from "constants/Common";
import type { SelectOption } from "contracts/Common";
import { TransitTimes } from "contracts/TransitTimes";

import { useCarriers, useCities, useValidation } from "hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "store";
import { CreateTransitTimeActions as CreateActions } from "store/ducks/transitTime";
import { CreateTransitTimeValidator } from "validators/TransitTimes";
import * as S from "./styles";

interface Props {
  onCreate?: () => void;
}

export const TransitTimeCreationForm: React.FC<Props> = ({ onCreate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const [ ibgeOrigin, setIbgeOrigin ] = useState<number|null>();
  const [ ibgeDestiny, setIbgeDestiny ] = useState<number|null>();
  const [ carrierCnpj, setCarrierCnpj ] = useState<string>();
  const [ queryType, setQueryType ] = useState<"origin" | "destiny" | "">("");
  const [ originOptions, setOrigin ] = useState<SelectOption[]>();
  const [ destinyOptions, setDestiny ] = useState<SelectOption[]>();
  const { cityOptions, loadingCities, fetchCities } = useCities();
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { carrierOptions, loadingCarriers, fetchCarriers } = useCarriers();
  const { loading: creatingTransitTime, validationErrors } = useSelector(
    (state: RootState) => state.createTransitTime
  );

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onCreate && onCreate();
  }, [onCreate]);

  const onCarrierChange = useCallback((option: SelectOption | null): void => {
    if (!option) return;
    setCarrierCnpj(option.cnpj as string);
  }, []);

  const onSubmit = useCallback(
    async (data: TransitTimes): Promise<void> => {
      try {
        formRef?.current?.setErrors({});
        const fullData = { ...data, carrierCnpj, ibgeOrigin, ibgeDestiny };
        const { schema } = new CreateTransitTimeValidator();
        const validData = await schema.validate(fullData, {
          abortEarly: false,
        });

        dispatch(CreateActions.request(validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess, formRef]
  );

  const Header = useCallback((): JSX.Element => {
    return (
      <FormPageHeader
        title="Novo Transit Time"
        icon={<S.TimerIcon />}
        actions={
          <S.LinkButton size="small" to="/configuracoes/transit-time">
            <S.ArrowLeftIcon /> Voltar
          </S.LinkButton>
        }
      />
    );
  }, []);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    fetchCarriers();
  }, [fetchCarriers]);

  useEffect(() => {
    if (queryType === "origin") setOrigin(cityOptions);
    if (queryType === "destiny") setDestiny(cityOptions);
  }, [cityOptions]);

  useEffect(() => {
    return () => {
      dispatch(CreateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <Header />
      <Form ref={formRef} onSubmit={onSubmit}>
        <S.FormRow>
          <Select
            name="carrierId"
            label="Transportadora"
            options={carrierOptions}
            onChange={onCarrierChange}
            isLoading={loadingCarriers}
          />
          <Input name="validUntil" label={"Validade"} type="date" />
          <Input name="hourCut" label={"Horário de corte"} type="time" />
          <Input name="startDays" label={"Dias iniciais"} type="number" />
        </S.FormRow>
        <S.FormRow>
          <Select
            name="countDay"
            label="Úteis/Corridos Fracionado"
            options={SELECT_OPTIONS.TYPE_DAY}
          />
          <Input
            name="fractionalDays"
            label="Contagem fracionado"
            type="number"
          />
          <Select
            name="countDedicatedDay"
            label="Úteis/Corridos Dedicado"
            options={SELECT_OPTIONS.TYPE_DAY}
          />
          <Input
            name="dedicatedDays"
            label={"Contagem dedicado"}
            type="number"
          />
        </S.FormRow>
        <S.FormRow>
          <Select
            name="ufOrigin"
            label="UF"
            options={SELECT_OPTIONS.STATES}
            isLoading={loadingCities}
            onChange={(e) => {
              fetchCities(e?.value);
              setQueryType("origin");
            }}
          />
          <Select
            name="cityOriginId"
            label="Cidade origem"
            options={originOptions}
            isLoading={loadingCities}
            isDisabled={!originOptions}
            onChange={(e)=> setIbgeOrigin(e?.ibge)}
          />
          <Select
            name="ufDestiny"
            label="UF"
            options={SELECT_OPTIONS.STATES}
            isLoading={loadingCities}
            onChange={(e) => {
              fetchCities(e?.value);
              setQueryType("destiny");
            }}
          />
          <Select
            name="cityDestinyId"
            label="Cidade destino"
            options={destinyOptions}
            isLoading={loadingCities}
            isDisabled={!destinyOptions}
            onChange={(e)=> setIbgeDestiny(e?.ibge)}
          />
        </S.FormRow>
        <S.FormRow>
          <Select
            name="operationTypeId"
            label="Tipo de operação"
            options={SELECT_OPTIONS.OPERATION_TYPES}
          />
          <Input name="weight" label="Peso KG" type="number" />
        </S.FormRow>
        <S.FormActions>
          <S.LinkButton mood="light" to="/configuracoes/transit-time">
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit" disabled={creatingTransitTime}>
            {creatingTransitTime ? <S.ActivityIndicator /> : "Salvar"}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.MainPanel>
  );
};
