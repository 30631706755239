import { useCallback, useContext } from "react";
import { Dialog, DialogTrigger } from "components/Shared/Dialog";
import { useAuth } from "hooks";
import {
  AddStatusModal,
  AddDeadlineModal,
  ChangeCarrierModal,
  ReleaseToTrackingModal,
  RecalculateModal,
} from "..";
import {
  ModalTypes,
  PendingReleaseContext,
} from "contexts/PendingReleaseContext";
import * as S from "./styles";
import { CorrectionLetterModal } from "../CorrectionLetterModal";
import { DisableInvoiceModal } from "../DisableInvoiceModal";
import { usePermission } from "hooks/usePermission";

const DropdownMenuInvoices = () => {
  const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission()
  const { openedModal, changeOpenModal, selectedList } = useContext(
    PendingReleaseContext
  );
//   const isCarrierMember = userBelongsToAnyOf('carrierMember');
  const isCarrierMember = hasPermissionTo(['CARRIER-MEMBERS.FINDMEMBERS', 'CARRIER-MEMBERS.FINDMEMBER']);



  const handleModal = useCallback(
    (event: Event, type: ModalTypes) => {
      event.preventDefault();
      changeOpenModal(type);
    },
    [changeOpenModal]
  );

  const hasSomeOrderNotReadyToTracking = selectedList.some(
    (order, _, array) => 
    !order.carrier || 
    !order.deadlineDate || 
    order.isPendingLetter ||
    order.carrierId !== array[0].carrierId
  );

  const canSendCorrectionLetter = 
    selectedList.length === 1 && 
    selectedList[0].isPendingLetter;

  return (
    <S.Box>
      <S.DropdownMenuRoot>
        <S.Trigger asChild disabled={!selectedList.length}>
          <S.IconButton aria-label="Customise options" clicable={selectedList.length > 0}>
            <S.Icon size={25} />
          </S.IconButton>
        </S.Trigger>
        {selectedList.length > 0 && (
          <S.Portal>
            <S.Content sideOffset={-1} side="bottom" align="end">
              <Dialog
                open={openedModal === "status"}
                key="status"
                onOpenChange={(open) => !open && changeOpenModal()}
              >
                <DialogTrigger asChild>
                  <S.Item onSelect={(e) => handleModal(e, "status")}>
                    Adicionar Status
                  </S.Item>
                </DialogTrigger>
                <AddStatusModal />
              </Dialog>
              <Dialog
                open={openedModal === "prazo"}
                key="prazo"
                onOpenChange={(open) => !open && changeOpenModal()}
              >
                <DialogTrigger asChild>
                  <S.Item onSelect={(e) => handleModal(e, "prazo")}>
                    Alterar prazo contratado
                  </S.Item>
                </DialogTrigger>
                <AddDeadlineModal />
              </Dialog>
              <Dialog
                open={openedModal === "transportadora"}
                key="transportadora"
                onOpenChange={(open) => !open && changeOpenModal()}
              >
                <DialogTrigger asChild>
                  <S.Item onSelect={(e) => handleModal(e, "transportadora")}>
                    Alterar transportadora
                  </S.Item>
                </DialogTrigger>
                <ChangeCarrierModal />
              </Dialog>
              <Dialog
                open={openedModal === "carta"}
                key="carta"
                onOpenChange={(open) => !open && changeOpenModal()}
              >
                <DialogTrigger asChild disabled={!canSendCorrectionLetter}>
                  <S.Item onSelect={(e) => handleModal(e, "carta")}>
                    Enviar Carta de Correção
                  </S.Item>
                </DialogTrigger>
                <CorrectionLetterModal />
              </Dialog>
              <Dialog
                open={openedModal === "liberar"}
                key="liberar"
                onOpenChange={(open) => !open && changeOpenModal()}
              >
                <DialogTrigger asChild>
                  <S.Item
                    onSelect={(e) => handleModal(e, "liberar")}
                    disabled={hasSomeOrderNotReadyToTracking}
                  >
                    Liberar para tracking
                  </S.Item>
                </DialogTrigger>
                <ReleaseToTrackingModal />
              </Dialog>
            {hasPermissionTo(['INVOICES.UPDATEINVOICESDEADLINE', 'INVOICES.UPDATEINVOICESCARRIER']) &&
               ( 
            <Dialog
                open={openedModal === "recalcular"}
                key="recalcular"
                onOpenChange={(open) => !open && changeOpenModal()}
              >
                <DialogTrigger asChild>
                  <S.Item
                    onSelect={(e) => handleModal(e, "recalcular")}
                  >
                    Recalcular prazo
                  </S.Item>
                </DialogTrigger>
                <RecalculateModal />
              </Dialog>)
            }

              <Dialog
                open={openedModal === "inutilizar"}
                key="inutilizar"
                onOpenChange={(open) => !open && changeOpenModal()}
              >
                <DialogTrigger asChild>
                  <S.Item
                    onSelect={(e) => handleModal(e, "inutilizar")}
                  >
                    Inutilizar Notas Fiscais
                  </S.Item>
                </DialogTrigger>
                <DisableInvoiceModal />
              </Dialog>
            </S.Content>
          </S.Portal>
        )}
      </S.DropdownMenuRoot>
    </S.Box>
  );
};

export { DropdownMenuInvoices };
