import { call, put } from '@redux-saga/core/effects';
import { notify, apiErrorHandler, importApi } from 'services';
import {
  InvoiceRecalculateActions as Actions,
  InvoiceRecalculateRequestAction as RequestAction,
} from 'store/ducks/pendingRelease';

export function* invoiceRecalculateTypesRequest(action: any) {
  const { payload, onSuccess, onFailure }: RequestAction = action;
  try {
    const { data } = yield call(importApi.post, `/transit-time/recalculate-many`, payload);
    onSuccess && onSuccess();
    if(data.errors?.length && data?.success.length){
      notify('warning','Algumas NFs não puderam ser recalculadas')
    }
    if(data.errors?.length && !data.success.length){
      notify('error','Não foi possível realizar o recalculo dessas NFs')
    }
    if(!data.errors?.length && data.success.length){
      notify('success', 'NFs recalculadas com sucesso')
    }
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
