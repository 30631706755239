import { FormHandles } from "@unform/core";
import { Clear, Input, Select } from "components/Shared";
import { OPERATIONS_TYPES } from "constants/Selects";
import { ReleasedContext } from "contexts/ReleasedContext";
import { useCarriers, useOperationTypes } from "hooks";
import { useDebounce } from "hooks/useDebounce";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FetchStatusListActions } from "store/ducks/status";
import * as S from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";

const ReleasedInvoicesFilter = () => {
  const dispatch = useDispatch();
  const { onQueryChange, query, clearQuery, showTrash } =
    useContext(ReleasedContext);
  const [invoiceNumber, setInvoiceNumber] = useState<string | undefined>(
    query.invoiceNumber
  );
  const formRef = useRef<FormHandles>(null);
  const debouncedSearchTerm = useDebounce<string | undefined>(
    invoiceNumber,
    500
  );
  const { operationTypesOptions, loadingOperationTypes, fetchOperationTypes } =
    useOperationTypes();
  const { carrierOptions, loadingCarriers, fetchCarriers } = useCarriers();
  const { data: statusList } = useSelector(
    (state: RootState) => state.fetchStatusList
  );

  const statusListOptions = statusList
    ? statusList.map((status) => ({
        value: status.name,
        label: status.name,
      }))
    : [];

  const selectedCarrier = carrierOptions.find(
    (carrier) => carrier.value === query?.carrierId
  );
  const selectedOperation = operationTypesOptions.find(
    (operation) => operation.value === query.operationTypeId
  );
  const selectedType = OPERATIONS_TYPES.find(
    (type) => type.value === query.type
  );

  const handleChangeCarrier = useCallback(
    (carrierId?: string | number) => {
      onQueryChange(
        {
          carrierId: carrierId ? Number(carrierId) : undefined,
          page: 1,
          limit: 10,
        },
        true
      );
    },
    [onQueryChange]
  );

  const handleChangeOperation = useCallback(
    (operation?: string | number) => {
      onQueryChange(
        {
          type: operation ? String(operation) : undefined,
          page: 1,
          limit: 10,
          operationTypeId: undefined,
        },
        true
      );
    },
    [onQueryChange]
  );

  const handleChangeBranch = useCallback(
    (opt?: string | number) => {
      onQueryChange(
        {
          operationTypeId: Number(opt),
          page: 1,
          limit: 10,
        },
        true
      );
    },
    [onQueryChange]
  );

  const handleChangeStatus = useCallback(
    (status?: string | number) => {
      onQueryChange({ status: status ? String(status) : undefined });
    },
    [onQueryChange]
  );

  const clearFilter = useCallback(() => {
    formRef.current && formRef.current.reset();
    clearQuery();
  }, [clearQuery]);

  useEffect(() => {
    onQueryChange(
      {
        invoiceNumber:
          debouncedSearchTerm !== "" ? debouncedSearchTerm : undefined,
        page: 1,
        limit: 10,
      },
      debouncedSearchTerm === ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchCarriers();
    dispatch(FetchStatusListActions.request({ asList: true, notFlow: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchOperationTypes();
  }, [fetchOperationTypes]);

  return (
    <S.ContainerFilter ref={formRef} onSubmit={() => {}}>
      <Select
        name="carrierId"
        options={carrierOptions}
        placeholder="Transportadora"
        onChange={(opt) => handleChangeCarrier(opt?.value)}
        isLoading={loadingCarriers}
        value={selectedCarrier}
        isClearable
      />
      <Select
        name="type"
        options={OPERATIONS_TYPES}
        placeholder="Operação"
        onChange={(opt) => handleChangeOperation(opt?.value)}
        value={selectedType}
        isClearable
      />
      <Select
        name="operationTypeId"
        options={operationTypesOptions}
        isDisabled={selectedType?.value !== "inbound"}
        placeholder="Ramificação"
        onChange={(opt) => handleChangeBranch(opt?.value)}
        isClearable
        isLoading={loadingOperationTypes}
        value={selectedOperation}
      />
      <Select
        name="status"
        options={statusListOptions}
        placeholder="Status"
        onChange={(opt) => handleChangeStatus(opt?.value)}
        isClearable
      />
      <Input
        name="invoiceNumber"
        type="search"
        placeholder="Nº da NF"
        defaultValue={invoiceNumber}
        onChange={(e) => setInvoiceNumber(e.target.value)}
      />
      <Clear onClick={clearFilter} title="Limpar filtro" show={showTrash} />
    </S.ContainerFilter>
  );
};

export { ReleasedInvoicesFilter };
