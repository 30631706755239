import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  ToggleDisableOperationTypeCFOPActions as Actions,
  ToggleDisableOperationTypeCFOPRequestAction as RequestAction,
} from 'store/ducks/operationsTypesCfop';

export function* toggleDisableOperationTypeCfopRequest(action: any) {
  const { id, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.put, `operation-types-cfop/${id}/toggle-disable`);
    onSuccess && onSuccess();
    notify('success', 'Roda atualizada com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
