import styled, { css } from "styled-components";
import { Colors } from "styles/constants";
import { BasePaginatorHeader, BasePaginatorListItem } from "styles/components";

export const Content = styled.div`
  margin: 10px 0 20px;
  color: ${Colors.Gray50};
  font-size: 1.25rem;
  line-height: 1.25rem;
`

export const Footer = styled.footer`
  border-top: 1px solid ${Colors.Gray30};
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > div {
    margin: 0;
    margin-right: 0.5rem;
  }
`

export const InputFlex = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > div {
    margin: 0;
    margin-right: 0.5rem;
  }
`

export const ContainerButton = styled.div`
  margin: 1rem 0px 1rem 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const baseGridCss = css`
  grid-template-columns: 120px 120px 140px auto;
  &:not(:last-child) {
    margin-bottom: 0;
  }
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`;

export const Button = styled.button`
  padding: 0.75rem 1.5rem;
  width: 30%;
  background-color: ${Colors.Orange};
  color: ${Colors.White};
  font-weight: 700;
  border-radius: 6px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const DeliveryDateEmpty = styled.span`
margin-top: 100px;
color: #ff0000;
`