import { Scaffold } from 'layouts';
import * as S from './styles';

import { InvoiceDetails } from 'components/Pages/Invoices/InvoiceDetails';

export const Delivered = () => {
    return (
        <Scaffold>
            <S.Container>
                <InvoiceDetails />
            </S.Container>
        </Scaffold>
    );
};
