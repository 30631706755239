import * as Yup from "yup"
import { getUserShape } from "validators/Common/baseUserValidator"

export class UpdateMemberValidator {
  public get schema() {
    return Yup.object().shape({
      userId: Yup.number(),
      companyId: Yup.array()
        .typeError("Selecione a transportadora")
        .required("Selecione a transportadora"),
      user: Yup.object().shape({
        ...getUserShape(),
        password: Yup.string().optional(),
      }),
    })
  }
}
