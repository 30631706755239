import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  OperationsListPage,
  OperationCreationPage,
} from 'pages/Operations';

const operationTypesRoutes = ({
  location,
  userBelongsToAnyOf,
  hasPermissionTo
}: RouterProps): RouteObject => ({
  path: 'configuracoes/rotas',
  element: hasPermissionTo(['OPERATION-TYPES-CFOP.FINDOPERATIONTYPESCFOP']) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <OperationsListPage />,
    },
    {
      path: 'criar',
      element: <OperationCreationPage />,
    }
  ],
});

export default operationTypesRoutes;
