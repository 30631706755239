import { useState, useCallback, useEffect, useRef } from "react";
import { Loading } from "components/Shared/Loading/styles";
import { Formatter } from "utils";
import { api, notify } from "services";
import * as S from "./styles";
import { FetchCollectActions as FetchActions } from "store/ducks/collects";
import { useDispatch } from "react-redux";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useAuth, useValidation } from "hooks";
import { ConfirmationDialog, ConfirmationDialogRef } from 'components/Shared';
import * as Yup from 'yup';
import { usePermission } from "hooks/usePermission";

export const ItemInfo = ({ collect }: any) => {
//   const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission()
//   const isCarrierMember = userBelongsToAnyOf("carrierMember");
  const isCarrierMember =  hasPermissionTo(['CARRIER-MEMBERS.FINDMEMBERS', 'CARRIER-MEMBERS.FINDMEMBER'])
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenCreateItem, setIsOpenCreateItem] = useState<boolean>(false);
  const ref = useRef(null);
  const formRef = useRef<FormHandles>(null);
  const dialogRef = useRef<ConfirmationDialogRef>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();

  const scroll = useCallback(() => {
    isOpenCreateItem
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
  }, [isOpenCreateItem]);

  const ListItems = useCallback(() => {
    
    const handleDeleteItem = async (itemId: number) => {
    
      if(!hasPermissionTo(["REVERSE-LOGISTICS.UPDATEREVERSELOGISTIC"])) {
        notify('error', 'Sem permissão para excluir.');
        return
      }
      setLoading(true);

      const confirmed = await dialogRef.current?.openDialog({
        title: 'Deseja remover o produto da coleta?',
      });

      if (confirmed) {
        try {
          await api.delete(`reverse-logistics/${collect.id}/delete-item/${itemId}`);
          notify("success", "Item removido com sucesso");
          setLoading(false);
          dispatch(FetchActions.request(collect.id));
        } catch (err) {
            notify("error", `${err}`);
            setLoading(false);
        }
      }
    }

    if (!items.length) {
      return <></>;
    }
    
    return (
      <S.MainGrid>
        <ConfirmationDialog ref={dialogRef} />
        <S.BoxHeader>
          <S.BoxTitle>Código</S.BoxTitle>
          <S.BoxTitle>Nome</S.BoxTitle>
          <S.BoxTitle>Lote</S.BoxTitle>
          <S.BoxTitle>Validade</S.BoxTitle>
          <S.BoxTitle>Qtd</S.BoxTitle>
          <S.BoxTitle>UN</S.BoxTitle>
          <S.BoxTitle></S.BoxTitle>
        </S.BoxHeader>
        {items.map((item) => {
          const {
            id,
            prodCprod: code,
            prodXprod: name,
            prodLote: lote,
            prodQcom: quantity,
            prodUcom: unity,
            expirationDate
          } = item;

          return (
            <S.Grid key={id}>
              <S.Column>{code}</S.Column>
              <S.Column>{name}</S.Column>
              <S.Column>{lote}</S.Column>
              <S.Column>{Formatter.date(expirationDate)}</S.Column>
              <S.Column>{Formatter.decimal(quantity)}</S.Column>
              <S.Column>{unity}</S.Column>
              <S.Column>
                {!collect.deletedAt && !collect.finishDate && <S.Trash onClick={() => handleDeleteItem(id)}/>}
              </S.Column>
            </S.Grid>
          );
        })}
      </S.MainGrid>        
    );
  }, [items, ref]);

  useEffect(() => {
    if (collect) {
      setItems(collect.items);
    }
  }, [collect]);

  const CreateItem = useCallback(() => {
    const onSubmit = async () => {
      setLoading(true);
      const prodCprod = formRef.current?.getFieldValue("prodCprod");
      const prodXprod = formRef.current?.getFieldValue("prodXprod");
      const prodLote = formRef.current?.getFieldValue("prodLote");
      const prodUcom = formRef.current?.getFieldValue("prodUcom");
      const prodQcom = formRef.current?.getFieldValue("prodQcom") ? formRef.current?.getFieldValue("prodQcom").toString().replace(',', '.') : '';
      const expirationDate = formRef.current?.getFieldValue("expirationDate");
      
      const data = {
        reverseLogisticId: collect.id,
        prodCprod:prodCprod,
        prodXprod:prodXprod,
        prodLote:prodLote,
        prodUcom:prodUcom,
        prodQcom:prodQcom,
        expirationDate:expirationDate,
      };

      try {

        const schema = Yup.object().shape({
          prodCprod: Yup.string().required('Informe o código do produto'),
          prodXprod: Yup.string().required('Informe a descrição do produto'),
          prodLote: Yup.string().required('Informe o lote do produto'),
          prodUcom: Yup.string().required('Informe a unidade comercial do produto'),
          prodQcom: Yup.string().required('Informe a quantidade do produto'),
          expirationDate: Yup.string().required('Informe a data de validade do produto'),
        });

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        await api.post(`reverse-logistics/item`, validData);
        notify("success", "Produto cadastrado com sucesso");
        setLoading(false);
        setIsOpenCreateItem(false);
        dispatch(FetchActions.request(collect.id));
      } catch (err) {
        handleFormErrors(err, formRef);
        // notify("error", `${err}`);
        setLoading(false);
        // setIsOpenCreateItem(false);
      }
    };
    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <S.TruckIcon />
            Adicionar Produto
            <S.Close onClick={() => setIsOpenCreateItem(false)} />
          </S.ModalTitle>
          <Form ref={formRef} onSubmit={onSubmit}>
            <S.InputDiv>
              <S.InputModal
                name="prodCprod"
                label="Código do Produto:"
              />
              <S.InputModal
                name="expirationDate"
                type="date"
                label="Data de Validade:"
              />
            </S.InputDiv>
            <S.InputModal
              name="prodXprod"
              label="Descrição do Produto:"
            />
            <S.InputDiv>
              <S.InputModal
                name="prodLote"
                label="Lote:"
              />
              <S.InputModal
                name="prodUcom"
                label="Unidade Comercial:"
              />
              <S.InputModal
                name="prodQcom"
                label="Quantidade:"
              />
            </S.InputDiv>
            <S.FormActions>
              <S.FormButton onClick={() => setIsOpenCreateItem(false)}>
                {loading && <Loading color="#fff" />}
                Voltar
              </S.FormButton>
              <S.FormButton type="submit" theme="accept">
                {loading && <Loading color="#fff" />}
                Salvar
              </S.FormButton>
            </S.FormActions>
          </Form>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [collect, dispatch]);

  useEffect(() => {
    scroll();
  }, [isOpenCreateItem]);

  if (!collect) {
    return <></>;
  }

  return (
    <S.Container>
      {isOpenCreateItem && <CreateItem />}
      <S.Header>
        <S.GridTitle>Produtos</S.GridTitle>
        {hasPermissionTo(['REVERSE-LOGISTICS.CREATEITEM']) && <S.Button disabled={collect.deletedAt || collect.finishDate} onClick = {() => setIsOpenCreateItem(true)}><S.Plus />Adicionar</S.Button>}
      </S.Header>
      <ListItems />
    </S.Container>
  );
};
