import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import * as S from './styles';
import { Scaffold } from 'layouts';
import { useAuth } from 'hooks';
import { NavigationDashboard } from '../NavigationDashboard';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePermission } from 'hooks/usePermission';
import { api } from 'services';

const Indicators = () => {
  const { profile, userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const isAdmin = hasPermissionTo([
    'ADMINS.FINDADMINS',
    'ADMINS.FINDADMIN',
    'ADMINS.UPDATEADMIN',
  ]);

  const ref = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showNavigator, setShowNavigator] = useState<boolean>(true);

  const handleClick = useCallback(() => {
    if (ref.current) {
      if (window.document.fullscreenElement) {
        if (window.document.exitFullscreen) {
          window.document.exitFullscreen();
        }
      } else {
        ref.current.requestFullscreen();
      }
    }
  }, []);

  const handleKeyDown = useCallback(() => {
    setIsFullscreen((v) => !v);
    setShowNavigator((prev) => !prev);
  }, []);

  useEffect(() => {
    if (window.document) {
      window.document.addEventListener('fullscreenchange', handleKeyDown);
    }

    return () => {
      window.document.removeEventListener('fullscreenchange', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (!['#rework', '#tracking', '#warehouse'].includes(hash) || !hash) {
      navigate('/dashboard#tracking');
    }
  }, [hash, navigate]);

  const url = useMemo(() => {
    const baseUrl = api.defaults.baseURL;
    // const zohoTrackingUrl = `${baseUrl}/iframe/tracking`;
    const zohoTrackingUrl = `https://analytics.zoho.com/open-view/1432280000090677672/3d8f4986692124ff4039c33ade7f875d`;
    // const zohoReworkUrl = `${baseUrl}/iframe/rework`;
    const zohoReworkUrl = `https://analytics.zoho.com/open-view/1432280000082592275/90847882cf66df5ed148b04cd21e4d7a`;
    const zohoWarehouseUrl = `${baseUrl}/iframe/warehouse`;

    if (hash === '#rework' && !isAdmin) {
      navigate('/dashboard#tracking');
      return '';
    }

    if (hash === '#rework' && isAdmin) {
      return zohoReworkUrl;
    }

    if (hash === '#warehouse' && isAdmin) {
      return zohoWarehouseUrl;
    }

    if (!profile?.carrier) return zohoTrackingUrl;

    if (profile.carrier.documentType === 'cnpj') {
      const cnpj = profile.carrier.document.substring(0, 8);
      return `${zohoTrackingUrl}?ZOHO_CRITERIA=substring("carriers"."document",1,8)=${cnpj}`;
    }

    if (profile.carrier.documentType === 'cpf') {
      return `${zohoTrackingUrl}?ZOHO_CRITERIA="carriers"."document"=${profile.carrier.document}`;
    }

    return '';
  }, [hash, navigate, profile, userBelongsToAnyOf]);

  return (
    <Scaffold showComponents={showNavigator}>
      <S.PageContainer ref={ref}>
        {showNavigator && <NavigationDashboard />}
        <S.Button onClick={handleClick} isFull={isFullscreen}>
          {isFullscreen ? <S.IconExitFullscreen /> : <S.IconFullscreen />}
        </S.Button>
        <S.Iframe width="100%" height="auto" src={url} />
      </S.PageContainer>
    </Scaffold>
  );
};

export { Indicators };
