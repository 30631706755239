import { call, put } from '@redux-saga/core/effects';
import { api, notify, formDataBuilder, apiErrorHandler } from 'services';
import {
  UpdateVehicleTypeActions as Actions,
  UpdateVehicleTypeRequestAction as RequestAction,
} from 'store/ducks/vehicleTypes';

export function* updateVehicleTypeRequest(action: any) {
  const { id, putData, onSuccess, onFailure }: RequestAction = action;
  try {
    const formData = formDataBuilder(putData);
    yield call(api.put, `vehicle-types/${id}`, formData);
    onSuccess && onSuccess();
    notify('success', 'Tipo de veículo atualizado com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
