import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { PaginatedVehicleType } from 'contracts/VehicleTypes';
import { SortingParams } from 'contracts/Common';
import React, { useCallback } from 'react';
import * as S from './styles';

interface Props {
  vehicleTypes: PaginatedVehicleType[];
  onSort?: (sort: SortingParams) => void;
  currentSort: SortingParams;
}

interface ItemProps {
  vehicleType: PaginatedVehicleType;
}

export const VehicleTypesList: React.FC<Props> = ({
  vehicleTypes,
  onSort,
  currentSort,
}) => {
  const Item = useCallback(({ vehicleType }: ItemProps): JSX.Element => {
    const { id, name } = vehicleType;

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column>{name}</S.Column>
        <S.ActionsColumn>
          <S.LinkActionButton
            to={`/configuracoes/tipos-veiculo/${id}/editar`}
          >
            <S.EditIcon />
          </S.LinkActionButton>
        </S.ActionsColumn>
      </S.ListItem>
    );
  }, []);

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="name" label="NOME" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {vehicleTypes.map((vehicleType) => (
        <Item key={vehicleType.id} vehicleType={vehicleType} />
      ))}
    </S.List>
  );
};
