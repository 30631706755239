import React from "react";
import * as S from "./styles";

type CleanProps = JSX.IntrinsicElements["button"] & {
  show?: boolean;
};

export const Clear = React.memo(({ show = true, ...rest }: CleanProps) => (
  <S.Container className={!show ? "hidden" : ""}>
    <button {...rest}>
      <S.TrashIcon />
    </button>
  </S.Container>
));
