import { SortableHeader, SortableHeadersGroup } from "components/Shared";
import { EMPTY_COLUMN_VALUE } from "constants/Common";
import type { PaginatedTransitTime } from "contracts/TransitTimes";
import { SortingParams } from "contracts/Common";
import React, { useCallback } from "react";
import { Formatter } from "utils";
import * as S from "./styles";

interface Props {
  transitTimes: PaginatedTransitTime[];
  onSort?: (sort: SortingParams) => void;
  currentSort: SortingParams;
}

interface ItemProps {
  transitTime: PaginatedTransitTime;
}

export const TransitTimesList: React.FC<Props> = ({
  transitTimes,
  onSort,
  currentSort,
}) => {

  const Item = useCallback(({ transitTime }: ItemProps): JSX.Element => {
    const { id, carrierCnpj, carrier, ibgeOrigin, ibgeDestiny, weight, cityOrigin, cityDestiny } =
      transitTime;

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column>
          {carrierCnpj
            ? Formatter.document(carrierCnpj, "cnpj")
            : EMPTY_COLUMN_VALUE}
        </S.Column>
        <S.Column>{carrier?.tradeName || EMPTY_COLUMN_VALUE}</S.Column>
        <S.Column>{cityOrigin?.name || EMPTY_COLUMN_VALUE}</S.Column>
        <S.Column>{cityDestiny?.name || EMPTY_COLUMN_VALUE}</S.Column>
        <S.Column>
          {weight ? Formatter.weight(weight) : EMPTY_COLUMN_VALUE}
        </S.Column>
        <S.ActionsColumn>
          <S.LinkActionButton to={`/configuracoes/transit-time/${id}/editar`}>
            <S.EditIcon />
          </S.LinkActionButton>
        </S.ActionsColumn>
      </S.ListItem>
    );
  }, []);

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="carrierCnpj" label="CNPJ" />
          <SortableHeader column="tradeName" label="TRANSPORTADORA" />
          <SortableHeader column="ibgeOrigin" label="ORIGEM" />
          <SortableHeader column="ibgeDestiny" label="DESTINO" />
          <SortableHeader column="weight" label="PESO" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {transitTimes.map((transitTime) => (
        <Item key={transitTime.id} transitTime={transitTime} />
      ))}
    </S.List>
  );
};
