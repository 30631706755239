import styled, { css } from "styled-components";
import { BasePaginatorHeader, BasePaginatorListItem } from "styles/components";
export {
  PaginatorColumn as Column,
  PaginatorActionsColumn as ActionsColumn,
  ActionButton,
  LinkActionButton,
  EditIcon,
} from "styles/components";

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const baseGridCss = css`
  grid-template-columns: 60px 140px repeat(3, 200px) auto minmax(
      0px,
      max-content
    );
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`;
