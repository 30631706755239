import { Fonts, getScrollbarStyle } from 'styles/constants';
import { BasePanel, MapPinIcon } from 'styles/components';
import { BaseButtonStyle } from 'styles/components';
import { Colors } from 'styles/constants';
import styled from "styled-components";
import { CloseIcon } from 'styles/components'
export {
  ListIcon,
  MapPinIcon
} from 'styles/components';

export const Container = styled.div`
  display: block;
  animation: animateContainer 500ms;
  @keyframes animateContainer {
    from { opacity: 0;}
    to { opacity: 1;}
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 400px;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  display: flex;
  ${BaseButtonStyle}
  background-color: ${Colors.Blue};
  color: ${Colors.White};
  font-family: ${Fonts.GilroySemiBold};
  font-size: 12px;
  padding: 5px 10px 5px 6px;
  justify-self: flex-end;
  border-radius: 4px;
  gap: 6px;
  white-space: nowrap;
`;

export const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: 120px 160px auto;
  column-gap: 16px;
  padding: 12px 0px;
  width: 100%;
  border-bottom: 1px dotted #d8dae8;
  &:last-child {
    border-bottom: none;
  }
  &:first-child {
    padding-top:0;
  }
`;

export const Grid = styled.div`
  flex-direction: column;
  width: 100%;
  overflow: hidden auto;
  height: 400px;
  margin-bottom: 24px;
  display: flex;
  ${getScrollbarStyle()};
`;

export const MapHeader = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: 0.5fr 0.5fr 2fr 1fr;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  width: 100%;
  margin-bottom: 13px;
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background-color: ${Colors.Black}cc;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  z-index: 999;
  overflow: hidden;
`;

export const ModalContainer = styled(BasePanel)`
  position: relative;
  width: 700px;
  height: 570px;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  padding: 35px;
  animation: animateModalContainer 150ms;

  form {
    width: 100%;
  }
  
  @keyframes animateModalContainer {
    from { opacity: 0; transform: scale(.96) }
    to { opacity: 1; transform: scale(1) }
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  gap: 8px;
  font-family: ${Fonts.GilroyBold};
  font-size: 1.25rem;
  width: 100%;
  align-items: center;
  justify-content: start;
  border-bottom: 1px solid ${Colors.Gray30};
  padding-bottom: 22px;
  margin-bottom: 22px;
`;

export const Info = styled.div`
  overflow: hidden;
  word-break: break-word;
  font-family: ${Fonts.GilroyRegular};
  font-size: 12px;
`;

export const Column = styled(Info)`
  font-family: ${Fonts.OpenSans};
  text-color: ${Colors.Gray60};
`;

export const Map = styled(BasePanel)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
  font-size: 14px;
  font-family: ${Fonts.GilroySemiBold};
  margin-bottom: 32px;
  @media (max-width: 1800px) {
    margin-bottom: 24px;
  }
`;

export const Box = styled.div`
  position: relative;
  width: 280px;
  padding: 24px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  left: -50%;
  animation: AnimateBox 200ms;
  @keyframes AnimateBox {
    from { opacity: 0; transform: scale(0.7)}
    to { opacity: 1; transform: scale(1)}
  }
`;

export const Label = styled.h3`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const Value = styled.p`
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Close = styled(CloseIcon)`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  height: 20px;
  color: ${Colors.Gray50};
`;

export const CloseModal = styled(Close)`
  height: 22px;
  top: 15px;
  right: 15px;
`;