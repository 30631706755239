import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  Input,
  Select,
  FormPageHeader,
} from 'components/Shared';
import { FORM_BACK_ACTION } from 'constants/Common';
import { useCarriers, useValidation, useCompanies, useSuppliers, useOperationTypes } from 'hooks';
import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { CreateOperationTypesCfopActions as CreateActions } from 'store/ducks/operationsTypesCfop';
import { CreateOperationValidator } from 'validators/Operations';
import * as S from './styles';

interface Props {
  onCreate?: () => void;
}

export const OperationCreationForm = ({ onCreate }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { carrierOptions, fetchCarriers, loadingCarriers } = useCarriers()
  const { companyOptions, fetchCompanies, loadingCompanies } = useCompanies()
  const { fetchSuppliers, loadingSuppliers, suppliersOptions } = useSuppliers()
  const { fetchOperationTypes, loadingOperationTypes, operationTypesOptions } = useOperationTypes()

  const { loading: creatingOperation, validationErrors } = useSelector(
    (state: RootState) => state.createOperationTypesCfop
  );

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onCreate && onCreate();
  }, [onCreate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});

        const { schema } = new CreateOperationValidator();

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(CreateActions.request(validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess]
  );

  const Header = useCallback((): JSX.Element => {
    return (
      <FormPageHeader
        title="Nova Rota"
        icon={<S.CompanyIcon />}
        actions={
          <S.LinkButton size="small" to="/configuracoes/rotas">
            <S.ArrowLeftIcon /> Voltar
          </S.LinkButton>
        }
      />
    );
  }, []);

  useEffect(() => {
    fetchCarriers();
  }, [fetchCarriers]);
  
  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  useEffect(() => {
    fetchOperationTypes();
  }, [fetchOperationTypes]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(CreateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <Header />
      <S.Content>
        <Form ref={formRef} onSubmit={onSubmit}>
         
          <S.FormRow>
            <Select
              name="companyId"
              label="Empresa"
              options={companyOptions || []}
              isLoading={loadingCompanies}
            />
            <Select
              name="supplierId"
              label="Fornecedor"
              options={suppliersOptions || []}
              isLoading={loadingSuppliers}
            />
            <Select
              name="carrierId"
              label="Transportadora"
              options={carrierOptions || []}
              isLoading={loadingCarriers}
            />
          </S.FormRow>

          <S.FormRow>
            <Select
              name="operationTypeId"
              label="Tipo de Operação"
              options={operationTypesOptions || []}
              isLoading={loadingOperationTypes}
            />
            <Input
              name="cfop"
              label="CFOP"
            />
          </S.FormRow>

          <S.FormActions>
            <S.LinkButton mood="light" to="/configuracoes/rotas">
              {FORM_BACK_ACTION}
            </S.LinkButton>
            <S.Button type="submit" disabled={creatingOperation}>
              {creatingOperation ? <S.ActivityIndicator /> : 'Salvar'}
            </S.Button>
          </S.FormActions>
        </Form>

      </S.Content>
    </S.MainPanel>
  );
};
