import * as S from './styles';
import { useCallback, useState, useEffect } from 'react';
import XLSX from 'sheetjs-style-v2';
import * as FileSaver from 'file-saver';
import { useExportInvoices, ExportTypes } from 'hooks';
import { FindInvoicesToExport } from 'contracts/Invoice';

interface Download {
  archiveName: string;
  type: ExportTypes;
  query: FindInvoicesToExport;
}

export const DownloadXLS = ({
  archiveName,
  type,
  query
}: Download) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet: charset=UTF8';
  const fileExtension = '.xlsx';
  const [ableToDownload, setAbleToDownload] = useState(false)

  const { dataExport, loadingExport, fetchInvoiceExport } =
    useExportInvoices(type);

  useEffect(() => {
    if (dataExport?.length && ableToDownload && !loadingExport) {
      const ws = XLSX.utils.json_to_sheet(dataExport);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, archiveName + fileExtension);
      setAbleToDownload(false);
    }
  }, [ableToDownload, archiveName, dataExport, loadingExport]);

  const exportInvoice = useCallback(() => {
    fetchInvoiceExport(query);
    setAbleToDownload(true)
  }, [fetchInvoiceExport, query]);

  return (
    <S.DownloadXLS onClick={() => exportInvoice()} loading={loadingExport ? true : undefined} disabled={loadingExport}>
      <span>Exportar Excel</span>
      <S.ContentAction>
        {loadingExport ? <S.XLSLoading /> : <S.DownloadIcon />}
      </S.ContentAction>
    </S.DownloadXLS>
  );
};
