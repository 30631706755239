import { ListVehicleTypesActions as MainActions } from 'store/ducks/vehicleTypes';
import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';

interface FindVehicleTypes {
  excludeBlocked?: boolean;
}

export const useVehicleTypes = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: vehicleTypes, loading: loadingVehicleTypes } = useSelector(
    (state: RootState) => state.listVehicleTypes
  );

  const [ignoredBlockedId, enableVehicleTypeIfBlocked] = useState<number | null>(
    null
  );

  const fetchVehicleTypes = useCallback(
    (query?: FindVehicleTypes): void => {
      dispatch(MainActions.request({ ...query }));
    },
    [dispatch]
  );

  const onVehicleTypesLoad = useMemo<SelectOption[]>(() => {
    return vehicleTypes.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }, [vehicleTypes, ignoredBlockedId]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    vehicleTypes,
    vehicleTypeOptions: onVehicleTypesLoad,
    loadingVehicleTypes,
    fetchVehicleTypes,
    enableVehicleTypeIfBlocked,
  };
};

export type VehicleTypesHook = ReturnType<typeof useVehicleTypes>;
