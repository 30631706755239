import { Role } from 'contracts/Roles';
import { api } from 'services';

type UpdatePayload = {
    id: number | string;
    perfil: any;
};

export async function updatePerfil(payload: UpdatePayload) {
    const { id, perfil } = payload;

    const { data } = await api.put<Role>(`/permissions/roles/${id}`, perfil);
    return data;
}
