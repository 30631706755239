import styled, { css } from 'styled-components';
import { Colors, Fonts } from 'styles/constants';
import { BasePaginatorHeader, BasePaginatorListItem } from 'styles/components';

export {
  ActivityIndicator,
  ModalContent,
  InvoiceIcon,
  PaginatorColumn as Column
} from 'styles/components';

export const PageContainer = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  max-width: 1280px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0,0,0,0.15);

  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Colors.White};
  padding: 1rem;
  width: 100%;
`;

export const ModalContainer = styled.section<{ isLoading?: boolean }>`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  color: ${Colors.Gray70};
  overflow-y: auto;

  ${props => props.isLoading && css`
    align-items: center;
    justify-content: center;
  `}
`;


export const ModalHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  border-bottom: 1px solid ${Colors.Gray40};
  margin-bottom: 32px;

  div.wrapper {
    display: flex;
    column-gap: 8px;
  }
`;


export const InvoiceNumber = styled.h1`
  font-family: ${Fonts.GilroyBold};
  font-size: 18px;
  line-height: 22px;
`;

const BaseGrid = styled.div`
  display: grid;
  gap: 32px 24px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const MainGrid = styled(BaseGrid)`
  display: grid;
  gap: 32px 24px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${Colors.Gray40};

  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
`;


export const TotalsGrid = styled.div`
  display: grid;
  gap: 32px 24px;
  padding-bottom: 24px;
  border-bottom: 2px dotted ${Colors.Gray40};
  margin-bottom: 24px;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DetailLabel = styled.label`
  font-family: ${Fonts.GilroySemiBold};
  font-size: 14px;
`;


export const DetailValue = styled.span`
  font-family: ${Fonts.OpenSans};
  font-size: 12px;
`;

export const DetailBigValue = styled.span`
  font-family: ${Fonts.GilroyBold};
  font-size: 24px;
`;

const baseGridItemsCss = css`
  grid-template-columns: 80px minmax(100px,auto) 100px repeat(6,90px);
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridItemsCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridItemsCss}
`;

export const ListHeaderLabel = styled(DetailLabel)``;
