import styled from "styled-components"
import {
    CheckCircleIcon,
    CloseIcon
  } from "styles/components";
import { Colors } from "styles/constants";

export const Check = styled(CheckCircleIcon)`
    color: ${Colors.Green};
    width: 22px;
    height: 22px;
  `

export const Close = styled(CloseIcon)`
color: ${Colors.Orange20};
width: 22px;
height: 22px;
`

