import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  ClientsListPage,
  ClientCreationPage,
  ClientUpdatePage,
} from 'pages/Clients';

const clientsRoutes = ({
  location,
  userBelongsToAnyOf,
  hasPermissionTo
}: RouterProps): RouteObject => ({
  path: 'configuracoes/clientes',
  element: hasPermissionTo(['CLIENTS.FINDCLIENTS']) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <ClientsListPage />,
    },
    {
      path: 'criar',
      element: <ClientCreationPage />,
    },
    {
      path: ':id/editar',
      element: <ClientUpdatePage />,
    },
  ],
});

export default clientsRoutes;
