import { ListCitiesActions as MainActions } from "./../../store/ducks/cities/listCities";
import { SelectOption } from "contracts/Common";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "store";
import { api } from "services";

export const useCities = () => {
  const dispatch: AppDispatch = useDispatch();
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [city, searchCity] = useState<number | string | null | undefined>(null);
  const { data: cities, loading: loadingCities } = useSelector(
    (state: RootState) => state.listCities
  );

  const fetchCities = useCallback((searchState?:any): void => {
    dispatch(MainActions.request({ search: city, state: searchState }));
  }, [dispatch]);

  const fetchCity = useCallback(async (searchState?:string)=>{
    const city = await api.get(`cities?state=${searchState}&asList=1`)
    return city
  },[])

  const onCitiesLoad = useCallback((): void => {
    setOptions(
      cities.map(({ id, ibge, name, uf }) => ({
        value: id,
        label: name,
        uf: uf,
        ibge: Number(ibge),
      }))
    );
  }, [cities]);

  useEffect(() => {
    onCitiesLoad();
  }, [onCitiesLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    cities,
    cityOptions: options,
    loadingCities,
    fetchCities,
    searchCity,
    fetchCity
  };
};

export type CitiesHook = ReturnType<typeof useCities>;
