import styled, { css } from 'styled-components';
import { Colors, Fonts } from 'styles/constants';
import { BaseButtonStyle, BasePaginatorHeader, BasePaginatorListItem } from 'styles/components';
import { getScrollbarStyle } from 'styles/constants';
export {
  ActivityIndicator,
  Button,
  LinkButton,
  AnchorButton,
  FormActions,
  FormRow,
  ModalBody,
  ModalContent,
  PackageIcon,
  PrinterIcon,
  InvoiceIcon,
  CloseIcon,
  PaginatorColumn as Column,
  UserCircleIcon,
  DotIcon,
} from 'styles/components';

export const ModalContainer = styled.section`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  color: ${Colors.Gray70};
  overflow-y: auto;
  ${getScrollbarStyle()};
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;

export const ModalHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  border-bottom: 1px solid ${Colors.Gray40};
  margin-bottom: 32px;

  div.wrapper {
    display: flex;
    column-gap: 8px;
  }
`;

export const RecognizeButton = styled.button`
  ${BaseButtonStyle};
  color: ${Colors.White};
  height: 60%;
  width: 80%;
  background-color: ${Colors.Orange};
  align-self: center;
`;

export const InvoiceNumber = styled.h1`
  font-family: ${Fonts.GilroyBold};
  font-size: 18px;
  line-height: 22px;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Gray70};
`;

const BaseGrid = styled.div`
  display: grid;
  gap: 32px 24px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const MainGrid = styled(BaseGrid)`
  display: grid;
  gap: 32px 24px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${Colors.Gray40};

  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const CorrectionGrid = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${Colors.Gray40};
  margin-bottom: 1.5rem;

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin: 0.5rem 0;
  }
`;

export const OccurrencesGrid = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${Colors.Gray40};
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin: 0.5rem 0;
  }
`;

export const CorrectionItem = styled.li`
  display: grid;
  gap: 0.5rem;
  align-items: center;
  grid-template-columns: 24px 1fr 24px;
  font-size: 14px;
  a {
    padding: 0;
  }
`;

export const OccurrenceItem = styled.li`
  display: grid;
  gap: 0.5rem;
  align-items: center;
  grid-template-columns: 20px auto 140px;
  padding: 0.25rem 0;
  font-size: 12px;

  strong {
    font-size: 14px;
  }
`;

export const TotalsGrid = styled.div`
  display: grid;
  gap: 32px 24px;
  padding-bottom: 24px;
  border-bottom: 2px dotted ${Colors.Gray40};
  margin-bottom: 24px;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DetailLabel = styled.label`
  font-family: ${Fonts.GilroySemiBold};
  font-size: 14px;
`;

export const DetailHeader = styled.h4`
  font-family: ${Fonts.GilroySemiBold};
  font-size: 18px;
`;

export const DetailValue = styled.span`
  font-family: ${Fonts.OpenSans};
  font-size: 12px;
`;

export const DetailBigValue = styled.span`
  font-family: ${Fonts.GilroyBold};
  font-size: 24px;
`;

const baseGridItemsCss = css`
  grid-template-columns: 100px minmax(100px, auto) 120px repeat(3, 120px);
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridItemsCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridItemsCss}
`;

export const ListHeaderLabel = styled(DetailLabel)``;

export const StatusGrid = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  max-height: 300px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Gray10};
  border-top: 1px solid ${Colors.Gray40};
  margin-top: 32px;
  overflow-y: auto;
  ${getScrollbarStyle()};
`;

export const StatusTitle = styled.h2`
  font-family: ${Fonts.GilroyBold};
  font-size: 16px;
  margin-bottom: 32px;
  padding-left: 32px;
`;

export const StatusWrapper = styled.div`
  position: relative;
  padding-left: 32px;

  border-left: 1px solid ${Colors.Gray40};
`;

export const StatusBox = styled.div`
  width: 100%;
  max-width: 80%;
  padding: 16px;
  border-radius: 4px;
  box-shadow: #00000025 0 20px 10px -16px;
  background-color: ${Colors.White};
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
  margin-bottom: 40px;

  svg.user {
    align-self: flex-start;
  }

  svg.dot {
    position: absolute;
    left: -39px;
  }

  div.wrapper {
    display: flex;
    flex-direction: column;
  }

  :before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: ${Colors.White};
    transform: rotate(45deg);
    position: absolute;
    left: -5px;
  }
`;

export const UserName = styled.h5`
  font-family: ${Fonts.GilroySemiBold};
  font-size: 14px;
  margin-bottom: 8px;
  color: ${Colors.Gray70};
`;

export const StatusDescription = styled.article`
  font-family: ${Fonts.OpenSans};
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.Gray60};
  margin-bottom: 16px;
`;

export const StatusDate = styled.span`
  font-family: ${Fonts.OpenSans};
  font-size: 12px;
  color: ${Colors.Gray50};
`;
