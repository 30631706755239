import { ClassificationUpdateForm } from 'components/Pages/ReverseLogistics/Configuration/Classifications';
import { Scaffold } from 'layouts';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const ClassificationUpdatePage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();

  const onUpdate = useCallback((): void => {
    navigate('/configuracoes/logistica-reversa/classificacoes');
  }, [navigate]);

  return (
    <Scaffold>
      <ClassificationUpdateForm classificationId={id} onUpdate={onUpdate} />
    </Scaffold>
  );
};
