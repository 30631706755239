import { SettingLayout } from 'layouts/SettingLayout';
import React, { useEffect, useState } from 'react';
import { PerfilInnerOptions } from '../PerfisInnerOptions';
import { DataTable } from 'components/ui/DataTable';
import { useColumns } from './columns';
import { useQuery } from 'react-query';
import { getRoles } from 'hooks/api/Permissions/getRoles';
import { useErrorHandler } from 'Exceptions/ErrorHandler';
import { Role } from 'contracts/Roles';
import { SettingsMenu } from 'components/Shared';

const ListPerfis: React.FC = () => {
    const [localData, setLocalData] = useState<Role[]>([]);
    const { errorHandler } = useErrorHandler();
    const { options } = PerfilInnerOptions();
    const { columns } = useColumns();
    const { data, isLoading } = useQuery<Role[]>({
        queryKey: 'roles',
        queryFn: getRoles,
        onError: errorHandler,
    });

    useEffect(() => {
        if(data) {
            setLocalData(data);
        }
        return () => {
            setLocalData([]);
        };
    }, [data]);

    return (
        <SettingLayout options={options}>
            <DataTable
                isLoading={isLoading}
                data={localData ?? []}
                columns={columns}
                showControlColumns={false}
            />
        </SettingLayout>
    );
};

export default ListPerfis;
