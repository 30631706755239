import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';
import { Colors, Fonts } from 'styles/constants';
import { DotsVerticalRounded } from 'styles/components/icons';

const BaseStyle = `
  min-width: 220px;
  background-color: white;
  border-radius: 6px;
  padding: 5px 0;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
`

export const Content = styled(DropdownMenuPrimitive.Content)`
  ${BaseStyle}
`;

export const Arrow = styled(DropdownMenuPrimitive.Arrow)`
  fill: white;
`

export const Portal = styled(DropdownMenuPrimitive.Portal)``

export const SubContent = styled(DropdownMenuPrimitive.SubContent)`
  ${BaseStyle}
`

export const DropdownMenuRoot = styled(DropdownMenuPrimitive.Root)``

const BaseItem = `
  font-size: 13px;
  line-height: 1;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 1rem 0.5rem;
  position: relative;
  cursor: pointer;
  font-family: ${Fonts.GilroySemiBold};

  &[data-disabled] {
    pointer-events: none;
    color: ${Colors.Gray50};
  }

  &:hover {
    background-color: ${Colors.Black};
    color: ${Colors.White};
  };
`

export const Item = styled(DropdownMenuPrimitive.Item)`
  ${BaseItem}
`

export const Sub = styled(DropdownMenuPrimitive.Sub)``
export const Trigger = styled(DropdownMenuPrimitive.Trigger)``

export const SubTrigger = styled(DropdownMenuPrimitive.SubTrigger)`
  &[data-state="open"] {
    color: red;
  }
`

export const Label = styled(DropdownMenuPrimitive.Label)``;

export const Separator = styled(DropdownMenuPrimitive.Separator)`
  height: 1px;
  margin: 5px;
  background-color: ${Colors.Gray20};
`;

export const ItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator)`
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div``

export const RightSlot = styled.div`
  margin-left: auto;
  padding-left: 20px;
  color: purple;

  &[data-highlighted] > & {
    color: white;
  };

  &[data-disabled] & {
    color: ${Colors.Gray30};
  };
`;

export const IconButton = styled.button<{clicable?: boolean}>`
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  svg {
    color: ${({clicable})=>(clicable ? Colors.Gray60 : Colors.Gray40 )};
  }
  &:hover { 
    svg {
      color: ${({clicable})=>(clicable ? Colors.Gray70 : Colors.Gray40 )};
    }
  }
`;

export const Icon = styled(DotsVerticalRounded)`
  color: ${Colors.Black};
`