import { FormHandles, Scope } from "@unform/core"
import { Form } from "@unform/web"
import { Input, Select, ToggleInput } from "components/Shared"
import { FORM_BACK_ACTION } from "constants/Common"
import { useCompanies, useValidation } from "hooks"
import React, { useCallback, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import type { AppDispatch, RootState } from "store"
import { CreateWarehouseMemberActions as CreateActions } from "store/ducks/warehouseMembers"
import { ListCarriersActions } from "store/ducks/carriers"
import { CreateMemberValidator } from "validators/WarehouseMembers"
import * as S from "./styles"

interface Props {
  onCreate?: () => void
}

type FormData = {
  companyId: number
  user: {
    name: string
    email: string
    password: string
    blockedAt: string
  }
}

export const MemberCreationForm: React.FC<Props> = ({ onCreate }) => {
  const dispatch: AppDispatch = useDispatch()
  const formRef = useRef<FormHandles>(null)
  const { handleFormErrors, handleApiErrors } = useValidation()

  const { companyOptions, loadingCompanies, fetchCompanies } = useCompanies()

  const { loading: creatingMember, validationErrors } = useSelector(
    (state: RootState) => state.createWarehouseMember
  )

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset()
    onCreate && onCreate()
  }, [onCreate])

  const onSubmit = useCallback(
    async (data: FormData): Promise<void> => {
      try {
        formRef?.current?.setErrors({})

        const formatData = {
          ...data,
          companyId: [data.companyId],
        }

        const { schema } = new CreateMemberValidator()

        const validData = await schema.validate(formatData, {
          abortEarly: false,
        })

        dispatch(CreateActions.request(validData, onSuccess))
      } catch (error) {
        handleFormErrors(error, formRef)
      }
    },
    [dispatch, handleFormErrors, onSuccess]
  )

  useEffect(() => {
    fetchCompanies()
  }, [fetchCompanies])

  useEffect(() => {
    handleApiErrors(validationErrors, formRef)
  }, [handleApiErrors, validationErrors])

  useEffect(() => {
    return () => {
      dispatch(CreateActions.reset())
      dispatch(ListCarriersActions.reset())
    }
  }, [dispatch])

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={onSubmit}>
        <Select
          name="companyId"
          label="Armazém"
          options={companyOptions}
          isLoading={loadingCompanies}
        />

        <Scope path="user">
          <S.FormRow>
            <Input name="name" label="Nome completo" />
            <Input name="email" label="Email" />
            <Input name="password" label="Senha" type="password" />
            <ToggleInput name="blockedAt" label="Bloqueado" />
          </S.FormRow>
        </Scope>
        <S.FormActions>
          <S.LinkButton mood="light" to="/configuracoes/armazem/usuarios">
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit">
            {creatingMember ? <S.ActivityIndicator /> : "Salvar"}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.Container>
  )
}
