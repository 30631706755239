import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { PaginateClassificationsActions as PaginateActions } from 'store/ducks/classifications';
import { usePaginationCache } from 'hooks';
import { Scaffold } from 'layouts';
import {
  ClassificationsList,
  ClassificationFilters,
  FindClassifications,
} from 'components/Pages/ReverseLogistics/Configuration/Classifications';
import { ListingPageHeader, Paginator, SettingsMenu } from 'components/Shared';
import * as S from './styles';

export const ClassificationsListPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { paginationCache, updatePaginationCache, handleFilter, handleSort } =
    usePaginationCache('Classifications');

  const {
    data: classifications,
    pagination,
    loading,
  } = useSelector((state: RootState) => state.paginateClassifications);

  const [query, setQuery] = useState<FindClassifications>({
    search: '',
    page: 1,
    limit: 10,
    ...paginationCache,
  });

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback((): void => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
      updatePaginationCache(query);
    };
  }, [dispatch, query, updatePaginationCache]);

  return (
    <Scaffold>
      <SettingsMenu />
      <S.MainPanel>
        <ListingPageHeader
          icon={<S.CategoryIcon />}
          title="Classificações - Logística Reversa"
          isLoading={loading}
          actions={
            <S.LinkButton to={'/configuracoes/logistica-reversa/classificacoes/criar'} size="small">
              <S.PlusIcon /> Nova classificação
            </S.LinkButton>
          }
        />
        <ClassificationFilters
          currentFilter={query}
          onFilter={(filter) => handleFilter(query, filter, setQuery)}
        />
        <ClassificationsList
          classifications={classifications}
          currentSort={query}
          onSort={(sort) => handleSort(query, sort, setQuery)}
        />
        <Paginator onPageChange={onPageChange} pagination={pagination} />
      </S.MainPanel>
    </Scaffold>
  );
};
