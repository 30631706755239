import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import type { InvoiceJustifications } from 'contracts/Invoice';

const { Types, Creators } = createActions(
  {
    request: ['payload', 'onSuccess', 'onFailure'],
    success: ['data'],
    failure: ['errorMessage'],
    softReset: [],
    reset: [],
  },
  {
    prefix: 'ABONED_INVOICEJUSTIFICATIONS_',
  }
);

export interface AbonedInvoiceJustificationsState {
  data: InvoiceJustifications | null;
  loading: boolean;
  errorMessage: string | null;
}


export type PayloadAboned = {
    justificationIds: number[]
    invoiceId: number
}

export interface AbonedInvoiceJustificationsRequestAction {
  payload: PayloadAboned;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface SuccessAction {
  data: InvoiceJustifications;
}

interface FailureAction {
  errorMessage: string;
}

const INITIAL_STATE: AbonedInvoiceJustificationsState = {
  data: null,
  loading: false,
  errorMessage: null,
};

const request = (state: AbonedInvoiceJustificationsState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (state: AbonedInvoiceJustificationsState, action: SuccessAction) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (state: AbonedInvoiceJustificationsState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const softReset = (state: AbonedInvoiceJustificationsState) =>
  update(state, {
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const abonedInvoiceJustifications = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const AbonedInvoiceJustificationsTypes = Types;
export const AbonedInvoiceJustificationsActions = Creators;
