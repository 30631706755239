import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  Input,
  Select,
  FormPageHeader,
} from 'components/Shared';
import { FORM_BACK_ACTION } from 'constants/Common';
import { 
  useValidation, 
  useCarriers, 
  useClients, 
  useAuditors, 
  useReasons, 
  useClassifications, 
  useCompanies,
} from 'hooks';
import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { CreateCollectActions as CreateActions } from 'store/ducks/collects';
import { CreateCollectValidator } from 'validators/ReverseLogistics';
import * as S from './styles';

interface Props {
  onCreate?: () => void;
}

export const CollectCreationForm: React.FC<Props> = ({ onCreate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { carrierOptions, loadingCarriers, fetchCarriers } = useCarriers();
  const { clientOptions, loadingClients, fetchClients } = useClients();
  const { reasonOptions, loadingReasons, fetchReasons } = useReasons();
  const { classificationOptions, loadingClassifications, fetchClassifications } = useClassifications();
  const { companyOptions, loadingCompanies, fetchCompanies } = useCompanies();
  const { auditorOptions, loadingAuditors, fetchAuditors } = useAuditors();

  const auditorFollowOptions = [
    {"value": 0, "label": 'Não'},
    {"value": 1, "label": 'Sim'}
  ];

  const incinerationOptions = [
    {"value": 0, "label": 'Não'},
    {"value": 1, "label": 'Sim'}
  ];

  const { loading: creatingCollect, validationErrors } = useSelector(
    (state: RootState) => state.createCollect
  );

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onCreate && onCreate();
  }, [onCreate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});
        Object.assign(data);

        const { schema } = new CreateCollectValidator();

        // Verificar o tipo dos inputs e definir como null se estiverem vazios
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const value = data[key];
            if (typeof value === 'string' && value === '') {
              data[key] = null;
            }
          }
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(CreateActions.request(data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      dispatch,
      handleFormErrors,
      onSuccess
    ]
  );

  const Header = useCallback((): JSX.Element => {
    return (
      <FormPageHeader
        title="Nova coleta"
        icon={<S.ReverseLogisticsIcon />}
        actions={
          <S.LinkButton size="small" to="/logistica-reversa/coletas">
            <S.ArrowLeftIcon /> Voltar
          </S.LinkButton>
        }
      />
    );
  }, []);

  useEffect(() => {
    fetchCarriers();
  }, [fetchCarriers]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    fetchReasons();
  }, [fetchReasons]);

  useEffect(() => {
    fetchClassifications();
  }, [fetchClassifications]);

  useEffect(() => {
    fetchAuditors();
  }, [fetchAuditors]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(CreateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <Header />
      <S.Content>
        <Form ref={formRef} onSubmit={onSubmit}>
          <S.FormRow>
            <Select
              name="carrierId"
              label="Transportadora"
              options={carrierOptions}
              placeholder="Selecione"
            />
            <Select
              name="clientId"
              label="Cliente"
              options={clientOptions}
              placeholder="Selecione"
            />
            <Select
              name="auditorId"
              label="Fiscal"
              options={auditorOptions}
              placeholder="Selecione"
            />
          </S.FormRow>

          <S.FormRowTitle>Solicitante</S.FormRowTitle>

          <S.FormRow>
            <Input name="requestRqps" label="RQPS" placeholder="Digite aqui..." />
            <Input name="requestRiv" label="RIV / Solicitante" placeholder="Digite aqui..." />
          </S.FormRow>

          <S.FormRow>
            <Input type="date" name="requestDate" label="Data da solicitação" placeholder="dd/mm/aaaa" />
            <Select
              name="requestAuditorFollow"
              label="Fiscal acompanha a coleta?"
              options={auditorFollowOptions}
              placeholder="Selecione"
              defaultValue={auditorFollowOptions.find((o) => o.value === 0)}
            />
          </S.FormRow>

          <S.FormRowTitle>Dados da coleta</S.FormRowTitle>

          <S.FormRow>
            <Select
              name="reverseLogisticsReasonId"
              label="Motivo da coleta"
              options={reasonOptions}
              placeholder="Selecione"
              menuPosition={'fixed'}
            />
            <Select
              name="reverseLogisticsClassificationId"
              label="Classificação da coleta"
              options={classificationOptions}
              placeholder="Selecione"
              menuPosition={'fixed'}
            />
            <Input type="date" name="collectNotificationDate" label="Data do recebimento da notificação" placeholder="dd/mm/aaaa" />
          </S.FormRow>
            
          <S.FormRow>
            <Input type="number" name="collectDaysLeft" label="Número de dias para coleta" placeholder="Digite aqui..." />
            <Input type="date" name="collectCarrierDeadline" label="Prazo da transportadora" placeholder="dd/mm/aaaa" />
            {/* <Input type="date" name="collectOficialDeadline" label="Prazo oficial" placeholder="dd/mm/aaaa" /> */}
            <Input name="collectNfeNumber" label="Número da NFe" placeholder="Digite aqui..." />
          </S.FormRow>

          <S.FormRow>
            
            <Select
              name="incineration"
              label="Incinerar?"
              options={incinerationOptions}
              placeholder="Selecione"
              defaultValue={incinerationOptions.find((o) => o.value === 0)}
            />
            <Select
              name="companyId"
              label="Armazém de destino"
              options={companyOptions}
              placeholder="Selecione"
              menuPosition={'fixed'}
            />
          </S.FormRow>

          <S.FormActions>
            <S.LinkButton mood="light" to="/logistica-reversa/coletas">
              {FORM_BACK_ACTION}
            </S.LinkButton>
            <S.Button type="submit">
              {creatingCollect ? <S.ActivityIndicator /> : 'Salvar'}
            </S.Button>
          </S.FormActions>
        </Form>
        {/* <LogoCropper
          label="Logo do cliente"
          helpText="Uma imagem de ao menos 320 x 320px"
          onChange={(file) => setLogoFile(file)}
        /> */}
      </S.Content>
    </S.MainPanel>
  );
};
