import type { RouterProps } from 'contracts/Router';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { 
  ListPendingRelease,
  ListReleased
} from 'pages/Invoices/PendingRelease';
import { PendingReleaseProvider } from 'contexts/PendingReleaseContext';
import { ReleasedInvoicesProvider } from 'contexts/ReleasedContext';
import { DeliveredInvoicesProvider } from 'contexts/DeliveredContext';
import { FinishedInvoicesProvider } from 'contexts/FinishedContext';
import { ListDelivered } from 'pages/Invoices/PendingRelease/ListDelivered';
import { ListFinished } from 'pages/Invoices/PendingRelease/ListFinished';
import { Tracking } from 'pages/Invoices/PendingRelease/Tracking';
import { Delivered } from 'pages/Invoices/PendingRelease/Delivered';
import { Finished } from 'pages/Invoices/PendingRelease/Finished';
import { PendingRelease } from 'pages/Invoices/PendingRelease/PendingRelease';

const pendingReleaseRoutes = ({
  isLoggedIn,
  location,
}: RouterProps): RouteObject => ({
  path: 'inbound/notas-fiscais',
  element: isLoggedIn ? (
      <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: 'pendentes-de-liberacao',
      element: (
        <PendingReleaseProvider>
          <ListPendingRelease />
        </PendingReleaseProvider>
      ),
    },
    {
        path: 'pendentes-de-liberacao/:id',
        element: (
          <PendingReleaseProvider>
            <PendingRelease />
          </PendingReleaseProvider>
        ),
      },
    {
      path: 'tracking/:selectedInvoice',
      element: (
        <ReleasedInvoicesProvider>
          <Tracking/>
        </ReleasedInvoicesProvider>
      ),
    },
    {
      path: 'tracking',
      element: (
        <ReleasedInvoicesProvider>
          <ListReleased />
        </ReleasedInvoicesProvider>
      ),
    },
    {
      path: 'entregues',
      element: (
        <DeliveredInvoicesProvider>
          <ListDelivered />
        </DeliveredInvoicesProvider>
      ),
    },
    {
        path: 'entregues/:id',
        element: (
          <DeliveredInvoicesProvider>
            <Delivered />
          </DeliveredInvoicesProvider>
        ),
      },
    {
      path: 'finalizadas',
      element: (
        <FinishedInvoicesProvider>
          <ListFinished />
        </FinishedInvoicesProvider>
      ),
    },
    {
        path: 'finalizadas/:id',
        element: (
          <FinishedInvoicesProvider>
            <Finished />
          </FinishedInvoicesProvider>
        ),
      },
  ],
});

export default pendingReleaseRoutes;
