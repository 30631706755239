import { SortingParams } from "contracts/Common";
import { FindDeliveredInvoices, PaginatedInvoice } from "contracts/Invoice";
import { Pagination } from "contracts/Pagination";
import { usePaginationCache } from "hooks";
import {
  createContext,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { PaginateDeliveredInvoicesActions as PaginateActions } from "store/ducks/deliveredInvoices";

export type ModalTypes = "sap" | "justificativas";

interface DeliveredContextType {
  deliveredInvoices: PaginatedInvoice[];
  deliveredInvoiceNotDelayed: PaginatedInvoice[];
  deliveredInvoicesDelayed: PaginatedInvoice[];
  query: FindDeliveredInvoices;
  pagination: Pagination | undefined;
  selectedInvoice: PaginatedInvoice | null;
  refModal: RefObject<HTMLDivElement>;
  selectedList: PaginatedInvoice[];
  openedModal: ModalTypes | null;
  loading: boolean;
  showTrash: boolean;
  changeOpenModal: (type?: ModalTypes) => void;
  onSelectInvoices: (invoices: PaginatedInvoice[]) => void;
  onRemoveSelecteds: (ids: number[]) => void;
  onToggleSelected: (invoice: PaginatedInvoice) => void;
  onSelectInvoice: (invoices: PaginatedInvoice) => void;
  clearSelectList: () => void;
  onRemoveSelected: () => void;
  onPageChange: (page: number) => void;
  onQueryChange: (newQuery?: FindDeliveredInvoices, force?: boolean) => void;
  clearQuery: () => void;
  updateFetch: () => void;
  onSort: (newState: SortingParams) => void;
  reset: () => void;
}

export const DeliveredContext = createContext({} as DeliveredContextType);

interface DeliveredInvoicesProviderProps {
  children: ReactNode;
}

const INITIAL_QUERY_STATE: FindDeliveredInvoices = {
  page: 1,
  limit: 10,
};

export function DeliveredInvoicesProvider({
  children,
}: DeliveredInvoicesProviderProps) {
  const { paginationCache, updatePaginationCache, handleSort } =
    usePaginationCache<FindDeliveredInvoices>("DeliveredInvoices");
  const [query, setQuery] = useState<FindDeliveredInvoices>({
    ...INITIAL_QUERY_STATE,
    ...paginationCache,
  });
  const [selectedInvoice, setSelectedInvoice] =
    useState<PaginatedInvoice | null>(null);
  const [selectedList, setSelectedList] = useState<PaginatedInvoice[]>([]);
  const [deliveredInvoiceNotDelayed, setDeliveredInvoiceNotDelayed] = useState<PaginatedInvoice[]>([]);
  const [deliveredInvoicesDelayed, setDeliveredInvoiceDelayed] = useState<PaginatedInvoice[]>([]);

  const [showModal, setShowModal] = useState<ModalTypes | null>(null);
  const refModal = useRef<HTMLDivElement>(null);
  const dispatch: AppDispatch = useDispatch();

  const {
    data: deliveredInvoices,
    pagination,
    loading,
  } = useSelector((state: RootState) => state.paginateDeliveredInvoices);

  const showTrash = useMemo(
    () => JSON.stringify(query) !== JSON.stringify(INITIAL_QUERY_STATE),
    [query]
  );

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const changeOpenModal = useCallback((type?: ModalTypes) => {
    setShowModal(type ? type : null);
  }, []);

  const updateFetch = useCallback(() => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  const onQueryChange = useCallback(
    (newQuery?: FindDeliveredInvoices, force = false): void => {
      const search = newQuery?.invoiceNumber;

      if (search) {
        newQuery.invoiceNumber = search.replaceAll(" ", "|");
      }
      const newData = { ...query, ...newQuery };
      if (JSON.stringify(newData) !== JSON.stringify(query) || force) {
        setQuery((prev) => ({ ...prev, ...newQuery, page: 1 }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [query]
  );

  const clearQuery = useCallback(() => setQuery(INITIAL_QUERY_STATE), []);

  function reset() {
    dispatch(PaginateActions.reset());
    updatePaginationCache(query);
  }

  useEffect(() => {
    const arrayDeliveredInvoicesNotDelayed: PaginatedInvoice[] = [];
    const deliveredInvoicesDelayed: PaginatedInvoice[] = [];
    deliveredInvoices.forEach((invoice: any) => {
      if(invoice.isDelayedLeadTime === false){
        arrayDeliveredInvoicesNotDelayed.push(invoice)
      }else{
        deliveredInvoicesDelayed.push(invoice)
      }
    });
    setDeliveredInvoiceDelayed(deliveredInvoicesDelayed);
    setDeliveredInvoiceNotDelayed(arrayDeliveredInvoicesNotDelayed);
    
  }, [deliveredInvoices]);

  const onSelectInvoice = useCallback((invoice: PaginatedInvoice) => {
    setSelectedInvoice(invoice);
  }, []);

  const onRemoveSelected = useCallback(() => {
    setSelectedInvoice(null);
  }, []);

  const onSort = useCallback((newState: SortingParams) => {
    handleSort(query, newState, setQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(PaginateActions.request(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const clearSelectList = useCallback(() => {
    setSelectedList([]);
  }, []);

  const onSelectInvoices = useCallback((invoices: PaginatedInvoice[]) => {
    setSelectedList((prev) => {
      const prevIDs = prev.map((invoice) => invoice.id);
      return [
        ...prev,
        ...invoices.filter((invoice) => !prevIDs.includes(invoice.id)),
      ];
    });
  }, []);

  const onRemoveSelecteds = useCallback((ids: number[]) => {
    setSelectedList((prev) =>
      prev.filter((invoice) => !ids.includes(invoice.id))
    );
  }, []);

  const onToggleSelected = useCallback((invoice: PaginatedInvoice) => {
    setSelectedList((prev) => {
      const hasOnList = prev.find(
        (invoiceFind) => invoiceFind.id === invoice.id
      );

      if (hasOnList) {
        return prev.filter((invoiceFilter) => invoiceFilter.id !== invoice.id);
      }

      return [...prev, invoice];
    });
  }, []);

  return (
    <DeliveredContext.Provider
      value={{
        pagination,
        deliveredInvoices,
        deliveredInvoiceNotDelayed,
        deliveredInvoicesDelayed,
        loading,
        showTrash,
        query,
        changeOpenModal,
        onRemoveSelected,
        onSelectInvoice,
        refModal,
        openedModal: showModal,
        selectedInvoice,
        updateFetch,
        onPageChange,
        onQueryChange,
        onSort,
        reset,
        clearSelectList,
        clearQuery,
        onSelectInvoices,
        onRemoveSelecteds,
        onToggleSelected,
        selectedList,
      }}
    >
      {children}
      <div ref={refModal} />
    </DeliveredContext.Provider>
  );
}
