import { FormHandles} from '@unform/core';
import { Form } from '@unform/web';
import { Input, Select } from 'components/Shared';
import { useValidation } from 'hooks';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_BACK_ACTION } from 'constants/Common';
import { ListJustificationsActions as ListTypes } from 'store/ducks/justifications';
import { FetchJustificationTypesActions } from 'store/ducks/justificationTypes';
import type { AppDispatch, RootState } from 'store';
import { CreateJustificationActions as CreateActions } from 'store/ducks/justifications';
import { CreateJustificationValidator } from 'validators/Justifications';
import * as S from './styles';

interface Props {
  onCreate?: () => void;
}

interface FormData {
  description: string
  type: number
}

export const JustificationCreationForm: React.FC<Props> = ({ onCreate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();

  const { loading: creatingJustification, validationErrors } = useSelector(
    (state: RootState) => state.createJustification
  );

  const fetchListTypes = useCallback((): void => {
    dispatch(ListTypes.request());
  }, []);

  const { loading: justificationTypesLoading, data: justificationTypes  } = useSelector(
    (state: RootState) => state.fetchJustificationTypes
  );

  const fetchJustificationTypes = useCallback((): void => {
    dispatch(FetchJustificationTypesActions.request());
  }, []);


  const justificationTypesOptions =  justificationTypes.map((type) => ({ label: type.name, value: type.id }))

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onCreate && onCreate();
  }, [onCreate]);

  const onSubmit = useCallback(
    async (data: FormData): Promise<void> => {

      try {
        formRef?.current?.setErrors({});
        const { schema } = new CreateJustificationValidator();

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(CreateActions.request(validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess]
  );

  useEffect(()=>{
    fetchListTypes()
    fetchJustificationTypes()
  },[])

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(CreateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <S.Content>
        <Form ref={formRef} onSubmit={onSubmit}>
            <S.FormRowCreate>
              <Input name="description" label="Justificativa" />
              <Select placeholder="Selecione um tipo" name="type" label="tipo" options={justificationTypesOptions} isLoading={justificationTypesLoading} />
            </S.FormRowCreate>
          <S.FormActions>
            <S.LinkButton mood="light" to="/configuracoes/justificativas">
              {FORM_BACK_ACTION}
            </S.LinkButton>
            <S.Button type="submit">
              {creatingJustification ? <S.ActivityIndicator /> : 'Salvar'}
            </S.Button>
          </S.FormActions>
        </Form>
      </S.Content>
    </S.MainPanel>
  );
};
