import { call, put } from '@redux-saga/core/effects';
import { notify, apiErrorHandler, importApi } from 'services';
import {
  RecalculateTransitTimeActions as Actions,
  RecalculateTransitTimeRequestAction as RequestAction,
} from 'store/ducks/transitTime';

export function* recalculateTransitTimeRequest(action: any) {
  const { invoiceKey, onSuccess, onFailure }: RequestAction = action;
  try {
    const { data } = yield call(importApi.get, `/transit-time/recalculate?invoiceKey=${invoiceKey}`);
    onSuccess && onSuccess();
    notify('success', 'NF recalculada com sucesso!')
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
