import { ExportInvoicesActions as MainActions } from "./../../store/ducks/exportInvoices/exportInvoices";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "store";
import { FindInvoicesToExport, Invoice } from "contracts/Invoice";
import { Formatter } from "utils";

export type ExportTypes = "Pendente" | "Liberada para Tracking" | "Entregue" | "Finalizada"
interface StepExport {
  step: ExportTypes;
}

export const useExportInvoices = (step: StepExport["step"]) => {
  const dispatch: AppDispatch = useDispatch();

  const toDate = useCallback(
    (date?: string | null, format?: any): string =>
      date ? Formatter.date(date, format) : "",
    []
  );
  const toDocument = useCallback(
    (document?: string): string =>
      document ? Formatter.document(document) : "",
    []
  );

  const { data: invoiceExport, loading: loadingExport } = useSelector(
    (state: RootState) => state.exportInvoices
  );

  const dataExport = useMemo(() => {
    const abonedStatus = {
      check: "Abonada",
      alert: "Pendente",
      none: "",
    };

    if (!invoiceExport?.length) return [];
    return invoiceExport.map((item: Invoice) => {
      const {
        abonedAt,
        allowance,
        infnfeIdeNnf,
        infnfeIdeSerie,
        deadlineDate,
        infnfeIdeDhemi,
        supplier,
        company,
        carrier,
        status,
        deliveryDate,
        releasedAt,
        note,
        vehicle,
        vehicleType,
        sapCode,
        returnDate
      } = item;

      const relativeStep = {
        Finalizada: {
          "LIBERADO EM": toDate(releasedAt),
          "ENTREGUE EM": toDate(deliveryDate),
          "CÓDIGO SAP": sapCode ? sapCode : "",
          "DATA EFETIVAÇÃO SAP": returnDate ? toDate(returnDate) : ""
        },
        Entregue: {
          "LIBERADO EM": toDate(releasedAt),
          "ENTREGUE EM": toDate(deliveryDate),
          "STATUS JUSTIFICATIVA": abonedStatus[allowance],
          "ABONADO EM": toDate(abonedAt, {
            format: "dd/MM/yyyy HH:mm",
          }),
        },
        "Liberada para Tracking": {
          "LIBERADO EM": toDate(releasedAt),
        },
        Pendente: {},
      };

      return {
        NF: `${infnfeIdeNnf}-${infnfeIdeSerie}`,
        "DT. EMISSAO": toDate(infnfeIdeDhemi),
        PRAZO: toDate(deadlineDate),
        ORIGEM: supplier ? supplier.tradeName : "",
        "CNPJ ORIGEM": toDocument(supplier?.document),
        DESTINO: company ? company.tradeName : "",
        "CNPJ DESTINO": toDocument(company?.document),
        TRANSPORTADORA: carrier ? carrier.tradeName : "",
        "CNPJ TRANSPORTADORA": toDocument(carrier?.document),
        OBSERVACOES: note ?? "",
        VEICULO: vehicle ?? "",
        "TIPO DE VEICULO": vehicleType?.name ?? "",
        ...relativeStep[step],
        STATUS: status?.length ? status[0].name : "Sem status",
      };
    });
  }, [invoiceExport, step, toDate, toDocument]);

  const fetchInvoiceExport = useCallback(
    (query: FindInvoicesToExport): void => {
      dispatch(MainActions.request({ ...query, flowStatus: step }));
    },
    [dispatch, step]
  );

  return {
    loadingExport,
    dataExport,
    fetchInvoiceExport,
  };
};

export type ExportInvoicesTypesHook = ReturnType<typeof useExportInvoices>;
