import React from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { useAuth } from 'hooks';
import routes from './routes';
import { usePermission } from 'hooks/usePermission';

const AppRoutes: React.FC = () => {
  const { isLoggedIn, userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission()
  const location = useLocation();
  const myRoutes = useRoutes(
    routes({ isLoggedIn, userBelongsToAnyOf, location, hasPermissionTo })
  );

  return <>{myRoutes}</>;
};

export default AppRoutes;
