import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  SuppliersListPage,
  SupplierCreationPage,
  SupplierUpdatePage,
} from '../pages/Suppliers';

const suppliersRoutes = ({
  location,
  userBelongsToAnyOf,
  hasPermissionTo
}: RouterProps): RouteObject => ({
  path: 'configuracoes/fornecedores',
  element: hasPermissionTo(['SUPPLIERS.FINDSUPPLIERS']) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <SuppliersListPage />,
    },
    {
      path: 'criar',
      element: <SupplierCreationPage />,
    },
    {
      path: ':id/editar',
      element: <SupplierUpdatePage />,
    },
  ],
});

export default suppliersRoutes;
