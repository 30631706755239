import { InnerNavigatorOption } from 'components/ui/InnerNavigator';
import { usePermission } from 'hooks/usePermission';

export function PerfilInnerOptions() {
  const { hasPermissionTo } = usePermission();
  const permissions = ['PERMISSIONS.GETACTIONS', 'PERMISSIONS.GETROLES', 'PERMISSIONS.STOREROLE', 'PERMISSIONS.SHOWROLE', 'PERMISSIONS.UPDATEROLE', 'PERMISSIONS.GETACTIONSBYUSER']
  const options: InnerNavigatorOption[] = [
    {
      title: 'Perfis de acesso',
      route: '/configuracoes/perfis',
      hasPermission: hasPermissionTo(permissions),
    },
    {
        title: 'Novo perfil de acesso',
        route: '/configuracoes/perfis/criar',
        hasPermission: hasPermissionTo(permissions),
      },
  ];

  return { options };
}