import { User } from "contracts/Users";
import { Formatter } from "utils";
import { JustificationsReverseLogistics } from "contracts/JustificationsReverseLogistics";
import * as S from "./styles";
import { useState, useEffect, useCallback, useRef } from "react";
import { api, notify } from "services";
import { FetchCollectActions as FetchActions } from "store/ducks/collects";
import { useDispatch } from "react-redux";
import { Loading } from "components/Shared/Loading/styles";
import { useAuth, useJustificationsReverseLogistics } from "hooks";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { ConfirmationDialog, ConfirmationDialogRef } from "components/Shared";
import { usePermission } from "hooks/usePermission";

export const JustificationsBox = ({ collect }: any) => {
  const [justifications, setJustifications] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);
  const [isOpenCancel, setIsOpenCancel] = useState<boolean>(false);
  const { justificationOptions, loadingJustifications, fetchJustifications } =
    useJustificationsReverseLogistics();
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { profile, userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission()
//   const isCarrierMember = userBelongsToAnyOf("carrierMember");

  const dialogRef = useRef<ConfirmationDialogRef>(null);

  const scroll = useCallback(() => {
    isOpenCreate
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
  }, [isOpenCreate, isOpenCancel]);

  const ListJustifications = useCallback(() => {
    if (!profile) return <></>;

    interface Item {
      id: number;
      description: string;
      user: User;
      approver: User;
      createdAt: string;
      justification: JustificationsReverseLogistics;
      approved: boolean;
      updatedAt: string;
    }

    const handleJustificationApprove = async (
      id: number,
      approverId: number,
      approved: boolean
    ) => {
      setLoading(true);

      const data = {
        approved: approved,
        approverId: approverId,
      };

      const dialogTitle = approved
        ? "Deseja aprovar essa justificativa?"
        : "Deseja reprovar essa justificativa?";

      const confirmed = await dialogRef.current?.openDialog({
        title: dialogTitle,
      });
      if (confirmed) {
        try {
          await api.put(`reverse-logistics/justification/${id}`, data);
          notify("success", "Alteração feita com sucesso");
          setLoading(false);
          dispatch(FetchActions.request(collect.id));
        } catch (err) {
          notify("error", `${err}`);
          setLoading(false);
        }
      }
    };

    if (!justifications.length) {
      return <></>;
    }

    return (
      <S.List>
        {justifications.map((item: Item) => {
          const {
            id,
            description,
            user,
            approver,
            justification,
            createdAt,
            approved,
            updatedAt,
          } = item;

          return (
            <S.Box>
              <ConfirmationDialog ref={dialogRef} />
              <S.BoxSide>
                <S.DotIcon className="dot" />
              </S.BoxSide>
              <S.BoxContent>
                <S.BoxHeader>
                  {user.name}
                  <S.CreatedData>
                    {(createdAt && Formatter.dateFull(createdAt)) || ""}
                  </S.CreatedData>
                </S.BoxHeader>
                <S.BoxType>{justification.description}</S.BoxType>
                <S.BoxText>{description}</S.BoxText>
                <S.BoxFooter>
                  {approved === null && hasPermissionTo(['REVERSE-LOGISTICS.UPDATEJUSTIFICATIONSTATUS']) ? (
                    <S.BtnBox>
                      <S.BtnReject
                        disabled={collect.deletedAt || collect.finishDate}
                        onClick={() =>
                          handleJustificationApprove(id, profile?.userId, false)
                        }
                      >
                        {(loading && <Loading color="#FFF" />) || (
                          <S.RejectIcon />
                        )}
                        Reprovar
                      </S.BtnReject>
                      <S.BtnApprove
                        disabled={collect.deletedAt || collect.finishDate}
                        onClick={() =>
                          handleJustificationApprove(id, profile?.userId, true)
                        }
                      >
                        {(loading && <Loading color="#FFF" />) || (
                          <S.CheckIcon />
                        )}
                        Aprovar
                      </S.BtnApprove>
                    </S.BtnBox>
                  ) : Boolean(approved) ? (
                    <S.Status>
                      <S.StatusFlagApproved>
                        <S.Check />
                      </S.StatusFlagApproved>
                      Aprovado em{" "}
                      {(updatedAt && Formatter.dateFull(updatedAt)) || ""}
                      {approver?.name && <span>Por {approver.name}</span>}
                    </S.Status>
                  ) : Boolean(approved) === false ? (
                    <S.Status>
                      <S.StatusFlag>
                        <S.RejectIcon />
                      </S.StatusFlag>
                      Reprovado em{" "}
                      {(updatedAt && Formatter.dateFull(updatedAt)) || ""}
                      {approver?.name && <span>Por {approver.name}</span>}
                    </S.Status>
                  ) : (
                    <></>
                  )}
                </S.BoxFooter>
              </S.BoxContent>
            </S.Box>
          );
        })}
      </S.List>
    );
  }, [justifications, ref]);

  useEffect(() => {
    if (collect) {
      setJustifications(collect.justifications);
    }
  }, [collect]);

  const CreateJustification = useCallback(() => {
    const onSubmit = async () => {
      setLoading(true);
      const justificationsReverseLogisticsId =
        formRef.current?.getFieldValue("justificationId");
      const description = formRef.current?.getFieldValue("description");

      const data = {
        reverseLogisticId: collect.id,
        justificationsReverseLogisticsId: justificationsReverseLogisticsId,
        description: description,
        userId: profile?.userId,
      };

      try {
        await api.post(`reverse-logistics/justification`, data);
        notify("success", "Justificativa cadastrada com sucesso");
        setLoading(false);
        setIsOpenCreate(false);
        dispatch(FetchActions.request(collect.id));
      } catch (err) {
        notify("error", `${err}`);
        setLoading(false);
        setIsOpenCreate(false);
      }
    };
    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <S.CommentIcon />
            Adicionar Justificativa
            <S.Close onClick={() => setIsOpenCreate(false)} />
          </S.ModalTitle>
          <Form ref={formRef} onSubmit={onSubmit}>
            <S.SelectJustification
              name="justificationId"
              label="Tipo da Justificativa"
              options={justificationOptions}
              isLoading={loadingJustifications}
            />
            <S.TextArea name="description" label="Comentário" />
            <S.FormActions>
              <S.FormButton onClick={() => setIsOpenCreate(false)}>
                {loading && <Loading color="#fff" />}
                Voltar
              </S.FormButton>
              <S.FormButton type="submit" theme="accept">
                {loading && <Loading color="#fff" />}
                Salvar
              </S.FormButton>
            </S.FormActions>
          </Form>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [justificationOptions, collect]);

  const CancelCollect = useCallback(() => {
    const onSubmit = async () => {
      setLoading(true);
      const justificationsReverseLogisticsId =
        formRef.current?.getFieldValue("justificationId");
      const description = formRef.current?.getFieldValue("description");

      const data = {
        justificationsReverseLogisticsId: justificationsReverseLogisticsId,
        description: description,
        userId: profile?.userId,
      };

      try {
        await api.put(`reverse-logistics/cancel/${collect.id}`, data);
        notify("success", "Coleta cancelada com sucesso");
        setLoading(false);
        setIsOpenCancel(false);
        dispatch(FetchActions.request(collect.id));
      } catch (err) {
        notify("error", `${err}`);
        setLoading(false);
        setIsOpenCancel(false);
      }
    };
    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <S.CommentIcon />
            Cancelar ordem de coleta
            <S.Close onClick={() => setIsOpenCancel(false)} />
          </S.ModalTitle>
          <Form ref={formRef} onSubmit={onSubmit}>
            <S.SelectJustification
              name="justificationId"
              label="Justificativa do Cancelamento"
              options={justificationOptions}
              isLoading={loadingJustifications}
            />
            <S.TextArea name="description" label="Comentário" />
            <S.FormActions>
              <S.FormButton onClick={() => setIsOpenCancel(false)}>
                {loading && <Loading color="#fff" />}
                Voltar
              </S.FormButton>
              <S.FormButton type="submit" theme="accept">
                {loading && <Loading color="#fff" />}
                Salvar
              </S.FormButton>
            </S.FormActions>
          </Form>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [justificationOptions]);

  const handleFinishCollect = async (id: number) => {
    setLoading(true);
    const finishDate = new Date();

    const data = {
      ...collect,
      finishDate: finishDate,
    };

    const dialogTitle = "Confirmar finalização da coleta";

    const confirmed = await dialogRef.current?.openDialog({
      title: dialogTitle,
    });
    if (confirmed) {
      try {
        await api.put(`reverse-logistics/${id}`, data);
        notify("success", "Coleta finalizada com sucesso");
        setLoading(false);
        dispatch(FetchActions.request(collect.id));
      } catch (err) {
        notify("error", `${err}`);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchJustifications();
  }, []);

  useEffect(() => {
    scroll();
  }, [isOpenCreate, isOpenCancel]);

  if (!collect) {
    return <></>;
  }

  return (
    <>
      <S.Container>
        {isOpenCreate && <CreateJustification />}
        {isOpenCancel && <CancelCollect />}
        <S.Header>
          Timeline de Justificativas
          <S.Button
            disabled={collect.deletedAt || collect.finishDate || !hasPermissionTo(['REVERSE-LOGISTICS.CREATEJUSTIFICATION'])}
            onClick={() => setIsOpenCreate(true)}
          >
            <S.PlusIcon />
            Adicionar
          </S.Button>
        </S.Header>
        <ListJustifications />
      </S.Container>
      {(hasPermissionTo(['REVERSE-LOGISTICS.CANCELAR-ORDEM-DE-COLETA']) ||  hasPermissionTo(['REVERSE-LOGISTICS.FINALIZAR-ORDEM-DE-COLETA'])) && 
       (
        <S.Cancel>
            {
                hasPermissionTo(['REVERSE-LOGISTICS.CANCELAR-ORDEM-DE-COLETA']) && (    
                <S.BtnReject
                    // disabled={collect.deletedAt || collect.finishDate}
                    onClick={() => setIsOpenCancel(true)}
                >
                    <S.RejectIcon />
                    Cancelar ordem de coleta
                </S.BtnReject>)
            }  

            {
                hasPermissionTo(['REVERSE-LOGISTICS.FINALIZAR-ORDEM-DE-COLETA']) &&    
                <S.BtnFinish
                    // disabled={collect.deletedAt || collect.finishDate}
                    onClick={() => handleFinishCollect(collect.id)}
                >
                    <S.CheckIcon />
                    Finalizar ordem de coleta
                </S.BtnFinish>
            }
        
            </S.Cancel>
        )
        }
    </>
  );
};
