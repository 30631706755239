import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListAuditorsActions as MainActions } from 'store/ducks/auditors';
import { Formatter } from 'utils';

export const useAuditors = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: auditors, loading: loadingAuditors } = useSelector(
    (state: RootState) => state.listAuditors
  );

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchAuditors = useCallback((): void => {
    dispatch(MainActions.request());
  }, [dispatch]);

  const onAuditorsLoad = useCallback((): void => {
    setOptions(
      auditors.map(({ id, auditorName }) => ({
        value: id,
        label: `${auditorName}`,
      }))
    );
  }, [auditors]);

  useEffect(() => {
    onAuditorsLoad();
  }, [onAuditorsLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    auditors,
    auditorOptions: options,
    loadingAuditors,
    fetchAuditors,
  };
};

export type AuditorsHook = ReturnType<typeof useAuditors>;
