import styled from 'styled-components';
import { Colors } from 'styles/constants';

export const PageContainer = styled.div`
  display: flex;
  background: ${Colors.Gray20};
`;

export const Backdrop = styled.div`
  width: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 1920px;
  padding: 24px;
  width: 100%;
  margin: 0 auto;
  gap: 24px;
`;

export const LeftPanelSlot = styled.div`
  width: min-content;
`;

export const MainSlot = styled.div`
  display: flex;
  flex: 0 1 100%;
  flex-direction: column;
  overflow: hidden;
`;

export const ChildrenSlot = styled.main`
  /* animation: ContentSlideIn 1000ms ease forwards; */
`;
