import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  HiddenInput,
  Input,
  MaskedInput,
  Select,
  FormPageHeader,
  Textarea,
} from 'components/Shared';
import { FORM_BACK_ACTION, SELECT_OPTIONS } from 'constants/Common';
import type { SelectOption } from 'contracts/Common';
import type { Auditor } from 'contracts/Auditors';
import { useAddressLookup, useCountries, useValidation } from 'hooks';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import {
  FetchAuditorActions as FetchActions,
  UpdateAuditorActions as UpdateActions,
} from 'store/ducks/auditors';
import { Formatter } from 'utils';
import { UpdateAuditorValidator } from 'validators/Auditors';
import * as S from './styles';

interface Props {
  auditorId: string | number;
  onUpdate?: () => void;
}

export const AuditorUpdateForm: React.FC<Props> = ({ auditorId, onUpdate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { onZipcodeChange, fetchingAddress } = useAddressLookup(formRef);
  const { countryOptions, loadingCountries, fetchCountries } = useCountries();

  const [selectedCountryId, setSelectedCountryId] = useState<number>(1);
  const [logoFile] = useState<File | undefined>();

  const { data: auditor, loading: fetchingAuditor } = useSelector(
    (state: RootState) => state.fetchAuditor
  );

  const { loading: updatingAuditor, validationErrors } = useSelector(
    (state: RootState) => state.updateAuditor
  );

  const fetchAuditor = useCallback((): void => {
    dispatch(FetchActions.request(auditorId));
  }, [dispatch, auditorId]);

  const onAuditorLoad = useCallback((): void => {
    if (!auditor) return;
    const { countryId, addressUf } = auditor;

    const autoSet: Array<keyof Auditor> = [
      'auditorName',
      'auditorOrgName',
      'auditorPhoneNumber',
      'auditorEmail',
      'addressStreet',
      'addressNumber',
      'addressComplement',
      'addressNeighborhood',
      'addressCity',
      'addressState',
      'addressZipcode',
      'addressLatitude',
      'addressLongitude',
      'particularities'
    ];

    for (const [field, value] of Object.entries(auditor)) {
      if (autoSet.includes(field as keyof Auditor)) {
        formRef.current?.setFieldValue(field, value);
      }
    }

    // manually set fields
    const countryOption = countryOptions.find((o) => o.value === countryId);

    setSelectedCountryId(countryId);
    if (countryOption) {
      formRef.current?.setFieldValue('countryId', countryOption);
    }

    const ufOption = SELECT_OPTIONS.STATES.find((o) => o.value === addressUf);

    if (ufOption) {
      formRef.current?.setFieldValue('addressUf', ufOption);
    }
  }, [auditor, countryOptions]);

  const onCountryChange = useCallback((option: SelectOption | null): void => {
    if (!option) return;
    setSelectedCountryId(option.value as number);
  }, []);

  const onUfChange = useCallback((option: SelectOption | null): void => {
    if (!option) {
      formRef.current?.setFieldValue('addressState', '');
      return;
    }
    formRef.current?.setFieldValue('addressState', option.label);
  }, []);

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onUpdate && onUpdate();
  }, [onUpdate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});
        Object.assign(data, { logoFile });

        const { schema } = new UpdateAuditorValidator({
          selectedCountryId,
        });

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(UpdateActions.request(auditorId, validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      auditorId,
      dispatch,
      handleFormErrors,
      logoFile,
      onSuccess,
      selectedCountryId,
    ]
  );

  const Header = useCallback((): JSX.Element => {
    return (
      <FormPageHeader
        title="Editar fiscal"
        icon={<S.CompanyIcon />}
        isLoading={fetchingAuditor}
        actions={
          <S.LinkButton size="small" to="/configuracoes/fiscais">
            <S.ArrowLeftIcon /> Voltar
          </S.LinkButton>
        }
      />
    );
  }, [fetchingAuditor]);

  useEffect(() => {
    fetchCountries({ excludeBlocked: true });
  }, [fetchCountries]);

  useEffect(() => {
    fetchAuditor();
  }, [fetchAuditor]);

  useEffect(() => {
    onAuditorLoad();
  }, [onAuditorLoad]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(FetchActions.reset());
      dispatch(UpdateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <Header />
      <S.Content>
        <Form ref={formRef} onSubmit={onSubmit}>
          <HiddenInput name="ibge" readOnly />

          <S.FormRow>
            <Input name="auditorName" label="Nome" />
            <Input name="auditorOrgName" label="Orgão fiscalizador" />
          </S.FormRow>

          <S.FormRow>
            <Input name="auditorPhoneNumber" label="Telefone" />
            <Input name="auditorEmail" label="Email" />
          </S.FormRow>

          <S.FormRow>
            <Select
              name="countryId"
              label="País"
              options={countryOptions}
              isLoading={loadingCountries}
              onChange={onCountryChange}
            />
            <Input
              name="addressZipcode"
              label="Código postal"
              onChange={onZipcodeChange}
              isLoading={fetchingAddress}
            />
            <Input name="addressStreet" label="Logradouro" />
            <Input name="addressNumber" label="Número" />
          </S.FormRow>

          <S.FormRow>
            <Input name="addressComplement" label="Complemento" />
            <Input name="addressNeighborhood" label="Bairro" />
            <Input name="addressCity" label="Cidade" />
          </S.FormRow>

          <S.FormRow>
            {(selectedCountryId === 1 && (
              <>
                <HiddenInput name="addressState" readOnly />
                <Select
                  name="addressUf"
                  label="Estado"
                  options={SELECT_OPTIONS.STATES}
                  onChange={onUfChange}
                />
              </>
            )) || <Input name="addressState" label="Estado" />}
            <Input name="addressLatitude" label="Latitude" />
            <Input name="addressLongitude" label="Longitude" />
          </S.FormRow>
          <S.FormRow>
            <Textarea name="particularities" label="Particularidades"/>
          </S.FormRow>
          <S.FormActions>
            <S.LinkButton mood="light" to="/configuracoes/fiscais">
              {FORM_BACK_ACTION}
            </S.LinkButton>
            <S.Button type="submit">
              {updatingAuditor ? <S.ActivityIndicator /> : 'Salvar'}
            </S.Button>
          </S.FormActions>
        </Form>
        {/* <LogoCropper
          label="Logo do cliente"
          helpText="Uma imagem de ao menos 320 x 320px"
          onChange={(file) => setLogoFile(file)}
          preview={client?.logoUrl}
        /> */}
      </S.Content>
    </S.MainPanel>
  );
};
