import React from 'react';
import * as S from './styles';

interface Props extends React.PropsWithChildren<{}> {
  width?: 'full'
}

export const GuestScaffold: React.FC<Props> = ({ children, width }) => {
  return (
    <S.PageContainer>
      <S.Backdrop />
      <S.ChildrenContainer style={{ width: width === 'full' ? '100%' : undefined }}>{children}</S.ChildrenContainer>
    </S.PageContainer>
  );
};
