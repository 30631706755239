import * as React from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table';
import { DataTablePagination } from './data-table-pagination';
import { DataTableViewOptions } from './data-table-view-options';
import { Input } from '../input';
import { ExportExcel } from '../ExportExcel';
import { Skeleton } from './data-table-skeleton';
import { Button } from '../ButtonGs';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  filterBy?: string;
  exportTable?: boolean;
  isLoading?: boolean;
  showControlColumns?: boolean;
  pageSize?: number;
  selecteble?: boolean;
  selectionAction?: (data: any) => void;
  selectionActionLabel?: string;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  filterBy,
  exportTable = false,
  isLoading = false,
  showControlColumns = true,
  pageSize = 10,
  selecteble = false,
  selectionAction,
  selectionActionLabel,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: pageSize,
      },
    },
  });

  if (isLoading) {
    return <Skeleton />;
  }

  if (!data.length) {
    return (
      <div className="flex items-center justify-center h-24 text-center font-sans">
        Sem resultados.
      </div>
    );
  }

  return (
    <div>
      <div className="w-full mb-4 flex flex-col md:flex-row gap-3 items-center justify-between">
        {filterBy && (
          <Input
            placeholder={`Filtrar ${filterBy.toLowerCase()} ...`}
            value={
              (table.getColumn(filterBy)?.getFilterValue() as string) ?? ''
            }
            onChange={(event) =>
              table.getColumn(filterBy)?.setFilterValue(event.target.value)
            }
            className="max-w-sm focus-visible:ring-primary-500"
          />
        )}
        {selectionAction && (
          <Button.Root
            onClick={() => selectionAction(table.getFilteredSelectedRowModel())}
            size="sm"
            disabled={!table.getFilteredSelectedRowModel().rows.length}
          >
            {selectionActionLabel ?? 'Action'} (
            {table.getFilteredSelectedRowModel().rows.length}/
            {table.getFilteredRowModel().rows.length} )
          </Button.Root>
        )}
        <div className="flex flex-1 gap-3 w-full justify-end">
          {exportTable && <ExportExcel data={data} />}
          {showControlColumns && <DataTableViewOptions table={table} />}
        </div>
      </div>
      <div className="rounded-md border bg-white">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="text-xs font-sans">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center font-sans"
                >
                  Sem resultados.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-start space-x-2 py-4">
        {selecteble && (
          <div className="flex-1 text-sm text-muted-foreground">
            {table.getFilteredSelectedRowModel().rows.length} of{' '}
            {table.getFilteredRowModel().rows.length} linha(s) selecionada(s).
          </div>
        )}
        <DataTablePagination table={table} />
      </div>
    </div>
  );
}
