import styled from "styled-components"
import { Colors, Fonts, getScrollbarStyle } from "styles/constants"
export { CloseIcon } from "styles/components"

export const ModalContent = styled.div.attrs({
  className: "modal-content",
})`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-self: stretch;
  max-height: calc(100vh - 4rem);
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  @media screen and (min-width: 800px) {
    max-width: 460px;
  }
`

export const ModalContainer = styled.section`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  color: ${Colors.Gray70};
  overflow-y: auto;
  ${getScrollbarStyle()};
  gap: 10px;
`
export const ModalHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  border-bottom: 1px solid ${Colors.Gray40};
  margin-bottom: 32px;

  div.wrapper {
    display: flex;
    column-gap: 8px;
  }
`

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Gray70};
`
export const ModalHeaderTitle = styled.h1`
  font-family: ${Fonts.GilroyBold};
  font-size: 18px;
  line-height: 22px;
`

export const ModalBody = styled.div`
  font-family: ${Fonts.GilroyRegular};
  font-size: 18px;
  line-height: 22px;
`

export const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`
