import styled from "styled-components";
import { Header } from '../../../Invoices/TrackingDetails/styles';
import { Colors, Fonts } from 'styles/constants';
import { BasePanel } from 'styles/components';
export { 
  Title, 
  Invoice, 
  InvoiceIcon, 
  Box, 
  BoxContent, 
  BoxHeader, 
  BoxBody, 
  CompanyIconOutline, 
  ClientIcon, 
  ArrowLeftIcon, 
  Back,
  EditAltIcon,
  Button
} from '../../../Invoices/TrackingDetails/styles';
export { Detail } from '../../../Invoices/TrackingDetails/Boxes/styles';
export * from '../Boxes/styles'

//Header Info
export const HeaderInfo = styled(BasePanel)`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1.5fr) repeat(4, 1fr) 0.5fr;
  gap: 16px;
  width: 100%;
  padding: 15px;
  font-size: 14px;
  font-family: ${Fonts.GilroySemiBold};
  margin-bottom: 24px;
  white-space: nowrap;
  @media (max-width: 920px){
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-family: ${Fonts.GilroySemiBold};
  gap: 5px;
  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;