import React, { useCallback, useState } from "react"

import * as S from "./styles"
import { Company } from "contracts/Company"
import ConfirmDeleteModal from "../ConfirmDeleteModal"
import { useDispatch } from "react-redux"
import { DeleteWarehouseCompanyMemberActions } from "store/ducks/warehouseMembers"

interface ItemProps {
  company: Company
}

interface MemberWarehouseListProps {
  userId: number | null
  companies: Company[]
  refresh: () => void
}

export const MemberWarehouseList = ({
  userId,
  refresh,
  companies,
}: MemberWarehouseListProps) => {
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [companyId, setCompanyId] = useState<number | null>(null)
  const dispatch = useDispatch()

  const removeCompany = (id: number) => {
    setIsRemoveModalOpen(true)
    setCompanyId(id)
  }

  const onSuccess = () => {
    setCompanyId(null)
    setIsRemoveModalOpen(false)
    refresh()
  }

  const confirmRemoveCompany = () => {
    if (companies.length === 1) return
    if (!userId) return
    dispatch(
      DeleteWarehouseCompanyMemberActions.request(companyId, userId, onSuccess)
    )
  }

  const Item = useCallback(({ company }: ItemProps): JSX.Element => {
    const { id, tradeName } = company

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column title={tradeName}>{tradeName}</S.Column>
        <S.ActionsColumn>
          <S.ActionButton
            mood="void"
            title="Fazer login como este usuário"
            onClick={() => removeCompany(id)}
          >
            <S.TrashIcon />
          </S.ActionButton>
        </S.ActionsColumn>
      </S.ListItem>
    )
  }, [])

  return (
    <S.List>
      <ConfirmDeleteModal
        confirm={confirmRemoveCompany}
        cancel={() => setIsRemoveModalOpen(false)}
        onCloseButton={() => setIsRemoveModalOpen(false)}
        isOpen={isRemoveModalOpen}
      />
      <S.ListHeader>
        <S.Column>
          <span>ID</span>
        </S.Column>
        <S.Column>
          <span>ARMAZÉM</span>
        </S.Column>
        <S.Column></S.Column>
      </S.ListHeader>
      {companies.map((company) => (
        <Item key={company.id.toString()} company={company} />
      ))}
    </S.List>
  )
}
