import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  JustificationUpdatePage,
  JustificationsListPage,
  JustificationCreationPage,
} from "pages/ReverseLogistics/Configuration/Justifications";
import {
  ReasonsListPage,
  ReasonCreationPage,
  ReasonUpdatePage,
} from "pages/ReverseLogistics/Configuration/Reasons";
import {
  ClassificationsListPage,
  ClassificationCreationPage,
  ClassificationUpdatePage,
} from "pages/ReverseLogistics/Configuration/Classifications";

const reverseLogisticsConfigurationRoutes = ({
  location,
  userBelongsToAnyOf,
  hasPermissionTo
}: RouterProps): RouteObject => ({
  path: 'configuracoes/logistica-reversa',
  element: hasPermissionTo(['REVERSE-LOGISTICS-CONFIGURATIONS-JUSTIFICATIONS.FINDJUSTIFICATIONS', 'REASONS.FINDREASONS', 'CLASSIFICATIONS.FINDCLASSIFICATIONS']) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: 'justificativas',
      element: (
        <JustificationsListPage />
      ),
    },
    {
      path: 'justificativas/criar',
      element: (
        <JustificationCreationPage />
      ),
    },
    {
      path: 'justificativas/:id/editar',
      element: (
        <JustificationUpdatePage />
      ),
    },
    {
      path: 'motivos',
      element: <ReasonsListPage />,
    },
    {
      path: 'motivos/criar',
      element: <ReasonCreationPage />,
    },
    {
      path: 'motivos/:id/editar',
      element: <ReasonUpdatePage />,
    },
    {
      path: 'classificacoes',
      element: <ClassificationsListPage />,
    },
    {
      path: 'classificacoes/criar',
      element: <ClassificationCreationPage />,
    },
    {
      path: 'classificacoes/:id/editar',
      element: <ClassificationUpdatePage />,
    },
  ],
});

export default reverseLogisticsConfigurationRoutes;
