import { UserType } from 'contracts/Auth';
import { useAuth } from 'hooks';
import React from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './styles';
import { usePermission } from 'hooks/usePermission';

interface RouteBase {
  title: string
  path: string
  blockedRoles?: UserType[]
}

export const Navigation = () => {
  const location = useLocation();
//   const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission()

  const rootPath = '/logistica-reversa/coletas';

  const routesBase: RouteBase[] = [
    { 
      title: 'Coleta de interditados', 
      path: '',
    },
  ]

  const isActive = (path: string) => path === location.pathname

  const routes = routesBase.filter(
    item => !item.blockedRoles || !hasPermissionTo(item.blockedRoles)
  )

  return (
    <S.Container>
      {routes.map(route => (
        <S.Button
          key={route.path}
          to={`${rootPath}${route.path}`}
          className={isActive(`${rootPath}${route.path}`) ? 'actived': ''}
        >
          {route.title}
        </S.Button>
      ))}
    </S.Container>
  );
};
