import styled from "styled-components";
import { Colors } from "styles/constants";

import { TrashIcon as Trash } from "styles/components";

export const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.Orange};
  padding: 4px;
  border-radius: 50%;
  aspect-ratio: 1;
  transition: all 300ms;

  &.hidden {
    display: none;
  }

  &:hover {
    background: ${Colors.Orange20}; 
    transform: scale(1.05);
  }

  button {
    display: flex;
  }
`;

export const TrashIcon = styled(Trash)`
  color: ${Colors.White};
  path{
    width: 20px;
    height: 20px;
  }
`
