import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { PaginateTransitTimesActions as PaginateActions } from 'store/ducks/transitTime';
import { usePaginationCache } from 'hooks';
import { Scaffold } from 'layouts';
import {
  TransitTimesList,
  TransitTimeFilters,
  FindTransitTimes,
} from 'components/Pages/TransitTimes';
import { ListingPageHeader, Paginator, SettingsMenu } from 'components/Shared';
import * as S from './styles';

export const TransitTimesListPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { paginationCache, updatePaginationCache, handleFilter, handleSort } =
    usePaginationCache('TransitTimes');

  const {
    data: transitTimes,
    pagination,
    loading,
  } = useSelector((state: RootState) => state.paginateTransitTimes);

  const [query, setQuery] = useState<FindTransitTimes>({
    search: '',
    page: 1,
    limit: 10,
    ...paginationCache,
  });

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback((): void => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
      updatePaginationCache(query);
    };
  }, [dispatch, query, updatePaginationCache]);

  return (
    <Scaffold>
      <SettingsMenu />
      <S.MainPanel>
        <ListingPageHeader
          icon={<S.TimerIcon />}
          title="Transit Time"
          isLoading={loading}
          actions={
            <S.LinkButton to={'/configuracoes/transit-time/criar'} size="small">
              <S.PlusIcon /> Novo Transit Time
            </S.LinkButton>
          }
        />
        <TransitTimeFilters
          currentFilter={query}
          onFilter={(filter) => handleFilter(query, filter, setQuery)}
        />
        <TransitTimesList
          transitTimes={transitTimes}
          currentSort={query}
          onSort={(sort) => handleSort(query, sort, setQuery)}
        />
        <Paginator onPageChange={onPageChange} pagination={pagination} />
      </S.MainPanel>
    </Scaffold>
  );
};
