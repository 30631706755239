import * as Yup from "yup";

export const getProductShape = () => ({
  operationTypeId: Yup.number()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
  carrierCnpj: Yup.string()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
  ibgeOrigin: Yup.number()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
  ibgeDestiny: Yup.number()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
  cityOriginId: Yup.number()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
  cityDestinyId: Yup.number()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
  countDay: Yup.string().nullable(),
  countDedicatedDay: Yup.string().nullable(),
  hourCut: Yup.string().nullable(),
  startDays: Yup.number().nullable().typeError("Campo obrigatório"),
  weight: Yup.number()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
  fractionalDays: Yup.number()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
  dedicatedDays: Yup.number()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
  validUntil: Yup.string()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
  carrierId: Yup.string()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
});
