import styled from 'styled-components';
import { BasePanel } from 'styles/components';
import { Colors } from 'styles/constants';
export {
  ActivityIndicator,
  ArrowLeftIcon,
  Button,
  FactoryIcon,
  FormActions,
  FormRow,
  LinkButton,
} from 'styles/components';

export const MainPanel = styled(BasePanel)`
  flex: 0 1 100%;
  flex-direction: column;
  background: ${Colors.White};
  padding: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex: 1 0 100%;
  form {
    width: 100%;
  }
`;
