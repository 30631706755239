import React, {
    ForwardRefRenderFunction,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import * as S from './styles';
import { Form } from '@unform/web';
import { Modal, Select } from 'components/Shared';
import { Loading } from 'components/Shared/Loading';
import { FormHandles } from '@unform/core';
import { useDispatch } from 'react-redux';

import { CreateInvoiceJustificationValidator as CreateValidator } from 'validators/InvoiceJustification';
import { CreateInvoiceJustificationActions as CreateActions } from 'store/ducks/invoiceJustifications';
import {
    useInvoiceJustifications,
    useJustifications,
    useValidation,
} from 'hooks';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

type ModalNewJustificationProps = {
    invoice: any;
};

export type ModalNewJustificationHandles = {
    open: () => void;
    close: () => void;
};

const ModalNewJustification: ForwardRefRenderFunction<
    ModalNewJustificationHandles,
    ModalNewJustificationProps
> = ({ invoice }, ref) => {
    const formRef = useRef<FormHandles>(null);
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch();

    const { handleFormErrors, handleApiErrors } = useValidation();
    const { fetchInvoiceJustifications } = useInvoiceJustifications();
    const { justificationOptions, fetchJustifications, loadingJustifications } =
        useJustifications();

    const { loading, validationErrors } = useSelector(
        (state: RootState) => state.createInvoiceJustification
    );

    const handleFetchJustifications = useCallback(() => {
        fetchInvoiceJustifications(invoice?.id);
    }, [invoice, fetchInvoiceJustifications]);

    const onSubmit = useCallback(
        async (data: any): Promise<void> => {
            try {
                formRef?.current?.setErrors({});
                const create = { ...data, invoiceId: invoice?.id };
                const { schema } = new CreateValidator();
                const validData = await schema.validate(create, {
                    abortEarly: false,
                });

                dispatch(
                    CreateActions.request(validData, handleFetchJustifications)
                );
            } catch (error) {
                handleFormErrors(error, formRef);
            }
        },
        [dispatch, handleFormErrors, invoice]
    );

    useEffect(() => {
        fetchJustifications();
    }, [invoice]);

    useEffect(() => {
        handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    useImperativeHandle(
        ref,
        () => ({
            open: () => setIsOpen(true),
            close: () => setIsOpen(false),
        }),
        []
    );

    return (
        <S.ModalBackground isOpen={isOpen}>
            <S.ModalContainer>
                <S.ModalTitle>
                    <S.CommentIcon />
                    Adicionar Justificativa
                    <S.Close onClick={() => setIsOpen(false)} />
                </S.ModalTitle>
                <Form ref={formRef} onSubmit={onSubmit}>
                    <Select
                        name="justificationId"
                        label="Justificativa"
                        options={justificationOptions}
                        isLoading={loadingJustifications}
                    />
                    <S.TextArea name="description" label="Comentário" />
                    <S.FormRow>
                        <S.FormButton type="reset" onClick={() => setIsOpen(false)}>
                            Voltar
                        </S.FormButton>
                        <S.FormButton type="submit" theme="accept">
                            {loading && <Loading />}
                            Salvar
                        </S.FormButton>
                    </S.FormRow>
                </Form>
            </S.ModalContainer>
        </S.ModalBackground>
    );
};

export default forwardRef(ModalNewJustification);
