import { TransitTimeUpdateForm } from 'components/Pages/TransitTimes';
import { Scaffold } from 'layouts';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const TransitTimeUpdatePage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();

  const onUpdate = useCallback((): void => {
    navigate('/configuracoes/transit-time');
  }, [navigate]);

  return (
    <Scaffold>
      <TransitTimeUpdateForm transitTimeId={id} onUpdate={onUpdate} />
    </Scaffold>
  );
};
