import React, {  useEffect } from "react";
import { Scaffold } from "layouts";
import * as S from "./styles";


import { useNavigate } from "react-router-dom";
import { usePermission } from "hooks/usePermission";
import { InvoiceDetails } from "components/Pages/Invoices/InvoiceDetails";

export const PendingRelease: React.FC = () => {
//   const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission()
  const navigate = useNavigate();

  useEffect(() => {
    // if (hasPermissionTo(["CARRIER-MEMBERS.FINDMEMBERS", "CARRIER-MEMBERS.FINDMEMBER"])) {
    //   navigate("/inbound/notas-fiscais/tracking");
    // }

    if (!hasPermissionTo(["ADMINS.FINDADMINS", "ADMINS.FINDADMIN", "WAREHOUSE_MEMBERS.INDEX", "WAREHOUSE_MEMBERS.SHOW"])) {
          navigate("/inbound/notas-fiscais/tracking");
        }
  }, [navigate, hasPermissionTo]);

  return (
    <Scaffold>
      <S.Container>
        <InvoiceDetails />
      </S.Container>
    </Scaffold>
  );
};
