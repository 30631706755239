import { Form } from "@unform/web";
import styled from "styled-components";

export const ContainerFilter = styled(Form)`
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  width: 80%;

  > div {
    width: 13rem;
    margin-bottom: 0;
  }

  > div.operation {
    width: 10rem;
  }
`