import React, { useCallback, useMemo } from "react";
import * as S from "./styles";
import { useLocation } from "react-router";
import { useAuth } from "hooks";
import { RootState } from "store";
import { useSelector, useDispatch } from "react-redux";
import { setRecall } from "store/ducks/leftPanel/recall";
import { usePermission } from "hooks/usePermission";

interface MenuItem {
  icon: React.ReactNode;
  title: string;
  info?: string;
  to: string;
  href?: string
  isAllowed: boolean;
}

const LeftPanel: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { userBelongsToAnyOf, profile } = useAuth();
  const { hasPermissionTo } = usePermission()
  const { data: recall } = useSelector((state: RootState) => state.recallSlice);
  const isCarrierMember = hasPermissionTo(['CARRIER-MEMBERS.FINDMEMBERS', 'CARRIER-MEMBERS.FINDMEMBER']);

  const handleCollapse = useCallback(
    () => dispatch(setRecall(!recall)),
    [recall]
  );

  const menuItems = useMemo((): MenuItem[] => {
    return [
      {
        icon: <S.BarChartIcon />,
        title: "Dashboard",
        info: "Gerencie seus indicadores.",
        to: "/dashboard",
        isAllowed: isCarrierMember
      },
      {
        icon: <S.InvoiceIcon />,
        title: "Notas Fiscais",
        info: "Curadoria das notas fiscais de importação e exportação.",
        to: "/",
        isAllowed: true,
      },
      {
        icon: <S.UserPinIcon />,
        title: "Meus dados",
        info: "Alter seus dados de acesso ao sistema.",
        to: "/meus-dados",
        isAllowed: true,
      },
      {
        icon: <S.CalendarIcon />,
        title: "Comex",
        info: "Acessar o comex.",
        to: "https://comex.webcol.gerasinergia.com.br",
        href: "https://comex.webcol.gerasinergia.com.br",
        isAllowed: true,
      },
      {
        icon: <S.BarChartIcon />,
        title: "Toller",
        info: "Acessar o Toller.",
        to: "https://toller.webcol.gerasinergia.com.br/",
        href: "https://toller.webcol.gerasinergia.com.br/",
        isAllowed: true,
      },
      {
        icon: <S.ReverseLogisticsIcon />,
        title: 'Logística Reversa',
        info: 'Gerenciamento do processo de coleta de interditados.',
        to: '/logistica-reversa/coletas',
        // isAllowed: !isCarrierMember || profile?.userId === 91,
        isAllowed: true,
      },
      {
        icon: <S.SettingsIcon />,
        title: "Configurações",
        info: "Acesso exclusivo para administradores do sistema.",
        to: "/configuracoes",
        isAllowed: hasPermissionTo(['ADMINS.CONFIGS']),
      },
    ];
  }, [isCarrierMember, profile?.userId, userBelongsToAnyOf]);

  const isMenuActive = useCallback(
    (item: MenuItem): boolean => {
      return item.to.split("/")[1] === location.pathname.split("/")[1];
    },
    [location]
  );

  return (
    <S.Container>
      <S.CollapseButton onClick={handleCollapse} recall={recall}>
        <S.ArrowIcon />
      </S.CollapseButton>
      <S.LeftPanel recall={recall}>
        <S.Header>
          <S.Logo>
            <S.LogoImage src={require("assets/images/light-logo.png")} />
          </S.Logo>
        </S.Header>
        <S.NavigationMenu>
          {menuItems
            .filter(({ isAllowed }) => {
              return isAllowed;
            })
            .map((item, index) => (
                item.href ? (
                <S.MenuHrefItem href={item.href} target="_blank">
                    <S.MenuItemHeader>
                      {item.icon} <p>{item.title}</p>
                    </S.MenuItemHeader>
                    {item?.info && <S.MenuItemBody>{item.info}</S.MenuItemBody>}
                </S.MenuHrefItem>
                ) : (
                    <S.MenuItem
                        to={item.to}
                        key={index}
                        active={isMenuActive(item) ? true : undefined}
                    >
                        <S.MenuItemHeader>
                        {item.icon} <p>{item.title}</p>
                        </S.MenuItemHeader>
                        {item?.info && <S.MenuItemBody>{item.info}</S.MenuItemBody>}
                    </S.MenuItem>
                )
            ))}
        </S.NavigationMenu>
        <S.Footer>
          {!recall && (
            <p>{new Date().getFullYear()} © Todos os direitos reservados</p>
          )}
        </S.Footer>
      </S.LeftPanel>
    </S.Container>
  );
};

export default LeftPanel;
