import { call, put } from '@redux-saga/core/effects';
import { api, notify, formDataBuilder, apiErrorHandler } from 'services';
import {
  CreateClientActions as Actions,
  CreateClientRequestAction as RequestAction,
} from 'store/ducks/clients';

export function* createClientRequest(action: any) {
  const { postData, onSuccess, onFailure }: RequestAction = action;
  try {
    const formData = formDataBuilder(postData);
    yield call(api.post, `clients`, formData);
    onSuccess && onSuccess();
    notify('success', 'Cliente criado com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
