import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Input } from 'components/Shared';
import { DialogContent } from 'components/Shared/Dialog'
import { PendingReleaseContext } from 'contexts/PendingReleaseContext'
import { useCallback, useContext, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { CalendarIcon } from 'styles/components';
import { Status } from 'components/Pages/Invoices';

import * as S from './styles';
import { Formatter } from 'utils';
import { UpdateInvoiceDeadlinePayload } from 'contracts/Invoice';
import { UpdateInvoiceDeadlineActions } from 'store/ducks/pendingRelease';

interface FormData {
  deadline: string;
}

const AddDeadlineModal = () => {
  const { 
    refModal, 
    selectedList, 
    onQueryChange, 
    clearSelectList,
    changeOpenModal
  } = useContext(PendingReleaseContext)
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch()

  const {
    loading
  } = useSelector((state: RootState) => state.updateInvoiceDeadline);

  const formattedList = useMemo(() => {
    return selectedList.map(invoice => ({
      id: invoice.id,
      nf: `${invoice.infnfeIdeNnf}-${invoice.infnfeIdeSerie}`,
      dhEmi: Formatter.date(invoice.infnfeIdeDhemi, { format: 'dd/MM/yyyy HH:mm' }),
      prev: invoice.deadlineDate 
        ? Formatter.date(invoice.deadlineDate, { format: 'dd/MM/yyyy HH:mm' }) 
        : '---',
      status: invoice.status.length > 0 ? ({
        name: invoice.status[0].name,
        color: invoice.status[0].color
      }): null
    }))
  }, [selectedList])

  const handleSubmit = useCallback<SubmitHandler>((data: FormData) => {
    const payloadData: UpdateInvoiceDeadlinePayload = {
      deadline: Formatter.dateToISO(data.deadline, { format: 'yyyy-MM-dd HH:mm' }),
      ids: selectedList.map(invoice => invoice.id)
    }

    dispatch(UpdateInvoiceDeadlineActions.request(payloadData, () => {
      onQueryChange({}, true)
      clearSelectList()
      changeOpenModal()
    }))
  }, [changeOpenModal, clearSelectList, dispatch, onQueryChange, selectedList])

  return (
    <DialogContent
      container={refModal}
      title="Adicionar prazo para as notas listadas abaixo"
      icon={<CalendarIcon />}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.Content>
          <S.ListHeader>
            <span>NF</span>
            <span>DT. Emissão</span>
            <span>P. Contratado</span>
            <span>Status</span>
          </S.ListHeader>
          {formattedList.map(invoice => (
            <S.ListItem key={invoice.id}>
              <span>{invoice.nf}</span>
              <span>{invoice.dhEmi}</span>
              <span>{invoice.prev}</span>
              <span>
              {invoice.status ? (
                <Status color={invoice.status.color}>{invoice.status.name}</Status>
              ) : (
                <Status>Sem status</Status>
              )}
              </span>
            </S.ListItem>
          ))}
        </S.Content>
        <S.Footer>
          <Input
            name="deadline"
            type="date"
            label="Novo prazo contratado"
          />
          <S.Button type="submit" disabled={loading}>
            {loading ? `Adicionando` : `Adicionar`}
          </S.Button>
        </S.Footer>
      </Form>
    </DialogContent>
  )
}

export { AddDeadlineModal }
