import * as Yup from 'yup';
import {
  getClientLogoShape,
  getClientShape,
  Props,
} from 'validators/Common/baseClientValidator';

export class CreateClientValidator {
  constructor(protected readonly props: Props) {}

  public get schema() {
    return Yup.object().shape({
      ...getClientShape(this.props),
      ...getClientLogoShape(),
    });
  }
}
