import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { PaginatedJustification } from 'contracts/Justifications';
import { SortingParams } from 'contracts/Common';
import React, { useCallback } from 'react';
import * as S from './styles';

interface Props {
  justifications: PaginatedJustification[];
  onSort?: (sort: SortingParams) => void;
  currentSort: SortingParams;
}

interface ItemProps {
  justification: PaginatedJustification;
}

export const JustificationsList: React.FC<Props> = ({
  justifications,
  onSort,
  currentSort,
}) => {
  const Item = useCallback(({ justification }: ItemProps): JSX.Element => {
    const { id, description } = justification;

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column>{description}</S.Column>
        <S.ActionsColumn>
          <S.LinkActionButton
            to={`/configuracoes/justificativas/${id}/editar`}
          >
            <S.EditIcon />
          </S.LinkActionButton>
        </S.ActionsColumn>
      </S.ListItem>
    );
  }, []);

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="description" label="DESCRIÇÃO" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {justifications.map((justification) => (
        <Item key={justification.id} justification={justification} />
      ))}
    </S.List>
  );
};
