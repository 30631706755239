import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  HiddenInput,
  Input,
  MaskedInput,
  Select,
  FormPageHeader,
  Textarea,
} from 'components/Shared';
import { FORM_BACK_ACTION, SELECT_OPTIONS } from 'constants/Common';
import type { SelectOption } from 'contracts/Common';
import { useAddressLookup, useCountries, useValidation } from 'hooks';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { CreateAuditorActions as CreateActions } from 'store/ducks/auditors';
import { CreateAuditorValidator } from 'validators/Auditors';
import * as S from './styles';

interface Props {
  onCreate?: () => void;
}

export const AuditorCreationForm: React.FC<Props> = ({ onCreate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { onZipcodeChange, fetchingAddress } = useAddressLookup(formRef);
  const { countryOptions, loadingCountries, fetchCountries } = useCountries();

  const [selectedCountryId, setSelectedCountryId] = useState<number>(1);

  const { loading: creatingAuditor, validationErrors } = useSelector(
    (state: RootState) => state.createAuditor
  );

  const onCountryChange = useCallback((option: SelectOption | null): void => {
    if (!option) return;
    setSelectedCountryId(option.value as number);
  }, []);

  const onUfChange = useCallback((option: SelectOption | null): void => {
    if (!option) {
      formRef.current?.setFieldValue('addressState', '');
      return;
    }
    formRef.current?.setFieldValue('addressState', option.label);
  }, []);

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onCreate && onCreate();
  }, [onCreate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});

        const { schema } = new CreateAuditorValidator({
          selectedCountryId,
        });

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(CreateActions.request(validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      dispatch,
      handleFormErrors,
      onSuccess,
      selectedCountryId,
    ]
  );

  const Header = useCallback((): JSX.Element => {
    return (
      <FormPageHeader
        title="Novo fiscal"
        icon={<S.CompanyIcon />}
        actions={
          <S.LinkButton size="small" to="/configuracoes/fiscais">
            <S.ArrowLeftIcon /> Voltar
          </S.LinkButton>
        }
      />
    );
  }, []);

  useEffect(() => {
    fetchCountries({ excludeBlocked: true });
  }, [fetchCountries]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(CreateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <Header />
      <S.Content>
        <Form ref={formRef} onSubmit={onSubmit}>
          <HiddenInput name="ibge" readOnly />
         
          <S.FormRow>
            <Input name="auditorName" label="Nome" />
            <Input name="auditorOrgName" label="Orgão fiscalizador" />
          </S.FormRow>

          <S.FormRow>
            <Input name="auditorPhoneNumber" label="Telefone" />
            <Input name="auditorEmail" label="Email" />
          </S.FormRow>

          <S.FormRow>
            <S.FormRow>
              {!!countryOptions.length && (
                <Select
                  name="countryId"
                  label="País"
                  options={countryOptions}
                  isLoading={loadingCountries}
                  onChange={onCountryChange}
                  defaultValue={countryOptions.find((o) => o.value === 1)}
                />
              )}
              <Input
                name="addressZipcode"
                label="Código postal"
                onChange={onZipcodeChange}
                isLoading={fetchingAddress}
              />
            </S.FormRow>
            <Input name="addressStreet" label="Logradouro" />
            <Input name="addressNumber" label="Número" />
          </S.FormRow>

          <S.FormRow>
            <Input name="addressComplement" label="Complemento" />
            <Input name="addressNeighborhood" label="Bairro" />
            <Input name="addressCity" label="Cidade" />
          </S.FormRow>

          <S.FormRow>
            {(selectedCountryId === 1 && (
              <>
                <HiddenInput name="addressState" readOnly />
                <Select
                  name="addressUf"
                  label="Estado"
                  options={SELECT_OPTIONS.STATES}
                  onChange={onUfChange}
                />
              </>
            )) || <Input name="addressState" label="Estado" />}
            <Input name="addressLatitude" label="Latitude" />
            <Input name="addressLongitude" label="Longitude" />
          </S.FormRow>
          <S.FormRow>
            <Textarea name="particularities" label="Particularidades"/>
          </S.FormRow>

          <S.FormActions>
            <S.LinkButton mood="light" to="/configuracoes/fiscais">
              {FORM_BACK_ACTION}
            </S.LinkButton>
            <S.Button type="submit">
              {creatingAuditor ? <S.ActivityIndicator /> : 'Salvar'}
            </S.Button>
          </S.FormActions>
        </Form>

      </S.Content>
    </S.MainPanel>
  );
};
