import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListClientsActions as MainActions } from 'store/ducks/clients';
import { Formatter } from 'utils';

export const useClients = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: clients, loading: loadingClients } = useSelector(
    (state: RootState) => state.listClients
  );

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchClients = useCallback((): void => {
    dispatch(MainActions.request());
  }, [dispatch]);

  const onClientsLoad = useCallback((): void => {
    setOptions(
      clients.map(({ id, tradeName, documentType, document }) => ({
        value: id,
        label: `${tradeName} - ${Formatter.document(document, documentType)}`,
      }))
    );
  }, [clients]);

  useEffect(() => {
    onClientsLoad();
  }, [onClientsLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    clients,
    clientOptions: options,
    loadingClients,
    fetchClients,
  };
};

export type ClientsHook = ReturnType<typeof useClients>;
