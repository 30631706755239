import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListReasonsActions as MainActions } from 'store/ducks/reasons';
import { Formatter } from 'utils';

export const useReasons = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: reasons, loading: loadingReasons } = useSelector(
    (state: RootState) => state.listReasons
  );

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchReasons = useCallback((): void => {
    dispatch(MainActions.request());
  }, [dispatch]);

  const onReasonsLoad = useCallback((): void => {
    setOptions(
      reasons.map(({ id, description }) => ({
        value: id,
        label: `${description}`,
      }))
    );
  }, [reasons]);

  useEffect(() => {
    onReasonsLoad();
  }, [onReasonsLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    reasons,
    reasonOptions: options,
    loadingReasons,
    fetchReasons,
  };
};

export type ReasonsHook = ReturnType<typeof useReasons>;
