import { Fonts } from 'styles/constants';
import { BasePanel } from 'styles/components';
import { BaseButtonStyle } from 'styles/components';
import { Colors } from 'styles/constants';
import styled from "styled-components";
import { Input } from "components/Shared";
import { Form } from "@unform/web";
import { Column } from '../Documents/styles';
export * from '../Boxes/styles'

export const Container = styled(BasePanel)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px 4px 20px;
  margin-bottom: 24px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px dashed ${Colors.Gray50};
  margin-bottom: 13px;
  padding-bottom: 13px;
  font-size: 14px;
  font-family: ${Fonts.GilroyRegular};
  gap: 15px;
  @media (max-width: 950px){
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const BoxTitle = styled(Column)`
  font-family: ${Fonts.GilroySemiBold};
`;

export const GridTitle = styled(BoxTitle)`
  font-size: 18px;
  text-transform: none;
`;

export const Button = styled.button`
  display: flex;
  ${BaseButtonStyle}
  background-color: ${Colors.Orange};
  color: ${Colors.White};
  font-family: ${Fonts.GilroySemiBold};
  font-size: 12px;
  padding: 3px 10px 3px 3px;
  justify-self: flex-end;
  border-radius: 4px;
  gap: 4px;
  white-space: nowrap;
`;

//Form
export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
`;

export const InputModal = styled(Input)`
  margin-bottom: 0;
`;

export const FormButton = styled(Button)<{theme?: string}>`
  background-color: ${({theme})=> theme === 'accept' ? Colors.Orange : Colors.Gray60};
  font-size: 12px;
  padding: 10px;
  margin-bottom: 16px;
`;