import { ListJustificationsActions as MainActions } from 'store/ducks/justifications';
import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { usePermission } from 'hooks/usePermission';

export const JUSTIFICATION_TYPES = {
  TRACKING: 1,
  WAREHOUSE: 2
}

interface FindJustifications {
  excludeBlocked?: boolean;
}

export const useJustifications = () => {
  const dispatch: AppDispatch = useDispatch();
  const { hasPermissionTo } = usePermission();
  const { data: justifications, loading: loadingJustifications } = useSelector(
    (state: RootState) => state.listJustifications
  );

  const [ignoredBlockedId, enableJustificationIfBlocked] = useState<number | null>(
    null
  );

  const fetchJustifications = useCallback(
    (query?: FindJustifications): void => {

      if(hasPermissionTo(['JUSTIFICATIONS.FINDALLINVOICEJUSTIFICATIONS'])){
        dispatch(MainActions.request({...query}));
      } else if (hasPermissionTo(['JUSTIFICATIONS.FINDTRACKINGJUSTIFICATIONS'])){
        dispatch(MainActions.request({ ...query, type: JUSTIFICATION_TYPES.TRACKING }));
      } else if (hasPermissionTo(['JUSTIFICATIONS.FINDWAREHOUSEJUSTIFICATIONS'])){
        dispatch(MainActions.request({ ...query, type: JUSTIFICATION_TYPES.WAREHOUSE }));
      }
    },
    []
  );

  const onJustificationsLoad = useMemo<SelectOption[]>(() => {
    return justifications.map(({ id, description }) => ({
      value: id,
      label: description,
    }))
  }, [justifications, ignoredBlockedId]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    justifications,
    justificationOptions: onJustificationsLoad,
    loadingJustifications,
    fetchJustifications,
    enableJustificationIfBlocked,
  };
};

export type JustificationsHook = ReturnType<typeof useJustifications>;
