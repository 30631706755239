import * as Yup from "yup"

export class AddWarehouseForMemberValidator {
  public get schema() {
    return Yup.object().shape({
      userId: Yup.number(),
      companyId: Yup.array()
        .typeError("Selecione a transportadora")
        .required("Selecione a transportadora"),
    })
  }
}
