import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Input, Select, FormPageHeader } from "components/Shared";
import { FORM_BACK_ACTION, SELECT_OPTIONS } from "constants/Common";
import type { SelectOption } from "contracts/Common";
import { TransitTimes } from "contracts/TransitTimes";
import { format } from "date-fns";
import { useCarriers, useCities, useValidation } from "hooks";
import { useTransitTimes } from "hooks/Selectors/transitTimes";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "store";
import { UpdateTransitTimeActions as UpdateActions } from "store/ducks/transitTime";
import { UpdateTransitTimeValidator } from "validators/TransitTimes";
import * as S from "./styles";

interface Props {
  onUpdate?: () => void;
  transitTimeId: string | number;
}

export const TransitTimeUpdateForm: React.FC<Props> = ({
  onUpdate,
  transitTimeId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const [carrierCnpj, setCarrierCnpj] = useState<string>();
  const [ibgeOrigin, setIbgeOrigin] = useState<number | null>();
  const [ibgeDestiny, setIbgeDestiny] = useState<number | null>();
  const [originOptions, setOrigin] = useState<SelectOption[]>();
  const [destinyOptions, setDestiny] = useState<SelectOption[]>();
  const { loadingCities, fetchCity } = useCities();
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { carrierOptions, loadingCarriers, fetchCarriers } = useCarriers();
  const { transitTime, loadingTransitTime, fetchTransitTime } =
    useTransitTimes();
  const { loading: updatingTransitTime, validationErrors } = useSelector(
    (state: RootState) => state.updateTransitTime
  );

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onUpdate && onUpdate();
  }, [onUpdate]);

  const onCarrierChange = useCallback((option: SelectOption | null): void => {
    if (!option) return;
    setCarrierCnpj(option.cnpj as string);
  }, []);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});
        const fullData = { ...data, carrierCnpj, ibgeOrigin, ibgeDestiny };
        const { schema } = new UpdateTransitTimeValidator();
        const validData = await schema.validate(fullData, {
          abortEarly: false,
        });
        dispatch(UpdateActions.request(transitTimeId, validData, onSuccess));
      } catch (error) {
        console.log("error", error);
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess, formRef, UpdateActions]
  );

  const Header = useCallback((): JSX.Element => {
    return (
      <FormPageHeader
        title="Editar Transit Time"
        icon={<S.TimerIcon />}
        isLoading={loadingTransitTime}
        actions={
          <S.LinkButton size="small" to="/configuracoes/transit-time">
            <S.ArrowLeftIcon /> Voltar
          </S.LinkButton>
        }
      />
    );
  }, []);

  const onTransitTimeLoad = useCallback(async () => {
    if (!transitTime) return;

    const autoSet: Array<keyof TransitTimes> = [
      "hourCut",
      "startDays",
      "weight",
      "fractionalDays",
      "dedicatedDays",
    ];

    for (const [field, value] of Object.entries(transitTime)) {
      if (autoSet.includes(field as keyof TransitTimes)) {
        formRef.current?.setFieldValue(field, value);
      }
      switch (field) {
        case "carrierCnpj":
          const carrierOption = carrierOptions.find((o) => {
            return o.cnpj === value.replace(/\s+/g, "").trim();
          });
          formRef.current?.setFieldValue("carrierId", carrierOption);

          break;
        case "countDay":
          const countDayOption = SELECT_OPTIONS.TYPE_DAY.find(
            (o) => o.value === value
          );
          formRef.current?.setFieldValue("countDay", countDayOption);
          break;
        case "countDedicatedDay":
          const countDedicated = SELECT_OPTIONS.TYPE_DAY.find(
            (o) => o.value === value
          );
          formRef.current?.setFieldValue("countDedicatedDay", countDedicated);
          break;
        case "operationTypeId":
          const operationTypeOption = SELECT_OPTIONS.OPERATION_TYPES.find(
            (o) => o.value === value
          );
          formRef.current?.setFieldValue(
            "operationTypeId",
            operationTypeOption
          );
          break;
        case "validUntil":
          try {
            const validDate = format(new Date(value), "yyyy-MM-dd");
            formRef.current?.setFieldValue("validUntil", validDate);
          } catch {
            formRef.current?.setFieldValue("validUntil", null);
          }
          break;
        case "ibgeOrigin":
          if (transitTime.cityOrigin) {
            const { id, ibge, name, uf } = transitTime.cityOrigin;
            const start = { value: id, label: name, uf, ibge: Number(ibge) };
            setIbgeOrigin(Number(ibge));
            setOrigin([start]);
            formRef.current?.setFieldValue("cityOriginId", start);

            const defaultUfOrigin = SELECT_OPTIONS.STATES.find(
              (o) => o.value === transitTime.cityOrigin?.uf
            );
            formRef.current?.setFieldValue("ufOrigin", defaultUfOrigin);
            onSelectUf(uf, "origin");
          }
          break;
        case "ibgeDestiny":
          if (transitTime.cityDestiny) {
            const { id, ibge, name, uf } = transitTime.cityDestiny;
            const end = { value: id, label: name, uf, ibge: Number(ibge) };
            setIbgeDestiny(Number(ibge));
            setDestiny([end]);
            formRef.current?.setFieldValue("cityDestinyId", end);

            const defaultUfDestiny = SELECT_OPTIONS.STATES.find(
              (o) => o.value === transitTime.cityDestiny?.uf
            );
            formRef.current?.setFieldValue("ufDestiny", defaultUfDestiny);
            onSelectUf(uf, "destiny");
          }
          break;
        default:
          break;
      }
    }
  }, [transitTime, carrierOptions]);

  const onSelectUf = useCallback(
    async (uf?: string, query?: "origin" | "destiny") => {
      if (query === "origin") {
        const cityOrigin = await fetchCity(uf);
        if (cityOrigin.data) {
          const optionOrigin = await cityOrigin.data.map(
            ({ id, ibge, name, uf }: any) => {
              if (transitTime)
                return {
                  value: id,
                  label: name,
                  uf: uf,
                  ibge: Number(ibge),
                };
            }
          );
          setOrigin(optionOrigin);
        }
      } else if (query === "destiny") {
        const cityDestiny = await fetchCity(uf);
        if (cityDestiny?.data) {
          const optionDestiny = await cityDestiny.data.map(
            ({ id, ibge, name, uf }: any) => ({
              value: id,
              label: name,
              uf: uf,
              ibge: Number(ibge),
            })
          );
          setDestiny(optionDestiny);
        }
      }
    },
    []
  );

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    fetchCarriers();
  }, [fetchCarriers]);

  useEffect(() => {
    fetchTransitTime(Number(transitTimeId));
  }, [fetchTransitTime]);

  useEffect(() => {
    onTransitTimeLoad();
  }, [onTransitTimeLoad]);

  useEffect(() => {
    return () => {
      dispatch(UpdateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <Header />
      <Form ref={formRef} onSubmit={onSubmit}>
        <S.FormRow>
          <Select
            name="carrierId"
            label="Transportadora"
            options={carrierOptions}
            onChange={onCarrierChange}
            isLoading={loadingCarriers}
          />
          <Input name="validUntil" label={"Validade"} type="date" />
          <Input name="hourCut" label={"Horário de corte"} type="time" />
          <Input name="startDays" label={"Dias iniciais"} type="number" />
        </S.FormRow>
        <S.FormRow>
          <Select
            name="countDay"
            label="Úteis/Corridos Fracionado"
            options={SELECT_OPTIONS.TYPE_DAY}
          />
          <Input
            name="fractionalDays"
            label="Contagem fracionado"
            type="number"
          />
          <Select
            name="countDedicatedDay"
            label="Úteis/Corridos Dedicado"
            options={SELECT_OPTIONS.TYPE_DAY}
          />
          <Input
            name="dedicatedDays"
            label={"Contagem dedicado"}
            type="number"
          />
        </S.FormRow>
        <S.FormRow>
          <Select
            name="ufOrigin"
            label="UF"
            options={SELECT_OPTIONS.STATES}
            isLoading={loadingCities}
            onChange={(e) => {
              onSelectUf(String(e?.value), "origin");
            }}
          />
          <Select
            name="cityOriginId"
            label="Cidade origem"
            options={originOptions}
            isLoading={loadingCities}
            isDisabled={!originOptions}
            onChange={(e) => setIbgeOrigin(e?.ibge)}
          />
          <Select
            name="ufDestiny"
            label="UF"
            options={SELECT_OPTIONS.STATES}
            isLoading={loadingCities}
            onChange={(e) => {
              onSelectUf(String(e?.value), "destiny");
            }}
          />
          <Select
            name="cityDestinyId"
            label="Cidade destino"
            options={destinyOptions}
            isLoading={loadingCities}
            isDisabled={!destinyOptions}
            onChange={(e) => setIbgeDestiny(e?.ibge)}
          />
        </S.FormRow>
        <S.FormRow>
          <Select
            name="operationTypeId"
            label="Tipo de operação"
            options={SELECT_OPTIONS.OPERATION_TYPES}
          />
          <Input name="weight" label="Peso KG" type="number" />
        </S.FormRow>
        <S.FormActions>
          <S.LinkButton mood="light" to="/configuracoes/transit-time">
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit" disabled={updatingTransitTime}>
            {updatingTransitTime ? <S.ActivityIndicator /> : "Salvar"}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.MainPanel>
  );
};
