import { ClassificationCreationForm } from 'components/Pages/ReverseLogistics/Configuration/Classifications';
import { Scaffold } from 'layouts';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const ClassificationCreationPage: React.FC = () => {
  const navigate = useNavigate();

  const onCreate = useCallback((): void => {
    navigate('/configuracoes/logistica-reversa/classificacoes');
  }, [navigate]);

  return (
    <Scaffold>
      <ClassificationCreationForm onCreate={onCreate} />
    </Scaffold>
  );
};
