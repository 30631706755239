import { Modal } from 'components/Shared';
import { Button } from 'components/ui/ButtonGs';
import {
    ForwardRefRenderFunction,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import { useColumns } from './columns';
import { DataTable } from 'components/ui/DataTable';
import { Action } from 'contracts/Action';

export type ActionsModalHandle = {
    open: (data: Action[]) => void;
    close: () => void;
};

type ActionsModalProps = {
    onSubmit: (selecteds: Action[], removeds: Action[]) => void;
    isAdded: number[];
};

const ActionsModal: ForwardRefRenderFunction<
    ActionsModalHandle,
    ActionsModalProps
> = ({ onSubmit, isAdded }, ref) => {
    const [actions, setActions] = useState<Action[] | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const { columns, selected, removeds, reset } = useColumns(actions ?? []);
    const currentActions = useCallback(
        () => actions?.filter((s) => isAdded?.includes(s.id)) ?? [],
        [actions, isAdded]
    );

    useImperativeHandle(
        ref,
        () => ({
            open: (data) => {
                setActions(data);
                setIsOpen(true);
            },
            close: () => {
                setActions(null);
                setIsOpen(false);
            },
        }),
        []
    );

    const handleSubmit = () => {
        onSubmit(selected, removeds);
    };

    const onCloseModal = () => {
        reset(currentActions() ?? []);
        setIsOpen(false);
    };

    useEffect(() => {
        reset(currentActions() ?? []);
    }, [currentActions]);

    return (
        <>
            <Modal isOpen={isOpen} onClickOutside={onCloseModal}>
                <div className="modal-content min-w-[700px]  bg-white flex flex-col justify-self-stretch rounded-sm p-8">
                    <div className="w-full flex flex-row justify-between items-center">
                        <span className="font-semibold text-base">
                            {actions &&
                                `${actions[0].group} (${selected.length}/${actions.length})`}
                        </span>
                    </div>
                    <div className="w-full min-w-[760px]">
                        <DataTable
                            isLoading={false}
                            data={actions ?? []}
                            columns={columns}
                            pageSize={5}
                            showControlColumns={false}
                        />
                    </div>
                    <div className="w-full flex flex-row justify-end items-center">
                        <Button.Root
                                className="bg-orange-500 self-end"
                                onClick={handleSubmit}
                            >
                                Adicionar
                        </Button.Root>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default forwardRef(ActionsModal);
