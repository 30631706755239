import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import { Select } from "components/Shared";
import { DialogContent } from "components/Shared/Dialog";
import { PendingReleaseContext } from "contexts/PendingReleaseContext";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { FetchStatusListActions } from "store/ducks/status";
import { InvoiceIcon } from "styles/components";
import { Status } from "components/Pages/Invoices";

import * as S from "./styles";
import { Formatter } from "utils";
import { UpdateInvoiceStatusPayload } from "contracts/Invoice";
import { UpdateInvoiceStatusActions } from "store/ducks/pendingRelease";

interface FormData {
  status: number;
}

const AddStatusModal = () => {
  const {
    refModal,
    selectedList,
    onQueryChange,
    clearSelectList,
    changeOpenModal,
  } = useContext(PendingReleaseContext);
  const formRef = useRef<FormHandles>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();

  const { data: statusList } = useSelector(
    (state: RootState) => state.fetchStatusList
  );

  const { loading } = useSelector(
    (state: RootState) => state.updateInvoiceStatus
  );

  const statusListOptions = statusList
    ? statusList
        .filter((status) => status.name !== "Cancelada")
        .map((status) => ({
          value: status.id,
          label: status.name,
        }))
    : [];

  const formattedList = useMemo(() => {
    return selectedList.map((invoice) => ({
      id: invoice.id,
      nf: `${invoice.infnfeIdeNnf}-${invoice.infnfeIdeSerie}`,
      dhEmi: Formatter.date(invoice.infnfeIdeDhemi, {
        format: "dd/MM/yyyy HH:mm",
      }),
      prev: invoice.deadlineDate
        ? Formatter.date(invoice.deadlineDate, { format: "dd/MM/yyyy HH:mm" })
        : "---",
      status:
        invoice.status.length > 0
          ? {
              name: invoice.status[0].name,
              color: invoice.status[0].color,
            }
          : null,
    }));
  }, [selectedList]);

  useEffect(() => {
    dispatch(FetchStatusListActions.request({ asList: true, notFlow: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback<SubmitHandler>(
    (data: FormData) => {
      const payloadData: UpdateInvoiceStatusPayload = {
        status_id: data.status,
        ids: selectedList.map((invoice) => invoice.id),
      };

      dispatch(
        UpdateInvoiceStatusActions.request(payloadData, () => {
          onQueryChange({}, true);
          clearSelectList();
          changeOpenModal();
        })
      );
    },
    [changeOpenModal, clearSelectList, dispatch, onQueryChange, selectedList]
  );

  return (
    <DialogContent
      container={refModal}
      title="Adicionar status nas notas listadas abaixo"
      icon={<InvoiceIcon />}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.Content>
          <S.ListHeader>
            <span>NF</span>
            <span>DT. Emissão</span>
            <span>P. Contratado</span>
            <span>Status</span>
          </S.ListHeader>
          {formattedList.map((invoice) => (
            <S.ListItem key={invoice.id}>
              <span>{invoice.nf}</span>
              <span>{invoice.dhEmi}</span>
              <span>{invoice.prev}</span>
              <span>
                {invoice.status ? (
                  <Status color={invoice.status.color}>
                    {invoice.status.name}
                  </Status>
                ) : (
                  <Status>Sem status</Status>
                )}
              </span>
            </S.ListItem>
          ))}
        </S.Content>
        <S.Footer>
          <Select
            name="status"
            options={statusListOptions}
            placeholder="Status"
            label="Status"
            onChange={(opt) => setIsDisabled(opt?.value ? false : true)}
            maxMenuHeight={120}
          />
          <S.Button type="submit" disabled={isDisabled || loading}>
            {loading ? `Adicionando` : `Adicionar`}
          </S.Button>
        </S.Footer>
      </Form>
    </DialogContent>
  );
};

export { AddStatusModal };
