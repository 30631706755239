import { Formatter } from "utils";
import { useCallback, useEffect, useState, useRef } from "react";
import * as S from "./styles";
import { Form } from "@unform/web";
import { useDispatch } from "react-redux";
import { CreateInvoiceJustificationActions as CreateActions } from "store/ducks/invoiceJustifications";
import { CreateInvoiceJustificationValidator as CreateValidator } from "validators/InvoiceJustification";
import { JUSTIFICATION_TYPES, useAuth, useInvoiceJustifications, useValidation } from "hooks";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { Loading } from "components/Shared/Loading";
import { Select } from "components/Shared";
import { FormHandles } from "@unform/core";
import { useJustifications, } from "hooks";
import { Invoice } from "contracts/Invoice";
import { AbonedInvoiceJustificationActions } from "store/ducks/invoiceJustifications";
import { useLocation } from "react-router-dom";
import { Modal } from "components/Shared";
import { usePermission } from "hooks/usePermission";

interface IModalJustifications {
  invoice: Invoice | null;
  title?: string | null;
  showAdd?: boolean;
  onNew?: () => void;
}

export const ModalJustifications = ({
  invoice,
  title,
  showAdd,
  onNew,
}: IModalJustifications) => {
  const dispatch = useDispatch();
  const [abonedId, setAbonedId] = useState<number | null>(null);
  const [isNew, setIsNew] = useState<boolean>(false);
  const { justificationOptions, fetchJustifications, loadingJustifications } =
    useJustifications();
  const { invoiceJustifications, fetchInvoiceJustifications } =
    useInvoiceJustifications();
  const { handleFormErrors, handleApiErrors } = useValidation();
  const formRef = useRef<FormHandles>(null);
  const { loading: carregando, validationErrors } = useSelector(
    (state: RootState) => state.createInvoiceJustification
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [idInvoiceJustification, setIdInvoiceJustification] = useState<number | null>(null);
  const delivered = "/inbound/notas-fiscais/entregues";
  const finished = "/inbound/notas-fiscais/finalizadas";
  const location = useLocation();
  const nowLocation = location.pathname;

  const { hasPermissionTo } = usePermission()


const isAdmin = hasPermissionTo(['ADMINS.FINDADMINS', 'ADMINS.FINDADMIN', 'ADMINS.UPDATEADMIN', 'ADMINS.DELETEADMIN', 'ADMINS.RESTOREADMIN'])


  const showRecognizeButton = isAdmin && (nowLocation === delivered || nowLocation === finished)


  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const scroll = useCallback(() => {
    isNew
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
  }, [isNew]);

  const handleFetchJustifications = useCallback(() => {
    fetchInvoiceJustifications(invoice?.id);
  }, [invoice,fetchInvoiceJustifications]);

  const handleAboned = useCallback((id: number | null) => {
    if (id) {
      setIsOpen(false);
      dispatch(AbonedInvoiceJustificationActions.request(id, onNew));
      setAbonedId(id)
      fetchInvoiceJustifications(invoice?.id);
    }
  }, [dispatch, handleFetchJustifications, onNew, invoice]);
  
  const openModelAboned = useCallback((id: number) => {
    if (id) {
      setIsOpen(true);
      setIdInvoiceJustification(id);
    }
  }, [setIsOpen, setIdInvoiceJustification]);

  const ModalConfirmAboned = useCallback(() => {
    return (
      <>
        <Modal isOpen={isOpen} onClickOutside={onClose} >
          <S.ModalContent style={{ maxWidth: "600px" }}>
            <S.ModalContainerConfirm>
              <S.MensageContainer>
                <S.MensageAlert>Tem certeza que deseja abonar a Justificativa ?</S.MensageAlert>
              </S.MensageContainer>
              <S.ButtonAlertContainer>
                <S.ButtonAlert onClick={() => setIsOpen(false)}>Cancelar</S.ButtonAlert>
                <S.ButtonAlert onClick={() => handleAboned(idInvoiceJustification)}>Confirmar</S.ButtonAlert>
              </S.ButtonAlertContainer>
            </S.ModalContainerConfirm>
          </S.ModalContent>
        </Modal>
      </>
    );
  }, [isOpen, onClose, setIsOpen, handleAboned, idInvoiceJustification]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});
        const create = { ...data, invoiceId: invoice?.id };
        const { schema } = new CreateValidator();
        const validData = await schema.validate(create, {
          abortEarly: false,
        });
        
        dispatch(CreateActions.request(validData, handleFetchJustifications));
        setIsNew(false);
        onNew && onNew();
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onNew]
  );

  const NewJustification = useCallback(() => {
    return (
      <S.ModalBackground>
        <S.ModalContainer>
          <S.ModalTitle>
            <S.CommentIcon />
            Adicionar Justificativa
            <S.Close onClick={() => setIsNew(false)} />
          </S.ModalTitle>
          <Form ref={formRef} onSubmit={onSubmit}>
            <Select
              name="justificationId"
              label="Justificativa"
              options={justificationOptions}
              isLoading={loadingJustifications}
            />
            <S.TextArea name="description" label="Comentário" />
            <S.FormRow>
              <S.FormButton type="reset" onClick={() => setIsNew(false)}>
                Voltar
              </S.FormButton>
              <S.FormButton type="submit" theme="accept">
                {carregando && <Loading />}
                Salvar
              </S.FormButton>
            </S.FormRow>
          </Form>
        </S.ModalContainer>
      </S.ModalBackground>
    );
  }, [
    justificationOptions,
    loadingJustifications,
    onSubmit,
    carregando,
    formRef,
  ]);

  useEffect(() => {
    fetchInvoiceJustifications(invoice?.id);
  }, [fetchInvoiceJustifications, invoice?.id]);

  useEffect(() => {
    fetchJustifications();
  }, [fetchJustifications,isNew]);

  useEffect(() => {
    scroll();
  }, [isNew]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  if (!invoice || !invoiceJustifications) return <></>;

  return (
    <S.StatusGrid>
      <ModalConfirmAboned />
      <S.StatusTitle>
        {invoiceJustifications.length > 0
          ? title
            ? title
            : "Últimos status"
          : "Nenhuma justificativa encontrada"}
        {showAdd && (
          <S.Button
            onClick={() => {
              setIsNew(true);
            }}
          >
            <S.MessageAddIcon height={"18px"} />
            Nova
          </S.Button>
        )}
      </S.StatusTitle>
      <S.StatusWrapper>
        {isNew && <NewJustification />}
        {invoiceJustifications.map(
          ({ id, user, description, created_at, aboned_at, justification }: any) => (
            <S.StatusBox key={id}>
              { aboned_at ? <S.SucessIcon className="dot aboned" /> : <S.DotIcon className="dot" /> }
              <S.UserCircleIcon className="user" />
              <div className="wrapper">
                <div className="user-info flex justify-between">
                <S.UserName>{user ? user.name : "Sistema"}</S.UserName>
                <div className="self-start">
                    <span>Check</span>
                </div>
                </div>
                <S.StatusDescription>
                {justification?.description &&  `Justificativa: "${justification?.description}"`}
                </S.StatusDescription>
                {description && (
                  <S.StatusDescription>
                    Comentário: "{description}"
                  </S.StatusDescription>
                )}
                <S.StatusDateContainer>
                  <S.StatusDate>
                    {Formatter.date(created_at, {
                      format: "dd/MM/yyyy HH:mm",
                    })}
                  </S.StatusDate>
                  <S.StatusImg>
                    {aboned_at || abonedId === id ? <S.Check /> : ""}
                  </S.StatusImg>
                </S.StatusDateContainer>
                { showRecognizeButton && !aboned_at && abonedId !== id ? <S.RecognizeButton onClick={() => openModelAboned(id)}>Abonar</S.RecognizeButton> : ""}
              </div>
            </S.StatusBox>
          )
        )}
      </S.StatusWrapper>
    </S.StatusGrid>
  );
};
