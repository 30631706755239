import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  CompaniesListPage,
  CompanyCreationPage,
  CompanyUpdatePage,
} from 'pages/Companies';

const companiesRoutes = ({
  location,
  userBelongsToAnyOf,
  hasPermissionTo
}: RouterProps): RouteObject => ({
  path: 'configuracoes/empresas',
  element: hasPermissionTo(['COMPANIES.FINDCOMPANIES']) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <CompaniesListPage />,
    },
    {
      path: 'criar',
      element: <CompanyCreationPage />,
    },
    {
      path: ':id/editar',
      element: <CompanyUpdatePage />,
    },
  ],
});

export default companiesRoutes;
