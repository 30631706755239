import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  AuditorsListPage,
  AuditorCreationPage,
  AuditorUpdatePage,
} from 'pages/Auditors';

const auditorsRoutes = ({
  location,
//   userBelongsToAnyOf,
  hasPermissionTo
}: RouterProps): RouteObject => ({
  path: 'configuracoes/fiscais',
  element: hasPermissionTo(['AUDITORS.FINDAUDITORS']) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <AuditorsListPage />,
    },
    {
      path: 'criar',
      element: <AuditorCreationPage />,
    },
    {
      path: ':id/editar',
      element: <AuditorUpdatePage />,
    },
  ],
});

export default auditorsRoutes;
