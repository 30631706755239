import { toast } from 'react-toastify';


export function notify(type: 'error' | 'success' | 'warning', message: string): void {
  if(toast.isActive(message)) return

  if (type === 'error') {
    toast.error(message, {
      toastId: message,
      position: toast.POSITION.TOP_RIGHT,
      className: 'toasterror',
    });
  }
  if (type === 'success') {
    toast.success(message, {
      toastId: message,
      position: toast.POSITION.TOP_RIGHT,
      className: 'toastsuccess',
    });
  }
  if (type === 'warning') {
    toast.warning(message, {
      toastId: message,
      position: toast.POSITION.TOP_RIGHT,
      className: 'toastwarning',
    });
  }
}
