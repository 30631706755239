import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import type { Carrier } from 'contracts/Carrier';

const { Types, Creators } = createActions(
  {
    request: ['params', 'onSuccess', 'onFailure'],
    success: ['data'],
    failure: ['errorMessage'],
    softReset: [],
    reset: [],
  },
  {
    prefix: 'FETCH_CARRIERS_LIST_',
  }
);

export interface FetchCarriersListState {
  data: Carrier[];
  loading: boolean;
  errorMessage: string | null;
}

export interface FetchCarriersListRequestAction {
  params: any;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface SuccessAction {
  data: Carrier[];
}

interface FailureAction {
  errorMessage: string;
}

const INITIAL_STATE: FetchCarriersListState = {
  data: [],
  loading: false,
  errorMessage: null,
};

const request = (state: FetchCarriersListState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (state: FetchCarriersListState, action: SuccessAction) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (state: FetchCarriersListState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const softReset = (state: FetchCarriersListState) =>
  update(state, {
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const fetchCarriersList = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const FetchCarriersListTypes = Types;
export const FetchCarriersListActions = Creators;
