import { Supplier } from "contracts/Supplier";
import { SelectOption } from "contracts/Common";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "store";
import { ListSuppliersActions as MainActions } from "store/ducks/suppliers";
import { Formatter } from "utils";

interface OptionSuppliers
  extends Pick<Supplier, "id" | "tradeName" | "documentType" | "document"> {}

export const useSuppliers = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: suppliers, loading: loadingSuppliers } = useSelector(
    (state: RootState) => state.listSuppliers
  );

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchSuppliers = useCallback((): void => {
    dispatch(MainActions.request());
  }, [dispatch]);

  const onSuppliersLoad = useCallback((): void => {
    setOptions(
      suppliers.map(
        ({ id, tradeName, documentType, document }: OptionSuppliers) => ({
          value: id,
          label: `${tradeName} - ${Formatter.document(document, documentType)}`,
          cnpj: document,
        })
      )
    );
  }, [suppliers]);

  useEffect(() => {
    onSuppliersLoad();
  }, [onSuppliersLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    suppliers,
    suppliersOptions: options,
    loadingSuppliers,
    fetchSuppliers,
  };
};

export type SuppliersHook = ReturnType<typeof useSuppliers>;
