import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Select } from 'components/Shared';
import { DialogContent } from 'components/Shared/Dialog'
import { PendingReleaseContext } from 'contexts/PendingReleaseContext'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { TruckIcon } from 'styles/components';
import { Status } from 'components/Pages/Invoices';

import * as S from './styles';
import { Formatter } from 'utils';
import { UpdateCarrierInvoicePayload } from 'contracts/Invoice';
import { UpdateInvoiceCarrierActions } from 'store/ducks/pendingRelease';
import { FetchCarriersListActions } from 'store/ducks/carriers';

interface FormData {
  carrierId: number;
}

const ChangeCarrierModal = () => {
  const { 
    refModal, 
    selectedList, 
    onQueryChange, 
    clearSelectList,
    changeOpenModal
  } = useContext(PendingReleaseContext)
  const formRef = useRef<FormHandles>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch()

  const {
    data: carrierList,
  } = useSelector((state: RootState) => state.fetchCarriersList);

  const {
    loading
  } = useSelector((state: RootState) => state.updateInvoiceCarrier);

  const carrierListOptions = carrierList ? 
    [...carrierList.map(carrier => ({
      value: carrier.id,
      label: carrier.tradeName,
    }))] : [];

  const formattedList = useMemo(() => {
    return selectedList.map(invoice => ({
      id: invoice.id,
      nf: `${invoice.infnfeIdeNnf}-${invoice.infnfeIdeSerie}`,
      dhEmi: Formatter.date(invoice.infnfeIdeDhemi, { format: 'dd/MM/yyyy HH:mm' }),
      carrierName: invoice.carrier?.tradeName || '---',
      status: invoice.status.length > 0 ? ({
        name: invoice.status[0].name,
        color: invoice.status[0].color
      }): null
    }))
  }, [selectedList])

  useEffect(() => {
    dispatch(FetchCarriersListActions.request({ asList: true}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = useCallback<SubmitHandler>((data: FormData) => {
    const payloadData: UpdateCarrierInvoicePayload = {
      carrier_id: data.carrierId,
      ids: selectedList.map(invoice => invoice.id)
    }

    dispatch(UpdateInvoiceCarrierActions.request(payloadData, () => {
      onQueryChange({}, true);
      clearSelectList()
      changeOpenModal()
    }))
  }, [changeOpenModal, clearSelectList, dispatch, onQueryChange, selectedList])

  return (
    <DialogContent
      container={refModal}
      title="Alterar transportadora das notas listadas abaixo"
      icon={<TruckIcon />}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.Content>
          <S.ListHeader>
            <span>NF</span>
            <span>DT. Emissão</span>
            <span>Transportadora</span>
            <span>Status</span>
          </S.ListHeader>
          {formattedList.map(invoice => (
            <S.ListItem key={invoice.id}>
              <span>{invoice.nf}</span>
              <span>{invoice.dhEmi}</span>
              <span>{invoice.carrierName}</span>
              <span>
              {invoice.status ? (
                <Status color={invoice.status.color}>{invoice.status.name}</Status>
              ) : (
                <Status>Sem status</Status>
              )}
              </span>
            </S.ListItem>
          ))}
        </S.Content>
        <S.Footer>
          <Select
            name="carrierId"
            options={carrierListOptions}
            placeholder="Transportadora"
            label="Transportadora"
            onChange={opt => setIsDisabled(opt?.value ? false : true)}
            isClearable
            maxMenuHeight={120}
          />
          <S.Button type="submit" disabled={isDisabled || loading}>
            {loading ? `Alterando` : `Alterar`}
          </S.Button>
        </S.Footer>
      </Form>
    </DialogContent>
  )
}

export { ChangeCarrierModal }
