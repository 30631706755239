import { call, put } from '@redux-saga/core/effects';
import { api, notify, formDataBuilder, apiErrorHandler } from 'services';
import {
  UpdateClientActions as Actions,
  UpdateClientRequestAction as RequestAction,
} from 'store/ducks/clients';

export function* updateClientRequest(action: any) {
  const { id, putData, onSuccess, onFailure }: RequestAction = action;
  try {
    const formData = formDataBuilder(putData);
    yield call(api.put, `clients/${id}`, formData);
    onSuccess && onSuccess();
    notify('success', 'Cliente atualizado com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
