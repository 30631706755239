import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import { CollectsListPage, CollectViewPage, CollectCreationPage } from 'pages/ReverseLogistics';
import { ReverseLogisticProvider } from 'contexts/ReverseLogisticContext';

const reverseLogisticsRoutes = ({
  isLoggedIn,
  location
}: RouterProps): RouteObject => ({
  path: 'logistica-reversa',
  element: isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: 'coletas',
      element: (
        <ReverseLogisticProvider>
          <CollectsListPage />
        </ReverseLogisticProvider>
      ),
    },
    {
      path: 'criar',
      element: <CollectCreationPage />,
    },
    {
      path: 'coleta/:id',
      element: <CollectViewPage />,
    },
  ],
});

export default reverseLogisticsRoutes;
