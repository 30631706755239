import { InvoiceRecalculateTypes } from './../ducks/pendingRelease/recalculateInvoice';
import {
  CreateTransitTimeTypes,
  FetchTransitTimeTypes,
  ListTransitTimesTypes,
  PaginateTransitTimesTypes,
  UpdateTransitTimeTypes,
  RecalculateTransitTimeTypes,
} from 'store/ducks/transitTime';
import { all, takeLatest } from 'redux-saga/effects';
// ducks
import {
  CreateAdminTypes,
  DeleteAdminTypes,
  FetchAdminTypes,
  PaginateAdminsTypes,
  UpdateAdminTypes,
} from '../ducks/admins';
import {
  CreateCarrierTypes,
  FetchCarrierTypes,
  ListCarriersTypes,
  PaginateCarriersTypes,
  UpdateCarrierTypes,
} from '../ducks/carriers';
import {
  CreateCarrierMemberTypes,
  DeleteCarrierMemberTypes,
  FetchCarrierMemberTypes,
  PaginateCarrierMembersTypes,
  UpdateCarrierMemberTypes,
} from '../ducks/carrierMembers';
import {
  AbonedInvoiceJustificationTypes,
  AbonedInvoiceJustificationsTypes,
  CreateInvoiceJustificationTypes,
  CreateManyInvoiceJustificationsTypes,
  FetchInvoiceJustificationTypes,
  ListInvoiceJustificationsTypes,
} from '../ducks/invoiceJustifications';
import {
  CreateJustificationTypes,
  FetchJustificationTypes,
  ListJustificationsTypes,
  PaginateJustificationsTypes,
  UpdateJustificationTypes,
} from '../ducks/justifications';
import {
  CreateJustificationReverseLogisticsTypes,
  FetchJustificationReverseLogisticsTypes,
  ListJustificationsReverseLogisticsTypes,
  PaginateJustificationsReverseLogisticsTypes,
  UpdateJustificationReverseLogisticsTypes,
} from '../ducks/justificationsReverseLogistics';
import {
  CreateVehicleTypeTypes,
  FetchVehicleTypeTypes,
  ListVehicleTypesTypes,
  PaginateVehicleTypesTypes,
  UpdateVehicleTypeTypes,
} from '../ducks/vehicleTypes';
import {
  LoginTypes,
  ImpersonateTypes,
  LogoutTypes,
  UpdateAccountTypes,
  GeneratePasswordResetTokenTypes,
  ResetPasswordTypes,
} from '../ducks/auth';
import { ListCountriesTypes } from './../ducks/countries/listCountries';
import { ListCitiesTypes } from './../ducks/cities/listCities';
import { ListProductUnitsTypes } from './../ducks/productUnits/listProductUnits';
import {
  CreateCompanyTypes,
  FetchCompanyTypes,
  ListCompaniesTypes,
  PaginateCompaniesTypes,
  UpdateCompanyTypes,
} from '../ducks/companies';
import {
  CreateClientTypes,
  FetchClientTypes,
  ListClientsTypes,
  UpdateClientTypes,
  PaginateClientsTypes,
} from '../ducks/clients';
import {
  FetchAuditorTypes,
  ListAuditorsTypes,
  PaginateAuditorsTypes,
  CreateAuditorTypes,
  UpdateAuditorTypes,
} from '../ducks/auditors';
import {
  FetchReasonTypes,
  ListReasonsTypes,
  PaginateReasonsTypes,
  CreateReasonTypes,
  UpdateReasonTypes,
} from '../ducks/reasons';
import {
  FetchClassificationTypes,
  ListClassificationsTypes,
  PaginateClassificationsTypes,
  CreateClassificationTypes,
  UpdateClassificationTypes,
} from '../ducks/classifications';
import {
  FetchCollectTypes,
  ListCollectsTypes,
  PaginateCollectsTypes,
  CreateCollectTypes,
  FetchDocumentTypes,
  ExportCollectsTypes,
} from '../ducks/collects';
import {
  CreateSupplierTypes,
  FetchSupplierTypes,
  ListSuppliersTypes,
  PaginateSuppliersTypes,
  UpdateSupplierTypes,
} from '../ducks/suppliers';
import { FetchAddressByZipcodeTypes } from '../ducks/addressLookup';
import {
  CreateProductTypes,
  FetchProductTypes,
  ListProductsTypes,
  PaginateProductsTypes,
  UpdateProductTypes,
} from '../ducks/products';
import {
  FetchOperationTypeTypes,
  ListOperationTypesTypes,
  PaginateOperationTypesTypes,
} from '../ducks/operationTypes';
import {
  PaginateOperationTypesCfopTypes,
  CreateOperationTypesCfopTypes,
  ToggleDisableOperationTypeCFOPTypes,
} from '../ducks/operationsTypesCfop';
import {
  CreateProductTypeTypes,
  FetchProductTypeTypes,
  ListProductTypesTypes,
  PaginateProductTypesTypes,
  UpdateProductTypeTypes,
} from '../ducks/productTypes';
import {
  PaginatePendingReleaseTypes,
  FetchPendingReleaseTypes,
  UpdateInvoiceStatusTypes,
  UpdateInvoiceDeadlineTypes,
  ReleaseInvoicesToTrackingTypes,
  UpdateInvoiceCarrierTypes,
  CreateCorrectionLetterTypes,
  FetchCorrectionLetterTypes,
  DisablePendingInvoiceTypes,
} from '../ducks/pendingRelease';
import { FetchCarriersListTypes } from 'store/ducks/carriers';
import { FetchStatusListTypes } from 'store/ducks/status';
import { PaginateReleasedInvoicesTypes } from 'store/ducks/releasedInvoices';
import {
  PaginateDeliveredInvoicesTypes,
  UpdateSAPCodeInvoiceTypes,
  IgnoreInvoiceTypes,
} from 'store/ducks/deliveredInvoices';
import { FetchPublicInvoiceTypes } from 'store/ducks/publicInvoice';

// sagas
import {
  createAdminRequest,
  deleteAdminRequest,
  fetchAdminRequest,
  paginateAdminsRequest,
  updateAdminRequest,
} from './admins';
import {
  createCarrierRequest,
  fetchCarrierRequest,
  listCarriersRequest,
  paginateCarriersRequest,
  updateCarrierRequest,
} from './carriers';
import {
  createCarrierMemberRequest,
  deleteCarrierMemberRequest,
  fetchCarrierMemberRequest,
  paginateCarrierMembersRequest,
  updateCarrierMemberRequest,
} from './carrierMembers';
import {
  createWarehouseMemberRequest,
  deleteWarehouseCompanyMemberRequest,
  deleteWarehouseMemberRequest,
  fetchWarehouseMemberRequest,
  paginateWarehouseMembersRequest,
  updateWarehouseMemberRequest,
} from './warehouseMembers';
import {
  createCompanyRequest,
  fetchCompanyRequest,
  listCompaniesRequest,
  paginateCompaniesRequest,
  updateCompanyRequest,
} from './companies';
import {
  createClientRequest,
  fetchClientRequest,
  listClientsRequest,
  updateClientRequest,
  paginateClientsRequest,
} from './clients';
import {
  fetchAuditorRequest,
  listAuditorsRequest,
  paginateAuditorsRequest,
  createAuditorRequest,
  updateAuditorRequest,
} from './auditors';
import {
  fetchReasonRequest,
  listReasonsRequest,
  paginateReasonsRequest,
  createReasonRequest,
  updateReasonRequest,
} from './reasons';
import {
  fetchClassificationRequest,
  listClassificationsRequest,
  paginateClassificationsRequest,
  createClassificationRequest,
  updateClassificationRequest,
} from './classifications';
import {
  fetchCollectRequest,
  listCollectsRequest,
  paginateCollectsRequest,
  createCollectRequest,
  fetchDocumentRequest,
  exportCollectsRequest,
} from './collects';
import {
  createSupplierRequest,
  fetchSupplierRequest,
  listSuppliersRequest,
  paginateSuppliersRequest,
  updateSupplierRequest,
} from './suppliers';
import { listCountriesRequest } from './countries';
import { listCitiesRequest } from './cities';
import { listProductUnitsRequest } from './productUnits';
import { rehydrateToken } from './hydration';
import {
  loginRequest,
  impersonateRequest,
  logoutRequest,
  updateAccountRequest,
  generatePasswordResetTokenRequest,
  resetPasswordRequest,
} from './auth';
import { fetchAddressByZipcodeRequest } from './addressLookup';
import {
  createProductRequest,
  fetchProductRequest,
  listProductsRequest,
  paginateProductsRequest,
  updateProductRequest,
} from './products';
import {
  fetchOperationTypeRequest,
  listOperationTypesRequest,
  paginateOperationTypesRequest,
} from './operationTypes';
import {
  createOperationTypesCfopRequest,
  paginateOperationTypesCfopRequest,
  toggleDisableOperationTypeCfopRequest,
} from './operationTypesCfop';
import {
  createProductTypeRequest,
  fetchProductTypeRequest,
  listProductTypesRequest,
  paginateProductTypesRequest,
  updateProductTypeRequest,
} from './productTypes';
import {
  paginatePendingReleaseRequest,
  fetchPendingReleaseRequest,
  updateInvoiceStatusRequest,
  updateInvoiceDeadlineRequest,
  releaseInvoicesToTrackingRequest,
  updateInvoiceCarrierRequest,
  createCorrectionLetterRequest,
  fetchCorrectionLetterRequest,
  disablePendingInvoiceRequest,
} from './pendingRelease';
import { fetchCarriersListRequest } from './carriers';
import { fetchStatusListRequest } from './status';
import { paginateReleasedInvoicesRequest } from './releasedInvoices';
import {
  paginateDeliveredInvoicesRequest,
  updateSAPCodeInvoiceRequest,
  IgnoreInvoiceRequest,
} from './deliveredInvoices';
import { PaginateFinishedInvoicesTypes } from 'store/ducks/finishedInvoices';
import { paginateFinishedInvoicesRequest } from './finishedInvoices';
import {
  createInvoiceJustificationRequest,
  createManyInvoiceJustificationsRequest,
  fetchInvoiceJustificationRequest,
  listInvoiceJustificationsRequest,
  abonedInvoiceJustificationRequest,
  abonedInvoiceJustificationsRequest,
} from './InvoiceJustifications';
import {
  createJustificationRequest,
  fetchJustificationRequest,
  listJustificationsRequest,
  paginateJustificationsRequest,
  updateJustificationRequest,
} from './justifications';
import {
  createJustificationReverseLogisticsRequest,
  fetchJustificationReverseLogisticsRequest,
  listJustificationsReverseLogisticsRequest,
  paginateJustificationsReverseLogisticsRequest,
  updateJustificationReverseLogisticsRequest,
} from './justificationsReverseLogistics';
import {
  createVehicleTypeRequest,
  fetchVehicleTypeRequest,
  listVehicleTypesRequest,
  paginateVehicleTypesRequest,
  updateVehicleTypeRequest,
} from './vehicleTypes';
import { fetchTransitTimeRequest } from './transitTime/fetchTransitTime';
import { invoiceRecalculateTypesRequest } from './pendingRelease/recalculateInvoice';
import {
  createTransitTimeRequest,
  listTransitTimesRequest,
  paginateTransitTimesRequest,
  updateTransitTimeRequest,
  recalculateTransitTimeRequest,
} from './transitTime';
import { ExportInvoicesTypes } from 'store/ducks/exportInvoices';
import { exportInvoicesRequest } from './exportInvoices';
import { fetchPublicInvoiceRequest } from './publicInvoice';
import {
  CreateWarehouseMemberTypes,
  DeleteWarehouseCompanyMemberTypes,
  DeleteWarehouseMemberTypes,
  FetchWarehouseMemberTypes,
  PaginateWarehouseMembersTypes,
  UpdateWarehouseMemberTypes,
} from 'store/ducks/warehouseMembers';
import { FetchJustificationTypesTypes } from 'store/ducks/justificationTypes';
import { fetchJustificationTypesRequest } from './justificationTypes';

export default function* rootSaga() {
  yield all([
    // hydration
    takeLatest('persist/REHYDRATE', rehydrateToken),
    // admins
    takeLatest(CreateAdminTypes.REQUEST, createAdminRequest),
    takeLatest(DeleteAdminTypes.REQUEST, deleteAdminRequest),
    takeLatest(FetchAdminTypes.REQUEST, fetchAdminRequest),
    takeLatest(PaginateAdminsTypes.REQUEST, paginateAdminsRequest),
    takeLatest(UpdateAdminTypes.REQUEST, updateAdminRequest),
    // auth
    takeLatest(LoginTypes.REQUEST, loginRequest),
    takeLatest(ImpersonateTypes.REQUEST, impersonateRequest),
    takeLatest(LogoutTypes.REQUEST, logoutRequest),
    takeLatest(UpdateAccountTypes.REQUEST, updateAccountRequest),
    takeLatest(
      GeneratePasswordResetTokenTypes.REQUEST,
      generatePasswordResetTokenRequest
    ),
    takeLatest(ResetPasswordTypes.REQUEST, resetPasswordRequest),
    // address lookup
    takeLatest(
      FetchAddressByZipcodeTypes.REQUEST,
      fetchAddressByZipcodeRequest
    ),
    // carriers
    takeLatest(CreateCarrierTypes.REQUEST, createCarrierRequest),
    takeLatest(FetchCarrierTypes.REQUEST, fetchCarrierRequest),
    takeLatest(ListCarriersTypes.REQUEST, listCarriersRequest),
    takeLatest(PaginateCarriersTypes.REQUEST, paginateCarriersRequest),
    takeLatest(UpdateCarrierTypes.REQUEST, updateCarrierRequest),
    // carrier members
    takeLatest(CreateCarrierMemberTypes.REQUEST, createCarrierMemberRequest),
    takeLatest(DeleteCarrierMemberTypes.REQUEST, deleteCarrierMemberRequest),
    takeLatest(FetchCarrierMemberTypes.REQUEST, fetchCarrierMemberRequest),
    takeLatest(
      PaginateCarrierMembersTypes.REQUEST,
      paginateCarrierMembersRequest
    ),
    takeLatest(UpdateCarrierMemberTypes.REQUEST, updateCarrierMemberRequest),
    // warehouse members
    takeLatest(
      CreateWarehouseMemberTypes.REQUEST,
      createWarehouseMemberRequest
    ),
    takeLatest(
      DeleteWarehouseMemberTypes.REQUEST,
      deleteWarehouseMemberRequest
    ),
    takeLatest(
      DeleteWarehouseCompanyMemberTypes.REQUEST,
      deleteWarehouseCompanyMemberRequest
    ),
    takeLatest(FetchWarehouseMemberTypes.REQUEST, fetchWarehouseMemberRequest),
    takeLatest(
      PaginateWarehouseMembersTypes.REQUEST,
      paginateWarehouseMembersRequest
    ),
    takeLatest(
      UpdateWarehouseMemberTypes.REQUEST,
      updateWarehouseMemberRequest
    ),
    // countries
    takeLatest(ListCountriesTypes.REQUEST, listCountriesRequest),
    // cities
    takeLatest(ListCitiesTypes.REQUEST, listCitiesRequest),
    // product units
    takeLatest(ListProductUnitsTypes.REQUEST, listProductUnitsRequest),
    // companies
    takeLatest(CreateCompanyTypes.REQUEST, createCompanyRequest),
    takeLatest(FetchCompanyTypes.REQUEST, fetchCompanyRequest),
    takeLatest(ListCompaniesTypes.REQUEST, listCompaniesRequest),
    takeLatest(PaginateCompaniesTypes.REQUEST, paginateCompaniesRequest),
    takeLatest(UpdateCompanyTypes.REQUEST, updateCompanyRequest),
    //clients
    takeLatest(CreateClientTypes.REQUEST, createClientRequest),
    takeLatest(FetchClientTypes.REQUEST, fetchClientRequest),
    takeLatest(ListClientsTypes.REQUEST, listClientsRequest),
    takeLatest(UpdateClientTypes.REQUEST, updateClientRequest),
    takeLatest(PaginateClientsTypes.REQUEST, paginateClientsRequest),
    //auditors
    takeLatest(FetchAuditorTypes.REQUEST, fetchAuditorRequest),
    takeLatest(ListAuditorsTypes.REQUEST, listAuditorsRequest),
    takeLatest(PaginateAuditorsTypes.REQUEST, paginateAuditorsRequest),
    takeLatest(CreateAuditorTypes.REQUEST, createAuditorRequest),
    takeLatest(UpdateAuditorTypes.REQUEST, updateAuditorRequest),
    //reasons
    takeLatest(FetchReasonTypes.REQUEST, fetchReasonRequest),
    takeLatest(ListReasonsTypes.REQUEST, listReasonsRequest),
    takeLatest(PaginateReasonsTypes.REQUEST, paginateReasonsRequest),
    takeLatest(CreateReasonTypes.REQUEST, createReasonRequest),
    takeLatest(UpdateReasonTypes.REQUEST, updateReasonRequest),
    //classifications
    takeLatest(FetchClassificationTypes.REQUEST, fetchClassificationRequest),
    takeLatest(ListClassificationsTypes.REQUEST, listClassificationsRequest),
    takeLatest(
      PaginateClassificationsTypes.REQUEST,
      paginateClassificationsRequest
    ),
    takeLatest(CreateClassificationTypes.REQUEST, createClassificationRequest),
    takeLatest(UpdateClassificationTypes.REQUEST, updateClassificationRequest),
    // collects
    takeLatest(FetchCollectTypes.REQUEST, fetchCollectRequest),
    takeLatest(ListCollectsTypes.REQUEST, listCollectsRequest),
    takeLatest(PaginateCollectsTypes.REQUEST, paginateCollectsRequest),
    takeLatest(CreateCollectTypes.REQUEST, createCollectRequest),
    takeLatest(FetchDocumentTypes.REQUEST, fetchDocumentRequest),
    takeLatest(ExportCollectsTypes.REQUEST, exportCollectsRequest),
    // pending release
    takeLatest(
      PaginatePendingReleaseTypes.REQUEST,
      paginatePendingReleaseRequest
    ),
    takeLatest(FetchPendingReleaseTypes.REQUEST, fetchPendingReleaseRequest),
    takeLatest(UpdateInvoiceStatusTypes.REQUEST, updateInvoiceStatusRequest),
    takeLatest(
      UpdateInvoiceDeadlineTypes.REQUEST,
      updateInvoiceDeadlineRequest
    ),
    takeLatest(
      ReleaseInvoicesToTrackingTypes.REQUEST,
      releaseInvoicesToTrackingRequest
    ),
    takeLatest(UpdateInvoiceCarrierTypes.REQUEST, updateInvoiceCarrierRequest),
    takeLatest(
      CreateCorrectionLetterTypes.REQUEST,
      createCorrectionLetterRequest
    ),
    takeLatest(
      FetchCorrectionLetterTypes.REQUEST,
      fetchCorrectionLetterRequest
    ),
    takeLatest(
      DisablePendingInvoiceTypes.REQUEST,
      disablePendingInvoiceRequest
    ),
    // released Invoices
    takeLatest(
      PaginateReleasedInvoicesTypes.REQUEST,
      paginateReleasedInvoicesRequest
    ),
    // export Invoices
    takeLatest(ExportInvoicesTypes.REQUEST, exportInvoicesRequest),
    // delivered Invoices
    takeLatest(
      PaginateDeliveredInvoicesTypes.REQUEST,
      paginateDeliveredInvoicesRequest
    ),
    takeLatest(UpdateSAPCodeInvoiceTypes.REQUEST, updateSAPCodeInvoiceRequest),
    takeLatest(IgnoreInvoiceTypes.REQUEST, IgnoreInvoiceRequest),
    // finished Invoices
    takeLatest(
      PaginateFinishedInvoicesTypes.REQUEST,
      paginateFinishedInvoicesRequest
    ),
    // carriers
    takeLatest(FetchCarriersListTypes.REQUEST, fetchCarriersListRequest),
    // products
    takeLatest(CreateProductTypes.REQUEST, createProductRequest),
    takeLatest(FetchProductTypes.REQUEST, fetchProductRequest),
    takeLatest(ListProductsTypes.REQUEST, listProductsRequest),
    takeLatest(PaginateProductsTypes.REQUEST, paginateProductsRequest),
    takeLatest(UpdateProductTypes.REQUEST, updateProductRequest),
    // operation types
    takeLatest(FetchOperationTypeTypes.REQUEST, fetchOperationTypeRequest),
    takeLatest(ListOperationTypesTypes.REQUEST, listOperationTypesRequest),
    takeLatest(
      PaginateOperationTypesTypes.REQUEST,
      paginateOperationTypesRequest
    ),
    // operation types cfop
    takeLatest(
      CreateOperationTypesCfopTypes.REQUEST,
      createOperationTypesCfopRequest
    ),
    takeLatest(
      PaginateOperationTypesCfopTypes.REQUEST,
      paginateOperationTypesCfopRequest
    ),
    takeLatest(
      ToggleDisableOperationTypeCFOPTypes.REQUEST,
      toggleDisableOperationTypeCfopRequest
    ),
    // product types
    takeLatest(CreateProductTypeTypes.REQUEST, createProductTypeRequest),
    takeLatest(FetchProductTypeTypes.REQUEST, fetchProductTypeRequest),
    takeLatest(ListProductTypesTypes.REQUEST, listProductTypesRequest),
    takeLatest(PaginateProductTypesTypes.REQUEST, paginateProductTypesRequest),
    takeLatest(UpdateProductTypeTypes.REQUEST, updateProductTypeRequest),
    //recalculate invoices
    takeLatest(InvoiceRecalculateTypes.REQUEST, invoiceRecalculateTypesRequest),
    // suppliers
    takeLatest(CreateSupplierTypes.REQUEST, createSupplierRequest),
    takeLatest(FetchSupplierTypes.REQUEST, fetchSupplierRequest),
    takeLatest(ListSuppliersTypes.REQUEST, listSuppliersRequest),
    takeLatest(PaginateSuppliersTypes.REQUEST, paginateSuppliersRequest),
    takeLatest(UpdateSupplierTypes.REQUEST, updateSupplierRequest),
    // invoiceJustifications
    takeLatest(
      CreateInvoiceJustificationTypes.REQUEST,
      createInvoiceJustificationRequest
    ),
    takeLatest(
      CreateManyInvoiceJustificationsTypes.REQUEST,
      createManyInvoiceJustificationsRequest
    ),
    takeLatest(
      FetchInvoiceJustificationTypes.REQUEST,
      fetchInvoiceJustificationRequest
    ),
    takeLatest(
      ListInvoiceJustificationsTypes.REQUEST,
      listInvoiceJustificationsRequest
    ),
    takeLatest(
      AbonedInvoiceJustificationTypes.REQUEST,
      abonedInvoiceJustificationRequest
    ),
    takeLatest(
        AbonedInvoiceJustificationsTypes.REQUEST,
        abonedInvoiceJustificationsRequest
      ),
    //justifications - tracking
    takeLatest(CreateJustificationTypes.REQUEST, createJustificationRequest),
    takeLatest(FetchJustificationTypes.REQUEST, fetchJustificationRequest),
    takeLatest(ListJustificationsTypes.REQUEST, listJustificationsRequest),
    takeLatest(UpdateJustificationTypes.REQUEST, updateJustificationRequest),
    takeLatest(
      PaginateJustificationsTypes.REQUEST,
      paginateJustificationsRequest
    ),
    //justifications - reverseLogistics
    takeLatest(
      CreateJustificationReverseLogisticsTypes.REQUEST,
      createJustificationReverseLogisticsRequest
    ),
    takeLatest(
      FetchJustificationReverseLogisticsTypes.REQUEST,
      fetchJustificationReverseLogisticsRequest
    ),
    takeLatest(
      ListJustificationsReverseLogisticsTypes.REQUEST,
      listJustificationsReverseLogisticsRequest
    ),
    takeLatest(
      UpdateJustificationReverseLogisticsTypes.REQUEST,
      updateJustificationReverseLogisticsRequest
    ),
    takeLatest(
      PaginateJustificationsReverseLogisticsTypes.REQUEST,
      paginateJustificationsReverseLogisticsRequest
    ),
    //vehicle types
    takeLatest(CreateVehicleTypeTypes.REQUEST, createVehicleTypeRequest),
    takeLatest(FetchVehicleTypeTypes.REQUEST, fetchVehicleTypeRequest),
    takeLatest(ListVehicleTypesTypes.REQUEST, listVehicleTypesRequest),
    takeLatest(UpdateVehicleTypeTypes.REQUEST, updateVehicleTypeRequest),
    takeLatest(PaginateVehicleTypesTypes.REQUEST, paginateVehicleTypesRequest),
    // status
    takeLatest(FetchStatusListTypes.REQUEST, fetchStatusListRequest),
    //transit time
    takeLatest(CreateTransitTimeTypes.REQUEST, createTransitTimeRequest),
    takeLatest(FetchTransitTimeTypes.REQUEST, fetchTransitTimeRequest),
    takeLatest(ListTransitTimesTypes.REQUEST, listTransitTimesRequest),
    takeLatest(PaginateTransitTimesTypes.REQUEST, paginateTransitTimesRequest),
    takeLatest(
      RecalculateTransitTimeTypes.REQUEST,
      recalculateTransitTimeRequest
    ),
    takeLatest(UpdateTransitTimeTypes.REQUEST, updateTransitTimeRequest),
    takeLatest(FetchPublicInvoiceTypes.REQUEST, fetchPublicInvoiceRequest),
    //justification types
    takeLatest(
      FetchJustificationTypesTypes.REQUEST,
      fetchJustificationTypesRequest
    ),
  ]);
}
