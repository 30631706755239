import styled, { css } from "styled-components";
import {
  BasePaginatorHeader,
  BasePaginatorListItem,
  PaginatorActionsColumn,
} from "styles/components";
import { ColorScheme } from "styles/constants";
export {
  ActionButton,
  PaginatorColumn as Column,
  LinkActionButton,
  EditIcon,
  ChevronRightCircleIcon,
} from "styles/components";

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const baseGridCss = css`
  grid-template-columns:
    60px repeat(2, 100px) repeat(3, 180px)
    minmax(150px, auto) minmax(0px, max-content);
  @media (max-width: 1800px) {
    gap: 0 0.25rem;
  }
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)<{ isDisabled?: boolean }>`
  ${baseGridCss}

  ${props => props.isDisabled === true && css`color: ${ColorScheme.Text}cc;`}
`;

export const ActionsColumn = styled(PaginatorActionsColumn)`
  align-items: center;
  overflow: visible;
  gap: 8px;
`;

export const GhostIcon = styled.div`
  width: 34px;
  height: 24px;
  display: block;
`;
