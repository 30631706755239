import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { 
  PaginateOperationTypesCfopActions as PaginateActions, 
  ToggleDisableOperationTypeCFOPActions as ToggleActions 
} from 'store/ducks/operationsTypesCfop';
import { usePaginationCache } from 'hooks';
import { Scaffold } from 'layouts';
import {
  OperationTypesCfopList,
  FindOperations,
  OperationFilters,
  INITIAL_QUERY_STATE
} from 'components/Pages/Operations';
import { Paginator, ListingPageHeader, SettingsMenu } from 'components/Shared';
import * as S from './styles';

export const OperationsListPage = () => {
  const dispatch: AppDispatch = useDispatch();

  const { paginationCache, updatePaginationCache, handleSort } =
    usePaginationCache('operations-types-cfop');

  const {
    data: operations,
    pagination,
    loading,
  } = useSelector((state: RootState) => state.paginateOperationTypesCfop);

  const [query, setQuery] = useState<FindOperations>({
    ...INITIAL_QUERY_STATE,
    ...paginationCache,
  });

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback((): void => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  const onChangeOperationTypeCFOPStatus = useCallback((id: number) => {
    dispatch(ToggleActions.request(id, () => onQueryChange()))
  }, [dispatch, onQueryChange]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
      updatePaginationCache(query);
    };
  }, [dispatch, query, updatePaginationCache]);

  return (
    <Scaffold>
      <SettingsMenu />
      <S.MainPanel>
        <ListingPageHeader
          icon={<S.CompanyIcon />}
          title="Rotas"
          isLoading={loading}
          actions={
            <S.LinkButton to={'/configuracoes/rotas/criar'} size="small">
              <S.PlusIcon /> Nova Rota
            </S.LinkButton>
          }
        />
        <OperationFilters
          currentFilter={query}
          onFilter={newQuery => setQuery(newQuery)}
        />
        <OperationTypesCfopList
          operations={operations}
          currentSort={query}
          onSort={(sort) => handleSort(query, sort, setQuery)}
          onToggle={(id) => onChangeOperationTypeCFOPStatus(id)}
        />
        <Paginator onPageChange={onPageChange} pagination={pagination} />
      </S.MainPanel>
    </Scaffold>
  );
};
