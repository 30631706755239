import { Form } from '@unform/web';
import styled from 'styled-components';
export { Button } from 'styles/components';

export const ContainerFilter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Content = styled(Form)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  align-items: center;
  width: 100%;

  > div {
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
