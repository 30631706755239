import { Fonts, getScrollbarStyle } from 'styles/constants';
import { BasePanel, CloseIcon, TrashIcon, PlusIcon } from 'styles/components';
import { BaseButtonStyle } from 'styles/components';
import { Colors } from 'styles/constants';
import styled from "styled-components";
import { Select, Input } from "components/Shared";
import { FormActions as _FormActions } from 'styles/components';
export {
  InvoiceIcon,
  ClientIcon,
  CompanyIconOutline,
  DetailIcon,
  TruckIcon,
  EditAltIcon,
  ListIcon,
} from 'styles/components';
export * from '../Boxes/styles'

export const Trash = styled(TrashIcon)`
  display: flex;
  cursor: pointer;
  height: 22px;
  color: ${Colors.Magenta};
`
export const Plus = styled(PlusIcon)``;

export const Detail = styled.div`
  padding: 7px 12px;
  min-width: 150px;
  flex-direction: column;
  background-color: ${Colors.Gray70};
  z-index: 3;
  position: absolute;
  right: 100%;
  bottom: 1px;
  border-radius: 3px;
  animation: contentShow 150ms;
  &.none{
    display: none;
  }
  @keyframes contentShow {
    from { opacity: 0; transform: scale(.90) }
    to { opacity: 1; transform: scale(1) }
  }
`;

export const DetailInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.White};
  border-top: 1px dashed ${Colors.Gray60};
  font-family: ${Fonts.GilroyRegular};
  padding: 5px 0px;
  font-size: 14px;
  gap: 7px;
  p {
    font-size: 13px;
    align-items: center;
    justify-content: center;
  }
  &.start {
    border-top: none;
  }
`;

export const Info = styled.div`
  display: flex;
  position: relative;
`;

export const Container = styled(BasePanel)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  margin-bottom: 24px;
`;

export const MainGrid = styled.div`
  width: 100%;
  @media (max-width: 1107px){
    overflow-x: auto;
    ${getScrollbarStyle()};
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px dashed ${Colors.Gray50};
  margin-bottom: 13px;
  padding-bottom: 13px;
  font-size: 14px;
  font-family: ${Fonts.GilroyRegular};
  gap: 15px;
  @media (max-width: 950px){
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const Grid = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 2fr 2fr 3fr 1fr 0.2fr;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  justify-items: start;
  gap: 20px;
  margin-bottom: 5px;
  @media (max-width: 929px){
    gap: 6px;
  }
`;

export const ModalGrid = styled(Grid)`
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 10px;
  padding: 2px 0px;
`;

export const ModalGridTitle = styled(ModalGrid)`
  margin-bottom: 13px;
  padding: 0;
`;

export const Body = styled.div`
  width: 100%;
`;

export const BoxHeader = styled(Grid)`
  margin-bottom: 10px;
  font-family: ${Fonts.GilroySemiBold};
  text-transform: uppercase;
`;

export const Value = styled.div`
  font-family: ${Fonts.GilroyBold};
  color: ${Colors.Black};
  font-size: 16px;
  white-space: nowrap;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  gap: 0.5rem;
  white-space: nowrap;
`;

export const Column = styled(Box)`
  font-family: ${Fonts.OpenSans};
  font-size: 12px;
  gap: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  width: inherit;

  a {
    color: #000;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const BoxTitle = styled(Column)`
  font-family: ${Fonts.GilroySemiBold};
`;

export const GridTitle = styled(BoxTitle)`
  font-size: 18px;
  text-transform: none;
`;

export const Button = styled.button`
  display: flex;
  ${BaseButtonStyle}
  background-color: ${Colors.Orange};
  color: ${Colors.White};
  font-family: ${Fonts.GilroySemiBold};
  font-size: 12px;
  padding: 3px 10px 3px 3px;
  justify-self: flex-end;
  border-radius: 4px;
  gap: 4px;
  white-space: nowrap;
`;

export const ModalTitle = styled.div`
  display: flex;
  gap: 8px;
  font-family: ${Fonts.GilroyBold};
  font-size: 1.10rem;
  width: 100%;
  align-items: center;
  justify-content: start;
  border-bottom: 1px solid ${Colors.Gray30};
  padding-bottom: 13px;
  padding-right: 13px;
  margin-bottom: 13px;
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background-color: ${Colors.Black}cc;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  overflow: hidden;
`;

export const ModalContainer = styled(BasePanel)`
  position: relative;
  min-width: 650px;
  justify-content: start;
  flex-direction: column;
  padding: 24px;
  animation: contentShow 150ms;
  
  @keyframes contentShow {
    from { opacity: 0; transform: scale(.96) }
    to { opacity: 1; transform: scale(1) }
  }
`;

export const Close = styled(CloseIcon)`
  display: flex;
  position: absolute;
  right: 9px;
  top: 13px;
  cursor: pointer;
  height: 20px;
  color: ${Colors.Gray50};
`

//Modal Form
export const InputDiv = styled.div`
  display: flex;
  gap: 16px;
`

export const InputModal = styled(Input)`
  width: 100%;
`

export const FormActions = styled(_FormActions)`
  justify-content: center;
`;

export const FormButton = styled(Button)<{theme?: string}>`
  background-color: ${({theme})=> theme === 'accept' ? Colors.Orange : Colors.Gray60};
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 35px;
  padding: 10px;
  width: 50%;
`;