import { Scaffold } from 'layouts';
import * as S from './styles';
import { TrackingDetails } from 'components/Pages/Invoices';

export const Tracking = () => {
    return (
        <Scaffold>
            <S.Container>
                <TrackingDetails />
            </S.Container>
        </Scaffold>
    );
};
