import * as Yup from 'yup';
import { SupplierDocumentType } from 'contracts/Common';
import { Validator } from 'utils';

export interface Props {
  selectedType: SupplierDocumentType;
  selectedCountryId: number;
}

export const getSupplierShape = (props: Props) => {
  const { selectedType, selectedCountryId } = props;

  const brazilianAddress = {
    ibge: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
    addressZipcode: Yup.string().required('Informe o CEP'),
    addressStreet: Yup.string().required('Informe o logradouro'),
    addressNumber: Yup.string().required('Informe o número'),
    addressComplement: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
    addressNeighborhood: Yup.string().required('Informe o bairro'),
    addressCity: Yup.string().required('Informe a cidade'),
    addressState: Yup.string().required('Informe o estado'),
    addressUf: Yup.string().required('Selecione o estado'),
    addressLatitude: Yup.string().required('Informe a latitude'),
    addressLongitude: Yup.string().required('Informe a longitude'),
  };

  const mercosulAddress = {
    ibge: Yup.mixed().transform((_) => null),
    addressZipcode: Yup.string()
      .optional()
      .nullable()
      .transform((value) => {
        return value ? value.trim() : null;
      }),
    addressStreet: Yup.string().required('Informe o logradouro'),
    addressNumber: Yup.string()
      .optional()
      .nullable()
      .transform((value) => {
        return value ? value.trim() : null;
      }),
    addressComplement: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
    addressNeighborhood: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
    addressCity: Yup.string().required('Informe a cidade'),
    addressState: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
    addressLatitude: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
    addressLongitude: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
  };

  const addressValidations =
    selectedCountryId === 1 ? brazilianAddress : mercosulAddress;

  return {
    documentType: Yup.string().required('Informe o tipo de cliente'),
    document: Yup.mixed().test({
      name: 'testDocument',
      test: function (value: string) {
        try {
          Validator.validateDocument(value, selectedType);
          return true;
        } catch (error: any) {
          return this.createError({
            message: error?.message,
          });
        }
      },
    }),
    tradeName: Yup.string().required((): string => {
      return selectedType === 'cnpj'
        ? 'Informe a razão social'
        : 'Informe o nome completo';
    }),
    // address
    countryId: Yup.number()
      .typeError('Selecione o país')
      .required('Selecione o país'),
    ...addressValidations,
  };
};

export const getSupplierLogoShape = () => {
  return {
    logoFile: Yup.mixed().test({
      name: 'testLogoFile',
      test: function (value) {
        try {
          if (value === undefined) return true;
          if (!(value instanceof File)) {
            throw new Error('Selecione uma imagem de logo');
          }
          return true;
        } catch (error: any) {
          return this.createError({
            message: error.message,
          });
        }
      },
    }),
  };
};
