import styled, { css } from 'styled-components';
import {
  BasePaginatorHeader,
  BasePaginatorListItem,
  PaginatorActionsColumn,
  SAPIcon,
  ActionButton,
} from 'styles/components';
import { Colors } from 'styles/constants';
export {
  ActionButton,
  PaginatorColumn as Column,
  // PaginatorActionsColumn as ActionsColumn,
  LinkActionButton,
  EditIcon,
  ChevronRightCircleIcon,
  ErrorIcon
} from 'styles/components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const baseGridCss = css`
  grid-template-columns:
    60px repeat(2, 100px) repeat(3, 150px)
    repeat(2, 120px) 60px 40px minmax(0px, auto);
  @media (max-width: 1800px) {
    gap: 0 0.25rem;
  }
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`;

export const ActionsColumn = styled(PaginatorActionsColumn)`
  align-items: center;
  gap: 0;
`;

export const ErrorTooltip = styled.div`
  display:flex;
  span{
    display:none
  }
  &:hover{
    span{
      display: inline-block;
      position: absolute;
      z-index:2;
      border-radius: 6px;
      margin-bottom: 24px;
      background: ${Colors.Gray50};
      color: white;
      padding: 5px;
    }
  }
`
export const IconSAP = styled(SAPIcon)`
  width: 20px;
`
export const ActionButtonSAP = styled(ActionButton)`
  svg {
    width: 24px;
    height: 11px;
  }
`

export const Ghost = styled.div`
width: 20px;
height: 20px;
`