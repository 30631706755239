import { Form } from '@unform/web';
import { FormHandles } from "@unform/core";
import { Clear, Input, Select } from 'components/Shared';
import { FindMany } from 'contracts/Common';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import * as S from './styles';
import { useCarriers, useCompanies, useOperationTypes, useSuppliers } from 'hooks';
import { useDebounce } from 'hooks/useDebounce';

export interface FindOperations extends FindMany {
  carrierId?: number
  supplierId?: number
  operationTypeId?: number
  cfop?: string
  companyId?: number
}

interface Props {
  onFilter?: (query: FindOperations) => void;
  currentFilter: FindOperations;
  delay?: number;
}

export const INITIAL_QUERY_STATE: FindOperations = {
  page: 1,
  limit: 10,
};

export const OperationFilters = ({
  delay = 1000,
  currentFilter,
  onFilter,
}: Props) => {
  const [filters, setFilters] = useState<FindOperations>({
    ...currentFilter,
  });
  const [cfop, setCFOP] = useState<string | undefined>(currentFilter?.cfop);
  const debouncedSearchTerm = useDebounce<string | undefined>(
    cfop?.toString(),
    500
  );
  const formRef = useRef<FormHandles>(null);
  const { carrierOptions, loadingCarriers, fetchCarriers } = useCarriers();
  const { operationTypesOptions, loadingOperationTypes, fetchOperationTypes } =
    useOperationTypes();
  const { companyOptions, loadingCompanies, fetchCompanies } = useCompanies();
  const { suppliersOptions, loadingSuppliers, fetchSuppliers } = useSuppliers()

  const selectedCarrier = carrierOptions.find(
    (carrier) => carrier.value === filters?.carrierId
  );
  const selectedCompany = companyOptions.find(
    (company) => company.value === filters?.companyId
  );
  const selectedOperation = operationTypesOptions.find(
    (operation) => operation.value === filters.operationTypeId
  );
  const selectedSupplier = suppliersOptions.find(
    (supplier) => supplier.value === filters?.supplierId
  );

  const showTrash = useMemo(
    () => JSON.stringify(filters) !== JSON.stringify(INITIAL_QUERY_STATE) 
          && JSON.stringify(filters) !== JSON.stringify({...INITIAL_QUERY_STATE, dirty:true}),
    [filters]
  );

  const invokeOnFilter = useCallback((): void => {
    if (filters.dirty && onFilter) {
      onFilter(filters);
    }
  }, [filters, onFilter]);

  useEffect(() => {
    invokeOnFilter();
  }, [invokeOnFilter]);

  const clearFilter = useCallback(() => {
    formRef.current && formRef.current.reset();
    setFilters(() => ({...INITIAL_QUERY_STATE, dirty: true}))
  }, []);

  useEffect(() => {
    fetchCarriers()
    fetchCompanies()
    fetchOperationTypes()
    fetchSuppliers()
  }, [fetchCarriers, fetchCompanies, fetchOperationTypes, fetchSuppliers])

  useEffect(() => {
    setFilters(prev => ({
      ...prev,
      cfop:
        debouncedSearchTerm !== "" ? debouncedSearchTerm : undefined,
      dirty: true
    })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleChangeCompany = useCallback((value?: string | number) => {
    setFilters((prev) => ({
      ...prev,
      companyId: value && !Number.isNaN(value) ? Number(value) : undefined,
      dirty: true,
    }))
  }, []);

  const handleChangeSupplier = useCallback((value?: string | number) => {
    setFilters((prev) => ({
      ...prev,
      supplierId: value && !Number.isNaN(value) ? Number(value) : undefined,
      dirty: true,
    }))
  }, []);

  const handleChangeCarrier = useCallback((value?: string | number) => {
    setFilters((prev) => ({
      ...prev,
      carrierId: value && !Number.isNaN(value) ? Number(value) : undefined,
      dirty: true,
    }))
  }, []);

  const handleChangeBranch = useCallback((value?: string | number) => {
    setFilters((prev) => ({
      ...prev,
      operationTypeId: value && !Number.isNaN(value) ? Number(value) : undefined,
      dirty: true,
    }))
  }, []);

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={() => {}}>
        <Select
          name="companyId"
          options={companyOptions}
          placeholder="Empresa"
          onChange={(opt) => handleChangeCompany(opt?.value)}
          isLoading={loadingCompanies}
          value={selectedCompany}
          isClearable
        />
        <Select
          name="supplierId"
          options={suppliersOptions}
          placeholder="Fornecedor"
          onChange={(opt) => handleChangeSupplier(opt?.value)}
          isLoading={loadingSuppliers}
          value={selectedSupplier}
          isClearable
        />
        <Select
          name="carrierId"
          options={carrierOptions}
          placeholder="Transportadora"
          onChange={(opt) => handleChangeCarrier(opt?.value)}
          isLoading={loadingCarriers}
          value={selectedCarrier}
          isClearable
        />
        <Select
          name="operationTypeId"
          options={operationTypesOptions}
          placeholder="Ramificação"
          onChange={(opt) => handleChangeBranch(opt?.value)}
          isClearable
          isLoading={loadingOperationTypes}
          value={selectedOperation}
        />
        <Input
          name="cfop"
          type="search"
          placeholder="Nº da CFOP"
          defaultValue={filters?.cfop?.toString()}
          onChange={(e) => setCFOP(e.target.value)}
        />
        <Clear onClick={clearFilter} title="Limpar filtro" show={showTrash} />
      </Form>
    </S.Container>
  );
};
