import * as Yup from 'yup';

export interface Props {
  selectedCountryId: number;
}

export const getAuditorShape = (props: Props) => {
  const { selectedCountryId } = props;

  const brazilianAddress = {
    ibge: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
    addressZipcode: Yup.string().required('Informe o CEP'),
    addressStreet: Yup.string().required('Informe o logradouro'),
    addressNumber: Yup.string().required('Informe o número'),
    addressComplement: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
    addressNeighborhood: Yup.string().required('Informe o bairro'),
    addressCity: Yup.string().required('Informe a cidade'),
    addressState: Yup.string().required('Informe o estado'),
    addressUf: Yup.string().required('Selecione o estado'),
    addressLatitude: Yup.string().required('Informe a latitude'),
    addressLongitude: Yup.string().required('Informe a longitude'),
  };

  const mercosulAddress = {
    ibge: Yup.mixed().transform((_) => null),
    addressZipcode: Yup.string()
      .optional()
      .nullable()
      .transform((value) => {
        return value ? value.trim() : null;
      }),
    addressStreet: Yup.string().required('Informe o logradouro'),
    addressNumber: Yup.string()
      .optional()
      .nullable()
      .transform((value) => {
        return value ? value.trim() : null;
      }),
    addressComplement: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
    addressNeighborhood: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
    addressCity: Yup.string().required('Informe a cidade'),
    addressState: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
    addressLatitude: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
    addressLongitude: Yup.string()
      .optional()
      .nullable()
      .transform((value: string) => {
        return value ? value.trim() : null;
      }),
  };

  const addressValidations =
    selectedCountryId === 1 ? brazilianAddress : mercosulAddress;

  return {
    auditorName: Yup.string().required('Preencha o nome do fiscal'),
    auditorOrgName: Yup.string().required('Preencha o orgão fiscalizador'),
    auditorPhoneNumber: Yup.string().required('Preencha o telefone'),
    auditorEmail: Yup.string().email('Email inválido').required('Preencha o email'),
    // address
    countryId: Yup.number()
      .typeError('Selecione o país')
      .required('Selecione o país'),
    ...addressValidations,
  };
};
