import { SortingParams } from 'contracts/Common';
import { FindCollect, PaginatedCollect } from 'contracts/Collects';
import { Pagination } from 'contracts/Pagination';
import { usePaginationCache } from 'hooks';
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import {
  ExportCollectsActions,
  PaginateCollectsActions as PaginateActions,
} from 'store/ducks/collects';
import * as XLSX from 'xlsx';

interface ReverseLogisticContextType {
  collects: PaginatedCollect[];
  loading: boolean;
  query: FindCollect;
  pagination: Pagination | undefined;
  onPageChange: (page: number) => void;
  onQueryChange: (newQuery?: FindCollect, force?: boolean) => void;
  onSort: (newState: SortingParams) => void;
  reset: () => void;
  handleExport: () => void;
  loadingExport: boolean;
}

export const ReverseLogisticContext = createContext(
  {} as ReverseLogisticContextType
);

interface ReverseLogisticsProviderProps {
  children: ReactNode;
}

export function ReverseLogisticProvider({
  children,
}: ReverseLogisticsProviderProps) {
  const { paginationCache, updatePaginationCache, handleSort } =
    usePaginationCache<FindCollect>('pendingRelease');
  const [query, setQuery] = useState<FindCollect>({
    page: 1,
    limit: 10,
    status: 'Todos',
    ...paginationCache,
  });

  const dispatch: AppDispatch = useDispatch();

  const {
    data: collects,
    pagination,
    loading,
  } = useSelector((state: RootState) => state.paginateCollects);

  const { loading: exportLoading } = useSelector(
    (state: RootState) => state.exportCollects
  );

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback(
    (newQuery?: FindCollect, force = false): void => {
      const search = newQuery?.search;
      if (search) {
        newQuery.search = search;
      }
      const newData = { ...query, ...newQuery };
      if (JSON.stringify(newData) !== JSON.stringify(query) || force) {
        setQuery((prev) => ({ ...prev, ...newQuery, page: 1 }));
      }
    },
    [query]
  );

  function reset() {
    dispatch(PaginateActions.reset());
    updatePaginationCache(query);
  }

  const onSort = useCallback((newState: SortingParams) => {
    handleSort(query, newState, setQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateExportFile = useCallback((data: any) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Coletas');
    XLSX.writeFile(wb, `coletas-${new Date().getTime()}.xlsx`);
  }, []);

  const handleExport = useCallback(() => {
    dispatch(
      ExportCollectsActions.request({ ...query, export: 1 }, generateExportFile)
    );
  }, [dispatch, generateExportFile, query]);

  useEffect(() => {
    dispatch(PaginateActions.request(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <ReverseLogisticContext.Provider
      value={{
        pagination,
        collects,
        loading,
        query,
        onPageChange,
        onQueryChange,
        onSort,
        reset,
        handleExport,
        loadingExport: exportLoading,
      }}
    >
      {children}
    </ReverseLogisticContext.Provider>
  );
}
