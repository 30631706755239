import styled, { css } from "styled-components";
import { Colors, getScrollbarStyle } from "styles/constants";
import { BasePaginatorHeader, BasePaginatorListItem } from "styles/components";
export { MessageDetailIcon } from "styles/components";

export const Content = styled.div`
  margin: 10px 0 20px;
  color: ${Colors.Gray50};
  font-size: 1.25rem;
  line-height: 1.25rem;
  max-height: 40vh;
  overflow-y: auto;
  ${getScrollbarStyle()}
`;

export const Footer = styled.footer`
  border-top: 1px solid ${Colors.Gray30};
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > div {
    width: 50%;
    margin: 0;
    margin-right: 0.5rem;
  }
`;

const baseGridCss = css`
  grid-template-columns: 1fr repeat(4, 2fr) 1fr repeat(4, 1fr);
  &:not(:last-child) {
    margin-bottom: 0;
  }
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`;

export const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: ${Colors.Orange};
  color: ${Colors.White};
  font-weight: 700;
  border-radius: 6px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
