import styled, { keyframes } from 'styled-components'
import { Loader } from 'styles/components/icons'
import { Colors } from 'styles/constants'

const spin = keyframes`
  to {
    transform: rotate(1turn)
  }
`

export const Loading = styled(Loader)<{color?: string}>`
  width: 24px;
  height: 24px;
  animation: ${spin} 1s linear infinite;
  color: ${({color})=>(color ? color : Colors.Gray70)}
`