import styled from "styled-components";
import { Colors } from 'styles/constants';
import { 
  Button
} from '../../../Invoices/TrackingDetails/styles';

export const BoxDelay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 25px;
`;

export const TagDelay = styled.div`
  display: flex;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  background-color: #F6E5B5;
  padding: 16px 22px;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled.div`

`;

export const ButtonAllowance = styled(Button)`
  padding: 6px 10px;
`;

export const Info = styled.div`
  span {

  }
`;