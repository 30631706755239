import { RouteObject, Outlet, Navigate } from "react-router-dom";
import type { RouterProps } from "contracts/Router";
import {
  VehicleTypeUpdatePage,
  VehicleTypesListPage,
  VehicleTypeCreationPage,
} from "pages/VehicleTypes";

const vehicleTypesRoutes = ({
  location,
  userBelongsToAnyOf,
  hasPermissionTo
}: RouterProps): RouteObject => ({
  path: "configuracoes/tipos-veiculo",
  element: hasPermissionTo(['VEHICLE-TYPES.FINDVEHICLETYPES']) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: "",
      element: <VehicleTypesListPage />,
    },
    {
      path: "criar",
      element: <VehicleTypeCreationPage />,
    },
    {
      path: ":id/editar",
      element: <VehicleTypeUpdatePage />,
    },
  ],
});

export default vehicleTypesRoutes;
