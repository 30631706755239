import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { DialogContent } from 'components/Shared/Dialog'
import { PendingReleaseContext } from 'contexts/PendingReleaseContext'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { TruckIcon } from 'styles/components';
import { Status } from 'components/Pages/Invoices';

import * as S from './styles';
import { Formatter } from 'utils';
import { DisablePendingInvoicePayload } from 'contracts/Invoice';
import { DisablePendingInvoiceActions } from 'store/ducks/pendingRelease';
import { FetchCarriersListActions } from 'store/ducks/carriers';

interface FormData {
  reason: string;
}

const DisableInvoiceModal = () => {
  const { 
    refModal, 
    selectedList, 
    onQueryChange, 
    clearSelectList,
    changeOpenModal
  } = useContext(PendingReleaseContext)
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch()

  const {
    loading
  } = useSelector((state: RootState) => state.disablePendingInvoice);

  const formattedList = useMemo(() => {
    return selectedList.map(invoice => ({
      id: invoice.id,
      nf: `${invoice.infnfeIdeNnf}-${invoice.infnfeIdeSerie}`,
      dhEmi: Formatter.date(invoice.infnfeIdeDhemi, { format: 'dd/MM/yyyy HH:mm' }),
      carrierName: invoice.carrier?.tradeName || '---',
      status: invoice.status.length > 0 ? ({
        name: invoice.status[0].name,
        color: invoice.status[0].color
      }): null
    }))
  }, [selectedList])

  useEffect(() => {
    dispatch(FetchCarriersListActions.request({ asList: true}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = useCallback<SubmitHandler>((data: FormData) => {

    const { reason } = data
    const payloadData: DisablePendingInvoicePayload = {
      reason,
      ids: selectedList.map(invoice => invoice.id)
    }
    
    dispatch(DisablePendingInvoiceActions.request(payloadData, () => {
      onQueryChange({}, true);
      clearSelectList()
      changeOpenModal()
    }))
  }, [changeOpenModal, clearSelectList, dispatch, onQueryChange, selectedList])

  return (
    <DialogContent
      container={refModal}
      title="Inutilizar as Notas Fiscais listadas abaixo"
      icon={<TruckIcon />}
      style={{ maxWidth: 600 }}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.Content>
          <S.ListHeader>
            <span>NF</span>
            <span>DT. Emissão</span>
            <span>Transportadora</span>
            <span>Status</span>
          </S.ListHeader>
          {formattedList.map(invoice => (
            <S.ListItem key={invoice.id}>
              <span>{invoice.nf}</span>
              <span>{invoice.dhEmi}</span>
              <span>{invoice.carrierName}</span>
              <span>
              {invoice.status ? (
                <Status color={invoice.status.color}>{invoice.status.name}</Status>
              ) : (
                <Status>Sem status</Status>
              )}
              </span>
            </S.ListItem>
          ))}
        </S.Content>
        <S.Footer>
          <S.TextArea name="reason" label="Justificativa" />
          <S.Button type="submit" disabled={loading}>
            {loading ? `Inutilizando` : `Inutilizar`}
          </S.Button>
        </S.Footer>
      </Form>
    </DialogContent>
  )
}

export { DisableInvoiceModal }
