import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { PaginatedClassification } from 'contracts/Classifications';
import { SortingParams } from 'contracts/Common';
import React, { useCallback } from 'react';
import * as S from './styles';

interface Props {
  classifications: PaginatedClassification[];
  onSort?: (sort: SortingParams) => void;
  currentSort: SortingParams;
}

interface ItemProps {
  classification: PaginatedClassification;
}

export const ClassificationsList: React.FC<Props> = ({
  classifications,
  onSort,
  currentSort,
}) => {
  const Item = useCallback(({ classification }: ItemProps): JSX.Element => {
    const { id, description } = classification;

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column>{description}</S.Column>
        <S.ActionsColumn>
          <S.LinkActionButton
            to={`/configuracoes/logistica-reversa/classificacoes/${id}/editar`}
          >
            <S.EditIcon />
          </S.LinkActionButton>
        </S.ActionsColumn>
      </S.ListItem>
    );
  }, []);

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="description" label="DESCRIÇÃO" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {classifications.map((classification) => (
        <Item key={classification.id} classification={classification} />
      ))}
    </S.List>
  );
};
