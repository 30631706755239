import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import type { ListedOperationType } from "contracts/OperationTypes";

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess", "onFailure"],
    success: ["data"],
    failure: ["errorMessage"],
    softReset: [],
    reset: [],
  },
  {
    prefix: "LIST_OPERATION_TYPES_",
  }
);

export interface ListOperationTypesState {
  data: ListedOperationType[];
  loading: boolean;
  errorMessage: string | null;
}

export interface ListOperationTypesRequestAction {
  query?: Record<string, any>;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface SuccessAction {
  data: ListedOperationType[];
}

interface FailureAction {
  errorMessage: string;
}

const INITIAL_STATE: ListOperationTypesState = {
  data: [],
  loading: false,
  errorMessage: null,
};

const request = (state: ListOperationTypesState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (state: ListOperationTypesState, action: SuccessAction) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (state: ListOperationTypesState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const softReset = (state: ListOperationTypesState) =>
  update(state, {
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const listOperationTypes = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const ListOperationTypesTypes = Types;
export const ListOperationTypesActions = Creators;
