import {
  ListTransitTimesActions as MainActions,
  FetchTransitTimeActions as FetchActions,
} from "store/ducks/transitTime";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "store";

export const useTransitTimes = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: transitTime, loading: loadingTransitTime } = useSelector(
    (state: RootState) => state.fetchTransitTime
  );

  const { data: transitTimes, loading: loadingTransitTimes } = useSelector(
    (state: RootState) => state.listTransitTimes
  );

  const fetchTransitTimes = useCallback(
    (query: any): void => {
      dispatch(MainActions.request({ ...query }));
    },
    [dispatch]
  );
  
  const fetchTransitTime = useCallback(
    (id: number): void => {
      dispatch(FetchActions.request(id));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    transitTime,
    transitTimes,
    loadingTransitTimes,
    loadingTransitTime,
    fetchTransitTimes,
    fetchTransitTime,
  };
};

export type TransitTimesHook = ReturnType<typeof useTransitTimes>;
