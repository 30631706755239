import { SettingLayout } from "layouts/SettingLayout";
import React from "react";
import { UsersInnerOptions } from "../UsersInnerOptions";
import { Box } from "components/ui/Box";
import CreateUsuarioForm from "./CreateForm";

const CreateUsuario = () => {
  const { options } = UsersInnerOptions();
  return (
    <SettingLayout options={options}>
      <Box>
        <CreateUsuarioForm />
      </Box>
    </SettingLayout>
  );
};

export default CreateUsuario;
