import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  FetchWarehouseMemberActions as FetchActions,
  UpdateWarehouseMemberActions as UpdateActions,
} from "store/ducks/warehouseMembers"
import { RootState } from "store"
import { Scaffold } from "layouts"
import { FormPageHeader } from "components/Shared"
import {
  MemberUpdateForm,
  MemberWarehouseList,
} from "components/Pages/WarehouseMembers"
import * as S from "./styles"
import { useDispatch } from "react-redux"
import AddWarehouseModal from "components/Pages/WarehouseMembers/AddWarehouseModal"
import { Button } from "styles/components"

export const WarehouseMemberUpdatePage: React.FC = () => {
  const { id } = useParams() as { id: string }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const { data, loading } = useSelector(
    (state: RootState) => state.fetchWarehouseMember
  )

  const fetchmember = useCallback((): void => {
    dispatch(FetchActions.request(id))
  }, [dispatch, id])

  const onUpdate = useCallback((): void => {
    navigate("/configuracoes/armazem/usuarios")
  }, [navigate])

  useEffect(() => {
    fetchmember()
  }, [fetchmember])

  return (
    <Scaffold>
      <AddWarehouseModal
        member={data}
        refresh={() => {
          fetchmember()
          setIsOpen(false)
        }}
        onCloseButton={() => setIsOpen(false)}
        isOpen={isOpen}
      />
      <S.PageContainer>
        <S.MainPanel>
          <FormPageHeader
            icon={<S.UserRectangleIcon />}
            title="Editar usuário de armazém"
            isLoading={loading}
            actions={
              <S.LinkButton size="small" to="/configuracoes/armazem/usuarios">
                <S.ArrowLeftIcon /> Voltar
              </S.LinkButton>
            }
          />
          <MemberUpdateForm member={data} onUpdate={onUpdate} />
        </S.MainPanel>
        <S.MainPanel>
          <FormPageHeader
            title="Acesso a outros armazéns"
            icon={<S.SupplierIcon />}
            actions={
              <Button size="small" onClick={() => setIsOpen(true)}>
                <S.PlusIcon /> Novo armazém
              </Button>
            }
          />
          <MemberWarehouseList
            companies={data?.companyUser ?? []}
            userId={data?.id ?? null}
            refresh={fetchmember}
          />
        </S.MainPanel>
      </S.PageContainer>
    </Scaffold>
  )
}
