import styled, { keyframes } from "styled-components";
import * as Dialog from "@radix-ui/react-dialog"
import { Colors } from "styles/constants";
export { CloseIcon } from 'styles/components';

const overlayShow = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`

const contentShow = keyframes`
  0% { opacity: 0; transform: translate(-50%, -48%) scale(.96) }
  100%' { opacity: 1; transform: translate(-50%, -50%) scale(1) }
`

export const DialogOverlay = styled(Dialog.Overlay)`
  background-color: ${Colors.Black}cc;
  pointer-events: none !important;
  position: fixed;
  z-index: 10;
  inset: 0;
  overflow-y: auto;
  @media (prefers-reduced-motion: no-preference) {
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
`

export const DialogContent = styled(Dialog.Content)`
  pointer-events: auto !important;
  background-color: ${Colors.White};
  z-index: 15;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 800px;
  max-height: 85vh;
  padding: 25px;
  @media (prefers-reduced-motion: no-preference) {
    animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &:focus {
    outline: none;
  }
`

export const DialogHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${Colors.Gray30};
  
`

export const DialogTitle = styled(Dialog.DialogTitle)`
  margin: 0;
  font-weight: 500;
  color: ${Colors.Black};
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  > svg {
    margin-right: 0.5rem;
  }
`