import { SettingLayout } from 'layouts/SettingLayout';
import React from 'react';
import { UsersInnerOptions } from '../UsersInnerOptions';
import { Box } from 'components/ui/Box';
import UpdateUsuarioForm from './UpdateForm';

const UpdateUsuario: React.FC = () => {
    const { options } = UsersInnerOptions();
    return (
        <SettingLayout options={options}>
            <Box>
                <UpdateUsuarioForm />
            </Box>
        </SettingLayout>
    );
};

export default UpdateUsuario;
