import { useCallback, useContext } from "react";
import { Dialog, DialogTrigger } from "components/Shared/Dialog";
import * as S from "./styles";
import { ReleasedContext, ModalTypes } from "contexts/ReleasedContext";
import { AddJustificationsModal } from "../AddJustificationsModal";
import { FinishedContext } from "contexts/FinishedContext";
import { usePermission } from "hooks/usePermission";

const DropdownMenuInvoices = () => {
  const { openedModal, changeOpenModal, selectedList } = useContext(
    FinishedContext
  );

  const { hasPermissionTo } = usePermission()

  const handleModal = useCallback(
    (event: Event, type: ModalTypes) => {
      event.preventDefault();
      changeOpenModal(type);
    },
    [changeOpenModal]
  );

  const hasPermission =  hasPermissionTo(['EFFECTIVEINVOICES.CREATEEFFECTIVATIONSAPJUSTIFICATION']) 


  return (
    <S.Box>
      <S.DropdownMenuRoot>
        <S.Trigger asChild disabled={!selectedList.length}>
          <S.IconButton
            aria-label="Customise options"
            clicable={selectedList.length > 0}
          >
            <S.Icon size={25} />
          </S.IconButton>
        </S.Trigger>
        {selectedList.length > 0 && (
          <S.Portal>
            <S.Content sideOffset={-1} side="bottom" align="end">
              <Dialog
                open={openedModal === "justificativas" && hasPermission}
                key="justificativas"
                onOpenChange={(open) => !open && changeOpenModal()}
              >
                <DialogTrigger asChild>
                  <S.Item onSelect={(e: Event) => handleModal(e, "justificativas")}>
                    Adicionar Justificativas
                  </S.Item>
                </DialogTrigger>
                <AddJustificationsModal />
              </Dialog>
            </S.Content>
          </S.Portal>
        )}
      </S.DropdownMenuRoot>
    </S.Box>
  );
};

export { DropdownMenuInvoices };
