import styled from 'styled-components';
import { Colors, Fonts } from 'styles/constants';
import { Header } from '../../Invoices/TrackingDetails/styles';
import { BasePanel } from 'styles/components';
export { 
  Title, 
  Invoice, 
  InvoiceIcon, 
  Box, 
  BoxContent, 
  BoxHeader, 
  BoxBody, 
  CompanyIconOutline, 
  ClientIcon, 
  ArrowLeftIcon, 
  Back,
  Info,
  EditAltIcon,
  Button
} from '../../Invoices/TrackingDetails/styles';
export { Detail } from '../../Invoices/TrackingDetails/Boxes/styles';

//Container
export const CollectView = styled.div`
  display: block;
`;

//HeaderBoxes
export const CancelInfo = styled(Header)`
  background-color: #FFB7B7;
  height: auto;
`

export const HeaderCollect = styled(Header)`
  /* margin-top: 0px; */
  text-transform: uppercase;

  .city, span {
    text-transform: none;
  }

`;
//End HeaderBoxes
