import React, { useContext, useRef, useCallback, useEffect } from "react";
import { Paginator } from "components/Shared";
import { Scaffold } from "layouts";
import * as S from "./styles";
import {
  DropdownMenuInvoices,
  PendingReleaseList,
  PendingReleaseFilter,
  Navigation,
  InvoiceModal,
  Ref as InvoiceModalRef,
} from "components/Pages/Invoices/";
import { PendingReleaseContext } from "contexts/PendingReleaseContext";
import { Loading } from "components/Shared/Loading";
import { DownloadXLS } from "components/Shared/DownloadXLS";
import { useNavigate } from "react-router-dom";
import { usePermission } from "hooks/usePermission";

export const ListPendingRelease: React.FC = () => {
//   const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission()
  const navigate = useNavigate();
  const invoiceModalRef = useRef<InvoiceModalRef>(null);
  const { onPageChange, onSort, pagination, loading, query } = useContext(
    PendingReleaseContext
  );

  const handleChangeInvoice = useCallback(
    (invoiceId: number) => {
      invoiceModalRef.current?.selectInvoice(invoiceId);
    },
    [invoiceModalRef]
  );

  useEffect(() => {
 
    if (!hasPermissionTo(["PENDINGINVOICES.VISUALIZE"])) {
          navigate("/inbound/notas-fiscais/tracking");
        }
  }, [navigate, hasPermissionTo]);

  return (
    <Scaffold>
      <S.Container>
        <Navigation />
        <S.Panel>
          <InvoiceModal ref={invoiceModalRef} />
          <S.FilterContainer>
            <PendingReleaseFilter />
            {loading && <Loading />}
            <S.IconsBox>
              <DownloadXLS
                archiveName="INBOUND-RELATORIO-PENDENTES"
                query={query}
                type="Pendente"
              />
              <DropdownMenuInvoices />
            </S.IconsBox>
          </S.FilterContainer>
          <PendingReleaseList
            onSort={onSort}
            onSelectInvoice={handleChangeInvoice}
          />
          <Paginator onPageChange={onPageChange} pagination={pagination} />
        </S.Panel>
      </S.Container>
    </Scaffold>
  );
};
