import { useCallback, useContext } from "react";
import type { SortingParams } from "contracts/Common";
import type { PaginatedInvoice } from "contracts/Invoice";
import { SortableHeader, SortableHeadersGroup } from "components/Shared";
import { Status } from "components/Pages/Invoices";

import * as S from "./styles";
import { Formatter } from "utils/Formatter";
import { CheckboxRadix } from "components/Shared/Forms/CheckboxRadix";
import { PendingReleaseContext } from "contexts/PendingReleaseContext";
import { useAuth } from "hooks";
import { ErrorIcon } from "styles/components";
import { usePermission } from "hooks/usePermission";
import { useNavigate } from "react-router-dom";

interface Props {
  onSort?: (sort: SortingParams) => void;
  onSelectInvoice?: (invoiceId: number) => void;
}

interface ItemProps {
  pendingRelease: PaginatedInvoice;
}

export const PendingReleaseList = ({ onSort, onSelectInvoice }: Props) => {
  const {
    pendingReleases,
    query,
    onToggleSelected,
    onRemoveSelecteds,
    onSelectInvoices,
    selectedList,
  } = useContext(PendingReleaseContext);
  const { userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission();
  const navigate = useNavigate();
  const Item = useCallback(
    ({ pendingRelease }: ItemProps): JSX.Element => {
      const {
        id,
        infnfeIdeNnf,
        infnfeIdeSerie,
        infnfeIdeDhemi,
        deadlineDate,
        supplier,
        company,
        carrier,
        status,
        isPendingLetter,
        canceledAt,
        disabledAt,
        destEnderdestUf,
      } = pendingRelease;

      const isChecked = selectedList.some((invoice) => invoice.id === id);
      const isImport = destEnderdestUf
        ? ["EXTERIOR", "EX"].includes(destEnderdestUf)
        : false;
      const finalOrigin = isImport ? company : supplier;
      const finalDestiny = isImport ? supplier : company;

      return (
        <S.ListItem isDisabled={!!disabledAt}>
          <S.Column>
            {infnfeIdeNnf}-{infnfeIdeSerie}
          </S.Column>
          <S.Column>
            {(infnfeIdeDhemi && Formatter.date(infnfeIdeDhemi)) || "---"}
          </S.Column>
          <S.Column>
            {(deadlineDate && Formatter.date(deadlineDate)) || "---"}
          </S.Column>
          <S.Column>{finalOrigin?.tradeName}</S.Column>
          <S.Column>{finalDestiny?.tradeName}</S.Column>
          <S.Column hasAlert={isPendingLetter ? true : undefined}>
            {carrier?.tradeName ? (
              <>
                {carrier.tradeName}
                {isPendingLetter ? (
                  <ErrorIcon title="Carta de correção pendente" width="20px" />
                ) : null}
              </>
            ) : (
              "---"
            )}
          </S.Column>
          <S.Column width="135px">
            {status.length > 0 ? (
              <Status color={status[0].color}>{status[0].name}</Status>
            ) : (
              <Status>Sem status</Status>
            )}
          </S.Column>
          <S.ActionsColumn>
            <S.ActionButton mood="void" onClick={() => navigate(`${id}`)}>
              <S.ChevronRightCircleIcon />
            </S.ActionButton>
            <CheckboxRadix
              id={id}
              isChecked={isChecked}
              handleCheck={() => onToggleSelected(pendingRelease)}
              hidden={!hasPermissionTo(["PENDINGINVOICES.RELEASEFORTRACKING"])}
              disabled={!!canceledAt || !!disabledAt}
            />
          </S.ActionsColumn>
        </S.ListItem>
      );
    },
    [onSelectInvoice, onToggleSelected, selectedList, userBelongsToAnyOf]
  );

  const selectedListIDs = selectedList.map((invoice) => invoice.id);

  const selectables = pendingReleases.filter(
    (invoice) => !invoice.canceledAt && !invoice.disabledAt
  );

  const allSelected = selectables.every((invoice) =>
    selectedListIDs.includes(invoice.id)
  );

  const handleToggleAllInvoices = () => {
    if (allSelected) {
      onRemoveSelecteds(pendingReleases.map((invoice) => invoice.id));
    } else {
      onSelectInvoices(pendingReleases);
    }
  };

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={query}>
          <SortableHeader column="infnfeIdeNnf" label="NF" />
          <SortableHeader column="infnfeIdeDhemi" label="DT.EMISSÃO" />
          <SortableHeader column="deadlineDate" label="PRAZO" />
          <SortableHeader column="supplierTradeName" label="ORIGEM" />
          <SortableHeader column="companyTradeName" label="DESTINO" />
          <SortableHeader column="carrierTradeName" label="TRANSPORTADORA" />
          <SortableHeader column="statusName" label="STATUS" />
          <S.ActionsColumn>
            {hasPermissionTo(["PENDINGINVOICES.RELEASEFORTRACKING"]) ? (
              <CheckboxRadix
                id={0}
                handleCheck={handleToggleAllInvoices}
                isChecked={allSelected}
              />
            ) : (
              <S.GhostIcon />
            )}
          </S.ActionsColumn>
        </SortableHeadersGroup>
      </S.ListHeader>
      {pendingReleases.map((pendingRelease) => (
        <Item key={pendingRelease.id} pendingRelease={pendingRelease} />
      ))}
    </S.List>
  );
};
