import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import type { Status } from 'contracts/Status';

const { Types, Creators } = createActions(
  {
    request: ['params', 'onSuccess', 'onFailure'],
    success: ['data'],
    failure: ['errorMessage'],
    softReset: [],
    reset: [],
  },
  {
    prefix: 'FETCH_STATUS_LIST_',
  }
);

export interface FetchStatusListState {
  data: Status[];
  loading: boolean;
  errorMessage: string | null;
}

export interface FetchStatusListRequestAction {
  params: URLSearchParams
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface SuccessAction {
  data: Status[];
}

interface FailureAction {
  errorMessage: string;
}

const INITIAL_STATE: FetchStatusListState = {
  data: [],
  loading: false,
  errorMessage: null,
};

const request = (state: FetchStatusListState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (state: FetchStatusListState, action: SuccessAction) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (state: FetchStatusListState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const softReset = (state: FetchStatusListState) =>
  update(state, {
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const fetchStatusList = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const FetchStatusListTypes = Types;
export const FetchStatusListActions = Creators;
