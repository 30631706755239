import * as Yup from "yup";

/**TODAS VALIDAÇÕES */

export const getCollectShape = () => {
  // Prazo oficial nunca maior que prazo transportadora; [ok]
  // Prazo transportadora nunca menor que prazo oficial e data de criação da coleta; [ok]
  // Prazo de entrega nunca menor prazo para coleta; [ok]

  // Data de saída nunca menor que data de criação da coleta e a data da notificação;
  // Data de coleta nunca menor que data de saída;
  // Data de entrega nunca menor que data de coleta;

  return {
    carrierId: Yup.number().nullable().required("Selecione a transportadora"),
    clientId: Yup.number().nullable().required("Selecione o cliente"),
    companyId: Yup.number().nullable().required("Selecione o destino"),
    auditorId: Yup.number().nullable().required("Selecione o fiscal"),
    requestRqps: Yup.string()
      .nullable()
      .required("Informe o RQPS do solicitante"),
    requestRiv: Yup.string()
      .nullable()
      .required("Informe o RIV do solicitante"),
    reverseLogisticsReasonId: Yup.number()
      .nullable()
      .required("Selecione o motivo da coleta"),
    reverseLogisticsClassificationId: Yup.number()
      .nullable()
      .required("Selecione a classificação da coleta"),
    collectDaysLeft: Yup.number()
      .nullable()
      .required("Informe o número de dias para coleta"),
    requestDate: Yup.date()
      .nullable()
      .default(undefined)
      .required("Informe a data de solicitação"),
    collectNotificationDate: Yup.date()
      .nullable()
      .default(undefined)
      .required("Informe a data de notificação"),
    collectCarrierDeadline: Yup.date()
      .nullable()
      .default(undefined)
      .required("Informe o prazo da transportadora")
      .when("requestDate", (requestDate: any, schema: any) => {
        if (requestDate && typeof requestDate === "object") {
          return schema.test({
            test: function (value: any) {
              return value >= requestDate;
            },
            message:
              "O prazo da transportadora deve ser maior que a data de solicitação",
          });
        }
        return schema;
      }),
    // collectOficialDeadline: Yup.date()
    //   .nullable()
    //   .default(undefined)
    //   .required('Informe o prazo oficial')
    //   .when('collectCarrierDeadline', (collectCarrierDeadline: any, schema: any) => {
    //     if (collectCarrierDeadline && typeof collectCarrierDeadline === 'object') {
    //       return schema.test({
    //         test: function(value: any) {
    //           return value <= collectCarrierDeadline;
    //         },
    //         message: 'O prazo oficial deve ser menor que o prazo da transportadora',
    //       });
    //     }
    //     return schema;
    //   }),
    collectDeliveryDeadline: Yup.date()
      .nullable()
      .default(undefined)
      .when(
        "collectCarrierDeadline",
        (collectCarrierDeadline: any, schema: any) => {
          if (
            collectCarrierDeadline &&
            typeof collectCarrierDeadline === "object"
          ) {
            return schema.test({
              test: function (value: any) {
                if (value) {
                  return value >= collectCarrierDeadline;
                }
                return schema;
              },
              message:
                "O prazo de entrega deve ser maior que o prazo da transportadora",
            });
          }
          return schema;
        }
      ),
    departureDate: Yup.date()
      .nullable()
      .default(undefined)
      .when("requestDate", (requestDate: any, schema: any) => {
        if (requestDate && typeof requestDate === "object") {
          return schema.test({
            test: function (value: any) {
              if (value) {
                return value >= requestDate;
              }
              return schema;
            },
            message: "A data de saída deve ser maior que a data de solicitação",
          });
        }
        return schema;
      })
      .when(
        "collectNotificationDate",
        (collectNotificationDate: any, schema: any) => {
          if (
            collectNotificationDate &&
            typeof collectNotificationDate === "object"
          ) {
            return schema.test({
              test: function (value: any) {
                if (value) {
                  return value >= collectNotificationDate;
                }
                return schema;
              },
              message:
                "A data de saída deve ser maior que a data de notificação",
            });
          }
          return schema;
        }
      ),
    collectDate: Yup.date()
      .nullable()
      .default(undefined)
      .when("departureDate", (departureDate: any, schema: any) => {
        if (departureDate && typeof departureDate === "object") {
          return schema.test({
            test: function (value: any) {
              if (value) {
                return value >= departureDate;
              }
              return schema;
            },
            message: "A data de coleta deve ser maior que a data de saída",
          });
        }
        return schema;
      })
      .when("departureDate", (departureDate: any, schema: any) => {
        return schema.test({
          test: function (value: any) {
            if (value && !departureDate) {
              return false;
            }
            return schema;
          },
          message:
            "Preencha a data de saída antes de informar a data da coleta",
        });
      }),
    deliveryDate: Yup.date()
      .nullable()
      .default(undefined)
      .when("collectDate", (collectDate: any, schema: any) => {
        if (collectDate && typeof collectDate === "object") {
          return schema.test({
            test: function (value: any) {
              if (value) {
                return value >= collectDate;
              }
              return schema;
            },
            message: "A data da entrega deve ser maior que a data da coleta",
          });
        }
        return schema;
      })
      .when("collectDate", (collectDate: any, schema: any) => {
        return schema.test({
          test: function (value: any) {
            if (value && !collectDate) {
              return false;
            }
            return schema;
          },
          message:
            "Preencha a data da coleta antes de informar a data da entrega",
        });
      }),
  };
};

/**VALIDAÇÃO DATA */

export const getCollectDateShape = () => {
  // Prazo oficial nunca maior que prazo transportadora; [ok]
  // Prazo transportadora nunca menor que prazo oficial e data de criação da coleta; [ok]
  // Prazo de entrega nunca menor prazo para coleta; [ok]

  // Data de saída nunca menor que data de criação da coleta e a data da notificação;
  // Data de coleta nunca menor que data de saída;
  // Data de entrega nunca menor que data de coleta;

  return {
    departureDate: Yup.date()
      .nullable()
      .default(undefined)
      .transform((curr, orig) => orig === '' ? null : curr)
      .when("requestDate", (requestDate: any, schema: any) => {
        if (requestDate && typeof requestDate === "object") {
          return schema.test({
            test: function (value: any) {
              if (value) {
                return value >= requestDate;
              }
              return schema;
            },
            message: "A data de saída deve ser maior que a data de solicitação",
          });
        }
        return schema;
      })
      .when(
        "collectNotificationDate",
        (collectNotificationDate: any, schema: any) => {
          if (
            collectNotificationDate &&
            typeof collectNotificationDate === "object"
          ) {
            return schema.test({
              test: function (value: any) {
                if (value) {
                  return value >= collectNotificationDate;
                }
                return schema;
              },
              message:
                "A data de saída deve ser maior que a data de notificação",
            });
          }
          return schema;
        }
      ),
    collectDate: Yup.date()
      .nullable()
      .default(undefined)
      .transform((curr, orig) => orig === '' ? null : curr)
      .when("departureDate", (departureDate: any, schema: any) => {
        if (departureDate && typeof departureDate === "object") {
          return schema.test({
            test: function (value: any) {
              if (value) {
                return value >= departureDate;
              }
              return schema;
            },
            message: "A data de coleta deve ser maior que a data de saída",
          });
        }
        return schema;
      })
      .when("departureDate", (departureDate: any, schema: any) => {
        return schema.test({
          test: function (value: any) {
            if (value && !departureDate) {
              return false;
            }
            return schema;
          },
          message:
            "Preencha a data de saída antes de informar a data da coleta",
        });
      }),
    deliveryDate: Yup.date()
      .nullable()
      .default(undefined)
      .transform((curr, orig) => orig === '' ? null : curr)
      .when("collectDate", (collectDate: any, schema: any) => {
        if (collectDate && typeof collectDate === "object") {
          return schema.test({
            test: function (value: any) {
              if (value) {
                return value >= collectDate;
              }
              return schema;
            },
            message: "A data da entrega deve ser maior que a data da coleta",
          });
        }
        return schema;
      })
      .when("collectDate", (collectDate: any, schema: any) => {
        return schema.test({
          test: function (value: any) {
            if (value && !collectDate) {
              return false;
            }
            return schema;
          },
          message:
            "Preencha a data da coleta antes de informar a data da entrega",
        });
      }),
  };
};

/**VALIDAÇÃO PRAZO */

export const getCollectDeadlineShape = () => {
  // Prazo oficial nunca maior que prazo transportadora; [ok]
  // Prazo transportadora nunca menor que prazo oficial e data de criação da coleta; [ok]
  // Prazo de entrega nunca menor prazo para coleta; [ok]

  // Data de saída nunca menor que data de criação da coleta e a data da notificação;
  // Data de coleta nunca menor que data de saída;
  // Data de entrega nunca menor que data de coleta;

  return {
    collectNotificationDate: Yup.date()
      .transform((curr, orig) => orig === '' ? null : curr)
      .nullable()
      .default(undefined)
      .required("Informe a data de notificação"),
    collectCarrierDeadline: Yup.date()
      .transform((curr, orig) => orig === '' ? null : curr)
      .nullable()
      .default(undefined)
      .required("Informe o prazo da transportadora")
      .when("requestDate", (requestDate: any, schema: any) => {
        if (requestDate && typeof requestDate === "object") {
          return schema.test({
            test: function (value: any) {
              return value >= requestDate;
            },
            message:
              "O prazo da transportadora deve ser maior que a data de solicitação",
          });
        }
        return schema;
      }),
    collectDeliveryDeadline: Yup.date()
      .transform((curr, orig) => orig === '' ? null : curr)
      .nullable()
      .default(undefined)
      .when(
        "collectCarrierDeadline",
        (collectCarrierDeadline: any, schema: any) => {
          if (
            collectCarrierDeadline &&
            typeof collectCarrierDeadline === "object"
          ) {
            return schema.test({
              test: function (value: any) {
                if (value) {
                  return value >= collectCarrierDeadline;
                }
                return schema;
              },
              message:
                "O prazo de entrega deve ser maior que o prazo da transportadora",
            });
          }
          return schema;
        }
      ),
  };
};
