import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  CarriersListPage,
  CarrierCreationPage,
  CarrierUpdatePage,
} from 'pages/Carriers';

const carriersRoutes = ({
  location,
  userBelongsToAnyOf,
  hasPermissionTo
}: RouterProps): RouteObject => ({
  path: 'configuracoes/transportadoras',
  element: hasPermissionTo(['CARRIERS.FINDCARRIERS']) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <CarriersListPage />,
    },
    {
      path: 'criar',
      element: <CarrierCreationPage />,
    },
    {
      path: ':id/editar',
      element: <CarrierUpdatePage />,
    },
  ],
});

export default carriersRoutes;
